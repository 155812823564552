import { History, State } from 'history';

// eslint-disable-next-line no-underscore-dangle, init-declarations
let _history: History;

const setHistory = (history: History) => {
    _history = history;
};

const navigate = (url: string, state?: State) => {
    _history.push(url, state);
};

const replace = (url: string, state?: State) => {
    _history.replace(url, state);
};

export const Router = {
    setHistory,
    navigate,
    replace,
};
