import { Moment } from 'moment';
import { PagedModelEntityModelUserAccountActionDTO } from 'src/generated-api-client';
import { userActionApi } from 'src/services/apiServices';
import { DEFAULT_DATE_FORMAT } from 'src/utils/DateHelper';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type ActionLogsStore = {
    id?: number;
    userFullName?: string;
    portalTypes?: Array<'BO' | 'SME'>;
    requestMethods?: Array<
        | 'GET'
        | 'HEAD'
        | 'POST'
        | 'PUT'
        | 'PATCH'
        | 'DELETE'
        | 'OPTIONS'
        | 'TRACE'
    >;
    url?: string;
    ip?: string;
    dateFrom?: Moment;
    dateTo?: Moment;
    page?: number;
    count?: number;
    countDistinct?: boolean;
    display?: boolean;
    sortFields?: Array<string>;
    sortOrder?: string;
};

export class ActionLogsStoreClass extends BasicStore<
    PagedModelEntityModelUserAccountActionDTO,
    ActionLogsStore
> {
    api: BasicStoreApi<PagedModelEntityModelUserAccountActionDTO> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                userActionApi.entityList1(
                    this.filterCriteria.filter.id,
                    this.filterCriteria.filter.userFullName,
                    this.filterCriteria.filter.portalTypes,
                    this.filterCriteria.filter.requestMethods,
                    this.filterCriteria.filter.url,
                    this.filterCriteria.filter.ip,
                    this.filterCriteria.filter.dateFrom?.format(
                        DEFAULT_DATE_FORMAT,
                    ),
                    this.filterCriteria.filter.dateTo?.format(
                        DEFAULT_DATE_FORMAT,
                    ),
                    this.pager?.page,
                    this.pager?.size,
                    this.filterCriteria.filter.countDistinct,
                    this.filterCriteria.filter.display,
                    this.sorter?.getSort(),
                    SorterDirectionToServerSortDirectionMap[
                        this.sorter?.order || 'descend'
                    ],
                ),
            );
            this.pager?.setTotal(result.page?.totalElements as number);

            return result.content || [];
        },
    };

    filterCriteria: FilterCriteria<ActionLogsStore> = new FilterCriteria();
    sorter?: Sorter<any> | undefined =
        new Sorter<PagedModelEntityModelUserAccountActionDTO>();

    pager?: Pager | undefined = new Pager();
}

export const ActionLogsStore = new ActionLogsStoreClass();

const SorterDirectionToServerSortDirectionMap = {
    ascend: 'ASC',
    descend: 'DESC',
};
