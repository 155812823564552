/* eslint-disable sonarjs/no-identical-functions */
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { LoanApplicationRatioFeature } from 'src/generated-api-client';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';

import { ApplicationsInsertFinancialsHeader } from '../Components';
import { FinancialAnalysisDataElement } from './FinancialAnalysisDataElement';

const store = ApplicationsStore;

const profitablitilyRatios = [
    {
        ratioKey: 'ebidta',
        label: 'Page.Application.Details.FinancialAnalysis.Ratio.Label.Ebidta',
    },
    {
        ratioKey: 'netProfit',
        label: 'Page.Application.Details.FinancialAnalysis.Ratio.Label.NetProfit',
    },
    {
        ratioKey: 'operationProfit',
        label: 'Page.Application.Details.FinancialAnalysis.Ratio.Label.OperationProfit',
    },
    {
        ratioKey: 'grossProfit',
        label: 'Page.Application.Details.FinancialAnalysis.Ratio.Label.GrossProfit',
    },
];

const liquidityRatios = [
    {
        ratioKey: 'currentRatio',
        label: 'Page.Application.Details.FinancialAnalysis.Ratio.Label.CurrentRatio',
    },
    {
        ratioKey: 'acidTestQuickRatio',
        label: 'Page.Application.Details.FinancialAnalysis.Ratio.Label.AcidTestQuickRatio',
    },
    {
        ratioKey: 'debtorCollectionPeriod',
        label: 'Page.Application.Details.FinancialAnalysis.Ratio.Label.DebtorsCollectionPeriod',
    },
    {
        ratioKey: 'creditorPaymentPeriod',
        label: 'Page.Application.Details.FinancialAnalysis.Ratio.Label.CreditorsPaymentPeriod',
    },
    {
        ratioKey: 'stockTurnoverPeriod',
        label: 'Page.Application.Details.FinancialAnalysis.Ratio.Label.StockTurnoverPeriod',
    },
];

export const FinancialAnalysis = observer(() => {
    const { t } = useTranslation();

    const currentApplicationRatio = store.currentItem?.data?.ratio;

    return (
        <>
            <Row>
                <BlockTitle>
                    {t(
                        'Page.Application.Details.FinancialAnalysis.TitleProfitablitilyRatios',
                    )}
                </BlockTitle>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <ApplicationsInsertFinancialsHeader isBottomBorder />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    {profitablitilyRatios.map((item) => (
                        <FinancialAnalysisDataElement
                            ratioItem={
                                currentApplicationRatio?.[
                                    item.ratioKey as keyof typeof currentApplicationRatio
                                ] as LoanApplicationRatioFeature
                            }
                            title={item.label}
                        />
                    ))}
                </Col>
            </Row>
            <Row>
                <BlockTitle>
                    {t(
                        'Page.Application.Details.FinancialAnalysis.LiquidityRatios',
                    )}
                </BlockTitle>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <ApplicationsInsertFinancialsHeader isBottomBorder />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    {liquidityRatios.map((item) => (
                        <FinancialAnalysisDataElement
                            ratioItem={
                                currentApplicationRatio?.[
                                    item.ratioKey as keyof typeof currentApplicationRatio
                                ] as LoanApplicationRatioFeature
                            }
                            title={item.label}
                        />
                    ))}
                </Col>
            </Row>
        </>
    );
});
