import { Table, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { DisbursementAccountStatistic } from 'src/generated-api-client';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const NullView = 'N/A';
const { Text } = Typography;

const StyledTable = styled(Table)`
    margin: 20px 30px 0;
    .ant-table-thead {
        .ant-table-cell {
            background: transparent;
            font-weight: 600;
        }
        .ant-table-cell::before {
            display: none;
        }
    }
    .ant-table-tbody {
        .ant-table-cell {
            color: #000000;
            font-size: 15px;
        }
    }
    .ant-table-summary {
        background: #bfbfbf5c;
    }
`;

const columns = [
    {
        dataIndex: 'disbursementAccountName',
        key: 'disbursementAccountName',
        render: (disbursementAccountName: string, row: any) => (
            <Text>{`${disbursementAccountName} / ${row.disbursementAccountNumber}`}</Text>
        ),
    },
    {
        title: <Trans i18nKey="Page.Statisc.Table.Header.Cost" />,
        dataIndex: 'costIncomeRation',
        key: 'costIncomeRation',
        width: 100,
        render: (costIncomeRation: any) => {
            if (costIncomeRation === null) {
                return NullView;
            }

            return <>{NumberHelper.formatToPercent(costIncomeRation)}</>;
        },
    },
    {
        title: <Trans i18nKey="Page.Statisc.Table.Header.Debts" />,
        dataIndex: 'combinedBadDebts',
        key: 'combinedBadDebts',
        render: (combinedBadDebts: any) => {
            if (combinedBadDebts === null) {
                return NullView;
            }

            return <>{NumberHelper.formatToPercent(combinedBadDebts)}</>;
        },
    },
    {
        title: <Trans i18nKey="Page.Statisc.Table.Header.Risk" />,
        dataIndex: 'portfolioAtRisk',
        key: 'portfolioAtRisk',
        render: (portfolioAtRisk: any) => {
            if (portfolioAtRisk === null) {
                return NullView;
            }

            return <>{NumberHelper.formatToPercent(portfolioAtRisk)}</>;
        },
    },
    {
        title: <Trans i18nKey="Page.Statisc.Table.Header.ServiceDebts" />,
        dataIndex: 'debtServiceCoverageRatio',
        key: 'debtServiceCoverageRatio',
        render: (debtServiceCoverageRatio: any) => {
            if (debtServiceCoverageRatio === null) {
                return NullView;
            }

            return (
                <>
                    {NumberHelper.formatThousandSeparator(
                        debtServiceCoverageRatio,
                    )}
                </>
            );
        },
    },
];

export const HeaderTable = observer(
    ({ dataSource }: { dataSource: DisbursementAccountStatistic[] }) => {
        return (
            <StyledTable
                pagination={false}
                columns={columns}
                dataSource={dataSource}
            />
        );
    },
);
