/* eslint-disable max-lines */
import { computed } from 'mobx';
import { Trans } from 'react-i18next';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { ApplicationsCCReviewPage } from 'src/pages/ApplicationsPage/ApplicationsCCReviewPage';
import { ApplicationsInsertFinancialsPage } from 'src/pages/ApplicationsPage/ApplicationsInsertFinancialsPage';
import { ApplicationsPage } from 'src/pages/ApplicationsPage/ApplicationsPage';
import { CustomersPage } from 'src/pages/CustomersPage/CustomersPage';
import { ImportDataPage } from 'src/pages/ImportDataPage/ImportDataPage';
import { IndexPage } from 'src/pages/IndexPage/IndexPage';
import { LoanProductsPage } from 'src/pages/LoanProductsPage/LoanProductsPage';
import { LoansPage } from 'src/pages/LoansPage/LoansPage';
import { NotificationsPage } from 'src/pages/NotificationsPage/NotificationsPage';
import { RolesPage } from 'src/pages/RolesPage/RolesPage';
import { StatisticsPage } from 'src/pages/StatisticsPage/StatisticsPage';
import { UsersPage } from 'src/pages/UsersPage/UsersPage';
import {
    isRouteGroup,
    RouteDefinition,
} from 'src/stores/RouterStore/RouterStore.types';

import { ActionLogsPage } from '../../pages/ActionLogsPage/ActionLogsPage';
import { SystemOperationsPage } from '../../pages/SystemOperationsPage/SystemOperationsPage';
import { UserPermission } from '../AppUserStore/UserPermissions';

export class RouterStoreClass {
    // eslint-disable-next-line no-underscore-dangle
    @computed private get routeDefinitions(): RouteDefinition[] {
        return [
            {
                key: RoutePaths.index,
                path: RoutePaths.index,
                Component: IndexPage,
                exact: true,
                hidden: true,
                available: true,
                permissions: [
                    UserPermission.ManageRole,
                    UserPermission.ManageUsers,
                    UserPermission.ManageLoanProduct,
                    UserPermission.ManageSME,
                    UserPermission.CreditCommittee,
                    UserPermission.LoanApplicationReviewer,
                    UserPermission.ManageLoans,
                    UserPermission.ManageNotifications,
                    UserPermission.StatisticObserve,
                    UserPermission.ImportData,
                    UserPermission.ManageSystemOperations,
                    UserPermission.ManageDocumentTemplates,
                ],
            },
            {
                key: RoutePaths.users,
                path: RoutePaths.users,
                Component: UsersPage,
                exact: false,
                menuTitle: () => (
                    <Trans i18nKey="Header.Security" defaults="Security" />
                ),
                permissions: [
                    UserPermission.ManageRole,
                    UserPermission.ManageUsers,
                ],
                children: [
                    {
                        menuTitle: () => (
                            <Trans i18nKey="Header.Users" defaults="Users" />
                        ),
                        key: RoutePaths.users,
                        path: RoutePaths.users,
                        Component: UsersPage,
                        exact: false,
                        permissions: [UserPermission.ManageUsers],
                    },
                    {
                        menuTitle: () => (
                            <Trans i18nKey="Header.Roles" defaults="Roles" />
                        ),
                        key: RoutePaths.roles,
                        path: RoutePaths.roles,
                        Component: RolesPage,
                        exact: false,
                        permissions: [UserPermission.ManageRole],
                    },
                ],
            },
            {
                menuTitle: () => (
                    <Trans
                        i18nKey="Header.LoanProducts"
                        defaults="Loan products"
                    />
                ),
                key: RoutePaths.loanProducts,
                path: RoutePaths.loanProducts,
                Component: LoanProductsPage,
                exact: false,
                permissions: [UserPermission.ManageLoanProduct],
            },
            {
                menuTitle: () => (
                    <Trans i18nKey="Header.Customers" defaults="Customers" />
                ),
                key: RoutePaths.customers,
                path: RoutePaths.customers,
                Component: CustomersPage,
                exact: false,
                permissions: [UserPermission.ManageSME],
            },
            {
                menuTitle: () => (
                    <Trans
                        i18nKey="Header.Applications"
                        defaults="Applications"
                    />
                ),
                key: RoutePaths.applications,
                path: RoutePaths.applications,
                Component: ApplicationsPage,
                exact: false,
                permissions: [
                    UserPermission.CreditCommittee,
                    UserPermission.LoanApplicationReviewer,
                ],
            },
            {
                key: RoutePaths.applicationsInsertFinancials,
                path: RoutePaths.applicationsInsertFinancials,
                Component: ApplicationsInsertFinancialsPage,
                exact: false,
                permissions: [
                    UserPermission.CreditCommittee,
                    UserPermission.LoanApplicationReviewer,
                ],
            },
            {
                key: RoutePaths.applicationsCCReviewPage,
                path: RoutePaths.applicationsCCReviewPage,
                Component: ApplicationsCCReviewPage,
                exact: false,
                permissions: [
                    UserPermission.CreditCommittee,
                    UserPermission.LoanApplicationReviewer,
                ],
            },
            {
                menuTitle: () => (
                    <Trans i18nKey="Header.Loans" defaults="Loans" />
                ),
                key: RoutePaths.loans,
                path: RoutePaths.loans,
                Component: LoansPage,
                exact: false,
                permissions: [UserPermission.ManageLoans],
            },
            {
                menuTitle: () => (
                    <Trans
                        i18nKey="Header.Notifications"
                        defaults="Notifications"
                    />
                ),
                key: RoutePaths.notifications,
                path: RoutePaths.notifications,
                Component: NotificationsPage,
                exact: false,
                permissions: [UserPermission.ManageNotifications],
            },
            {
                menuTitle: () => (
                    <Trans i18nKey="Header.Statistics" defaults="Statistics" />
                ),
                key: RoutePaths.statistics,
                path: RoutePaths.statistics,
                Component: StatisticsPage,
                exact: false,
                permissions: [UserPermission.StatisticObserve],
            },
            {
                menuTitle: () => (
                    <Trans i18nKey="Header.ImportData" defaults="Import data" />
                ),
                key: RoutePaths.importData,
                path: RoutePaths.importData,
                Component: ImportDataPage,
                exact: false,
                permissions: [UserPermission.ImportData],
            },
            {
                key: RoutePaths.systemOperations,
                path: RoutePaths.systemOperations,
                Component: SystemOperationsPage,
                exact: false,
                menuTitle: () => (
                    <Trans
                        i18nKey="Header.Administration"
                        defaults="Administration"
                    />
                ),
                permissions: [UserPermission.ManageSystemOperations],
                children: [
                    {
                        menuTitle: () => (
                            <Trans
                                i18nKey="Header.SystemOperations"
                                defaults="System operations"
                            />
                        ),
                        key: RoutePaths.systemOperations,
                        path: RoutePaths.systemOperations,
                        Component: SystemOperationsPage,
                        exact: false,
                        permissions: [UserPermission.ManageSystemOperations],
                    },
                    {
                        menuTitle: () => (
                            <Trans
                                i18nKey="Header.ActionLogs"
                                defaults="Action log"
                            />
                        ),
                        key: RoutePaths.userActions,
                        path: RoutePaths.userActions,
                        Component: ActionLogsPage,
                        exact: false,
                        permissions: [UserPermission.ManageSystemOperations],
                    },
                ],
            },
        ];
    }

    @computed get routes() {
        return this.routeDefinitions
            .flatMap((route) => {
                if (isRouteGroup(route)) {
                    return [route, ...(route.children || [])];
                }

                return route;
            })
            .filter(Boolean) as RouteDefinition[];
    }

    @computed get menuItems(): RouteDefinition[] {
        return this.routeDefinitions.filter((route) => route?.menuTitle);
    }
}

export const RouterStore = new RouterStoreClass();
