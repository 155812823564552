// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    buildingName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    city: string;
    /**
     * 
     * @type {CountryDTO}
     * @memberof AddressDTO
     */
    country: CountryDTO;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    house: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    postalCode: string;
    /**
     * 
     * @type {ProvinceDTO}
     * @memberof AddressDTO
     */
    province: ProvinceDTO;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    street: string;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyBackOfficeUserProfileDTO
 */
export interface ApiResponseBodyBackOfficeUserProfileDTO {
    /**
     * 
     * @type {BackOfficeUserProfileDTO}
     * @memberof ApiResponseBodyBackOfficeUserProfileDTO
     */
    data?: BackOfficeUserProfileDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyBackOfficeUserProfileDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyBackofficeRoleDTO
 */
export interface ApiResponseBodyBackofficeRoleDTO {
    /**
     * 
     * @type {BackofficeRoleDTO}
     * @memberof ApiResponseBodyBackofficeRoleDTO
     */
    data?: BackofficeRoleDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyBackofficeRoleDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyBackofficeUserDTO
 */
export interface ApiResponseBodyBackofficeUserDTO {
    /**
     * 
     * @type {BackofficeUserDTO}
     * @memberof ApiResponseBodyBackofficeUserDTO
     */
    data?: BackofficeUserDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyBackofficeUserDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyBaseLoanOperationParams
 */
export interface ApiResponseBodyBaseLoanOperationParams {
    /**
     * 
     * @type {BaseLoanOperationParams}
     * @memberof ApiResponseBodyBaseLoanOperationParams
     */
    data?: BaseLoanOperationParams;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyBaseLoanOperationParams
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyBoolean
 */
export interface ApiResponseBodyBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseBodyBoolean
     */
    data?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyBoolean
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyCreditCommitteeReviewInfoDTO
 */
export interface ApiResponseBodyCreditCommitteeReviewInfoDTO {
    /**
     * 
     * @type {CreditCommitteeReviewInfoDTO}
     * @memberof ApiResponseBodyCreditCommitteeReviewInfoDTO
     */
    data?: CreditCommitteeReviewInfoDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyCreditCommitteeReviewInfoDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyCsvLogDTO
 */
export interface ApiResponseBodyCsvLogDTO {
    /**
     * 
     * @type {CsvLogDTO}
     * @memberof ApiResponseBodyCsvLogDTO
     */
    data?: CsvLogDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyCsvLogDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyCustomerUserDTO
 */
export interface ApiResponseBodyCustomerUserDTO {
    /**
     * 
     * @type {CustomerUserDTO}
     * @memberof ApiResponseBodyCustomerUserDTO
     */
    data?: CustomerUserDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyCustomerUserDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyDocumentTemplateDTO
 */
export interface ApiResponseBodyDocumentTemplateDTO {
    /**
     * 
     * @type {DocumentTemplateDTO}
     * @memberof ApiResponseBodyDocumentTemplateDTO
     */
    data?: DocumentTemplateDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyDocumentTemplateDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListAuthorityItem
 */
export interface ApiResponseBodyListAuthorityItem {
    /**
     * 
     * @type {Array<AuthorityItem>}
     * @memberof ApiResponseBodyListAuthorityItem
     */
    data?: Array<AuthorityItem>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListAuthorityItem
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListContractSigningLog
 */
export interface ApiResponseBodyListContractSigningLog {
    /**
     * 
     * @type {Array<ContractSigningLog>}
     * @memberof ApiResponseBodyListContractSigningLog
     */
    data?: Array<ContractSigningLog>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListContractSigningLog
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListCreditBureauCheckLog
 */
export interface ApiResponseBodyListCreditBureauCheckLog {
    /**
     * 
     * @type {Array<CreditBureauCheckLog>}
     * @memberof ApiResponseBodyListCreditBureauCheckLog
     */
    data?: Array<CreditBureauCheckLog>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListCreditBureauCheckLog
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListDisbursementAccountStatistic
 */
export interface ApiResponseBodyListDisbursementAccountStatistic {
    /**
     * 
     * @type {Array<DisbursementAccountStatistic>}
     * @memberof ApiResponseBodyListDisbursementAccountStatistic
     */
    data?: Array<DisbursementAccountStatistic>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListDisbursementAccountStatistic
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListDocumentDTO
 */
export interface ApiResponseBodyListDocumentDTO {
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof ApiResponseBodyListDocumentDTO
     */
    data?: Array<DocumentDTO>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListDocumentDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListLoanApplicationListDTO
 */
export interface ApiResponseBodyListLoanApplicationListDTO {
    /**
     * 
     * @type {Array<LoanApplicationListDTO>}
     * @memberof ApiResponseBodyListLoanApplicationListDTO
     */
    data?: Array<LoanApplicationListDTO>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListLoanApplicationListDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListLoanSnapshotDTO
 */
export interface ApiResponseBodyListLoanSnapshotDTO {
    /**
     * 
     * @type {Array<LoanSnapshotDTO>}
     * @memberof ApiResponseBodyListLoanSnapshotDTO
     */
    data?: Array<LoanSnapshotDTO>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListLoanSnapshotDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListLoanViewDTO
 */
export interface ApiResponseBodyListLoanViewDTO {
    /**
     * 
     * @type {Array<LoanViewDTO>}
     * @memberof ApiResponseBodyListLoanViewDTO
     */
    data?: Array<LoanViewDTO>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListLoanViewDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListSystemOperationType
 */
export interface ApiResponseBodyListSystemOperationType {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiResponseBodyListSystemOperationType
     */
    data?: Array<ApiResponseBodyListSystemOperationTypeDataEnum>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListSystemOperationType
     */
    status?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiResponseBodyListSystemOperationTypeDataEnum {
    DAILYLOANCALCULATION = 'DAILY_LOAN_CALCULATION',
    FULLLOANRECALCULATION = 'FULL_LOAN_RECALCULATION',
    RECREATELOANSFROMAPPLICATION = 'RECREATE_LOANS_FROM_APPLICATION',
    SENDNOTIFICATIONSFORWEEKPAYMENT = 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT',
    SENDNOTIFICATIONSFORTOMORROWPAYMENT = 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT',
    SENDNOTIFICATIONSFOR1DAYOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR5DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR7DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR90DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN',
    REPORTGENERATION = 'REPORT_GENERATION',
    CREDITBUREAUCHECKRESTARTFAILEDLOGS = 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS',
    DELETECUSTOMERSWITHNOTCOMPLETEDREGISTRATION = 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION',
    RETRIEVEFAILEDNOTIFICATIONS = 'RETRIEVE_FAILED_NOTIFICATIONS',
    CLEARNOTIFICATIONLOG = 'CLEAR_NOTIFICATION_LOG',
    REPROCESSAPPLICATIONSFORREVIEWEDCUSTOMERS = 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'
}

/**
 * 
 * @export
 * @interface ApiResponseBodyLoanApplicationViewDTO
 */
export interface ApiResponseBodyLoanApplicationViewDTO {
    /**
     * 
     * @type {LoanApplicationViewDTO}
     * @memberof ApiResponseBodyLoanApplicationViewDTO
     */
    data?: LoanApplicationViewDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyLoanApplicationViewDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyLoanProductDTO
 */
export interface ApiResponseBodyLoanProductDTO {
    /**
     * 
     * @type {LoanProductDTO}
     * @memberof ApiResponseBodyLoanProductDTO
     */
    data?: LoanProductDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyLoanProductDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyLoanViewDTO
 */
export interface ApiResponseBodyLoanViewDTO {
    /**
     * 
     * @type {LoanViewDTO}
     * @memberof ApiResponseBodyLoanViewDTO
     */
    data?: LoanViewDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyLoanViewDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyMapLongString
 */
export interface ApiResponseBodyMapLongString {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiResponseBodyMapLongString
     */
    data?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyMapLongString
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyNotificationDTO
 */
export interface ApiResponseBodyNotificationDTO {
    /**
     * 
     * @type {NotificationDTO}
     * @memberof ApiResponseBodyNotificationDTO
     */
    data?: NotificationDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyNotificationDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyReportDTO
 */
export interface ApiResponseBodyReportDTO {
    /**
     * 
     * @type {ReportDTO}
     * @memberof ApiResponseBodyReportDTO
     */
    data?: ReportDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyReportDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyString
 */
export interface ApiResponseBodyString {
    /**
     * 
     * @type {string}
     * @memberof ApiResponseBodyString
     */
    data?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyString
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodySystemOperationExecutionHistoryDTO
 */
export interface ApiResponseBodySystemOperationExecutionHistoryDTO {
    /**
     * 
     * @type {SystemOperationExecutionHistoryDTO}
     * @memberof ApiResponseBodySystemOperationExecutionHistoryDTO
     */
    data?: SystemOperationExecutionHistoryDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodySystemOperationExecutionHistoryDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyUserAccountActionDTO
 */
export interface ApiResponseBodyUserAccountActionDTO {
    /**
     * 
     * @type {UserAccountActionDTO}
     * @memberof ApiResponseBodyUserAccountActionDTO
     */
    data?: UserAccountActionDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyUserAccountActionDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApprovedConditionForm
 */
export interface ApprovedConditionForm {
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof ApprovedConditionForm
     */
    approvedTerm: LoanApplicationTerm;
    /**
     * 
     * @type {Array<DisbursementBankAccountInfoForm>}
     * @memberof ApprovedConditionForm
     */
    disbursementBankAccountInfos: Array<DisbursementBankAccountInfoForm>;
    /**
     * 
     * @type {number}
     * @memberof ApprovedConditionForm
     */
    financedPercentage: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof ApprovedConditionForm
     */
    holidayTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {number}
     * @memberof ApprovedConditionForm
     */
    loanProductId: number;
    /**
     * 
     * @type {string}
     * @memberof ApprovedConditionForm
     */
    precedentConditions?: string;
}
/**
 * 
 * @export
 * @interface Authentication
 */
export interface Authentication {
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    realm?: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface AuthorityItem
 */
export interface AuthorityItem {
    /**
     * 
     * @type {string}
     * @memberof AuthorityItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorityItem
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface BackOfficeUserProfileDTO
 */
export interface BackOfficeUserProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUserProfileDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUserProfileDTO
     */
    fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof BackOfficeUserProfileDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUserProfileDTO
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUserProfileDTO
     */
    phone?: string;
    /**
     * 
     * @type {BackofficeRoleDTO}
     * @memberof BackOfficeUserProfileDTO
     */
    role?: BackofficeRoleDTO;
    /**
     * 
     * @type {number}
     * @memberof BackOfficeUserProfileDTO
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUserProfileDTO
     */
    roleName?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof BackOfficeUserProfileDTO
     */
    status?: UserStatus;
}
/**
 * 
 * @export
 * @interface BackofficeRoleDTO
 */
export interface BackofficeRoleDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof BackofficeRoleDTO
     */
    authorities?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BackofficeRoleDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof BackofficeRoleDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BackofficeRoleDTO
     */
    name?: string;
    /**
     * 
     * @type {StartPage}
     * @memberof BackofficeRoleDTO
     */
    startPage?: StartPage;
}
/**
 * 
 * @export
 * @interface BackofficeUserDTO
 */
export interface BackofficeUserDTO {
    /**
     * 
     * @type {string}
     * @memberof BackofficeUserDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BackofficeUserDTO
     */
    fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof BackofficeUserDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BackofficeUserDTO
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof BackofficeUserDTO
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof BackofficeUserDTO
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof BackofficeUserDTO
     */
    roleName?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof BackofficeUserDTO
     */
    status?: UserStatus;
}
/**
 * 
 * @export
 * @interface BankAccountDTO
 */
export interface BankAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof BankAccountDTO
     */
    accountName: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDTO
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDTO
     */
    accountType: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDTO
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDTO
     */
    branchCode: string;
    /**
     * 
     * @type {number}
     * @memberof BankAccountDTO
     */
    id?: number;
    /**
     * 
     * @type {BankAccountType}
     * @memberof BankAccountDTO
     */
    type?: BankAccountType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BankAccountType {
    CUSTOMER = 'CUSTOMER',
    DISBURSEMENT = 'DISBURSEMENT',
    THIRDPARTY = 'THIRD_PARTY'
}

/**
 * 
 * @export
 * @interface BaseLoanOperationParams
 */
export interface BaseLoanOperationParams {
    /**
     * 
     * @type {number}
     * @memberof BaseLoanOperationParams
     */
    loanId: number;
    /**
     * 
     * @type {string}
     * @memberof BaseLoanOperationParams
     */
    operationType: BaseLoanOperationParamsOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BaseLoanOperationParams
     */
    reason: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BaseLoanOperationParamsOperationTypeEnum {
    DISBURSEMENT = 'DISBURSEMENT',
    PAYMENT = 'PAYMENT',
    WRITEOFF = 'WRITE_OFF'
}

/**
 * 
 * @export
 * @interface ConstraintViolationProblem
 */
export interface ConstraintViolationProblem {
    /**
     * 
     * @type {ThrowableProblem}
     * @memberof ConstraintViolationProblem
     */
    cause?: ThrowableProblem;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    instance?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    message?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ConstraintViolationProblem
     */
    parameters?: { [key: string]: object; };
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ConstraintViolationProblem
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
    /**
     * 
     * @type {StatusType}
     * @memberof ConstraintViolationProblem
     */
    status?: StatusType;
    /**
     * 
     * @type {Array<ConstraintViolationProblemSuppressed>}
     * @memberof ConstraintViolationProblem
     */
    suppressed?: Array<ConstraintViolationProblemSuppressed>;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    type?: string;
    /**
     * 
     * @type {Array<Violation>}
     * @memberof ConstraintViolationProblem
     */
    violations?: Array<Violation>;
}
/**
 * 
 * @export
 * @interface ConstraintViolationProblemCause
 */
export interface ConstraintViolationProblemCause {
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemCause
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemCause
     */
    message?: string;
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ConstraintViolationProblemCause
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
}
/**
 * 
 * @export
 * @interface ConstraintViolationProblemStackTrace
 */
export interface ConstraintViolationProblemStackTrace {
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    classLoaderName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ConstraintViolationProblemStackTrace
     */
    lineNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    methodName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    moduleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    moduleVersion?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConstraintViolationProblemStackTrace
     */
    nativeMethod?: boolean;
}
/**
 * 
 * @export
 * @interface ConstraintViolationProblemSuppressed
 */
export interface ConstraintViolationProblemSuppressed {
    /**
     * 
     * @type {ConstraintViolationProblemCause}
     * @memberof ConstraintViolationProblemSuppressed
     */
    cause?: ConstraintViolationProblemCause;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemSuppressed
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemSuppressed
     */
    message?: string;
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ConstraintViolationProblemSuppressed
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ContentType {
    HTML = 'HTML',
    PLAINTEXT = 'PLAIN_TEXT'
}

/**
 * 
 * @export
 * @interface ContractSigningLog
 */
export interface ContractSigningLog {
    /**
     * 
     * @type {string}
     * @memberof ContractSigningLog
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractSigningLog
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractSigningLog
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ContractSigningLog
     */
    loanApplicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractSigningLog
     */
    requestedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractSigningLog
     */
    status?: ContractSigningLogStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ContractSigningLog
     */
    step?: ContractSigningLogStepEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ContractSigningLogStatusEnum {
    INPROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum ContractSigningLogStepEnum {
    CREATINGDOCUMENT = 'CREATING_DOCUMENT',
    SENDINGDOCUMENT = 'SENDING_DOCUMENT',
    CREATINGCUSTOMERSESSION = 'CREATING_CUSTOMER_SESSION',
    CREATINGTHIRDPARTYSESSION = 'CREATING_THIRD_PARTY_SESSION',
    SENDINGTHIRDPARTYEMAIL = 'SENDING_THIRD_PARTY_EMAIL',
    COMPLETESIGNING = 'COMPLETE_SIGNING',
    CHANGEDOCUMENTSTATUSTODECLINED = 'CHANGE_DOCUMENT_STATUS_TO_DECLINED'
}

/**
 * 
 * @export
 * @interface CountryDTO
 */
export interface CountryDTO {
    /**
     * 
     * @type {number}
     * @memberof CountryDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CreateBackofficeRoleForm
 */
export interface CreateBackofficeRoleForm {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBackofficeRoleForm
     */
    authorities: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateBackofficeRoleForm
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackofficeRoleForm
     */
    name: string;
    /**
     * 
     * @type {StartPage}
     * @memberof CreateBackofficeRoleForm
     */
    startPage: StartPage;
}
/**
 * 
 * @export
 * @interface CreateBackofficeUserForm
 */
export interface CreateBackofficeUserForm {
    /**
     * 
     * @type {string}
     * @memberof CreateBackofficeUserForm
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackofficeUserForm
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackofficeUserForm
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackofficeUserForm
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackofficeUserForm
     */
    passwordConfirmation: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackofficeUserForm
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBackofficeUserForm
     */
    roleId: number;
}
/**
 * 
 * @export
 * @interface CreateLoanProductForm
 */
export interface CreateLoanProductForm {
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    initiationFee: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    insuranceRateMultiplier: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    interestRate: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    interestRateRestriction: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    monthlyFee: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    penaltyInterestMultiplier: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLoanProductForm
     */
    productName: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    vatRate: number;
}
/**
 * 
 * @export
 * @interface CreateNotificationForm
 */
export interface CreateNotificationForm {
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationForm
     */
    content: string;
    /**
     * 
     * @type {ContentType}
     * @memberof CreateNotificationForm
     */
    contentType?: ContentType;
    /**
     * 
     * @type {NotificationStatus}
     * @memberof CreateNotificationForm
     */
    status: NotificationStatus;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationForm
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationForm
     */
    title: string;
    /**
     * 
     * @type {NotificationTriggerType}
     * @memberof CreateNotificationForm
     */
    triggerType: NotificationTriggerType;
    /**
     * 
     * @type {NotificationType}
     * @memberof CreateNotificationForm
     */
    type: NotificationType;
}
/**
 * 
 * @export
 * @interface CreditBureauCheckLog
 */
export interface CreditBureauCheckLog {
    /**
     * 
     * @type {string}
     * @memberof CreditBureauCheckLog
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditBureauCheckLog
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditBureauCheckLog
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditBureauCheckLog
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditBureauCheckLog
     */
    itNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditBureauCheckLog
     */
    loanApplicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditBureauCheckLog
     */
    requestedAt?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreditBureauCheckLog
     */
    responseProductCodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreditBureauCheckLog
     */
    status?: CreditBureauCheckLogStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreditBureauCheckLog
     */
    step?: CreditBureauCheckLogStepEnum;
    /**
     * 
     * @type {string}
     * @memberof CreditBureauCheckLog
     */
    ticketNumber?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreditBureauCheckLogStatusEnum {
    INPROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum CreditBureauCheckLogStepEnum {
    COMMERCIAL = 'COMMERCIAL',
    REPORT = 'REPORT'
}

/**
 * 
 * @export
 * @interface CreditCommitteeReviewInfoDTO
 */
export interface CreditCommitteeReviewInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof CreditCommitteeReviewInfoDTO
     */
    applicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditCommitteeReviewInfoDTO
     */
    contrOffer?: string;
    /**
     * 
     * @type {Array<BankAccountDTO>}
     * @memberof CreditCommitteeReviewInfoDTO
     */
    disbursementBankAccounts?: Array<BankAccountDTO>;
    /**
     * 
     * @type {number}
     * @memberof CreditCommitteeReviewInfoDTO
     */
    disclosedLoansExposure?: number;
    /**
     * 
     * @type {Array<LoanApplicationFinancedItemDTO>}
     * @memberof CreditCommitteeReviewInfoDTO
     */
    financedItems?: Array<LoanApplicationFinancedItemDTO>;
    /**
     * 
     * @type {number}
     * @memberof CreditCommitteeReviewInfoDTO
     */
    overdueDisclosedLoansExposure?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCommitteeReviewInfoDTO
     */
    overdueUndisclosedLoansExposure?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCommitteeReviewInfoDTO
     */
    totalLoansExposure?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCommitteeReviewInfoDTO
     */
    totalOverdueLoansExposure?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCommitteeReviewInfoDTO
     */
    undisclosedLoansExposure?: number;
}
/**
 * 
 * @export
 * @interface CsvLogDTO
 */
export interface CsvLogDTO {
    /**
     * 
     * @type {string}
     * @memberof CsvLogDTO
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvLogDTO
     */
    createdBy?: string;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof CsvLogDTO
     */
    documents?: Array<DocumentDTO>;
    /**
     * 
     * @type {string}
     * @memberof CsvLogDTO
     */
    error?: string;
    /**
     * 
     * @type {number}
     * @memberof CsvLogDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CsvLogDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvLogDTO
     */
    pojoClassName?: string;
    /**
     * 
     * @type {string}
     * @memberof CsvLogDTO
     */
    status?: CsvLogDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CsvLogDTO
     */
    type?: CsvLogDTOTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CsvLogDTOStatusEnum {
    SUCCESSFUL = 'SUCCESSFUL',
    FAILURE = 'FAILURE'
}
/**
    * @export
    * @enum {string}
    */
export enum CsvLogDTOTypeEnum {
    IMPORT = 'IMPORT',
    EXPORT = 'EXPORT'
}

/**
 * 
 * @export
 * @interface CustomerContactDetailsDto
 */
export interface CustomerContactDetailsDto {
    /**
     * 
     * @type {AddressDTO}
     * @memberof CustomerContactDetailsDto
     */
    address: AddressDTO;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetailsDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetailsDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetailsDto
     */
    representativeEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetailsDto
     */
    representativeFullName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetailsDto
     */
    representativePhoneNumber?: string;
}
/**
 * 
 * @export
 * @interface CustomerUserDTO
 */
export interface CustomerUserDTO {
    /**
     * 
     * @type {Array<BankAccountDTO>}
     * @memberof CustomerUserDTO
     */
    bankAccounts: Array<BankAccountDTO>;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserDTO
     */
    cipc?: string;
    /**
     * 
     * @type {CustomerContactDetailsDto}
     * @memberof CustomerUserDTO
     */
    contactDetails: CustomerContactDetailsDto;
    /**
     * 
     * @type {number}
     * @memberof CustomerUserDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserDTO
     */
    lastActivityAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserDTO
     */
    legalName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserDTO
     */
    taxId: string;
    /**
     * 
     * @type {CustomerVerificationStatus}
     * @memberof CustomerUserDTO
     */
    verificationStatus?: CustomerVerificationStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerVerificationStatus {
    WAITDOCUMENTS = 'WAIT_DOCUMENTS',
    CREDITBUREAUCHECK = 'CREDIT_BUREAU_CHECK',
    INREVIEW = 'IN_REVIEW',
    VERIFIED = 'VERIFIED',
    DECLINED = 'DECLINED',
    REJECTEDBYCREDITBUREAU = 'REJECTED_BY_CREDIT_BUREAU',
    INFOREQUESTED = 'INFO_REQUESTED'
}

/**
 * 
 * @export
 * @interface DisbursementAccountStatistic
 */
export interface DisbursementAccountStatistic {
    /**
     * 
     * @type {number}
     * @memberof DisbursementAccountStatistic
     */
    combinedBadDebts?: number;
    /**
     * 
     * @type {number}
     * @memberof DisbursementAccountStatistic
     */
    costIncomeRation?: number;
    /**
     * 
     * @type {number}
     * @memberof DisbursementAccountStatistic
     */
    debtServiceCoverageRatio?: number;
    /**
     * 
     * @type {string}
     * @memberof DisbursementAccountStatistic
     */
    disbursementAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof DisbursementAccountStatistic
     */
    disbursementAccountNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof DisbursementAccountStatistic
     */
    portfolioAtRisk?: number;
}
/**
 * 
 * @export
 * @interface DisbursementBankAccountInfoDTO
 */
export interface DisbursementBankAccountInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof DisbursementBankAccountInfoDTO
     */
    accountAllocationPercentage?: number;
    /**
     * 
     * @type {BankAccountDTO}
     * @memberof DisbursementBankAccountInfoDTO
     */
    bankAccount?: BankAccountDTO;
    /**
     * 
     * @type {number}
     * @memberof DisbursementBankAccountInfoDTO
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface DisbursementBankAccountInfoForm
 */
export interface DisbursementBankAccountInfoForm {
    /**
     * 
     * @type {number}
     * @memberof DisbursementBankAccountInfoForm
     */
    accountAllocationPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof DisbursementBankAccountInfoForm
     */
    bankAccountId: number;
}
/**
 * 
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    documentId?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    documentType?: DocumentDTODocumentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    fileType?: DocumentDTOFileTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentDTODocumentTypeEnum {
    CUSTOMERCIPC = 'CUSTOMER_CIPC',
    CUSTOMERTAXCLEARANCE = 'CUSTOMER_TAX_CLEARANCE',
    CUSTOMERPROOFOFADDRESS = 'CUSTOMER_PROOF_OF_ADDRESS',
    CUSTOMERDIRECTORIDS = 'CUSTOMER_DIRECTOR_IDS',
    CUSTOMERCREDITBUREAU = 'CUSTOMER_CREDIT_BUREAU',
    CUSTOMERSOLVENCY = 'CUSTOMER_SOLVENCY',
    CUSTOMERAUTHORIZATIONLETTER = 'CUSTOMER_AUTHORIZATION_LETTER',
    LOANAPPLICATIONMAINCONTRACT = 'LOAN_APPLICATION_MAIN_CONTRACT',
    LOANAPPLICATIONDEBTORSCONTRACT = 'LOAN_APPLICATION_DEBTORS_CONTRACT',
    LOANAPPLICATIONFINANCIALSTATEMENT = 'LOAN_APPLICATION_FINANCIAL_STATEMENT',
    LOANAPPLICATIONPURCHASEORDER = 'LOAN_APPLICATION_PURCHASE_ORDER',
    LOANAPPLICATIONPURCHASEORDERCONTRACT = 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT',
    LOANAPPLICATIONPROFITANDLOSSBUDGET = 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET',
    LOANAPPLICATIONACCOUNTSAGING = 'LOAN_APPLICATION_ACCOUNTS_AGING',
    LOANAPPLICATIONTAXINVOICE = 'LOAN_APPLICATION_TAX_INVOICE',
    LOANAPPLICATIONINVOICECONTRACT = 'LOAN_APPLICATION_INVOICE_CONTRACT',
    LOANAPPLICATIONBANKSTATEMENT = 'LOAN_APPLICATION_BANK_STATEMENT',
    LOANAPPLICATIONTHIRDPARTYPROOFLETTER = 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER',
    LOANAPPLICATIONQUOTATION = 'LOAN_APPLICATION_QUOTATION',
    LOANAPPLICATIONDELIVERYNOTE = 'LOAN_APPLICATION_DELIVERY_NOTE'
}
/**
    * @export
    * @enum {string}
    */
export enum DocumentDTOFileTypeEnum {
    PDF = 'PDF',
    TXT = 'TXT',
    HTML = 'HTML',
    JPEG = 'JPEG',
    JPG = 'JPG',
    PNG = 'PNG',
    ZIP = 'ZIP',
    RAR = 'RAR',
    DOC = 'DOC',
    DOCX = 'DOCX',
    XLS = 'XLS',
    XLSX = 'XLSX',
    CSV = 'CSV',
    RTF = 'RTF',
    SVG = 'SVG',
    PPT = 'PPT',
    PPTX = 'PPTX'
}

/**
 * 
 * @export
 * @interface DocumentTemplateDTO
 */
export interface DocumentTemplateDTO {
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDTO
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDTO
     */
    footer?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDTO
     */
    header?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplateDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDTO
     */
    outputFormat?: DocumentTemplateDTOOutputFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDTO
     */
    title?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentTemplateDTOOutputFormatEnum {
    TXT = 'TXT',
    HTML = 'HTML',
    PDF = 'PDF'
}

/**
 * 
 * @export
 * @interface EntityModelBackofficeRoleDTO
 */
export interface EntityModelBackofficeRoleDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityModelBackofficeRoleDTO
     */
    authorities?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelBackofficeRoleDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelBackofficeRoleDTO
     */
    id?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelBackofficeRoleDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelBackofficeRoleDTO
     */
    name?: string;
    /**
     * 
     * @type {StartPage}
     * @memberof EntityModelBackofficeRoleDTO
     */
    startPage?: StartPage;
}
/**
 * 
 * @export
 * @interface EntityModelBackofficeUserDTO
 */
export interface EntityModelBackofficeUserDTO {
    /**
     * 
     * @type {string}
     * @memberof EntityModelBackofficeUserDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelBackofficeUserDTO
     */
    fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelBackofficeUserDTO
     */
    id?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelBackofficeUserDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelBackofficeUserDTO
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelBackofficeUserDTO
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelBackofficeUserDTO
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelBackofficeUserDTO
     */
    roleName?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof EntityModelBackofficeUserDTO
     */
    status?: UserStatus;
}
/**
 * 
 * @export
 * @interface EntityModelCsvLogDTO
 */
export interface EntityModelCsvLogDTO {
    /**
     * 
     * @type {string}
     * @memberof EntityModelCsvLogDTO
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelCsvLogDTO
     */
    createdBy?: string;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof EntityModelCsvLogDTO
     */
    documents?: Array<DocumentDTO>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelCsvLogDTO
     */
    error?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelCsvLogDTO
     */
    id?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelCsvLogDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelCsvLogDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelCsvLogDTO
     */
    pojoClassName?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelCsvLogDTO
     */
    status?: EntityModelCsvLogDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelCsvLogDTO
     */
    type?: EntityModelCsvLogDTOTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelCsvLogDTOStatusEnum {
    SUCCESSFUL = 'SUCCESSFUL',
    FAILURE = 'FAILURE'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelCsvLogDTOTypeEnum {
    IMPORT = 'IMPORT',
    EXPORT = 'EXPORT'
}

/**
 * 
 * @export
 * @interface EntityModelCustomerUserDTO
 */
export interface EntityModelCustomerUserDTO {
    /**
     * 
     * @type {Array<BankAccountDTO>}
     * @memberof EntityModelCustomerUserDTO
     */
    bankAccounts: Array<BankAccountDTO>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelCustomerUserDTO
     */
    cipc?: string;
    /**
     * 
     * @type {CustomerContactDetailsDto}
     * @memberof EntityModelCustomerUserDTO
     */
    contactDetails: CustomerContactDetailsDto;
    /**
     * 
     * @type {number}
     * @memberof EntityModelCustomerUserDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelCustomerUserDTO
     */
    lastActivityAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelCustomerUserDTO
     */
    legalName: string;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelCustomerUserDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelCustomerUserDTO
     */
    taxId: string;
    /**
     * 
     * @type {CustomerVerificationStatus}
     * @memberof EntityModelCustomerUserDTO
     */
    verificationStatus?: CustomerVerificationStatus;
}
/**
 * 
 * @export
 * @interface EntityModelDocumentTemplateDTO
 */
export interface EntityModelDocumentTemplateDTO {
    /**
     * 
     * @type {string}
     * @memberof EntityModelDocumentTemplateDTO
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelDocumentTemplateDTO
     */
    footer?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelDocumentTemplateDTO
     */
    header?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelDocumentTemplateDTO
     */
    id?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelDocumentTemplateDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelDocumentTemplateDTO
     */
    outputFormat?: EntityModelDocumentTemplateDTOOutputFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelDocumentTemplateDTO
     */
    title?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelDocumentTemplateDTOOutputFormatEnum {
    TXT = 'TXT',
    HTML = 'HTML',
    PDF = 'PDF'
}

/**
 * 
 * @export
 * @interface EntityModelLoanApplicationListDTO
 */
export interface EntityModelLoanApplicationListDTO {
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanApplicationListDTO
     */
    approvedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof EntityModelLoanApplicationListDTO
     */
    approvedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanApplicationListDTO
     */
    customerLegalName?: string;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof EntityModelLoanApplicationListDTO
     */
    disclosure?: LoanApplicationDisclosure;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanApplicationListDTO
     */
    id?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelLoanApplicationListDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanApplicationListDTO
     */
    requestedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof EntityModelLoanApplicationListDTO
     */
    requestedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof EntityModelLoanApplicationListDTO
     */
    status?: LoanApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanApplicationListDTO
     */
    submitDate?: string;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof EntityModelLoanApplicationListDTO
     */
    type?: LoanApplicationType;
}
/**
 * 
 * @export
 * @interface EntityModelLoanProductDTO
 */
export interface EntityModelLoanProductDTO {
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    initiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    insuranceRateMultiplier?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    interestRate?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    interestRateRestriction?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelLoanProductDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    monthlyFee?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    penaltyInterestMultiplier?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanProductDTO
     */
    productName?: string;
    /**
     * 
     * @type {LoanProductStatus}
     * @memberof EntityModelLoanProductDTO
     */
    status?: LoanProductStatus;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    vatRate?: number;
}
/**
 * 
 * @export
 * @interface EntityModelLoanViewDTO
 */
export interface EntityModelLoanViewDTO {
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    accruedInterestDue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    accruedInterestOverdue?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof EntityModelLoanViewDTO
     */
    approvedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    balance?: number;
    /**
     * 
     * @type {LoanProductCalculationData}
     * @memberof EntityModelLoanViewDTO
     */
    calculationData?: LoanProductCalculationData;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    customerId?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    dailyPenaltyRatePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    daysOverdue?: number;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof EntityModelLoanViewDTO
     */
    disclosure?: LoanApplicationDisclosure;
    /**
     * 
     * @type {FeeRepaymentMethod}
     * @memberof EntityModelLoanViewDTO
     */
    feeRepaymentMethod?: FeeRepaymentMethod;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    financedItemTotalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanViewDTO
     */
    finishedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    initialAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    initiationFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    initiationFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    insuranceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    insuranceFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    interestDaily?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanViewDTO
     */
    latestPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanViewDTO
     */
    legalName?: string;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelLoanViewDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    loanApplicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanViewDTO
     */
    maturityDate?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    monthlyServiceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    monthlyServiceFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    penaltyInterest?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    principalDue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    principalOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanViewDTO
     */
    productName?: string;
    /**
     * 
     * @type {Array<LoanScheduleDTO>}
     * @memberof EntityModelLoanViewDTO
     */
    schedules?: Array<LoanScheduleDTO>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanViewDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanViewDTO
     */
    status?: EntityModelLoanViewDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanViewDTO
     */
    thirdPartyCipc?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanViewDTO
     */
    thirdPartyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanViewDTO
     */
    thirdPartyLegalName?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    totalExposure?: number;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof EntityModelLoanViewDTO
     */
    type?: LoanApplicationType;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    vatInitiationFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    vatInitiationFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    vatInsuranceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    vatInsuranceFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    vatMonthlyServiceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanViewDTO
     */
    vatMonthlyServiceFeeOverdue?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelLoanViewDTOStatusEnum {
    ACTIVE = 'ACTIVE',
    OVERDUE = 'OVERDUE',
    DEFAULT = 'DEFAULT',
    PAIDUP = 'PAID_UP',
    WRITTENOFF = 'WRITTEN_OFF'
}

/**
 * 
 * @export
 * @interface EntityModelNotificationDTO
 */
export interface EntityModelNotificationDTO {
    /**
     * 
     * @type {string}
     * @memberof EntityModelNotificationDTO
     */
    content?: string;
    /**
     * 
     * @type {ContentType}
     * @memberof EntityModelNotificationDTO
     */
    contentType?: ContentType;
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelNotificationDTO
     */
    editDisabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EntityModelNotificationDTO
     */
    id?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelNotificationDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {NotificationStatus}
     * @memberof EntityModelNotificationDTO
     */
    status?: NotificationStatus;
    /**
     * 
     * @type {string}
     * @memberof EntityModelNotificationDTO
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelNotificationDTO
     */
    title?: string;
    /**
     * 
     * @type {NotificationTriggerType}
     * @memberof EntityModelNotificationDTO
     */
    triggerType?: NotificationTriggerType;
    /**
     * 
     * @type {NotificationType}
     * @memberof EntityModelNotificationDTO
     */
    type?: NotificationType;
}
/**
 * 
 * @export
 * @interface EntityModelReportDTO
 */
export interface EntityModelReportDTO {
    /**
     * 
     * @type {string}
     * @memberof EntityModelReportDTO
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelReportDTO
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelReportDTO
     */
    fileType?: EntityModelReportDTOFileTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof EntityModelReportDTO
     */
    id?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelReportDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelReportDTO
     */
    status?: EntityModelReportDTOStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelReportDTOFileTypeEnum {
    PDF = 'PDF',
    TXT = 'TXT',
    HTML = 'HTML',
    JPEG = 'JPEG',
    JPG = 'JPG',
    PNG = 'PNG',
    ZIP = 'ZIP',
    RAR = 'RAR',
    DOC = 'DOC',
    DOCX = 'DOCX',
    XLS = 'XLS',
    XLSX = 'XLSX',
    CSV = 'CSV',
    RTF = 'RTF',
    SVG = 'SVG',
    PPT = 'PPT',
    PPTX = 'PPTX'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelReportDTOStatusEnum {
    SUCCESSFUL = 'SUCCESSFUL',
    FAILURE = 'FAILURE'
}

/**
 * 
 * @export
 * @interface EntityModelSystemOperationExecutionHistoryDTO
 */
export interface EntityModelSystemOperationExecutionHistoryDTO {
    /**
     * 
     * @type {string}
     * @memberof EntityModelSystemOperationExecutionHistoryDTO
     */
    backofficeUserFullName?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelSystemOperationExecutionHistoryDTO
     */
    backofficeUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSystemOperationExecutionHistoryDTO
     */
    finishedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelSystemOperationExecutionHistoryDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSystemOperationExecutionHistoryDTO
     */
    launchType?: EntityModelSystemOperationExecutionHistoryDTOLaunchTypeEnum;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelSystemOperationExecutionHistoryDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSystemOperationExecutionHistoryDTO
     */
    operationType?: EntityModelSystemOperationExecutionHistoryDTOOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSystemOperationExecutionHistoryDTO
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSystemOperationExecutionHistoryDTO
     */
    status?: EntityModelSystemOperationExecutionHistoryDTOStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelSystemOperationExecutionHistoryDTOLaunchTypeEnum {
    SCHEDULED = 'SCHEDULED',
    MANUAL = 'MANUAL'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelSystemOperationExecutionHistoryDTOOperationTypeEnum {
    DAILYLOANCALCULATION = 'DAILY_LOAN_CALCULATION',
    FULLLOANRECALCULATION = 'FULL_LOAN_RECALCULATION',
    RECREATELOANSFROMAPPLICATION = 'RECREATE_LOANS_FROM_APPLICATION',
    SENDNOTIFICATIONSFORWEEKPAYMENT = 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT',
    SENDNOTIFICATIONSFORTOMORROWPAYMENT = 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT',
    SENDNOTIFICATIONSFOR1DAYOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR5DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR7DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR90DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN',
    REPORTGENERATION = 'REPORT_GENERATION',
    CREDITBUREAUCHECKRESTARTFAILEDLOGS = 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS',
    DELETECUSTOMERSWITHNOTCOMPLETEDREGISTRATION = 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION',
    RETRIEVEFAILEDNOTIFICATIONS = 'RETRIEVE_FAILED_NOTIFICATIONS',
    CLEARNOTIFICATIONLOG = 'CLEAR_NOTIFICATION_LOG',
    REPROCESSAPPLICATIONSFORREVIEWEDCUSTOMERS = 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelSystemOperationExecutionHistoryDTOStatusEnum {
    STARTED = 'STARTED',
    ERROR = 'ERROR',
    FINISHED = 'FINISHED'
}

/**
 * 
 * @export
 * @interface EntityModelUserAccountActionDTO
 */
export interface EntityModelUserAccountActionDTO {
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserAccountActionDTO
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelUserAccountActionDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserAccountActionDTO
     */
    ip?: string;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelUserAccountActionDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserAccountActionDTO
     */
    params?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserAccountActionDTO
     */
    portalType?: EntityModelUserAccountActionDTOPortalTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserAccountActionDTO
     */
    requestMethod?: EntityModelUserAccountActionDTORequestMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserAccountActionDTO
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserAccountActionDTO
     */
    userFullName?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelUserAccountActionDTO
     */
    userId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelUserAccountActionDTOPortalTypeEnum {
    BO = 'BO',
    SME = 'SME'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelUserAccountActionDTORequestMethodEnum {
    GET = 'GET',
    HEAD = 'HEAD',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    OPTIONS = 'OPTIONS',
    TRACE = 'TRACE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FeeRepaymentMethod {
    UPFRONTPAYMENT = 'UPFRONT_PAYMENT',
    DEDUCTFROMDISBURSEMENT = 'DEDUCT_FROM_DISBURSEMENT',
    ADDTOLOAN = 'ADD_TO_LOAN'
}

/**
 * 
 * @export
 * @interface FinancialFiguresForm
 */
export interface FinancialFiguresForm {
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    acidTestQuickRatioIndustryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    averageInventory?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    averageTradeCreditors?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    averageTradeDebtors?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    creditorPaymentPeriodIndustryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    currentAssets?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    currentLiabilities?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    currentRatioIndustryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    debtorCollectionPeriodIndustryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    ebidta?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    ebidtaIndustryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    grossProfit?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    grossProfitIndustryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    inventory?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    netProfit?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    netProfitIndustryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    operationProfit?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    operationProfitIndustryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    soldGoodsCost?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    stockTurnoverPeriodIndustryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof FinancialFiguresForm
     */
    totalSales?: LoanApplicationQuarterFigures;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    file: any;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    deprecation?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    href?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    hreflang?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    media?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    profile?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    rel?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    type?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanApplicationDisclosure {
    DISCLOSED = 'DISCLOSED',
    UNDISCLOSED = 'UNDISCLOSED'
}

/**
 * 
 * @export
 * @interface LoanApplicationFinancedItemDTO
 */
export interface LoanApplicationFinancedItemDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItemDTO
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItemDTO
     */
    creditNote?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItemDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItemDTO
     */
    itemNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItemDTO
     */
    maturityDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItemDTO
     */
    originationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItemDTO
     */
    totalAmount?: number;
}
/**
 * 
 * @export
 * @interface LoanApplicationListDTO
 */
export interface LoanApplicationListDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationListDTO
     */
    approvedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationListDTO
     */
    approvedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationListDTO
     */
    customerLegalName?: string;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof LoanApplicationListDTO
     */
    disclosure?: LoanApplicationDisclosure;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationListDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationListDTO
     */
    requestedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationListDTO
     */
    requestedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof LoanApplicationListDTO
     */
    status?: LoanApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationListDTO
     */
    submitDate?: string;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof LoanApplicationListDTO
     */
    type?: LoanApplicationType;
}
/**
 * 
 * @export
 * @interface LoanApplicationQuarterFigures
 */
export interface LoanApplicationQuarterFigures {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationQuarterFigures
     */
    currentQuarter: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationQuarterFigures
     */
    previousQuarter: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationQuarterFigures
     */
    threeQuarterBefore?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationQuarterFigures
     */
    twoQuarterBefore?: number;
}
/**
 * 
 * @export
 * @interface LoanApplicationRatio
 */
export interface LoanApplicationRatio {
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    acidTestQuickRatio?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    creditorPaymentPeriod?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    currentRatio?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    debtorCollectionPeriod?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    ebidta?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    grossProfit?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    netProfit?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    operationProfit?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    stockTurnoverPeriod?: LoanApplicationRatioFeature;
}
/**
 * 
 * @export
 * @interface LoanApplicationRatioFeature
 */
export interface LoanApplicationRatioFeature {
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof LoanApplicationRatioFeature
     */
    industryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof LoanApplicationRatioFeature
     */
    quarterChange?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof LoanApplicationRatioFeature
     */
    value?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof LoanApplicationRatioFeature
     */
    variance?: LoanApplicationQuarterFigures;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanApplicationStatus {
    INITIALWAITINGDOCUMENTS = 'INITIAL_WAITING_DOCUMENTS',
    WAITINGACCOUNTVERIFICATION = 'WAITING_ACCOUNT_VERIFICATION',
    CREDITBUREAUCHECKING = 'CREDIT_BUREAU_CHECKING',
    INITIALREVIEW = 'INITIAL_REVIEW',
    INFOREQUESTED = 'INFO_REQUESTED',
    CREDITCOMMITTEEREVIEW = 'CREDIT_COMMITTEE_REVIEW',
    OFFERISSUED = 'OFFER_ISSUED',
    FINALOFFERISSUED = 'FINAL_OFFER_ISSUED',
    SIGNINGPROCESS = 'SIGNING_PROCESS',
    SIGNED = 'SIGNED',
    EXPIRED = 'EXPIRED',
    DECLINEDBYREVIEWER = 'DECLINED_BY_REVIEWER',
    DECLINEDBYCREDITBUREAU = 'DECLINED_BY_CREDIT_BUREAU',
    DECLINEDBYCREDITCOMMITTEE = 'DECLINED_BY_CREDIT_COMMITTEE',
    DECLINEDBYCUSTOMER = 'DECLINED_BY_CUSTOMER'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanApplicationTerm {
    THIRTY = 'THIRTY',
    SIXTY = 'SIXTY',
    NINETY = 'NINETY'
}

/**
 * 
 * @export
 * @interface LoanApplicationThirdPartyDTO
 */
export interface LoanApplicationThirdPartyDTO {
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdPartyDTO
     */
    address?: string;
    /**
     * 
     * @type {BankAccountDTO}
     * @memberof LoanApplicationThirdPartyDTO
     */
    bankAccount?: BankAccountDTO;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdPartyDTO
     */
    cipc?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdPartyDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdPartyDTO
     */
    legalName?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanApplicationType {
    INVOICEFINANCE = 'INVOICE_FINANCE',
    PURCHASEORDERFINANCE = 'PURCHASE_ORDER_FINANCE',
    FINANCEOFDEBTOR = 'FINANCE_OF_DEBTOR',
    OUTANDOUTINVOICEFINANCE = 'OUT_AND_OUT_INVOICE_FINANCE'
}

/**
 * 
 * @export
 * @interface LoanApplicationViewDTO
 */
export interface LoanApplicationViewDTO {
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    agreementSigningDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    approvedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationViewDTO
     */
    approvedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {BankAccountDTO}
     * @memberof LoanApplicationViewDTO
     */
    bankAccount?: BankAccountDTO;
    /**
     * 
     * @type {LoanProductCalculationData}
     * @memberof LoanApplicationViewDTO
     */
    calculationData?: LoanProductCalculationData;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    ccReviewer?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    ccReviewerId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    contrOffer?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    creditBureauCheckDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    creditCommitteeApprovalDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    customerLegalName?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    decliningDate?: string;
    /**
     * 
     * @type {Array<DisbursementBankAccountInfoDTO>}
     * @memberof LoanApplicationViewDTO
     */
    disbursementBankAccountInfos?: Array<DisbursementBankAccountInfoDTO>;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof LoanApplicationViewDTO
     */
    disclosure?: LoanApplicationDisclosure;
    /**
     * 
     * @type {FeeRepaymentMethod}
     * @memberof LoanApplicationViewDTO
     */
    feeRepaymentMethod?: FeeRepaymentMethod;
    /**
     * 
     * @type {Array<LoanApplicationFinancedItemDTO>}
     * @memberof LoanApplicationViewDTO
     */
    financedItems?: Array<LoanApplicationFinancedItemDTO>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    financedPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    firstReviewer?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    firstReviewerId?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationViewDTO
     */
    holidayTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    loanProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    loanProductName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationViewDTO
     */
    loansCreated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    offerAcceptanceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    precedentConditions?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    purchaseOrderDescription?: string;
    /**
     * 
     * @type {LoanApplicationRatio}
     * @memberof LoanApplicationViewDTO
     */
    ratio?: LoanApplicationRatio;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    requestNote?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    requestedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationViewDTO
     */
    requestedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    reviewApprovalDate?: string;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof LoanApplicationViewDTO
     */
    status?: LoanApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    submitDate?: string;
    /**
     * 
     * @type {LoanApplicationThirdPartyDTO}
     * @memberof LoanApplicationViewDTO
     */
    thirdParty?: LoanApplicationThirdPartyDTO;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof LoanApplicationViewDTO
     */
    type?: LoanApplicationType;
}
/**
 * 
 * @export
 * @interface LoanPaymentParams
 */
export interface LoanPaymentParams {
    /**
     * 
     * @type {string}
     * @memberof LoanPaymentParams
     */
    activatedAt: string;
    /**
     * 
     * @type {number}
     * @memberof LoanPaymentParams
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanPaymentParams
     */
    loanId: number;
    /**
     * 
     * @type {string}
     * @memberof LoanPaymentParams
     */
    operationType: LoanPaymentParamsOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanPaymentParams
     */
    reason: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LoanPaymentParamsOperationTypeEnum {
    DISBURSEMENT = 'DISBURSEMENT',
    PAYMENT = 'PAYMENT',
    WRITEOFF = 'WRITE_OFF'
}

/**
 * 
 * @export
 * @interface LoanProductCalculationData
 */
export interface LoanProductCalculationData {
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    initiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    insuranceRateMultiplierPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    interestRatePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    interestRateRestrictionPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    monthlyFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    penaltyInterestMultiplier?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    vatRatePercentage?: number;
}
/**
 * 
 * @export
 * @interface LoanProductDTO
 */
export interface LoanProductDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    initiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    insuranceRateMultiplier?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    interestRate?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    interestRateRestriction?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    monthlyFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    penaltyInterestMultiplier?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProductDTO
     */
    productName?: string;
    /**
     * 
     * @type {LoanProductStatus}
     * @memberof LoanProductDTO
     */
    status?: LoanProductStatus;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    vatRate?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanProductStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

/**
 * 
 * @export
 * @interface LoanScheduleDTO
 */
export interface LoanScheduleDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    initiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    insuranceFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    interest?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    monthlyServiceFee?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanScheduleDTO
     */
    paymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    principal?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    vatInitiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    vatInsuranceFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    vatMonthlyServiceFee?: number;
}
/**
 * 
 * @export
 * @interface LoanSnapshotDTO
 */
export interface LoanSnapshotDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    accruedInterestDue?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotDTO
     */
    calculationEvent?: LoanSnapshotDTOCalculationEventEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotDTO
     */
    dateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    initiationFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    insuranceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    monthlyServiceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    overdueTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    penaltyInterest?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    principalDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    vatInitiationFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    vatInsuranceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    vatMonthlyServiceFeeDue?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LoanSnapshotDTOCalculationEventEnum {
    DISBURSEMENT = 'DISBURSEMENT',
    DAILYCALCULATION = 'DAILY_CALCULATION',
    REPAYMENT = 'REPAYMENT',
    WRITEOFF = 'WRITE_OFF'
}

/**
 * 
 * @export
 * @interface LoanViewDTO
 */
export interface LoanViewDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    accruedInterestDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    accruedInterestOverdue?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanViewDTO
     */
    approvedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    balance?: number;
    /**
     * 
     * @type {LoanProductCalculationData}
     * @memberof LoanViewDTO
     */
    calculationData?: LoanProductCalculationData;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    customerId?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    dailyPenaltyRatePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    daysOverdue?: number;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof LoanViewDTO
     */
    disclosure?: LoanApplicationDisclosure;
    /**
     * 
     * @type {FeeRepaymentMethod}
     * @memberof LoanViewDTO
     */
    feeRepaymentMethod?: FeeRepaymentMethod;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    financedItemTotalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    finishedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    initialAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    initiationFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    initiationFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    insuranceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    insuranceFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    interestDaily?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    latestPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    legalName?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    loanApplicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    maturityDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    monthlyServiceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    monthlyServiceFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    penaltyInterest?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    principalDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    principalOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    productName?: string;
    /**
     * 
     * @type {Array<LoanScheduleDTO>}
     * @memberof LoanViewDTO
     */
    schedules?: Array<LoanScheduleDTO>;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    status?: LoanViewDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    thirdPartyCipc?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    thirdPartyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    thirdPartyLegalName?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    totalExposure?: number;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof LoanViewDTO
     */
    type?: LoanApplicationType;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatInitiationFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatInitiationFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatInsuranceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatInsuranceFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatMonthlyServiceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatMonthlyServiceFeeOverdue?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LoanViewDTOStatusEnum {
    ACTIVE = 'ACTIVE',
    OVERDUE = 'OVERDUE',
    DEFAULT = 'DEFAULT',
    PAIDUP = 'PAID_UP',
    WRITTENOFF = 'WRITTEN_OFF'
}

/**
 * 
 * @export
 * @interface LoanWriteOffParams
 */
export interface LoanWriteOffParams {
    /**
     * 
     * @type {number}
     * @memberof LoanWriteOffParams
     */
    loanId: number;
    /**
     * 
     * @type {string}
     * @memberof LoanWriteOffParams
     */
    operationType: LoanWriteOffParamsOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanWriteOffParams
     */
    reason: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LoanWriteOffParamsOperationTypeEnum {
    DISBURSEMENT = 'DISBURSEMENT',
    PAYMENT = 'PAYMENT',
    WRITEOFF = 'WRITE_OFF'
}

/**
 * 
 * @export
 * @interface NotificationDTO
 */
export interface NotificationDTO {
    /**
     * 
     * @type {string}
     * @memberof NotificationDTO
     */
    content?: string;
    /**
     * 
     * @type {ContentType}
     * @memberof NotificationDTO
     */
    contentType?: ContentType;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDTO
     */
    editDisabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotificationDTO
     */
    id?: number;
    /**
     * 
     * @type {NotificationStatus}
     * @memberof NotificationDTO
     */
    status?: NotificationStatus;
    /**
     * 
     * @type {string}
     * @memberof NotificationDTO
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDTO
     */
    title?: string;
    /**
     * 
     * @type {NotificationTriggerType}
     * @memberof NotificationDTO
     */
    triggerType?: NotificationTriggerType;
    /**
     * 
     * @type {NotificationType}
     * @memberof NotificationDTO
     */
    type?: NotificationType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationTriggerType {
    CUSTOMERACCOUNTVERIFIED = 'CUSTOMER_ACCOUNT_VERIFIED',
    CUSTOMERAPPLICATIONREVIEW = 'CUSTOMER_APPLICATION_REVIEW',
    CUSTOMERAPPLICATIONFAILEDCBCHECK = 'CUSTOMER_APPLICATION_FAILED_CB_CHECK',
    CUSTOMERAPPLICATIONAPPROVED = 'CUSTOMER_APPLICATION_APPROVED',
    CUSTOMERAPPLICATIONDECLINED = 'CUSTOMER_APPLICATION_DECLINED',
    CUSTOMERAPPLICATIONDECLINEDBYCC = 'CUSTOMER_APPLICATION_DECLINED_BY_CC',
    CUSTOMERAPPLICATIONAPPROVEDBYCC = 'CUSTOMER_APPLICATION_APPROVED_BY_CC',
    CUSTOMERPAYMENT7DAYS = 'CUSTOMER_PAYMENT_7_DAYS',
    CUSTOMERPAYMENTTOMORROW = 'CUSTOMER_PAYMENT_TOMORROW',
    CUSTOMEROVERDUE1DAY = 'CUSTOMER_OVERDUE_1_DAY',
    CUSTOMEROVERDUE5DAYS = 'CUSTOMER_OVERDUE_5_DAYS',
    CUSTOMEROVERDUE7DAYS = 'CUSTOMER_OVERDUE_7_DAYS',
    CUSTOMEROVERDUE90DAYS = 'CUSTOMER_OVERDUE_90_DAYS',
    BACKOFFICECUSTOMERINREVIEW = 'BACKOFFICE_CUSTOMER_IN_REVIEW',
    BACKOFFICEAPPLICATIONINREVIEW = 'BACKOFFICE_APPLICATION_IN_REVIEW',
    BACKOFFICEAPPLICATIONCCREVIEW = 'BACKOFFICE_APPLICATION_CC_REVIEW'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationType {
    SMS = 'SMS',
    EMAIL = 'EMAIL'
}

/**
 * 
 * @export
 * @interface PageMetadata
 */
export interface PageMetadata {
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelBackofficeRoleDTO
 */
export interface PagedModelEntityModelBackofficeRoleDTO {
    /**
     * 
     * @type {Array<EntityModelBackofficeRoleDTO>}
     * @memberof PagedModelEntityModelBackofficeRoleDTO
     */
    content?: Array<EntityModelBackofficeRoleDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelBackofficeRoleDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelBackofficeRoleDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelBackofficeUserDTO
 */
export interface PagedModelEntityModelBackofficeUserDTO {
    /**
     * 
     * @type {Array<EntityModelBackofficeUserDTO>}
     * @memberof PagedModelEntityModelBackofficeUserDTO
     */
    content?: Array<EntityModelBackofficeUserDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelBackofficeUserDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelBackofficeUserDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelCsvLogDTO
 */
export interface PagedModelEntityModelCsvLogDTO {
    /**
     * 
     * @type {Array<EntityModelCsvLogDTO>}
     * @memberof PagedModelEntityModelCsvLogDTO
     */
    content?: Array<EntityModelCsvLogDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelCsvLogDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelCsvLogDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelCustomerUserDTO
 */
export interface PagedModelEntityModelCustomerUserDTO {
    /**
     * 
     * @type {Array<EntityModelCustomerUserDTO>}
     * @memberof PagedModelEntityModelCustomerUserDTO
     */
    content?: Array<EntityModelCustomerUserDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelCustomerUserDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelCustomerUserDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelDocumentTemplateDTO
 */
export interface PagedModelEntityModelDocumentTemplateDTO {
    /**
     * 
     * @type {Array<EntityModelDocumentTemplateDTO>}
     * @memberof PagedModelEntityModelDocumentTemplateDTO
     */
    content?: Array<EntityModelDocumentTemplateDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelDocumentTemplateDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelDocumentTemplateDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelLoanApplicationListDTO
 */
export interface PagedModelEntityModelLoanApplicationListDTO {
    /**
     * 
     * @type {Array<EntityModelLoanApplicationListDTO>}
     * @memberof PagedModelEntityModelLoanApplicationListDTO
     */
    content?: Array<EntityModelLoanApplicationListDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelLoanApplicationListDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelLoanApplicationListDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelLoanProductDTO
 */
export interface PagedModelEntityModelLoanProductDTO {
    /**
     * 
     * @type {Array<EntityModelLoanProductDTO>}
     * @memberof PagedModelEntityModelLoanProductDTO
     */
    content?: Array<EntityModelLoanProductDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelLoanProductDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelLoanProductDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelLoanViewDTO
 */
export interface PagedModelEntityModelLoanViewDTO {
    /**
     * 
     * @type {Array<EntityModelLoanViewDTO>}
     * @memberof PagedModelEntityModelLoanViewDTO
     */
    content?: Array<EntityModelLoanViewDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelLoanViewDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelLoanViewDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelNotificationDTO
 */
export interface PagedModelEntityModelNotificationDTO {
    /**
     * 
     * @type {Array<EntityModelNotificationDTO>}
     * @memberof PagedModelEntityModelNotificationDTO
     */
    content?: Array<EntityModelNotificationDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelNotificationDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelNotificationDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelReportDTO
 */
export interface PagedModelEntityModelReportDTO {
    /**
     * 
     * @type {Array<EntityModelReportDTO>}
     * @memberof PagedModelEntityModelReportDTO
     */
    content?: Array<EntityModelReportDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelReportDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelReportDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelSystemOperationExecutionHistoryDTO
 */
export interface PagedModelEntityModelSystemOperationExecutionHistoryDTO {
    /**
     * 
     * @type {Array<EntityModelSystemOperationExecutionHistoryDTO>}
     * @memberof PagedModelEntityModelSystemOperationExecutionHistoryDTO
     */
    content?: Array<EntityModelSystemOperationExecutionHistoryDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelSystemOperationExecutionHistoryDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelSystemOperationExecutionHistoryDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelUserAccountActionDTO
 */
export interface PagedModelEntityModelUserAccountActionDTO {
    /**
     * 
     * @type {Array<EntityModelUserAccountActionDTO>}
     * @memberof PagedModelEntityModelUserAccountActionDTO
     */
    content?: Array<EntityModelUserAccountActionDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelUserAccountActionDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelUserAccountActionDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PasswordForm
 */
export interface PasswordForm {
    /**
     * 
     * @type {string}
     * @memberof PasswordForm
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordForm
     */
    passwordConfirmation: string;
}
/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    instance?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Problem
     */
    parameters?: { [key: string]: object; };
    /**
     * 
     * @type {StatusType}
     * @memberof Problem
     */
    status?: StatusType;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface ProvinceDTO
 */
export interface ProvinceDTO {
    /**
     * 
     * @type {number}
     * @memberof ProvinceDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProvinceDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ReportDTO
 */
export interface ReportDTO {
    /**
     * 
     * @type {string}
     * @memberof ReportDTO
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDTO
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDTO
     */
    fileType?: ReportDTOFileTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ReportDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportDTO
     */
    status?: ReportDTOStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReportDTOFileTypeEnum {
    PDF = 'PDF',
    TXT = 'TXT',
    HTML = 'HTML',
    JPEG = 'JPEG',
    JPG = 'JPG',
    PNG = 'PNG',
    ZIP = 'ZIP',
    RAR = 'RAR',
    DOC = 'DOC',
    DOCX = 'DOCX',
    XLS = 'XLS',
    XLSX = 'XLSX',
    CSV = 'CSV',
    RTF = 'RTF',
    SVG = 'SVG',
    PPT = 'PPT',
    PPTX = 'PPTX'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportDTOStatusEnum {
    SUCCESSFUL = 'SUCCESSFUL',
    FAILURE = 'FAILURE'
}

/**
 * 
 * @export
 * @interface RequestInformationForm
 */
export interface RequestInformationForm {
    /**
     * 
     * @type {string}
     * @memberof RequestInformationForm
     */
    requestNote: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StartPage {
    LOANS = 'LOANS',
    APPLICATIONS = 'APPLICATIONS',
    CUSTOMERS = 'CUSTOMERS',
    LOANPRODUCTS = 'LOAN_PRODUCTS',
    USERS = 'USERS',
    ROLES = 'ROLES',
    NOTIFICATIONS = 'NOTIFICATIONS',
    PAYMENTS = 'PAYMENTS'
}

/**
 * 
 * @export
 * @interface StatusType
 */
export interface StatusType {
    /**
     * 
     * @type {string}
     * @memberof StatusType
     */
    reasonPhrase?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusType
     */
    statusCode?: number;
}
/**
 * 
 * @export
 * @interface SystemOperationExecuteRequestDTO
 */
export interface SystemOperationExecuteRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof SystemOperationExecuteRequestDTO
     */
    operation?: SystemOperationExecuteRequestDTOOperationEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SystemOperationExecuteRequestDTOOperationEnum {
    DAILYLOANCALCULATION = 'DAILY_LOAN_CALCULATION',
    FULLLOANRECALCULATION = 'FULL_LOAN_RECALCULATION',
    RECREATELOANSFROMAPPLICATION = 'RECREATE_LOANS_FROM_APPLICATION',
    SENDNOTIFICATIONSFORWEEKPAYMENT = 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT',
    SENDNOTIFICATIONSFORTOMORROWPAYMENT = 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT',
    SENDNOTIFICATIONSFOR1DAYOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR5DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR7DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR90DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN',
    REPORTGENERATION = 'REPORT_GENERATION',
    CREDITBUREAUCHECKRESTARTFAILEDLOGS = 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS',
    DELETECUSTOMERSWITHNOTCOMPLETEDREGISTRATION = 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION',
    RETRIEVEFAILEDNOTIFICATIONS = 'RETRIEVE_FAILED_NOTIFICATIONS',
    CLEARNOTIFICATIONLOG = 'CLEAR_NOTIFICATION_LOG',
    REPROCESSAPPLICATIONSFORREVIEWEDCUSTOMERS = 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'
}

/**
 * 
 * @export
 * @interface SystemOperationExecutionHistoryDTO
 */
export interface SystemOperationExecutionHistoryDTO {
    /**
     * 
     * @type {string}
     * @memberof SystemOperationExecutionHistoryDTO
     */
    backofficeUserFullName?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemOperationExecutionHistoryDTO
     */
    backofficeUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemOperationExecutionHistoryDTO
     */
    finishedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemOperationExecutionHistoryDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemOperationExecutionHistoryDTO
     */
    launchType?: SystemOperationExecutionHistoryDTOLaunchTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemOperationExecutionHistoryDTO
     */
    operationType?: SystemOperationExecutionHistoryDTOOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemOperationExecutionHistoryDTO
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemOperationExecutionHistoryDTO
     */
    status?: SystemOperationExecutionHistoryDTOStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SystemOperationExecutionHistoryDTOLaunchTypeEnum {
    SCHEDULED = 'SCHEDULED',
    MANUAL = 'MANUAL'
}
/**
    * @export
    * @enum {string}
    */
export enum SystemOperationExecutionHistoryDTOOperationTypeEnum {
    DAILYLOANCALCULATION = 'DAILY_LOAN_CALCULATION',
    FULLLOANRECALCULATION = 'FULL_LOAN_RECALCULATION',
    RECREATELOANSFROMAPPLICATION = 'RECREATE_LOANS_FROM_APPLICATION',
    SENDNOTIFICATIONSFORWEEKPAYMENT = 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT',
    SENDNOTIFICATIONSFORTOMORROWPAYMENT = 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT',
    SENDNOTIFICATIONSFOR1DAYOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR5DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR7DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN',
    SENDNOTIFICATIONSFOR90DAYSOVERDUELOAN = 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN',
    REPORTGENERATION = 'REPORT_GENERATION',
    CREDITBUREAUCHECKRESTARTFAILEDLOGS = 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS',
    DELETECUSTOMERSWITHNOTCOMPLETEDREGISTRATION = 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION',
    RETRIEVEFAILEDNOTIFICATIONS = 'RETRIEVE_FAILED_NOTIFICATIONS',
    CLEARNOTIFICATIONLOG = 'CLEAR_NOTIFICATION_LOG',
    REPROCESSAPPLICATIONSFORREVIEWEDCUSTOMERS = 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'
}
/**
    * @export
    * @enum {string}
    */
export enum SystemOperationExecutionHistoryDTOStatusEnum {
    STARTED = 'STARTED',
    ERROR = 'ERROR',
    FINISHED = 'FINISHED'
}

/**
 * 
 * @export
 * @interface ThrowableProblem
 */
export interface ThrowableProblem {
    /**
     * 
     * @type {ThrowableProblem}
     * @memberof ThrowableProblem
     */
    cause?: ThrowableProblem;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    instance?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    message?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ThrowableProblem
     */
    parameters?: { [key: string]: object; };
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ThrowableProblem
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
    /**
     * 
     * @type {StatusType}
     * @memberof ThrowableProblem
     */
    status?: StatusType;
    /**
     * 
     * @type {Array<ConstraintViolationProblemSuppressed>}
     * @memberof ThrowableProblem
     */
    suppressed?: Array<ConstraintViolationProblemSuppressed>;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface UiSettings
 */
export interface UiSettings {
    /**
     * 
     * @type {Authentication}
     * @memberof UiSettings
     */
    authentication?: Authentication;
}
/**
 * 
 * @export
 * @interface UpdateBackofficeRoleForm
 */
export interface UpdateBackofficeRoleForm {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateBackofficeRoleForm
     */
    authorities: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackofficeRoleForm
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBackofficeRoleForm
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackofficeRoleForm
     */
    name: string;
    /**
     * 
     * @type {StartPage}
     * @memberof UpdateBackofficeRoleForm
     */
    startPage: StartPage;
}
/**
 * 
 * @export
 * @interface UpdateBackofficeUserForm
 */
export interface UpdateBackofficeUserForm {
    /**
     * 
     * @type {string}
     * @memberof UpdateBackofficeUserForm
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackofficeUserForm
     */
    fullName: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBackofficeUserForm
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackofficeUserForm
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackofficeUserForm
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBackofficeUserForm
     */
    roleId: number;
}
/**
 * 
 * @export
 * @interface UpdateLoanProductForm
 */
export interface UpdateLoanProductForm {
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    initiationFee: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    insuranceRateMultiplier: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    interestRate: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    interestRateRestriction: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    monthlyFee: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    penaltyInterestMultiplier: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoanProductForm
     */
    productName: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    vatRate: number;
}
/**
 * 
 * @export
 * @interface UpdateNotificationForm
 */
export interface UpdateNotificationForm {
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationForm
     */
    content: string;
    /**
     * 
     * @type {ContentType}
     * @memberof UpdateNotificationForm
     */
    contentType?: ContentType;
    /**
     * 
     * @type {number}
     * @memberof UpdateNotificationForm
     */
    id: number;
    /**
     * 
     * @type {NotificationStatus}
     * @memberof UpdateNotificationForm
     */
    status: NotificationStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationForm
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationForm
     */
    title: string;
    /**
     * 
     * @type {NotificationTriggerType}
     * @memberof UpdateNotificationForm
     */
    triggerType: NotificationTriggerType;
    /**
     * 
     * @type {NotificationType}
     * @memberof UpdateNotificationForm
     */
    type: NotificationType;
}
/**
 * 
 * @export
 * @interface UserAccountActionDTO
 */
export interface UserAccountActionDTO {
    /**
     * 
     * @type {string}
     * @memberof UserAccountActionDTO
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof UserAccountActionDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccountActionDTO
     */
    ip?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountActionDTO
     */
    params?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountActionDTO
     */
    portalType?: UserAccountActionDTOPortalTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAccountActionDTO
     */
    requestMethod?: UserAccountActionDTORequestMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAccountActionDTO
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountActionDTO
     */
    userFullName?: string;
    /**
     * 
     * @type {number}
     * @memberof UserAccountActionDTO
     */
    userId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UserAccountActionDTOPortalTypeEnum {
    BO = 'BO',
    SME = 'SME'
}
/**
    * @export
    * @enum {string}
    */
export enum UserAccountActionDTORequestMethodEnum {
    GET = 'GET',
    HEAD = 'HEAD',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    OPTIONS = 'OPTIONS',
    TRACE = 'TRACE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

/**
 * 
 * @export
 * @interface Violation
 */
export interface Violation {
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    message?: string;
}

/**
 * BackofficeCustomerUserControllerApi - axios parameter creator
 * @export
 */
export const BackofficeCustomerUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change customer status to Verified
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate3: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activate3.');
            }
            const localVarPath = `/api/customer/{id}/verify`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restart credit bureau check log
         * @param {number} customerId 
         * @param {number} logId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditBureauCheckLogRestart: async (customerId: number, logId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling creditBureauCheckLogRestart.');
            }
            // verify required parameter 'logId' is not null or undefined
            if (logId === null || logId === undefined) {
                throw new RequiredError('logId','Required parameter logId was null or undefined when calling creditBureauCheckLogRestart.');
            }
            const localVarPath = `/api/customer/{customerId}/credit-bureau-check-log/{logId}/restart`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"logId"}}`, encodeURIComponent(String(logId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get credit bureau check logs
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditBureauCheckLogs: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling creditBureauCheckLogs.');
            }
            const localVarPath = `/api/customer/{id}/credit-bureau-check-log`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change customer status to Declined
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate3: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deactivate3.');
            }
            const localVarPath = `/api/customer/{id}/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {number} [id] 
         * @param {string} [legalName] 
         * @param {string} [cipc] 
         * @param {Array<CustomerVerificationStatus>} [verificationStatuses] 
         * @param {string} [email] 
         * @param {string} [representativeEmail] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList9: async (id?: number, legalName?: string, cipc?: string, verificationStatuses?: Array<CustomerVerificationStatus>, email?: string, representativeEmail?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (legalName !== undefined) {
                localVarQueryParameter['legalName'] = legalName;
            }

            if (cipc !== undefined) {
                localVarQueryParameter['cipc'] = cipc;
            }

            if (verificationStatuses) {
                localVarQueryParameter['verificationStatuses'] = verificationStatuses;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (representativeEmail !== undefined) {
                localVarQueryParameter['representativeEmail'] = representativeEmail;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView9: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView9.');
            }
            const localVarPath = `/api/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all loans for customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoans: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLoans.');
            }
            const localVarPath = `/api/customer/{id}/loans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeCustomerUserControllerApi - functional programming interface
 * @export
 */
export const BackofficeCustomerUserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change customer status to Verified
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate3(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCustomerUserDTO>> {
            const localVarAxiosArgs = await BackofficeCustomerUserControllerApiAxiosParamCreator(configuration).activate3(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Restart credit bureau check log
         * @param {number} customerId 
         * @param {number} logId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditBureauCheckLogRestart(customerId: number, logId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BackofficeCustomerUserControllerApiAxiosParamCreator(configuration).creditBureauCheckLogRestart(customerId, logId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get credit bureau check logs
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditBureauCheckLogs(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListCreditBureauCheckLog>> {
            const localVarAxiosArgs = await BackofficeCustomerUserControllerApiAxiosParamCreator(configuration).creditBureauCheckLogs(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change customer status to Declined
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivate3(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCustomerUserDTO>> {
            const localVarAxiosArgs = await BackofficeCustomerUserControllerApiAxiosParamCreator(configuration).deactivate3(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {number} [id] 
         * @param {string} [legalName] 
         * @param {string} [cipc] 
         * @param {Array<CustomerVerificationStatus>} [verificationStatuses] 
         * @param {string} [email] 
         * @param {string} [representativeEmail] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList9(id?: number, legalName?: string, cipc?: string, verificationStatuses?: Array<CustomerVerificationStatus>, email?: string, representativeEmail?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelCustomerUserDTO>> {
            const localVarAxiosArgs = await BackofficeCustomerUserControllerApiAxiosParamCreator(configuration).entityList9(id, legalName, cipc, verificationStatuses, email, representativeEmail, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView9(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCustomerUserDTO>> {
            const localVarAxiosArgs = await BackofficeCustomerUserControllerApiAxiosParamCreator(configuration).entityView9(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all loans for customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoans(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListLoanViewDTO>> {
            const localVarAxiosArgs = await BackofficeCustomerUserControllerApiAxiosParamCreator(configuration).getLoans(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BackofficeCustomerUserControllerApi - factory interface
 * @export
 */
export const BackofficeCustomerUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Change customer status to Verified
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate3(id: number, options?: any): AxiosPromise<ApiResponseBodyCustomerUserDTO> {
            return BackofficeCustomerUserControllerApiFp(configuration).activate3(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restart credit bureau check log
         * @param {number} customerId 
         * @param {number} logId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditBureauCheckLogRestart(customerId: number, logId: number, options?: any): AxiosPromise<void> {
            return BackofficeCustomerUserControllerApiFp(configuration).creditBureauCheckLogRestart(customerId, logId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get credit bureau check logs
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditBureauCheckLogs(id: number, options?: any): AxiosPromise<ApiResponseBodyListCreditBureauCheckLog> {
            return BackofficeCustomerUserControllerApiFp(configuration).creditBureauCheckLogs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change customer status to Declined
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate3(id: number, options?: any): AxiosPromise<ApiResponseBodyCustomerUserDTO> {
            return BackofficeCustomerUserControllerApiFp(configuration).deactivate3(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {number} [id] 
         * @param {string} [legalName] 
         * @param {string} [cipc] 
         * @param {Array<CustomerVerificationStatus>} [verificationStatuses] 
         * @param {string} [email] 
         * @param {string} [representativeEmail] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList9(id?: number, legalName?: string, cipc?: string, verificationStatuses?: Array<CustomerVerificationStatus>, email?: string, representativeEmail?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelCustomerUserDTO> {
            return BackofficeCustomerUserControllerApiFp(configuration).entityList9(id, legalName, cipc, verificationStatuses, email, representativeEmail, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView9(id: number, options?: any): AxiosPromise<ApiResponseBodyCustomerUserDTO> {
            return BackofficeCustomerUserControllerApiFp(configuration).entityView9(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all loans for customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoans(id: number, options?: any): AxiosPromise<ApiResponseBodyListLoanViewDTO> {
            return BackofficeCustomerUserControllerApiFp(configuration).getLoans(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackofficeCustomerUserControllerApi - object-oriented interface
 * @export
 * @class BackofficeCustomerUserControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeCustomerUserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Change customer status to Verified
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeCustomerUserControllerApi
     */
    public activate3(id: number, options?: any) {
        return BackofficeCustomerUserControllerApiFp(this.configuration).activate3(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restart credit bureau check log
     * @param {number} customerId 
     * @param {number} logId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeCustomerUserControllerApi
     */
    public creditBureauCheckLogRestart(customerId: number, logId: number, options?: any) {
        return BackofficeCustomerUserControllerApiFp(this.configuration).creditBureauCheckLogRestart(customerId, logId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get credit bureau check logs
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeCustomerUserControllerApi
     */
    public creditBureauCheckLogs(id: number, options?: any) {
        return BackofficeCustomerUserControllerApiFp(this.configuration).creditBureauCheckLogs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change customer status to Declined
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeCustomerUserControllerApi
     */
    public deactivate3(id: number, options?: any) {
        return BackofficeCustomerUserControllerApiFp(this.configuration).deactivate3(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pageable list of entities
     * @param {number} [id] 
     * @param {string} [legalName] 
     * @param {string} [cipc] 
     * @param {Array<CustomerVerificationStatus>} [verificationStatuses] 
     * @param {string} [email] 
     * @param {string} [representativeEmail] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeCustomerUserControllerApi
     */
    public entityList9(id?: number, legalName?: string, cipc?: string, verificationStatuses?: Array<CustomerVerificationStatus>, email?: string, representativeEmail?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return BackofficeCustomerUserControllerApiFp(this.configuration).entityList9(id, legalName, cipc, verificationStatuses, email, representativeEmail, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeCustomerUserControllerApi
     */
    public entityView9(id: number, options?: any) {
        return BackofficeCustomerUserControllerApiFp(this.configuration).entityView9(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all loans for customer
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeCustomerUserControllerApi
     */
    public getLoans(id: number, options?: any) {
        return BackofficeCustomerUserControllerApiFp(this.configuration).getLoans(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BackofficeCustomerUserDocumentControllerApi - axios parameter creator
 * @export
 */
export const BackofficeCustomerUserDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download customer document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCustomerDocuments: async (documentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling downloadCustomerDocuments.');
            }
            const localVarPath = `/api/customer/document/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return customer documents for loan application
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentForApplication: async (customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling getDocumentForApplication.');
            }
            const localVarPath = `/api/customer/document/application`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return customer documents
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentForCustomer: async (customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling getDocumentForCustomer.');
            }
            const localVarPath = `/api/customer/document`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeCustomerUserDocumentControllerApi - functional programming interface
 * @export
 */
export const BackofficeCustomerUserDocumentControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download customer document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCustomerDocuments(documentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await BackofficeCustomerUserDocumentControllerApiAxiosParamCreator(configuration).downloadCustomerDocuments(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return customer documents for loan application
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentForApplication(customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListDocumentDTO>> {
            const localVarAxiosArgs = await BackofficeCustomerUserDocumentControllerApiAxiosParamCreator(configuration).getDocumentForApplication(customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return customer documents
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentForCustomer(customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListDocumentDTO>> {
            const localVarAxiosArgs = await BackofficeCustomerUserDocumentControllerApiAxiosParamCreator(configuration).getDocumentForCustomer(customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BackofficeCustomerUserDocumentControllerApi - factory interface
 * @export
 */
export const BackofficeCustomerUserDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Download customer document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCustomerDocuments(documentId: number, options?: any): AxiosPromise<any> {
            return BackofficeCustomerUserDocumentControllerApiFp(configuration).downloadCustomerDocuments(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return customer documents for loan application
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentForApplication(customerId: number, options?: any): AxiosPromise<ApiResponseBodyListDocumentDTO> {
            return BackofficeCustomerUserDocumentControllerApiFp(configuration).getDocumentForApplication(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return customer documents
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentForCustomer(customerId: number, options?: any): AxiosPromise<ApiResponseBodyListDocumentDTO> {
            return BackofficeCustomerUserDocumentControllerApiFp(configuration).getDocumentForCustomer(customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackofficeCustomerUserDocumentControllerApi - object-oriented interface
 * @export
 * @class BackofficeCustomerUserDocumentControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeCustomerUserDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Download customer document
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeCustomerUserDocumentControllerApi
     */
    public downloadCustomerDocuments(documentId: number, options?: any) {
        return BackofficeCustomerUserDocumentControllerApiFp(this.configuration).downloadCustomerDocuments(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return customer documents for loan application
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeCustomerUserDocumentControllerApi
     */
    public getDocumentForApplication(customerId: number, options?: any) {
        return BackofficeCustomerUserDocumentControllerApiFp(this.configuration).getDocumentForApplication(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return customer documents
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeCustomerUserDocumentControllerApi
     */
    public getDocumentForCustomer(customerId: number, options?: any) {
        return BackofficeCustomerUserDocumentControllerApiFp(this.configuration).getDocumentForCustomer(customerId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BackofficeLoanApplicationControllerApi - axios parameter creator
 * @export
 */
export const BackofficeLoanApplicationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change loan application status to offer issued
         * @param {number} id 
         * @param {ApprovedConditionForm} approvedConditionForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveByCreditCommittee: async (id: number, approvedConditionForm: ApprovedConditionForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling approveByCreditCommittee.');
            }
            // verify required parameter 'approvedConditionForm' is not null or undefined
            if (approvedConditionForm === null || approvedConditionForm === undefined) {
                throw new RequiredError('approvedConditionForm','Required parameter approvedConditionForm was null or undefined when calling approveByCreditCommittee.');
            }
            const localVarPath = `/api/application/{id}/committee-approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof approvedConditionForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(approvedConditionForm !== undefined ? approvedConditionForm : {}) : (approvedConditionForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change loan application status to Credit Committee review
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveByReviewer: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling approveByReviewer.');
            }
            const localVarPath = `/api/application/{id}/reviewer-approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate and set ratio for loan application
         * @param {number} id 
         * @param {FinancialFiguresForm} financialFiguresForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAndSetRatio: async (id: number, financialFiguresForm: FinancialFiguresForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling calculateAndSetRatio.');
            }
            // verify required parameter 'financialFiguresForm' is not null or undefined
            if (financialFiguresForm === null || financialFiguresForm === undefined) {
                throw new RequiredError('financialFiguresForm','Required parameter financialFiguresForm was null or undefined when calling calculateAndSetRatio.');
            }
            const localVarPath = `/api/application/{id}/ratio`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof financialFiguresForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(financialFiguresForm !== undefined ? financialFiguresForm : {}) : (financialFiguresForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restart contract signing log
         * @param {number} applicationId 
         * @param {number} logId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSigningLogRestart: async (applicationId: number, logId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling contractSigningLogRestart.');
            }
            // verify required parameter 'logId' is not null or undefined
            if (logId === null || logId === undefined) {
                throw new RequiredError('logId','Required parameter logId was null or undefined when calling contractSigningLogRestart.');
            }
            const localVarPath = `/api/application/{applicationId}/contract-signing-log/{logId}/restart`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"logId"}}`, encodeURIComponent(String(logId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract signing logs
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSigningLogs: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling contractSigningLogs.');
            }
            const localVarPath = `/api/application/{applicationId}/contract-signing-log`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create loan from application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLoansFromApplication: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createLoansFromApplication.');
            }
            const localVarPath = `/api/application/{id}/create-loans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change loan application status to decline by credit committee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineByCreditCommittee: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling declineByCreditCommittee.');
            }
            const localVarPath = `/api/application/{id}/committee-decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change loan application status to decline
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineByReviewer: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling declineByReviewer.');
            }
            const localVarPath = `/api/application/{id}/reviewer-decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [customerLegalName] 
         * @param {string} [customerCipc] 
         * @param {Array<LoanApplicationStatus>} [statuses] 
         * @param {Array<LoanApplicationType>} [types] 
         * @param {Array<LoanApplicationDisclosure>} [disclosures] 
         * @param {string} [submitDateFrom] 
         * @param {string} [submitDateTo] 
         * @param {number} [approvedAmountFrom] 
         * @param {number} [approvedAmountTo] 
         * @param {Array<LoanApplicationTerm>} [approvedTerms] 
         * @param {Array<number>} [loanProducts] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList11: async (id?: string, customerLegalName?: string, customerCipc?: string, statuses?: Array<LoanApplicationStatus>, types?: Array<LoanApplicationType>, disclosures?: Array<LoanApplicationDisclosure>, submitDateFrom?: string, submitDateTo?: string, approvedAmountFrom?: number, approvedAmountTo?: number, approvedTerms?: Array<LoanApplicationTerm>, loanProducts?: Array<number>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/application`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (customerLegalName !== undefined) {
                localVarQueryParameter['customerLegalName'] = customerLegalName;
            }

            if (customerCipc !== undefined) {
                localVarQueryParameter['customerCipc'] = customerCipc;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (disclosures) {
                localVarQueryParameter['disclosures'] = disclosures;
            }

            if (submitDateFrom !== undefined) {
                localVarQueryParameter['submitDateFrom'] = (submitDateFrom as any instanceof Date) ?
                    (submitDateFrom as any).toISOString().substr(0,10) :
                    submitDateFrom;
            }

            if (submitDateTo !== undefined) {
                localVarQueryParameter['submitDateTo'] = (submitDateTo as any instanceof Date) ?
                    (submitDateTo as any).toISOString().substr(0,10) :
                    submitDateTo;
            }

            if (approvedAmountFrom !== undefined) {
                localVarQueryParameter['approvedAmountFrom'] = approvedAmountFrom;
            }

            if (approvedAmountTo !== undefined) {
                localVarQueryParameter['approvedAmountTo'] = approvedAmountTo;
            }

            if (approvedTerms) {
                localVarQueryParameter['approvedTerms'] = approvedTerms;
            }

            if (loanProducts) {
                localVarQueryParameter['loanProducts'] = loanProducts;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView11: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView11.');
            }
            const localVarPath = `/api/application/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer loan application list
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerApplications: async (customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling getCustomerApplications.');
            }
            const localVarPath = `/api/application/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all loans for application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoans1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLoans1.');
            }
            const localVarPath = `/api/application/{id}/loans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information for credit committee review
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initCreditCommitteeReview: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling initCreditCommitteeReview.');
            }
            const localVarPath = `/api/application/{id}/committee-review`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set request note and change loan application status to info requested
         * @param {number} id 
         * @param {RequestInformationForm} requestInformationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAdditionalInformation: async (id: number, requestInformationForm: RequestInformationForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling requestAdditionalInformation.');
            }
            // verify required parameter 'requestInformationForm' is not null or undefined
            if (requestInformationForm === null || requestInformationForm === undefined) {
                throw new RequiredError('requestInformationForm','Required parameter requestInformationForm was null or undefined when calling requestAdditionalInformation.');
            }
            const localVarPath = `/api/application/{id}/request-information`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestInformationForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestInformationForm !== undefined ? requestInformationForm : {}) : (requestInformationForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update loan application comment
         * @param {string} comment 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: async (comment: string, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'comment' is not null or undefined
            if (comment === null || comment === undefined) {
                throw new RequiredError('comment','Required parameter comment was null or undefined when calling updateComment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateComment.');
            }
            const localVarPath = `/api/application/{id}/comment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeLoanApplicationControllerApi - functional programming interface
 * @export
 */
export const BackofficeLoanApplicationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change loan application status to offer issued
         * @param {number} id 
         * @param {ApprovedConditionForm} approvedConditionForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveByCreditCommittee(id: number, approvedConditionForm: ApprovedConditionForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).approveByCreditCommittee(id, approvedConditionForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change loan application status to Credit Committee review
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveByReviewer(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).approveByReviewer(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Calculate and set ratio for loan application
         * @param {number} id 
         * @param {FinancialFiguresForm} financialFiguresForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateAndSetRatio(id: number, financialFiguresForm: FinancialFiguresForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBoolean>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).calculateAndSetRatio(id, financialFiguresForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Restart contract signing log
         * @param {number} applicationId 
         * @param {number} logId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractSigningLogRestart(applicationId: number, logId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).contractSigningLogRestart(applicationId, logId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get contract signing logs
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractSigningLogs(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListContractSigningLog>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).contractSigningLogs(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create loan from application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLoansFromApplication(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyString>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).createLoansFromApplication(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change loan application status to decline by credit committee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declineByCreditCommittee(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).declineByCreditCommittee(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change loan application status to decline
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declineByReviewer(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).declineByReviewer(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [customerLegalName] 
         * @param {string} [customerCipc] 
         * @param {Array<LoanApplicationStatus>} [statuses] 
         * @param {Array<LoanApplicationType>} [types] 
         * @param {Array<LoanApplicationDisclosure>} [disclosures] 
         * @param {string} [submitDateFrom] 
         * @param {string} [submitDateTo] 
         * @param {number} [approvedAmountFrom] 
         * @param {number} [approvedAmountTo] 
         * @param {Array<LoanApplicationTerm>} [approvedTerms] 
         * @param {Array<number>} [loanProducts] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList11(id?: string, customerLegalName?: string, customerCipc?: string, statuses?: Array<LoanApplicationStatus>, types?: Array<LoanApplicationType>, disclosures?: Array<LoanApplicationDisclosure>, submitDateFrom?: string, submitDateTo?: string, approvedAmountFrom?: number, approvedAmountTo?: number, approvedTerms?: Array<LoanApplicationTerm>, loanProducts?: Array<number>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelLoanApplicationListDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).entityList11(id, customerLegalName, customerCipc, statuses, types, disclosures, submitDateFrom, submitDateTo, approvedAmountFrom, approvedAmountTo, approvedTerms, loanProducts, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView11(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).entityView11(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get customer loan application list
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerApplications(customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListLoanApplicationListDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).getCustomerApplications(customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all loans for application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoans1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListLoanViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).getLoans1(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get information for credit committee review
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initCreditCommitteeReview(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCreditCommitteeReviewInfoDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).initCreditCommitteeReview(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set request note and change loan application status to info requested
         * @param {number} id 
         * @param {RequestInformationForm} requestInformationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestAdditionalInformation(id: number, requestInformationForm: RequestInformationForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).requestAdditionalInformation(id, requestInformationForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update loan application comment
         * @param {string} comment 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComment(comment: string, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationControllerApiAxiosParamCreator(configuration).updateComment(comment, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BackofficeLoanApplicationControllerApi - factory interface
 * @export
 */
export const BackofficeLoanApplicationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Change loan application status to offer issued
         * @param {number} id 
         * @param {ApprovedConditionForm} approvedConditionForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveByCreditCommittee(id: number, approvedConditionForm: ApprovedConditionForm, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).approveByCreditCommittee(id, approvedConditionForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change loan application status to Credit Committee review
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveByReviewer(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).approveByReviewer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate and set ratio for loan application
         * @param {number} id 
         * @param {FinancialFiguresForm} financialFiguresForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAndSetRatio(id: number, financialFiguresForm: FinancialFiguresForm, options?: any): AxiosPromise<ApiResponseBodyBoolean> {
            return BackofficeLoanApplicationControllerApiFp(configuration).calculateAndSetRatio(id, financialFiguresForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restart contract signing log
         * @param {number} applicationId 
         * @param {number} logId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSigningLogRestart(applicationId: number, logId: number, options?: any): AxiosPromise<void> {
            return BackofficeLoanApplicationControllerApiFp(configuration).contractSigningLogRestart(applicationId, logId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract signing logs
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSigningLogs(applicationId: number, options?: any): AxiosPromise<ApiResponseBodyListContractSigningLog> {
            return BackofficeLoanApplicationControllerApiFp(configuration).contractSigningLogs(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create loan from application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLoansFromApplication(id: number, options?: any): AxiosPromise<ApiResponseBodyString> {
            return BackofficeLoanApplicationControllerApiFp(configuration).createLoansFromApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change loan application status to decline by credit committee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineByCreditCommittee(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).declineByCreditCommittee(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change loan application status to decline
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineByReviewer(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).declineByReviewer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [customerLegalName] 
         * @param {string} [customerCipc] 
         * @param {Array<LoanApplicationStatus>} [statuses] 
         * @param {Array<LoanApplicationType>} [types] 
         * @param {Array<LoanApplicationDisclosure>} [disclosures] 
         * @param {string} [submitDateFrom] 
         * @param {string} [submitDateTo] 
         * @param {number} [approvedAmountFrom] 
         * @param {number} [approvedAmountTo] 
         * @param {Array<LoanApplicationTerm>} [approvedTerms] 
         * @param {Array<number>} [loanProducts] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList11(id?: string, customerLegalName?: string, customerCipc?: string, statuses?: Array<LoanApplicationStatus>, types?: Array<LoanApplicationType>, disclosures?: Array<LoanApplicationDisclosure>, submitDateFrom?: string, submitDateTo?: string, approvedAmountFrom?: number, approvedAmountTo?: number, approvedTerms?: Array<LoanApplicationTerm>, loanProducts?: Array<number>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelLoanApplicationListDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).entityList11(id, customerLegalName, customerCipc, statuses, types, disclosures, submitDateFrom, submitDateTo, approvedAmountFrom, approvedAmountTo, approvedTerms, loanProducts, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView11(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).entityView11(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer loan application list
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerApplications(customerId: number, options?: any): AxiosPromise<ApiResponseBodyListLoanApplicationListDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).getCustomerApplications(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all loans for application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoans1(id: number, options?: any): AxiosPromise<ApiResponseBodyListLoanViewDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).getLoans1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information for credit committee review
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initCreditCommitteeReview(id: number, options?: any): AxiosPromise<ApiResponseBodyCreditCommitteeReviewInfoDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).initCreditCommitteeReview(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set request note and change loan application status to info requested
         * @param {number} id 
         * @param {RequestInformationForm} requestInformationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAdditionalInformation(id: number, requestInformationForm: RequestInformationForm, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).requestAdditionalInformation(id, requestInformationForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update loan application comment
         * @param {string} comment 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(comment: string, id: number, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return BackofficeLoanApplicationControllerApiFp(configuration).updateComment(comment, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackofficeLoanApplicationControllerApi - object-oriented interface
 * @export
 * @class BackofficeLoanApplicationControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeLoanApplicationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Change loan application status to offer issued
     * @param {number} id 
     * @param {ApprovedConditionForm} approvedConditionForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public approveByCreditCommittee(id: number, approvedConditionForm: ApprovedConditionForm, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).approveByCreditCommittee(id, approvedConditionForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change loan application status to Credit Committee review
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public approveByReviewer(id: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).approveByReviewer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate and set ratio for loan application
     * @param {number} id 
     * @param {FinancialFiguresForm} financialFiguresForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public calculateAndSetRatio(id: number, financialFiguresForm: FinancialFiguresForm, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).calculateAndSetRatio(id, financialFiguresForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restart contract signing log
     * @param {number} applicationId 
     * @param {number} logId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public contractSigningLogRestart(applicationId: number, logId: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).contractSigningLogRestart(applicationId, logId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract signing logs
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public contractSigningLogs(applicationId: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).contractSigningLogs(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create loan from application
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public createLoansFromApplication(id: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).createLoansFromApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change loan application status to decline by credit committee
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public declineByCreditCommittee(id: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).declineByCreditCommittee(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change loan application status to decline
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public declineByReviewer(id: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).declineByReviewer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pageable list of entities
     * @param {string} [id] 
     * @param {string} [customerLegalName] 
     * @param {string} [customerCipc] 
     * @param {Array<LoanApplicationStatus>} [statuses] 
     * @param {Array<LoanApplicationType>} [types] 
     * @param {Array<LoanApplicationDisclosure>} [disclosures] 
     * @param {string} [submitDateFrom] 
     * @param {string} [submitDateTo] 
     * @param {number} [approvedAmountFrom] 
     * @param {number} [approvedAmountTo] 
     * @param {Array<LoanApplicationTerm>} [approvedTerms] 
     * @param {Array<number>} [loanProducts] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public entityList11(id?: string, customerLegalName?: string, customerCipc?: string, statuses?: Array<LoanApplicationStatus>, types?: Array<LoanApplicationType>, disclosures?: Array<LoanApplicationDisclosure>, submitDateFrom?: string, submitDateTo?: string, approvedAmountFrom?: number, approvedAmountTo?: number, approvedTerms?: Array<LoanApplicationTerm>, loanProducts?: Array<number>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).entityList11(id, customerLegalName, customerCipc, statuses, types, disclosures, submitDateFrom, submitDateTo, approvedAmountFrom, approvedAmountTo, approvedTerms, loanProducts, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public entityView11(id: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).entityView11(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer loan application list
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public getCustomerApplications(customerId: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).getCustomerApplications(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all loans for application
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public getLoans1(id: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).getLoans1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information for credit committee review
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public initCreditCommitteeReview(id: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).initCreditCommitteeReview(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set request note and change loan application status to info requested
     * @param {number} id 
     * @param {RequestInformationForm} requestInformationForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public requestAdditionalInformation(id: number, requestInformationForm: RequestInformationForm, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).requestAdditionalInformation(id, requestInformationForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update loan application comment
     * @param {string} comment 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationControllerApi
     */
    public updateComment(comment: string, id: number, options?: any) {
        return BackofficeLoanApplicationControllerApiFp(this.configuration).updateComment(comment, id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BackofficeLoanApplicationDocumentControllerApi - axios parameter creator
 * @export
 */
export const BackofficeLoanApplicationDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download loan application document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadApplicationDocument: async (documentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling downloadApplicationDocument.');
            }
            const localVarPath = `/api/application/document/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export loan application to PDF
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateLoanApplicationPdf: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling generateLoanApplicationPdf.');
            }
            const localVarPath = `/api/application/document/pdf`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get documents for loan application
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentForApplication1: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling getDocumentForApplication1.');
            }
            const localVarPath = `/api/application/document`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeLoanApplicationDocumentControllerApi - functional programming interface
 * @export
 */
export const BackofficeLoanApplicationDocumentControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download loan application document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadApplicationDocument(documentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationDocumentControllerApiAxiosParamCreator(configuration).downloadApplicationDocument(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export loan application to PDF
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateLoanApplicationPdf(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationDocumentControllerApiAxiosParamCreator(configuration).generateLoanApplicationPdf(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get documents for loan application
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentForApplication1(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListDocumentDTO>> {
            const localVarAxiosArgs = await BackofficeLoanApplicationDocumentControllerApiAxiosParamCreator(configuration).getDocumentForApplication1(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BackofficeLoanApplicationDocumentControllerApi - factory interface
 * @export
 */
export const BackofficeLoanApplicationDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Download loan application document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadApplicationDocument(documentId: number, options?: any): AxiosPromise<any> {
            return BackofficeLoanApplicationDocumentControllerApiFp(configuration).downloadApplicationDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export loan application to PDF
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateLoanApplicationPdf(applicationId: number, options?: any): AxiosPromise<any> {
            return BackofficeLoanApplicationDocumentControllerApiFp(configuration).generateLoanApplicationPdf(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get documents for loan application
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentForApplication1(applicationId: number, options?: any): AxiosPromise<ApiResponseBodyListDocumentDTO> {
            return BackofficeLoanApplicationDocumentControllerApiFp(configuration).getDocumentForApplication1(applicationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackofficeLoanApplicationDocumentControllerApi - object-oriented interface
 * @export
 * @class BackofficeLoanApplicationDocumentControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeLoanApplicationDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Download loan application document
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationDocumentControllerApi
     */
    public downloadApplicationDocument(documentId: number, options?: any) {
        return BackofficeLoanApplicationDocumentControllerApiFp(this.configuration).downloadApplicationDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export loan application to PDF
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationDocumentControllerApi
     */
    public generateLoanApplicationPdf(applicationId: number, options?: any) {
        return BackofficeLoanApplicationDocumentControllerApiFp(this.configuration).generateLoanApplicationPdf(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get documents for loan application
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanApplicationDocumentControllerApi
     */
    public getDocumentForApplication1(applicationId: number, options?: any) {
        return BackofficeLoanApplicationDocumentControllerApiFp(this.configuration).getDocumentForApplication1(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BackofficeLoanControllerApi - axios parameter creator
 * @export
 */
export const BackofficeLoanControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [legalName] 
         * @param {string} [cipc] 
         * @param {Array<'ACTIVE' | 'OVERDUE' | 'DEFAULT' | 'PAID_UP' | 'WRITTEN_OFF'>} [statuses] 
         * @param {Array<LoanApplicationType>} [types] 
         * @param {Array<LoanApplicationDisclosure>} [disclosures] 
         * @param {string} [startDateFrom] 
         * @param {string} [startDateTo] 
         * @param {string} [maturityDateFrom] 
         * @param {string} [maturityDateTo] 
         * @param {number} [totalExposureFrom] 
         * @param {number} [totalExposureTo] 
         * @param {string} [productName] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList6: async (id?: string, legalName?: string, cipc?: string, statuses?: Array<'ACTIVE' | 'OVERDUE' | 'DEFAULT' | 'PAID_UP' | 'WRITTEN_OFF'>, types?: Array<LoanApplicationType>, disclosures?: Array<LoanApplicationDisclosure>, startDateFrom?: string, startDateTo?: string, maturityDateFrom?: string, maturityDateTo?: string, totalExposureFrom?: number, totalExposureTo?: number, productName?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (legalName !== undefined) {
                localVarQueryParameter['legalName'] = legalName;
            }

            if (cipc !== undefined) {
                localVarQueryParameter['cipc'] = cipc;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (disclosures) {
                localVarQueryParameter['disclosures'] = disclosures;
            }

            if (startDateFrom !== undefined) {
                localVarQueryParameter['startDateFrom'] = (startDateFrom as any instanceof Date) ?
                    (startDateFrom as any).toISOString().substr(0,10) :
                    startDateFrom;
            }

            if (startDateTo !== undefined) {
                localVarQueryParameter['startDateTo'] = (startDateTo as any instanceof Date) ?
                    (startDateTo as any).toISOString().substr(0,10) :
                    startDateTo;
            }

            if (maturityDateFrom !== undefined) {
                localVarQueryParameter['maturityDateFrom'] = (maturityDateFrom as any instanceof Date) ?
                    (maturityDateFrom as any).toISOString().substr(0,10) :
                    maturityDateFrom;
            }

            if (maturityDateTo !== undefined) {
                localVarQueryParameter['maturityDateTo'] = (maturityDateTo as any instanceof Date) ?
                    (maturityDateTo as any).toISOString().substr(0,10) :
                    maturityDateTo;
            }

            if (totalExposureFrom !== undefined) {
                localVarQueryParameter['totalExposureFrom'] = totalExposureFrom;
            }

            if (totalExposureTo !== undefined) {
                localVarQueryParameter['totalExposureTo'] = totalExposureTo;
            }

            if (productName !== undefined) {
                localVarQueryParameter['productName'] = productName;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView6: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView6.');
            }
            const localVarPath = `/api/loan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recalculate loan from scratch
         * @param {number} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fullLoanRecalculate: async (loanId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            if (loanId === null || loanId === undefined) {
                throw new RequiredError('loanId','Required parameter loanId was null or undefined when calling fullLoanRecalculate.');
            }
            const localVarPath = `/api/loan/{loanId}/recalculate`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all snapshots for loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSnapshots: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAllSnapshots.');
            }
            const localVarPath = `/api/loan/{id}/snapshots`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeLoanControllerApi - functional programming interface
 * @export
 */
export const BackofficeLoanControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [legalName] 
         * @param {string} [cipc] 
         * @param {Array<'ACTIVE' | 'OVERDUE' | 'DEFAULT' | 'PAID_UP' | 'WRITTEN_OFF'>} [statuses] 
         * @param {Array<LoanApplicationType>} [types] 
         * @param {Array<LoanApplicationDisclosure>} [disclosures] 
         * @param {string} [startDateFrom] 
         * @param {string} [startDateTo] 
         * @param {string} [maturityDateFrom] 
         * @param {string} [maturityDateTo] 
         * @param {number} [totalExposureFrom] 
         * @param {number} [totalExposureTo] 
         * @param {string} [productName] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList6(id?: string, legalName?: string, cipc?: string, statuses?: Array<'ACTIVE' | 'OVERDUE' | 'DEFAULT' | 'PAID_UP' | 'WRITTEN_OFF'>, types?: Array<LoanApplicationType>, disclosures?: Array<LoanApplicationDisclosure>, startDateFrom?: string, startDateTo?: string, maturityDateFrom?: string, maturityDateTo?: string, totalExposureFrom?: number, totalExposureTo?: number, productName?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelLoanViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanControllerApiAxiosParamCreator(configuration).entityList6(id, legalName, cipc, statuses, types, disclosures, startDateFrom, startDateTo, maturityDateFrom, maturityDateTo, totalExposureFrom, totalExposureTo, productName, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView6(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanControllerApiAxiosParamCreator(configuration).entityView6(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Recalculate loan from scratch
         * @param {number} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fullLoanRecalculate(loanId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanViewDTO>> {
            const localVarAxiosArgs = await BackofficeLoanControllerApiAxiosParamCreator(configuration).fullLoanRecalculate(loanId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all snapshots for loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSnapshots(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListLoanSnapshotDTO>> {
            const localVarAxiosArgs = await BackofficeLoanControllerApiAxiosParamCreator(configuration).getAllSnapshots(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BackofficeLoanControllerApi - factory interface
 * @export
 */
export const BackofficeLoanControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [legalName] 
         * @param {string} [cipc] 
         * @param {Array<'ACTIVE' | 'OVERDUE' | 'DEFAULT' | 'PAID_UP' | 'WRITTEN_OFF'>} [statuses] 
         * @param {Array<LoanApplicationType>} [types] 
         * @param {Array<LoanApplicationDisclosure>} [disclosures] 
         * @param {string} [startDateFrom] 
         * @param {string} [startDateTo] 
         * @param {string} [maturityDateFrom] 
         * @param {string} [maturityDateTo] 
         * @param {number} [totalExposureFrom] 
         * @param {number} [totalExposureTo] 
         * @param {string} [productName] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList6(id?: string, legalName?: string, cipc?: string, statuses?: Array<'ACTIVE' | 'OVERDUE' | 'DEFAULT' | 'PAID_UP' | 'WRITTEN_OFF'>, types?: Array<LoanApplicationType>, disclosures?: Array<LoanApplicationDisclosure>, startDateFrom?: string, startDateTo?: string, maturityDateFrom?: string, maturityDateTo?: string, totalExposureFrom?: number, totalExposureTo?: number, productName?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelLoanViewDTO> {
            return BackofficeLoanControllerApiFp(configuration).entityList6(id, legalName, cipc, statuses, types, disclosures, startDateFrom, startDateTo, maturityDateFrom, maturityDateTo, totalExposureFrom, totalExposureTo, productName, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView6(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanViewDTO> {
            return BackofficeLoanControllerApiFp(configuration).entityView6(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recalculate loan from scratch
         * @param {number} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fullLoanRecalculate(loanId: number, options?: any): AxiosPromise<ApiResponseBodyLoanViewDTO> {
            return BackofficeLoanControllerApiFp(configuration).fullLoanRecalculate(loanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all snapshots for loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSnapshots(id: number, options?: any): AxiosPromise<ApiResponseBodyListLoanSnapshotDTO> {
            return BackofficeLoanControllerApiFp(configuration).getAllSnapshots(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackofficeLoanControllerApi - object-oriented interface
 * @export
 * @class BackofficeLoanControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeLoanControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get pageable list of entities
     * @param {string} [id] 
     * @param {string} [legalName] 
     * @param {string} [cipc] 
     * @param {Array<'ACTIVE' | 'OVERDUE' | 'DEFAULT' | 'PAID_UP' | 'WRITTEN_OFF'>} [statuses] 
     * @param {Array<LoanApplicationType>} [types] 
     * @param {Array<LoanApplicationDisclosure>} [disclosures] 
     * @param {string} [startDateFrom] 
     * @param {string} [startDateTo] 
     * @param {string} [maturityDateFrom] 
     * @param {string} [maturityDateTo] 
     * @param {number} [totalExposureFrom] 
     * @param {number} [totalExposureTo] 
     * @param {string} [productName] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanControllerApi
     */
    public entityList6(id?: string, legalName?: string, cipc?: string, statuses?: Array<'ACTIVE' | 'OVERDUE' | 'DEFAULT' | 'PAID_UP' | 'WRITTEN_OFF'>, types?: Array<LoanApplicationType>, disclosures?: Array<LoanApplicationDisclosure>, startDateFrom?: string, startDateTo?: string, maturityDateFrom?: string, maturityDateTo?: string, totalExposureFrom?: number, totalExposureTo?: number, productName?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return BackofficeLoanControllerApiFp(this.configuration).entityList6(id, legalName, cipc, statuses, types, disclosures, startDateFrom, startDateTo, maturityDateFrom, maturityDateTo, totalExposureFrom, totalExposureTo, productName, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanControllerApi
     */
    public entityView6(id: number, options?: any) {
        return BackofficeLoanControllerApiFp(this.configuration).entityView6(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recalculate loan from scratch
     * @param {number} loanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanControllerApi
     */
    public fullLoanRecalculate(loanId: number, options?: any) {
        return BackofficeLoanControllerApiFp(this.configuration).fullLoanRecalculate(loanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all snapshots for loan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeLoanControllerApi
     */
    public getAllSnapshots(id: number, options?: any) {
        return BackofficeLoanControllerApiFp(this.configuration).getAllSnapshots(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BackofficeReportControllerApi - axios parameter creator
 * @export
 */
export const BackofficeReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download report
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport: async (reportId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            if (reportId === null || reportId === undefined) {
                throw new RequiredError('reportId','Required parameter reportId was null or undefined when calling downloadReport.');
            }
            const localVarPath = `/api/report/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList4: async (dateFrom?: string, dateTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView4: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView4.');
            }
            const localVarPath = `/api/report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get statistics about loan portfolio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/statistic`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Regenerate report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateReport: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling regenerateReport.');
            }
            const localVarPath = `/api/report/{id}/regenerate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeReportControllerApi - functional programming interface
 * @export
 */
export const BackofficeReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download report
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReport(reportId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await BackofficeReportControllerApiAxiosParamCreator(configuration).downloadReport(reportId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList4(dateFrom?: string, dateTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelReportDTO>> {
            const localVarAxiosArgs = await BackofficeReportControllerApiAxiosParamCreator(configuration).entityList4(dateFrom, dateTo, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView4(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyReportDTO>> {
            const localVarAxiosArgs = await BackofficeReportControllerApiAxiosParamCreator(configuration).entityView4(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get statistics about loan portfolio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatistics(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListDisbursementAccountStatistic>> {
            const localVarAxiosArgs = await BackofficeReportControllerApiAxiosParamCreator(configuration).getStatistics(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Regenerate report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerateReport(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBoolean>> {
            const localVarAxiosArgs = await BackofficeReportControllerApiAxiosParamCreator(configuration).regenerateReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BackofficeReportControllerApi - factory interface
 * @export
 */
export const BackofficeReportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Download report
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(reportId: number, options?: any): AxiosPromise<any> {
            return BackofficeReportControllerApiFp(configuration).downloadReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList4(dateFrom?: string, dateTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelReportDTO> {
            return BackofficeReportControllerApiFp(configuration).entityList4(dateFrom, dateTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView4(id: number, options?: any): AxiosPromise<ApiResponseBodyReportDTO> {
            return BackofficeReportControllerApiFp(configuration).entityView4(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get statistics about loan portfolio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics(options?: any): AxiosPromise<ApiResponseBodyListDisbursementAccountStatistic> {
            return BackofficeReportControllerApiFp(configuration).getStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Regenerate report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateReport(id: number, options?: any): AxiosPromise<ApiResponseBodyBoolean> {
            return BackofficeReportControllerApiFp(configuration).regenerateReport(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackofficeReportControllerApi - object-oriented interface
 * @export
 * @class BackofficeReportControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeReportControllerApi extends BaseAPI {
    /**
     * 
     * @summary Download report
     * @param {number} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeReportControllerApi
     */
    public downloadReport(reportId: number, options?: any) {
        return BackofficeReportControllerApiFp(this.configuration).downloadReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pageable list of entities
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeReportControllerApi
     */
    public entityList4(dateFrom?: string, dateTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return BackofficeReportControllerApiFp(this.configuration).entityList4(dateFrom, dateTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeReportControllerApi
     */
    public entityView4(id: number, options?: any) {
        return BackofficeReportControllerApiFp(this.configuration).entityView4(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get statistics about loan portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeReportControllerApi
     */
    public getStatistics(options?: any) {
        return BackofficeReportControllerApiFp(this.configuration).getStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Regenerate report
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeReportControllerApi
     */
    public regenerateReport(id: number, options?: any) {
        return BackofficeReportControllerApiFp(this.configuration).regenerateReport(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BackofficeRoleControllerApi - axios parameter creator
 * @export
 */
export const BackofficeRoleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get authority list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorities: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/authorities`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new backoffice user role
         * @param {CreateBackofficeRoleForm} createBackofficeRoleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (createBackofficeRoleForm: CreateBackofficeRoleForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBackofficeRoleForm' is not null or undefined
            if (createBackofficeRoleForm === null || createBackofficeRoleForm === undefined) {
                throw new RequiredError('createBackofficeRoleForm','Required parameter createBackofficeRoleForm was null or undefined when calling createRole.');
            }
            const localVarPath = `/api/role/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createBackofficeRoleForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createBackofficeRoleForm !== undefined ? createBackofficeRoleForm : {}) : (createBackofficeRoleForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList3: async (id?: string, name?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView3: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView3.');
            }
            const localVarPath = `/api/role/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update backoffice user role
         * @param {UpdateBackofficeRoleForm} updateBackofficeRoleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (updateBackofficeRoleForm: UpdateBackofficeRoleForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBackofficeRoleForm' is not null or undefined
            if (updateBackofficeRoleForm === null || updateBackofficeRoleForm === undefined) {
                throw new RequiredError('updateBackofficeRoleForm','Required parameter updateBackofficeRoleForm was null or undefined when calling updateRole.');
            }
            const localVarPath = `/api/role/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateBackofficeRoleForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateBackofficeRoleForm !== undefined ? updateBackofficeRoleForm : {}) : (updateBackofficeRoleForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeRoleControllerApi - functional programming interface
 * @export
 */
export const BackofficeRoleControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get authority list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorities(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListAuthorityItem>> {
            const localVarAxiosArgs = await BackofficeRoleControllerApiAxiosParamCreator(configuration).authorities(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create new backoffice user role
         * @param {CreateBackofficeRoleForm} createBackofficeRoleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(createBackofficeRoleForm: CreateBackofficeRoleForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBackofficeRoleDTO>> {
            const localVarAxiosArgs = await BackofficeRoleControllerApiAxiosParamCreator(configuration).createRole(createBackofficeRoleForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList3(id?: string, name?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelBackofficeRoleDTO>> {
            const localVarAxiosArgs = await BackofficeRoleControllerApiAxiosParamCreator(configuration).entityList3(id, name, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView3(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBackofficeRoleDTO>> {
            const localVarAxiosArgs = await BackofficeRoleControllerApiAxiosParamCreator(configuration).entityView3(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update backoffice user role
         * @param {UpdateBackofficeRoleForm} updateBackofficeRoleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(updateBackofficeRoleForm: UpdateBackofficeRoleForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBackofficeRoleDTO>> {
            const localVarAxiosArgs = await BackofficeRoleControllerApiAxiosParamCreator(configuration).updateRole(updateBackofficeRoleForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BackofficeRoleControllerApi - factory interface
 * @export
 */
export const BackofficeRoleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get authority list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorities(options?: any): AxiosPromise<ApiResponseBodyListAuthorityItem> {
            return BackofficeRoleControllerApiFp(configuration).authorities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new backoffice user role
         * @param {CreateBackofficeRoleForm} createBackofficeRoleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(createBackofficeRoleForm: CreateBackofficeRoleForm, options?: any): AxiosPromise<ApiResponseBodyBackofficeRoleDTO> {
            return BackofficeRoleControllerApiFp(configuration).createRole(createBackofficeRoleForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList3(id?: string, name?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelBackofficeRoleDTO> {
            return BackofficeRoleControllerApiFp(configuration).entityList3(id, name, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView3(id: number, options?: any): AxiosPromise<ApiResponseBodyBackofficeRoleDTO> {
            return BackofficeRoleControllerApiFp(configuration).entityView3(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update backoffice user role
         * @param {UpdateBackofficeRoleForm} updateBackofficeRoleForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(updateBackofficeRoleForm: UpdateBackofficeRoleForm, options?: any): AxiosPromise<ApiResponseBodyBackofficeRoleDTO> {
            return BackofficeRoleControllerApiFp(configuration).updateRole(updateBackofficeRoleForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackofficeRoleControllerApi - object-oriented interface
 * @export
 * @class BackofficeRoleControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeRoleControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get authority list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeRoleControllerApi
     */
    public authorities(options?: any) {
        return BackofficeRoleControllerApiFp(this.configuration).authorities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new backoffice user role
     * @param {CreateBackofficeRoleForm} createBackofficeRoleForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeRoleControllerApi
     */
    public createRole(createBackofficeRoleForm: CreateBackofficeRoleForm, options?: any) {
        return BackofficeRoleControllerApiFp(this.configuration).createRole(createBackofficeRoleForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pageable list of entities
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeRoleControllerApi
     */
    public entityList3(id?: string, name?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return BackofficeRoleControllerApiFp(this.configuration).entityList3(id, name, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeRoleControllerApi
     */
    public entityView3(id: number, options?: any) {
        return BackofficeRoleControllerApiFp(this.configuration).entityView3(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update backoffice user role
     * @param {UpdateBackofficeRoleForm} updateBackofficeRoleForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeRoleControllerApi
     */
    public updateRole(updateBackofficeRoleForm: UpdateBackofficeRoleForm, options?: any) {
        return BackofficeRoleControllerApiFp(this.configuration).updateRole(updateBackofficeRoleForm, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BackofficeUserControllerApi - axios parameter creator
 * @export
 */
export const BackofficeUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change backoffice user status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activate.');
            }
            const localVarPath = `/api/users/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change backoffice user password
         * @param {number} id 
         * @param {PasswordForm} passwordForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (id: number, passwordForm: PasswordForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling changePassword.');
            }
            // verify required parameter 'passwordForm' is not null or undefined
            if (passwordForm === null || passwordForm === undefined) {
                throw new RequiredError('passwordForm','Required parameter passwordForm was null or undefined when calling changePassword.');
            }
            const localVarPath = `/api/users/{id}/change-password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof passwordForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(passwordForm !== undefined ? passwordForm : {}) : (passwordForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new backoffice user
         * @param {CreateBackofficeUserForm} createBackofficeUserForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createBackofficeUserForm: CreateBackofficeUserForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBackofficeUserForm' is not null or undefined
            if (createBackofficeUserForm === null || createBackofficeUserForm === undefined) {
                throw new RequiredError('createBackofficeUserForm','Required parameter createBackofficeUserForm was null or undefined when calling createUser.');
            }
            const localVarPath = `/api/users/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createBackofficeUserForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createBackofficeUserForm !== undefined ? createBackofficeUserForm : {}) : (createBackofficeUserForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change backoffice user status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deactivate.');
            }
            const localVarPath = `/api/users/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [fullName] 
         * @param {Array<number>} [roles] 
         * @param {string} [email] 
         * @param {Array<UserStatus>} [statuses] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList: async (id?: string, fullName?: string, roles?: Array<number>, email?: string, statuses?: Array<UserStatus>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['fullName'] = fullName;
            }

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView.');
            }
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return backoffice user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileByKeycloakId: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update backoffice user
         * @param {UpdateBackofficeUserForm} updateBackofficeUserForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (updateBackofficeUserForm: UpdateBackofficeUserForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBackofficeUserForm' is not null or undefined
            if (updateBackofficeUserForm === null || updateBackofficeUserForm === undefined) {
                throw new RequiredError('updateBackofficeUserForm','Required parameter updateBackofficeUserForm was null or undefined when calling updateUser.');
            }
            const localVarPath = `/api/users/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateBackofficeUserForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateBackofficeUserForm !== undefined ? updateBackofficeUserForm : {}) : (updateBackofficeUserForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeUserControllerApi - functional programming interface
 * @export
 */
export const BackofficeUserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change backoffice user status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBackofficeUserDTO>> {
            const localVarAxiosArgs = await BackofficeUserControllerApiAxiosParamCreator(configuration).activate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change backoffice user password
         * @param {number} id 
         * @param {PasswordForm} passwordForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(id: number, passwordForm: PasswordForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBoolean>> {
            const localVarAxiosArgs = await BackofficeUserControllerApiAxiosParamCreator(configuration).changePassword(id, passwordForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create new backoffice user
         * @param {CreateBackofficeUserForm} createBackofficeUserForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createBackofficeUserForm: CreateBackofficeUserForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBackofficeUserDTO>> {
            const localVarAxiosArgs = await BackofficeUserControllerApiAxiosParamCreator(configuration).createUser(createBackofficeUserForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change backoffice user status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBackofficeUserDTO>> {
            const localVarAxiosArgs = await BackofficeUserControllerApiAxiosParamCreator(configuration).deactivate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [fullName] 
         * @param {Array<number>} [roles] 
         * @param {string} [email] 
         * @param {Array<UserStatus>} [statuses] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList(id?: string, fullName?: string, roles?: Array<number>, email?: string, statuses?: Array<UserStatus>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelBackofficeUserDTO>> {
            const localVarAxiosArgs = await BackofficeUserControllerApiAxiosParamCreator(configuration).entityList(id, fullName, roles, email, statuses, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBackofficeUserDTO>> {
            const localVarAxiosArgs = await BackofficeUserControllerApiAxiosParamCreator(configuration).entityView(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return backoffice user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileByKeycloakId(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBackOfficeUserProfileDTO>> {
            const localVarAxiosArgs = await BackofficeUserControllerApiAxiosParamCreator(configuration).getProfileByKeycloakId(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update backoffice user
         * @param {UpdateBackofficeUserForm} updateBackofficeUserForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(updateBackofficeUserForm: UpdateBackofficeUserForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBackofficeUserDTO>> {
            const localVarAxiosArgs = await BackofficeUserControllerApiAxiosParamCreator(configuration).updateUser(updateBackofficeUserForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BackofficeUserControllerApi - factory interface
 * @export
 */
export const BackofficeUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Change backoffice user status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(id: number, options?: any): AxiosPromise<ApiResponseBodyBackofficeUserDTO> {
            return BackofficeUserControllerApiFp(configuration).activate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change backoffice user password
         * @param {number} id 
         * @param {PasswordForm} passwordForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(id: number, passwordForm: PasswordForm, options?: any): AxiosPromise<ApiResponseBodyBoolean> {
            return BackofficeUserControllerApiFp(configuration).changePassword(id, passwordForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new backoffice user
         * @param {CreateBackofficeUserForm} createBackofficeUserForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createBackofficeUserForm: CreateBackofficeUserForm, options?: any): AxiosPromise<ApiResponseBodyBackofficeUserDTO> {
            return BackofficeUserControllerApiFp(configuration).createUser(createBackofficeUserForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change backoffice user status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate(id: number, options?: any): AxiosPromise<ApiResponseBodyBackofficeUserDTO> {
            return BackofficeUserControllerApiFp(configuration).deactivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [fullName] 
         * @param {Array<number>} [roles] 
         * @param {string} [email] 
         * @param {Array<UserStatus>} [statuses] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList(id?: string, fullName?: string, roles?: Array<number>, email?: string, statuses?: Array<UserStatus>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelBackofficeUserDTO> {
            return BackofficeUserControllerApiFp(configuration).entityList(id, fullName, roles, email, statuses, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView(id: number, options?: any): AxiosPromise<ApiResponseBodyBackofficeUserDTO> {
            return BackofficeUserControllerApiFp(configuration).entityView(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return backoffice user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileByKeycloakId(options?: any): AxiosPromise<ApiResponseBodyBackOfficeUserProfileDTO> {
            return BackofficeUserControllerApiFp(configuration).getProfileByKeycloakId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update backoffice user
         * @param {UpdateBackofficeUserForm} updateBackofficeUserForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(updateBackofficeUserForm: UpdateBackofficeUserForm, options?: any): AxiosPromise<ApiResponseBodyBackofficeUserDTO> {
            return BackofficeUserControllerApiFp(configuration).updateUser(updateBackofficeUserForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackofficeUserControllerApi - object-oriented interface
 * @export
 * @class BackofficeUserControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeUserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Change backoffice user status to Active
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeUserControllerApi
     */
    public activate(id: number, options?: any) {
        return BackofficeUserControllerApiFp(this.configuration).activate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change backoffice user password
     * @param {number} id 
     * @param {PasswordForm} passwordForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeUserControllerApi
     */
    public changePassword(id: number, passwordForm: PasswordForm, options?: any) {
        return BackofficeUserControllerApiFp(this.configuration).changePassword(id, passwordForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new backoffice user
     * @param {CreateBackofficeUserForm} createBackofficeUserForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeUserControllerApi
     */
    public createUser(createBackofficeUserForm: CreateBackofficeUserForm, options?: any) {
        return BackofficeUserControllerApiFp(this.configuration).createUser(createBackofficeUserForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change backoffice user status to Inactive
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeUserControllerApi
     */
    public deactivate(id: number, options?: any) {
        return BackofficeUserControllerApiFp(this.configuration).deactivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pageable list of entities
     * @param {string} [id] 
     * @param {string} [fullName] 
     * @param {Array<number>} [roles] 
     * @param {string} [email] 
     * @param {Array<UserStatus>} [statuses] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeUserControllerApi
     */
    public entityList(id?: string, fullName?: string, roles?: Array<number>, email?: string, statuses?: Array<UserStatus>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return BackofficeUserControllerApiFp(this.configuration).entityList(id, fullName, roles, email, statuses, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeUserControllerApi
     */
    public entityView(id: number, options?: any) {
        return BackofficeUserControllerApiFp(this.configuration).entityView(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return backoffice user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeUserControllerApi
     */
    public getProfileByKeycloakId(options?: any) {
        return BackofficeUserControllerApiFp(this.configuration).getProfileByKeycloakId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update backoffice user
     * @param {UpdateBackofficeUserForm} updateBackofficeUserForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeUserControllerApi
     */
    public updateUser(updateBackofficeUserForm: UpdateBackofficeUserForm, options?: any) {
        return BackofficeUserControllerApiFp(this.configuration).updateUser(updateBackofficeUserForm, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CsvLogControllerApi - axios parameter creator
 * @export
 */
export const CsvLogControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download csv example by type
         * @param {'PAYMENTS'} csvType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsvExample: async (csvType: 'PAYMENTS', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvType' is not null or undefined
            if (csvType === null || csvType === undefined) {
                throw new RequiredError('csvType','Required parameter csvType was null or undefined when calling downloadCsvExample.');
            }
            const localVarPath = `/api/csv/log/example`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (csvType !== undefined) {
                localVarQueryParameter['csvType'] = csvType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download csv log file
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsvFile: async (id: number, documentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadCsvFile.');
            }
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling downloadCsvFile.');
            }
            const localVarPath = `/api/csv/log/{id}/document/{documentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [user] 
         * @param {Array<'SUCCESSFUL' | 'FAILURE'>} [statuses] 
         * @param {string} [createdAtFrom] 
         * @param {string} [createdAtTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList10: async (id?: string, user?: string, statuses?: Array<'SUCCESSFUL' | 'FAILURE'>, createdAtFrom?: string, createdAtTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/csv/log`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = (createdAtFrom as any instanceof Date) ?
                    (createdAtFrom as any).toISOString().substr(0,10) :
                    createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = (createdAtTo as any instanceof Date) ?
                    (createdAtTo as any).toISOString().substr(0,10) :
                    createdAtTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView10: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView10.');
            }
            const localVarPath = `/api/csv/log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import csv by type
         * @param {'PAYMENTS'} csvType 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsv: async (csvType: 'PAYMENTS', file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvType' is not null or undefined
            if (csvType === null || csvType === undefined) {
                throw new RequiredError('csvType','Required parameter csvType was null or undefined when calling importCsv.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling importCsv.');
            }
            const localVarPath = `/api/csv/log/import`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (csvType !== undefined) {
                localVarQueryParameter['csvType'] = csvType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CsvLogControllerApi - functional programming interface
 * @export
 */
export const CsvLogControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download csv example by type
         * @param {'PAYMENTS'} csvType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCsvExample(csvType: 'PAYMENTS', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await CsvLogControllerApiAxiosParamCreator(configuration).downloadCsvExample(csvType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download csv log file
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCsvFile(id: number, documentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await CsvLogControllerApiAxiosParamCreator(configuration).downloadCsvFile(id, documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [user] 
         * @param {Array<'SUCCESSFUL' | 'FAILURE'>} [statuses] 
         * @param {string} [createdAtFrom] 
         * @param {string} [createdAtTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList10(id?: string, user?: string, statuses?: Array<'SUCCESSFUL' | 'FAILURE'>, createdAtFrom?: string, createdAtTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelCsvLogDTO>> {
            const localVarAxiosArgs = await CsvLogControllerApiAxiosParamCreator(configuration).entityList10(id, user, statuses, createdAtFrom, createdAtTo, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView10(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCsvLogDTO>> {
            const localVarAxiosArgs = await CsvLogControllerApiAxiosParamCreator(configuration).entityView10(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Import csv by type
         * @param {'PAYMENTS'} csvType 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importCsv(csvType: 'PAYMENTS', file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyString>> {
            const localVarAxiosArgs = await CsvLogControllerApiAxiosParamCreator(configuration).importCsv(csvType, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CsvLogControllerApi - factory interface
 * @export
 */
export const CsvLogControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Download csv example by type
         * @param {'PAYMENTS'} csvType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsvExample(csvType: 'PAYMENTS', options?: any): AxiosPromise<Array<string>> {
            return CsvLogControllerApiFp(configuration).downloadCsvExample(csvType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download csv log file
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsvFile(id: number, documentId: number, options?: any): AxiosPromise<any> {
            return CsvLogControllerApiFp(configuration).downloadCsvFile(id, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [user] 
         * @param {Array<'SUCCESSFUL' | 'FAILURE'>} [statuses] 
         * @param {string} [createdAtFrom] 
         * @param {string} [createdAtTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList10(id?: string, user?: string, statuses?: Array<'SUCCESSFUL' | 'FAILURE'>, createdAtFrom?: string, createdAtTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelCsvLogDTO> {
            return CsvLogControllerApiFp(configuration).entityList10(id, user, statuses, createdAtFrom, createdAtTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView10(id: number, options?: any): AxiosPromise<ApiResponseBodyCsvLogDTO> {
            return CsvLogControllerApiFp(configuration).entityView10(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import csv by type
         * @param {'PAYMENTS'} csvType 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsv(csvType: 'PAYMENTS', file: any, options?: any): AxiosPromise<ApiResponseBodyString> {
            return CsvLogControllerApiFp(configuration).importCsv(csvType, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CsvLogControllerApi - object-oriented interface
 * @export
 * @class CsvLogControllerApi
 * @extends {BaseAPI}
 */
export class CsvLogControllerApi extends BaseAPI {
    /**
     * 
     * @summary Download csv example by type
     * @param {'PAYMENTS'} csvType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvLogControllerApi
     */
    public downloadCsvExample(csvType: 'PAYMENTS', options?: any) {
        return CsvLogControllerApiFp(this.configuration).downloadCsvExample(csvType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download csv log file
     * @param {number} id 
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvLogControllerApi
     */
    public downloadCsvFile(id: number, documentId: number, options?: any) {
        return CsvLogControllerApiFp(this.configuration).downloadCsvFile(id, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pageable list of entities
     * @param {string} [id] 
     * @param {string} [user] 
     * @param {Array<'SUCCESSFUL' | 'FAILURE'>} [statuses] 
     * @param {string} [createdAtFrom] 
     * @param {string} [createdAtTo] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvLogControllerApi
     */
    public entityList10(id?: string, user?: string, statuses?: Array<'SUCCESSFUL' | 'FAILURE'>, createdAtFrom?: string, createdAtTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return CsvLogControllerApiFp(this.configuration).entityList10(id, user, statuses, createdAtFrom, createdAtTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvLogControllerApi
     */
    public entityView10(id: number, options?: any) {
        return CsvLogControllerApiFp(this.configuration).entityView10(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import csv by type
     * @param {'PAYMENTS'} csvType 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvLogControllerApi
     */
    public importCsv(csvType: 'PAYMENTS', file: any, options?: any) {
        return CsvLogControllerApiFp(this.configuration).importCsv(csvType, file, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * DocumentTemplateControllerApi - axios parameter creator
 * @export
 */
export const DocumentTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [title] 
         * @param {Array<'TXT' | 'HTML' | 'PDF'>} [outputFormat] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList8: async (title?: string, outputFormat?: Array<'TXT' | 'HTML' | 'PDF'>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/document/template`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (outputFormat) {
                localVarQueryParameter['outputFormat'] = outputFormat;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView8: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView8.');
            }
            const localVarPath = `/api/document/template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplateControllerApi - functional programming interface
 * @export
 */
export const DocumentTemplateControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [title] 
         * @param {Array<'TXT' | 'HTML' | 'PDF'>} [outputFormat] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList8(title?: string, outputFormat?: Array<'TXT' | 'HTML' | 'PDF'>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelDocumentTemplateDTO>> {
            const localVarAxiosArgs = await DocumentTemplateControllerApiAxiosParamCreator(configuration).entityList8(title, outputFormat, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView8(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyDocumentTemplateDTO>> {
            const localVarAxiosArgs = await DocumentTemplateControllerApiAxiosParamCreator(configuration).entityView8(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentTemplateControllerApi - factory interface
 * @export
 */
export const DocumentTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [title] 
         * @param {Array<'TXT' | 'HTML' | 'PDF'>} [outputFormat] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList8(title?: string, outputFormat?: Array<'TXT' | 'HTML' | 'PDF'>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelDocumentTemplateDTO> {
            return DocumentTemplateControllerApiFp(configuration).entityList8(title, outputFormat, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView8(id: number, options?: any): AxiosPromise<ApiResponseBodyDocumentTemplateDTO> {
            return DocumentTemplateControllerApiFp(configuration).entityView8(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTemplateControllerApi - object-oriented interface
 * @export
 * @class DocumentTemplateControllerApi
 * @extends {BaseAPI}
 */
export class DocumentTemplateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get pageable list of entities
     * @param {string} [title] 
     * @param {Array<'TXT' | 'HTML' | 'PDF'>} [outputFormat] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public entityList8(title?: string, outputFormat?: Array<'TXT' | 'HTML' | 'PDF'>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return DocumentTemplateControllerApiFp(this.configuration).entityList8(title, outputFormat, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public entityView8(id: number, options?: any) {
        return DocumentTemplateControllerApiFp(this.configuration).entityView8(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoanOperationControllerApi - axios parameter creator
 * @export
 */
export const LoanOperationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create payment loan operation
         * @param {LoanPaymentParams} loanPaymentParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment: async (loanPaymentParams: LoanPaymentParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanPaymentParams' is not null or undefined
            if (loanPaymentParams === null || loanPaymentParams === undefined) {
                throw new RequiredError('loanPaymentParams','Required parameter loanPaymentParams was null or undefined when calling createPayment.');
            }
            const localVarPath = `/api/loan/operation/create-payment`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loanPaymentParams !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loanPaymentParams !== undefined ? loanPaymentParams : {}) : (loanPaymentParams || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create WriteOff loan operation
         * @param {LoanWriteOffParams} loanWriteOffParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWriteOffOperation: async (loanWriteOffParams: LoanWriteOffParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanWriteOffParams' is not null or undefined
            if (loanWriteOffParams === null || loanWriteOffParams === undefined) {
                throw new RequiredError('loanWriteOffParams','Required parameter loanWriteOffParams was null or undefined when calling createWriteOffOperation.');
            }
            const localVarPath = `/api/loan/operation/write-off`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loanWriteOffParams !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loanWriteOffParams !== undefined ? loanWriteOffParams : {}) : (loanWriteOffParams || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get params for loan operation creation
         * @param {number} loanId 
         * @param {'DISBURSEMENT' | 'PAYMENT' | 'WRITE_OFF'} loanOperationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initLoanOperationCreation: async (loanId: number, loanOperationType: 'DISBURSEMENT' | 'PAYMENT' | 'WRITE_OFF', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            if (loanId === null || loanId === undefined) {
                throw new RequiredError('loanId','Required parameter loanId was null or undefined when calling initLoanOperationCreation.');
            }
            // verify required parameter 'loanOperationType' is not null or undefined
            if (loanOperationType === null || loanOperationType === undefined) {
                throw new RequiredError('loanOperationType','Required parameter loanOperationType was null or undefined when calling initLoanOperationCreation.');
            }
            const localVarPath = `/api/loan/operation/init`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }

            if (loanOperationType !== undefined) {
                localVarQueryParameter['loanOperationType'] = loanOperationType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanOperationControllerApi - functional programming interface
 * @export
 */
export const LoanOperationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create payment loan operation
         * @param {LoanPaymentParams} loanPaymentParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPayment(loanPaymentParams: LoanPaymentParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LoanOperationControllerApiAxiosParamCreator(configuration).createPayment(loanPaymentParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create WriteOff loan operation
         * @param {LoanWriteOffParams} loanWriteOffParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWriteOffOperation(loanWriteOffParams: LoanWriteOffParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LoanOperationControllerApiAxiosParamCreator(configuration).createWriteOffOperation(loanWriteOffParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get params for loan operation creation
         * @param {number} loanId 
         * @param {'DISBURSEMENT' | 'PAYMENT' | 'WRITE_OFF'} loanOperationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initLoanOperationCreation(loanId: number, loanOperationType: 'DISBURSEMENT' | 'PAYMENT' | 'WRITE_OFF', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBaseLoanOperationParams>> {
            const localVarAxiosArgs = await LoanOperationControllerApiAxiosParamCreator(configuration).initLoanOperationCreation(loanId, loanOperationType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanOperationControllerApi - factory interface
 * @export
 */
export const LoanOperationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create payment loan operation
         * @param {LoanPaymentParams} loanPaymentParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment(loanPaymentParams: LoanPaymentParams, options?: any): AxiosPromise<void> {
            return LoanOperationControllerApiFp(configuration).createPayment(loanPaymentParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create WriteOff loan operation
         * @param {LoanWriteOffParams} loanWriteOffParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWriteOffOperation(loanWriteOffParams: LoanWriteOffParams, options?: any): AxiosPromise<void> {
            return LoanOperationControllerApiFp(configuration).createWriteOffOperation(loanWriteOffParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get params for loan operation creation
         * @param {number} loanId 
         * @param {'DISBURSEMENT' | 'PAYMENT' | 'WRITE_OFF'} loanOperationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initLoanOperationCreation(loanId: number, loanOperationType: 'DISBURSEMENT' | 'PAYMENT' | 'WRITE_OFF', options?: any): AxiosPromise<ApiResponseBodyBaseLoanOperationParams> {
            return LoanOperationControllerApiFp(configuration).initLoanOperationCreation(loanId, loanOperationType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanOperationControllerApi - object-oriented interface
 * @export
 * @class LoanOperationControllerApi
 * @extends {BaseAPI}
 */
export class LoanOperationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create payment loan operation
     * @param {LoanPaymentParams} loanPaymentParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanOperationControllerApi
     */
    public createPayment(loanPaymentParams: LoanPaymentParams, options?: any) {
        return LoanOperationControllerApiFp(this.configuration).createPayment(loanPaymentParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create WriteOff loan operation
     * @param {LoanWriteOffParams} loanWriteOffParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanOperationControllerApi
     */
    public createWriteOffOperation(loanWriteOffParams: LoanWriteOffParams, options?: any) {
        return LoanOperationControllerApiFp(this.configuration).createWriteOffOperation(loanWriteOffParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get params for loan operation creation
     * @param {number} loanId 
     * @param {'DISBURSEMENT' | 'PAYMENT' | 'WRITE_OFF'} loanOperationType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanOperationControllerApi
     */
    public initLoanOperationCreation(loanId: number, loanOperationType: 'DISBURSEMENT' | 'PAYMENT' | 'WRITE_OFF', options?: any) {
        return LoanOperationControllerApiFp(this.configuration).initLoanOperationCreation(loanId, loanOperationType, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoanProductControllerApi - axios parameter creator
 * @export
 */
export const LoanProductControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change loan product status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate2: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activate2.');
            }
            const localVarPath = `/api/loan/product/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new loan product
         * @param {CreateLoanProductForm} createLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLoanProduct: async (createLoanProductForm: CreateLoanProductForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLoanProductForm' is not null or undefined
            if (createLoanProductForm === null || createLoanProductForm === undefined) {
                throw new RequiredError('createLoanProductForm','Required parameter createLoanProductForm was null or undefined when calling createLoanProduct.');
            }
            const localVarPath = `/api/loan/product/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createLoanProductForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createLoanProductForm !== undefined ? createLoanProductForm : {}) : (createLoanProductForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change loan product status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate2: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deactivate2.');
            }
            const localVarPath = `/api/loan/product/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {Array<string>} [productNames] 
         * @param {Array<LoanProductStatus>} [statuses] 
         * @param {number} [interestRateFrom] 
         * @param {number} [interestRateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList7: async (id?: string, productNames?: Array<string>, statuses?: Array<LoanProductStatus>, interestRateFrom?: number, interestRateTo?: number, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan/product`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (productNames) {
                localVarQueryParameter['productNames'] = productNames;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (interestRateFrom !== undefined) {
                localVarQueryParameter['interestRateFrom'] = interestRateFrom;
            }

            if (interestRateTo !== undefined) {
                localVarQueryParameter['interestRateTo'] = interestRateTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView7: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView7.');
            }
            const localVarPath = `/api/loan/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get map with all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductMap: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan/product/all-product-map`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update loan product
         * @param {UpdateLoanProductForm} updateLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanProduct: async (updateLoanProductForm: UpdateLoanProductForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLoanProductForm' is not null or undefined
            if (updateLoanProductForm === null || updateLoanProductForm === undefined) {
                throw new RequiredError('updateLoanProductForm','Required parameter updateLoanProductForm was null or undefined when calling updateLoanProduct.');
            }
            const localVarPath = `/api/loan/product/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateLoanProductForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateLoanProductForm !== undefined ? updateLoanProductForm : {}) : (updateLoanProductForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanProductControllerApi - functional programming interface
 * @export
 */
export const LoanProductControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change loan product status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate2(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).activate2(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create new loan product
         * @param {CreateLoanProductForm} createLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLoanProduct(createLoanProductForm: CreateLoanProductForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).createLoanProduct(createLoanProductForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change loan product status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivate2(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).deactivate2(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {Array<string>} [productNames] 
         * @param {Array<LoanProductStatus>} [statuses] 
         * @param {number} [interestRateFrom] 
         * @param {number} [interestRateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList7(id?: string, productNames?: Array<string>, statuses?: Array<LoanProductStatus>, interestRateFrom?: number, interestRateTo?: number, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).entityList7(id, productNames, statuses, interestRateFrom, interestRateTo, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView7(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).entityView7(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get map with all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductMap(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyMapLongString>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).getProductMap(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update loan product
         * @param {UpdateLoanProductForm} updateLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLoanProduct(updateLoanProductForm: UpdateLoanProductForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).updateLoanProduct(updateLoanProductForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanProductControllerApi - factory interface
 * @export
 */
export const LoanProductControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Change loan product status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate2(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).activate2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new loan product
         * @param {CreateLoanProductForm} createLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLoanProduct(createLoanProductForm: CreateLoanProductForm, options?: any): AxiosPromise<ApiResponseBodyLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).createLoanProduct(createLoanProductForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change loan product status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate2(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).deactivate2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {Array<string>} [productNames] 
         * @param {Array<LoanProductStatus>} [statuses] 
         * @param {number} [interestRateFrom] 
         * @param {number} [interestRateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList7(id?: string, productNames?: Array<string>, statuses?: Array<LoanProductStatus>, interestRateFrom?: number, interestRateTo?: number, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).entityList7(id, productNames, statuses, interestRateFrom, interestRateTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView7(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).entityView7(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get map with all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductMap(options?: any): AxiosPromise<ApiResponseBodyMapLongString> {
            return LoanProductControllerApiFp(configuration).getProductMap(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update loan product
         * @param {UpdateLoanProductForm} updateLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanProduct(updateLoanProductForm: UpdateLoanProductForm, options?: any): AxiosPromise<ApiResponseBodyLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).updateLoanProduct(updateLoanProductForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanProductControllerApi - object-oriented interface
 * @export
 * @class LoanProductControllerApi
 * @extends {BaseAPI}
 */
export class LoanProductControllerApi extends BaseAPI {
    /**
     * 
     * @summary Change loan product status to Active
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public activate2(id: number, options?: any) {
        return LoanProductControllerApiFp(this.configuration).activate2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new loan product
     * @param {CreateLoanProductForm} createLoanProductForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public createLoanProduct(createLoanProductForm: CreateLoanProductForm, options?: any) {
        return LoanProductControllerApiFp(this.configuration).createLoanProduct(createLoanProductForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change loan product status to Inactive
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public deactivate2(id: number, options?: any) {
        return LoanProductControllerApiFp(this.configuration).deactivate2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pageable list of entities
     * @param {string} [id] 
     * @param {Array<string>} [productNames] 
     * @param {Array<LoanProductStatus>} [statuses] 
     * @param {number} [interestRateFrom] 
     * @param {number} [interestRateTo] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public entityList7(id?: string, productNames?: Array<string>, statuses?: Array<LoanProductStatus>, interestRateFrom?: number, interestRateTo?: number, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return LoanProductControllerApiFp(this.configuration).entityList7(id, productNames, statuses, interestRateFrom, interestRateTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public entityView7(id: number, options?: any) {
        return LoanProductControllerApiFp(this.configuration).entityView7(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get map with all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public getProductMap(options?: any) {
        return LoanProductControllerApiFp(this.configuration).getProductMap(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update loan product
     * @param {UpdateLoanProductForm} updateLoanProductForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public updateLoanProduct(updateLoanProductForm: UpdateLoanProductForm, options?: any) {
        return LoanProductControllerApiFp(this.configuration).updateLoanProduct(updateLoanProductForm, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * NotificationControllerApi - axios parameter creator
 * @export
 */
export const NotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change notification status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activate1.');
            }
            const localVarPath = `/api/notifications/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new notification
         * @param {CreateNotificationForm} createNotificationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: async (createNotificationForm: CreateNotificationForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNotificationForm' is not null or undefined
            if (createNotificationForm === null || createNotificationForm === undefined) {
                throw new RequiredError('createNotificationForm','Required parameter createNotificationForm was null or undefined when calling createNotification.');
            }
            const localVarPath = `/api/notifications/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createNotificationForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createNotificationForm !== undefined ? createNotificationForm : {}) : (createNotificationForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change notification status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deactivate1.');
            }
            const localVarPath = `/api/notifications/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [title] 
         * @param {Array<NotificationType>} [types] 
         * @param {Array<NotificationStatus>} [statuses] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList5: async (id?: string, title?: string, types?: Array<NotificationType>, statuses?: Array<NotificationStatus>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView5: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView5.');
            }
            const localVarPath = `/api/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update notification
         * @param {UpdateNotificationForm} updateNotificationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification: async (updateNotificationForm: UpdateNotificationForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNotificationForm' is not null or undefined
            if (updateNotificationForm === null || updateNotificationForm === undefined) {
                throw new RequiredError('updateNotificationForm','Required parameter updateNotificationForm was null or undefined when calling updateNotification.');
            }
            const localVarPath = `/api/notifications/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateNotificationForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateNotificationForm !== undefined ? updateNotificationForm : {}) : (updateNotificationForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change notification status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyNotificationDTO>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).activate1(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create new notification
         * @param {CreateNotificationForm} createNotificationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotification(createNotificationForm: CreateNotificationForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyNotificationDTO>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).createNotification(createNotificationForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change notification status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivate1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyNotificationDTO>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).deactivate1(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [title] 
         * @param {Array<NotificationType>} [types] 
         * @param {Array<NotificationStatus>} [statuses] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList5(id?: string, title?: string, types?: Array<NotificationType>, statuses?: Array<NotificationStatus>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelNotificationDTO>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).entityList5(id, title, types, statuses, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView5(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyNotificationDTO>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).entityView5(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update notification
         * @param {UpdateNotificationForm} updateNotificationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotification(updateNotificationForm: UpdateNotificationForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyNotificationDTO>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).updateNotification(updateNotificationForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Change notification status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate1(id: number, options?: any): AxiosPromise<ApiResponseBodyNotificationDTO> {
            return NotificationControllerApiFp(configuration).activate1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new notification
         * @param {CreateNotificationForm} createNotificationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(createNotificationForm: CreateNotificationForm, options?: any): AxiosPromise<ApiResponseBodyNotificationDTO> {
            return NotificationControllerApiFp(configuration).createNotification(createNotificationForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change notification status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate1(id: number, options?: any): AxiosPromise<ApiResponseBodyNotificationDTO> {
            return NotificationControllerApiFp(configuration).deactivate1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {string} [title] 
         * @param {Array<NotificationType>} [types] 
         * @param {Array<NotificationStatus>} [statuses] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList5(id?: string, title?: string, types?: Array<NotificationType>, statuses?: Array<NotificationStatus>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelNotificationDTO> {
            return NotificationControllerApiFp(configuration).entityList5(id, title, types, statuses, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView5(id: number, options?: any): AxiosPromise<ApiResponseBodyNotificationDTO> {
            return NotificationControllerApiFp(configuration).entityView5(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update notification
         * @param {UpdateNotificationForm} updateNotificationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification(updateNotificationForm: UpdateNotificationForm, options?: any): AxiosPromise<ApiResponseBodyNotificationDTO> {
            return NotificationControllerApiFp(configuration).updateNotification(updateNotificationForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Change notification status to Active
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public activate1(id: number, options?: any) {
        return NotificationControllerApiFp(this.configuration).activate1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new notification
     * @param {CreateNotificationForm} createNotificationForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public createNotification(createNotificationForm: CreateNotificationForm, options?: any) {
        return NotificationControllerApiFp(this.configuration).createNotification(createNotificationForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change notification status to Inactive
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public deactivate1(id: number, options?: any) {
        return NotificationControllerApiFp(this.configuration).deactivate1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pageable list of entities
     * @param {string} [id] 
     * @param {string} [title] 
     * @param {Array<NotificationType>} [types] 
     * @param {Array<NotificationStatus>} [statuses] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public entityList5(id?: string, title?: string, types?: Array<NotificationType>, statuses?: Array<NotificationStatus>, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return NotificationControllerApiFp(this.configuration).entityList5(id, title, types, statuses, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public entityView5(id: number, options?: any) {
        return NotificationControllerApiFp(this.configuration).entityView5(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update notification
     * @param {UpdateNotificationForm} updateNotificationForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public updateNotification(updateNotificationForm: UpdateNotificationForm, options?: any) {
        return NotificationControllerApiFp(this.configuration).updateNotification(updateNotificationForm, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SqsMessageTestControllerApi - axios parameter creator
 * @export
 */
export const SqsMessageTestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} queueName 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageToQueue: async (queueName: string, body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueName' is not null or undefined
            if (queueName === null || queueName === undefined) {
                throw new RequiredError('queueName','Required parameter queueName was null or undefined when calling sendMessageToQueue.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendMessageToQueue.');
            }
            const localVarPath = `/api/test/sqs/send`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (queueName !== undefined) {
                localVarQueryParameter['queueName'] = queueName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SqsMessageTestControllerApi - functional programming interface
 * @export
 */
export const SqsMessageTestControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} queueName 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessageToQueue(queueName: string, body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SqsMessageTestControllerApiAxiosParamCreator(configuration).sendMessageToQueue(queueName, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SqsMessageTestControllerApi - factory interface
 * @export
 */
export const SqsMessageTestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} queueName 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageToQueue(queueName: string, body: object, options?: any): AxiosPromise<void> {
            return SqsMessageTestControllerApiFp(configuration).sendMessageToQueue(queueName, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SqsMessageTestControllerApi - object-oriented interface
 * @export
 * @class SqsMessageTestControllerApi
 * @extends {BaseAPI}
 */
export class SqsMessageTestControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} queueName 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqsMessageTestControllerApi
     */
    public sendMessageToQueue(queueName: string, body: object, options?: any) {
        return SqsMessageTestControllerApiFp(this.configuration).sendMessageToQueue(queueName, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SystemOperationControllerApi - axios parameter creator
 * @export
 */
export const SystemOperationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Launch system operation
         * @param {SystemOperationExecuteRequestDTO} systemOperationExecuteRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeLaunch: async (systemOperationExecuteRequestDTO: SystemOperationExecuteRequestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemOperationExecuteRequestDTO' is not null or undefined
            if (systemOperationExecuteRequestDTO === null || systemOperationExecuteRequestDTO === undefined) {
                throw new RequiredError('systemOperationExecuteRequestDTO','Required parameter systemOperationExecuteRequestDTO was null or undefined when calling executeLaunch.');
            }
            const localVarPath = `/api/system-operations/launch`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof systemOperationExecuteRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(systemOperationExecuteRequestDTO !== undefined ? systemOperationExecuteRequestDTO : {}) : (systemOperationExecuteRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all system operations for manual run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManualSystemOperations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system-operations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemOperationControllerApi - functional programming interface
 * @export
 */
export const SystemOperationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Launch system operation
         * @param {SystemOperationExecuteRequestDTO} systemOperationExecuteRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeLaunch(systemOperationExecuteRequestDTO: SystemOperationExecuteRequestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBoolean>> {
            const localVarAxiosArgs = await SystemOperationControllerApiAxiosParamCreator(configuration).executeLaunch(systemOperationExecuteRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all system operations for manual run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManualSystemOperations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListSystemOperationType>> {
            const localVarAxiosArgs = await SystemOperationControllerApiAxiosParamCreator(configuration).getManualSystemOperations(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemOperationControllerApi - factory interface
 * @export
 */
export const SystemOperationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Launch system operation
         * @param {SystemOperationExecuteRequestDTO} systemOperationExecuteRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeLaunch(systemOperationExecuteRequestDTO: SystemOperationExecuteRequestDTO, options?: any): AxiosPromise<ApiResponseBodyBoolean> {
            return SystemOperationControllerApiFp(configuration).executeLaunch(systemOperationExecuteRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all system operations for manual run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManualSystemOperations(options?: any): AxiosPromise<ApiResponseBodyListSystemOperationType> {
            return SystemOperationControllerApiFp(configuration).getManualSystemOperations(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemOperationControllerApi - object-oriented interface
 * @export
 * @class SystemOperationControllerApi
 * @extends {BaseAPI}
 */
export class SystemOperationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Launch system operation
     * @param {SystemOperationExecuteRequestDTO} systemOperationExecuteRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemOperationControllerApi
     */
    public executeLaunch(systemOperationExecuteRequestDTO: SystemOperationExecuteRequestDTO, options?: any) {
        return SystemOperationControllerApiFp(this.configuration).executeLaunch(systemOperationExecuteRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all system operations for manual run
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemOperationControllerApi
     */
    public getManualSystemOperations(options?: any) {
        return SystemOperationControllerApiFp(this.configuration).getManualSystemOperations(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SystemOperationHistoryControllerApi - axios parameter creator
 * @export
 */
export const SystemOperationHistoryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {Array<'DAILY_LOAN_CALCULATION' | 'FULL_LOAN_RECALCULATION' | 'RECREATE_LOANS_FROM_APPLICATION' | 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN' | 'REPORT_GENERATION' | 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS' | 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION' | 'RETRIEVE_FAILED_NOTIFICATIONS' | 'CLEAR_NOTIFICATION_LOG' | 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'>} [types] 
         * @param {Array<'SCHEDULED' | 'MANUAL'>} [launchTypes] 
         * @param {Array<'STARTED' | 'ERROR' | 'FINISHED'>} [statuses] 
         * @param {string} [startedAtFrom] 
         * @param {string} [startedAtTo] 
         * @param {string} [finishedAtFrom] 
         * @param {string} [finishedAtTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList2: async (types?: Array<'DAILY_LOAN_CALCULATION' | 'FULL_LOAN_RECALCULATION' | 'RECREATE_LOANS_FROM_APPLICATION' | 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN' | 'REPORT_GENERATION' | 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS' | 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION' | 'RETRIEVE_FAILED_NOTIFICATIONS' | 'CLEAR_NOTIFICATION_LOG' | 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'>, launchTypes?: Array<'SCHEDULED' | 'MANUAL'>, statuses?: Array<'STARTED' | 'ERROR' | 'FINISHED'>, startedAtFrom?: string, startedAtTo?: string, finishedAtFrom?: string, finishedAtTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system-operation/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (launchTypes) {
                localVarQueryParameter['launchTypes'] = launchTypes;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (startedAtFrom !== undefined) {
                localVarQueryParameter['startedAtFrom'] = (startedAtFrom as any instanceof Date) ?
                    (startedAtFrom as any).toISOString().substr(0,10) :
                    startedAtFrom;
            }

            if (startedAtTo !== undefined) {
                localVarQueryParameter['startedAtTo'] = (startedAtTo as any instanceof Date) ?
                    (startedAtTo as any).toISOString().substr(0,10) :
                    startedAtTo;
            }

            if (finishedAtFrom !== undefined) {
                localVarQueryParameter['finishedAtFrom'] = (finishedAtFrom as any instanceof Date) ?
                    (finishedAtFrom as any).toISOString().substr(0,10) :
                    finishedAtFrom;
            }

            if (finishedAtTo !== undefined) {
                localVarQueryParameter['finishedAtTo'] = (finishedAtTo as any instanceof Date) ?
                    (finishedAtTo as any).toISOString().substr(0,10) :
                    finishedAtTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView2: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView2.');
            }
            const localVarPath = `/api/system-operation/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemOperationHistoryControllerApi - functional programming interface
 * @export
 */
export const SystemOperationHistoryControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {Array<'DAILY_LOAN_CALCULATION' | 'FULL_LOAN_RECALCULATION' | 'RECREATE_LOANS_FROM_APPLICATION' | 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN' | 'REPORT_GENERATION' | 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS' | 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION' | 'RETRIEVE_FAILED_NOTIFICATIONS' | 'CLEAR_NOTIFICATION_LOG' | 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'>} [types] 
         * @param {Array<'SCHEDULED' | 'MANUAL'>} [launchTypes] 
         * @param {Array<'STARTED' | 'ERROR' | 'FINISHED'>} [statuses] 
         * @param {string} [startedAtFrom] 
         * @param {string} [startedAtTo] 
         * @param {string} [finishedAtFrom] 
         * @param {string} [finishedAtTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList2(types?: Array<'DAILY_LOAN_CALCULATION' | 'FULL_LOAN_RECALCULATION' | 'RECREATE_LOANS_FROM_APPLICATION' | 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN' | 'REPORT_GENERATION' | 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS' | 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION' | 'RETRIEVE_FAILED_NOTIFICATIONS' | 'CLEAR_NOTIFICATION_LOG' | 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'>, launchTypes?: Array<'SCHEDULED' | 'MANUAL'>, statuses?: Array<'STARTED' | 'ERROR' | 'FINISHED'>, startedAtFrom?: string, startedAtTo?: string, finishedAtFrom?: string, finishedAtTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelSystemOperationExecutionHistoryDTO>> {
            const localVarAxiosArgs = await SystemOperationHistoryControllerApiAxiosParamCreator(configuration).entityList2(types, launchTypes, statuses, startedAtFrom, startedAtTo, finishedAtFrom, finishedAtTo, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView2(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodySystemOperationExecutionHistoryDTO>> {
            const localVarAxiosArgs = await SystemOperationHistoryControllerApiAxiosParamCreator(configuration).entityView2(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemOperationHistoryControllerApi - factory interface
 * @export
 */
export const SystemOperationHistoryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {Array<'DAILY_LOAN_CALCULATION' | 'FULL_LOAN_RECALCULATION' | 'RECREATE_LOANS_FROM_APPLICATION' | 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN' | 'REPORT_GENERATION' | 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS' | 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION' | 'RETRIEVE_FAILED_NOTIFICATIONS' | 'CLEAR_NOTIFICATION_LOG' | 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'>} [types] 
         * @param {Array<'SCHEDULED' | 'MANUAL'>} [launchTypes] 
         * @param {Array<'STARTED' | 'ERROR' | 'FINISHED'>} [statuses] 
         * @param {string} [startedAtFrom] 
         * @param {string} [startedAtTo] 
         * @param {string} [finishedAtFrom] 
         * @param {string} [finishedAtTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList2(types?: Array<'DAILY_LOAN_CALCULATION' | 'FULL_LOAN_RECALCULATION' | 'RECREATE_LOANS_FROM_APPLICATION' | 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN' | 'REPORT_GENERATION' | 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS' | 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION' | 'RETRIEVE_FAILED_NOTIFICATIONS' | 'CLEAR_NOTIFICATION_LOG' | 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'>, launchTypes?: Array<'SCHEDULED' | 'MANUAL'>, statuses?: Array<'STARTED' | 'ERROR' | 'FINISHED'>, startedAtFrom?: string, startedAtTo?: string, finishedAtFrom?: string, finishedAtTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelSystemOperationExecutionHistoryDTO> {
            return SystemOperationHistoryControllerApiFp(configuration).entityList2(types, launchTypes, statuses, startedAtFrom, startedAtTo, finishedAtFrom, finishedAtTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView2(id: number, options?: any): AxiosPromise<ApiResponseBodySystemOperationExecutionHistoryDTO> {
            return SystemOperationHistoryControllerApiFp(configuration).entityView2(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemOperationHistoryControllerApi - object-oriented interface
 * @export
 * @class SystemOperationHistoryControllerApi
 * @extends {BaseAPI}
 */
export class SystemOperationHistoryControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get pageable list of entities
     * @param {Array<'DAILY_LOAN_CALCULATION' | 'FULL_LOAN_RECALCULATION' | 'RECREATE_LOANS_FROM_APPLICATION' | 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN' | 'REPORT_GENERATION' | 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS' | 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION' | 'RETRIEVE_FAILED_NOTIFICATIONS' | 'CLEAR_NOTIFICATION_LOG' | 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'>} [types] 
     * @param {Array<'SCHEDULED' | 'MANUAL'>} [launchTypes] 
     * @param {Array<'STARTED' | 'ERROR' | 'FINISHED'>} [statuses] 
     * @param {string} [startedAtFrom] 
     * @param {string} [startedAtTo] 
     * @param {string} [finishedAtFrom] 
     * @param {string} [finishedAtTo] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemOperationHistoryControllerApi
     */
    public entityList2(types?: Array<'DAILY_LOAN_CALCULATION' | 'FULL_LOAN_RECALCULATION' | 'RECREATE_LOANS_FROM_APPLICATION' | 'SEND_NOTIFICATIONS_FOR_WEEK_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_TOMORROW_PAYMENT' | 'SEND_NOTIFICATIONS_FOR_1_DAY_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_5_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_7_DAYS_OVERDUE_LOAN' | 'SEND_NOTIFICATIONS_FOR_90_DAYS_OVERDUE_LOAN' | 'REPORT_GENERATION' | 'CREDIT_BUREAU_CHECK_RESTART_FAILED_LOGS' | 'DELETE_CUSTOMERS_WITH_NOT_COMPLETED_REGISTRATION' | 'RETRIEVE_FAILED_NOTIFICATIONS' | 'CLEAR_NOTIFICATION_LOG' | 'REPROCESS_APPLICATIONS_FOR_REVIEWED_CUSTOMERS'>, launchTypes?: Array<'SCHEDULED' | 'MANUAL'>, statuses?: Array<'STARTED' | 'ERROR' | 'FINISHED'>, startedAtFrom?: string, startedAtTo?: string, finishedAtFrom?: string, finishedAtTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return SystemOperationHistoryControllerApiFp(this.configuration).entityList2(types, launchTypes, statuses, startedAtFrom, startedAtTo, finishedAtFrom, finishedAtTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemOperationHistoryControllerApi
     */
    public entityView2(id: number, options?: any) {
        return SystemOperationHistoryControllerApiFp(this.configuration).entityView2(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UiSettingsControllerApi - axios parameter creator
 * @export
 */
export const UiSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ui-settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UiSettingsControllerApi - functional programming interface
 * @export
 */
export const UiSettingsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiSettings>> {
            const localVarAxiosArgs = await UiSettingsControllerApiAxiosParamCreator(configuration).getSettings(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UiSettingsControllerApi - factory interface
 * @export
 */
export const UiSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<UiSettings> {
            return UiSettingsControllerApiFp(configuration).getSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UiSettingsControllerApi - object-oriented interface
 * @export
 * @class UiSettingsControllerApi
 * @extends {BaseAPI}
 */
export class UiSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UiSettingsControllerApi
     */
    public getSettings(options?: any) {
        return UiSettingsControllerApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserActionControllerApi - axios parameter creator
 * @export
 */
export const UserActionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {number} [id] 
         * @param {string} [userFullName] 
         * @param {Array<'BO' | 'SME'>} [portalTypes] 
         * @param {Array<'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE'>} [requestMethods] 
         * @param {string} [url] 
         * @param {string} [ip] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList1: async (id?: number, userFullName?: string, portalTypes?: Array<'BO' | 'SME'>, requestMethods?: Array<'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE'>, url?: string, ip?: string, dateFrom?: string, dateTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/action`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (userFullName !== undefined) {
                localVarQueryParameter['userFullName'] = userFullName;
            }

            if (portalTypes) {
                localVarQueryParameter['portalTypes'] = portalTypes;
            }

            if (requestMethods) {
                localVarQueryParameter['requestMethods'] = requestMethods;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (ip !== undefined) {
                localVarQueryParameter['ip'] = ip;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView1.');
            }
            const localVarPath = `/api/user/action/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserActionControllerApi - functional programming interface
 * @export
 */
export const UserActionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {number} [id] 
         * @param {string} [userFullName] 
         * @param {Array<'BO' | 'SME'>} [portalTypes] 
         * @param {Array<'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE'>} [requestMethods] 
         * @param {string} [url] 
         * @param {string} [ip] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList1(id?: number, userFullName?: string, portalTypes?: Array<'BO' | 'SME'>, requestMethods?: Array<'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE'>, url?: string, ip?: string, dateFrom?: string, dateTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelUserAccountActionDTO>> {
            const localVarAxiosArgs = await UserActionControllerApiAxiosParamCreator(configuration).entityList1(id, userFullName, portalTypes, requestMethods, url, ip, dateFrom, dateTo, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyUserAccountActionDTO>> {
            const localVarAxiosArgs = await UserActionControllerApiAxiosParamCreator(configuration).entityView1(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserActionControllerApi - factory interface
 * @export
 */
export const UserActionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get pageable list of entities
         * @param {number} [id] 
         * @param {string} [userFullName] 
         * @param {Array<'BO' | 'SME'>} [portalTypes] 
         * @param {Array<'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE'>} [requestMethods] 
         * @param {string} [url] 
         * @param {string} [ip] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList1(id?: number, userFullName?: string, portalTypes?: Array<'BO' | 'SME'>, requestMethods?: Array<'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE'>, url?: string, ip?: string, dateFrom?: string, dateTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelUserAccountActionDTO> {
            return UserActionControllerApiFp(configuration).entityList1(id, userFullName, portalTypes, requestMethods, url, ip, dateFrom, dateTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView1(id: number, options?: any): AxiosPromise<ApiResponseBodyUserAccountActionDTO> {
            return UserActionControllerApiFp(configuration).entityView1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserActionControllerApi - object-oriented interface
 * @export
 * @class UserActionControllerApi
 * @extends {BaseAPI}
 */
export class UserActionControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get pageable list of entities
     * @param {number} [id] 
     * @param {string} [userFullName] 
     * @param {Array<'BO' | 'SME'>} [portalTypes] 
     * @param {Array<'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE'>} [requestMethods] 
     * @param {string} [url] 
     * @param {string} [ip] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserActionControllerApi
     */
    public entityList1(id?: number, userFullName?: string, portalTypes?: Array<'BO' | 'SME'>, requestMethods?: Array<'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE'>, url?: string, ip?: string, dateFrom?: string, dateTo?: string, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return UserActionControllerApiFp(this.configuration).entityList1(id, userFullName, portalTypes, requestMethods, url, ip, dateFrom, dateTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserActionControllerApi
     */
    public entityView1(id: number, options?: any) {
        return UserActionControllerApiFp(this.configuration).entityView1(id, options).then((request) => request(this.axios, this.basePath));
    }

}


