import { Col, Row, Tooltip } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { MasterDetailsAsideItem } from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { EntityModelCustomerUserDTO } from 'src/generated-api-client';
import { customerMapColors } from 'src/utils/common-constants';
import styled from 'styled-components';

type CustomerListItemProps = { item: EntityModelCustomerUserDTO };

export const CustomersListItem = observer(({ item }: CustomerListItemProps) => {
    return (
        <MasterDetailsAsideItem to={EntityLinks.customers.itemDetails(item.id)}>
            <StyledHeaderRow gutter={5} wrap={false}>
                <Col flex="auto">
                    <BoldText>
                        <NameContainer>#{item.id}</NameContainer>
                    </BoldText>
                </Col>
                <Col>
                    <Tooltip title={item.legalName}>
                        <EllipsisContainer>
                            <NameContainer>{item.legalName}</NameContainer>
                        </EllipsisContainer>
                    </Tooltip>
                </Col>
            </StyledHeaderRow>
            <Row wrap={false}>
                <Col flex="auto">
                    <StyledStatusWrapper status={item.verificationStatus}>
                        {t(
                            `Customer.VerificationStatus.${item.verificationStatus}`,
                        )}
                    </StyledStatusWrapper>
                </Col>
                <Col>
                    <SecondaryText>{item.cipc}</SecondaryText>
                </Col>
            </Row>
        </MasterDetailsAsideItem>
    );
});

const StyledHeaderRow = styled(Row)`
    margin-bottom: 5px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    max-width: 160px;
    padding: 5px 19px;
    background: ${(props) =>
        customerMapColors[props?.status as keyof typeof customerMapColors]};
    border-radius: 22px;
    font-size: 13px;
    height: 22px;
    line-height: 22px;
    align-items: center;
    justify-content: center;

    color: #000000;
`;

const SecondaryText = styled.span`
    font-size: 13px;
    line-height: 150%;
    color: #000000;
`;

const BoldText = styled.span`
    font-weight: 600;
`;

const NameContainer = styled.span`
    font-size: 20px;
`;
