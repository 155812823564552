/* eslint-disable max-lines */
import {
    Button,
    Col,
    Divider,
    Form as AntForm,
    notification,
    Row,
    Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { Form } from 'src/components/Form/Form';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import styled from 'styled-components';

import { ApplicationsInsertFinancialsHeader } from './Components';
import { FieldRenderComponent } from './Components/FieldRenderComponent';

const { Title, Text, Paragraph } = Typography;

const store = ApplicationsStore;

const matrixOfFields1 = [
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.NetProfit"
                defaults="Net profit"
            />
        ),
        parentObject: 'netProfit',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.Ebitda"
                defaults="EBITDA %"
            />
        ),
        parentObject: 'ebidta',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.TotalSales"
                defaults="Total sales"
            />
        ),
        parentObject: 'totalSales',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.OperationProfit"
                defaults="Operation profit"
            />
        ),
        parentObject: 'operationProfit',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.GrossProfit"
                defaults="Gross profit"
            />
        ),
        parentObject: 'grossProfit',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.CurrentAssets"
                defaults="Current assets"
            />
        ),
        parentObject: 'currentAssets',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.CurrentLiabilities"
                defaults="Current liabilities"
            />
        ),
        parentObject: 'currentLiabilities',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.Inventory"
                defaults="Inventory"
            />
        ),
        parentObject: 'inventory',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.AverageTradeDebtors"
                defaults="Average trade debtors"
            />
        ),
        parentObject: 'averageTradeDebtors',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.AverageTradeCreditors"
                defaults="Average trade creditors"
            />
        ),
        parentObject: 'averageTradeCreditors',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.AverageInventory"
                defaults="Average inventory"
            />
        ),
        parentObject: 'averageInventory',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.CostOfGoodsSold"
                defaults="Cost of goods sold"
            />
        ),
        parentObject: 'soldGoodsCost',
    },
];

const matrixOfFields2 = [
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.NetProfitIndustryAverage"
                defaults="Net profit % industry average"
            />
        ),
        parentObject: 'netProfitIndustryAverage',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.OperationProfitIndustryAverage"
                defaults="Operation profit % industry average"
            />
        ),
        parentObject: 'operationProfitIndustryAverage',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.GrossProfitIndustryAverage"
                defaults="Gross profit % industry average"
            />
        ),
        parentObject: 'grossProfitIndustryAverage',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.EBITDAIndustryAverage"
                defaults="EBITDA % industry average"
            />
        ),
        parentObject: 'ebidtaIndustryAverage',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.CurrentRatioIndustryAverage"
                defaults="Current ratio industry average"
            />
        ),
        parentObject: 'currentRatioIndustryAverage',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.AcidTestRatio"
                defaults="Acid test quick ratio industry average"
            />
        ),
        parentObject: 'acidTestQuickRatioIndustryAverage',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.CurrentLiabilities"
                defaults="Debtor collection period industry average"
            />
        ),
        parentObject: 'debtorCollectionPeriodIndustryAverage',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.CreditorCollection"
                defaults="Creditor collection period industry average"
            />
        ),
        parentObject: 'creditorPaymentPeriodIndustryAverage',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Applications.InsertFinancials.StockTurnover"
                defaults="Stock turnover period industry average"
            />
        ),
        parentObject: 'stockTurnoverPeriodIndustryAverage',
    },
];

export const ApplicationsInsertFinancialsPage = observer(() => {
    const { t } = useTranslation();
    const [form] = AntForm.useForm();
    const { applicationId } = useApplicationId();

    return (
        <StyledPageContainer>
            <Row gutter={24} justify="start">
                <Col>
                    <StyledPageTitle level={2}>
                        {t(
                            'Page.Applications.InsertFinancials.Title',
                            'Please, insert financial figures of this customer',
                        )}
                    </StyledPageTitle>
                </Col>
            </Row>
            <Row gutter={24} justify="start">
                <Col>
                    <StyledParagraph>
                        <Trans i18nKey="Page.Applications.InsertFinancials.SubTitle" />
                    </StyledParagraph>
                </Col>
            </Row>
            <Form
                form={form}
                onFinish={async (values) => {
                    await store.calculateRatio(Number(applicationId), values);
                    if (!store.calculateRatioAction.hasError) {
                        await store.activate(Number(applicationId));
                        await store.loadList();
                        await store.loadItem();
                        Router.navigate(
                            `${RoutePaths.applications}/${applicationId}`,
                        );
                        notification.success({
                            message: t('Message.Success'),
                        });
                        form.resetFields();
                    } else {
                        notification.error({
                            message: store.calculateRatioAction.error,
                        });
                    }
                }}
            >
                <ApplicationsInsertFinancialsHeader />
                {matrixOfFields1.map((field) => (
                    <StyledRow>
                        <StyledTitleCol>
                            <Text>{field.title}</Text>
                        </StyledTitleCol>
                        <StyledInputGroup>
                            <FieldRenderComponent
                                parentObject={field.parentObject}
                            />
                        </StyledInputGroup>
                    </StyledRow>
                ))}
                <Divider type="vertical" />
                <ApplicationsInsertFinancialsHeader />
                {matrixOfFields2.map((field) => (
                    <StyledRow
                        key={`${new Date().getMilliseconds()}${Math.random()}`}
                    >
                        <StyledTitleCol>
                            <Text>{field.title}</Text>
                        </StyledTitleCol>
                        <StyledInputGroup>
                            <FieldRenderComponent
                                parentObject={field.parentObject}
                            />
                        </StyledInputGroup>
                    </StyledRow>
                ))}

                <Row gutter={24} justify="center">
                    <StyledButtonContainer span={12} flex="nowrap">
                        <StyledResetButton
                            key="cancel"
                            onClick={() => {
                                form.resetFields();
                                Router.navigate(
                                    `${RoutePaths.applications}/${applicationId}`,
                                );
                            }}
                        >
                            {t('Component.Modal.Btn.Cancel')}
                        </StyledResetButton>
                        <StyledSaveButton
                            key="ok"
                            type="primary"
                            loading={store.calculateRatioAction.isLoading}
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            {t('Component.Modal.Btn.Ok')}
                        </StyledSaveButton>
                    </StyledButtonContainer>
                </Row>
            </Form>
        </StyledPageContainer>
    );
});

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
`;

const StyledParagraph = styled(Paragraph)`
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    font-size: 18px;
`;

const StyledInputGroup = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2.5em;
    .ant-form-item {
        margin: 0;
        flex: 1;
    }
`;

const StyledRow = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
`;

const StyledTitleCol = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
`;

const StyledButtonContainer = styled(Col)`
    margin-top: 20px;
    text-align: center;
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const StyledPageContainer = styled.div`
    margin: 20px 29px;
`;
