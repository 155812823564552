import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { MasterDetailsAsideItem } from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import {
    EntityModelReportDTO,
    EntityModelReportDTOStatusEnum,
} from 'src/generated-api-client';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

type StatisticItemProps = { item: EntityModelReportDTO };

export const StatisticListItem = observer(({ item }: StatisticItemProps) => {
    return (
        <MasterDetailsAsideItem to={EntityLinks.statistic.itemDetails(item.id)}>
            <StyledHeaderRow gutter={5} wrap={false}>
                <Col flex="auto">
                    <BoldText>
                        <NameContainer>#{item.id}</NameContainer>
                    </BoldText>
                </Col>
                <Col>
                    <NameContainer>
                        {DateHelper.format(item.date as string)}
                    </NameContainer>
                </Col>
            </StyledHeaderRow>
            <Row wrap={false}>
                <Col flex="auto">
                    <StyledStatusWrapper status={item.status}>
                        {item.status?.toLocaleLowerCase()}
                    </StyledStatusWrapper>
                </Col>
            </Row>
        </MasterDetailsAsideItem>
    );
});

const StyledHeaderRow = styled(Row)`
    margin-bottom: 29px;
`;

const BoldText = styled.span`
    font-weight: 600;
`;

const NameContainer = styled.span`
    font-size: 20px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    max-width: 120px;
    text-transform: capitalize;
    padding: 5px 19px;
    background: ${(props) =>
        props.status === EntityModelReportDTOStatusEnum.SUCCESSFUL
            ? '#ACF392'
            : '#FFE4E4'};
    border-radius: 22px;
    font-size: 13px;
    height: 22px;
    line-height: 22px;
    align-items: center;
    justify-content: center;

    color: ${(props) =>
        props.status === EntityModelReportDTOStatusEnum.SUCCESSFUL
            ? '#005723'
            : '#F63743'};
`;
