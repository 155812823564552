import { action, computed } from 'mobx';
import { AttachmentsFormat } from 'src/components/Form/ValidationRules';
import {
    DocumentDTO,
    DocumentDTODocumentTypeEnum,
    DocumentDTOFileTypeEnum,
} from 'src/generated-api-client';
import { customerDocumentsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class CustomerDocumnetsStoreClass extends BasicStore<DocumentDTO> {
    api: BasicStoreApi<DocumentDTO> = {
        loadList: async (customerId: number) => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                customerDocumentsApi.getDocumentForCustomer(customerId),
            );

            return result.data || [];
        },
    };

    downloadDocumentsAction = new AsyncOperationWithStatus(
        (documentId: number) =>
            RequestHelper.unwrapFromAxiosPromise(
                customerDocumentsApi.downloadCustomerDocuments(documentId, {
                    responseType: 'arraybuffer',
                }),
            ),
    );

    @action async downloadDocuments(
        documentId: number,
        type: DocumentDTOFileTypeEnum,
        fileName: string,
    ) {
        const res = await this.downloadDocumentsAction.call(Number(documentId));
        const file = window.URL.createObjectURL(
            new Blob([res], {
                type: AttachmentsFormat[
                    type.toLocaleLowerCase() as keyof typeof AttachmentsFormat
                ],
            }),
        );
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    @computed get customerCIPCDocs() {
        return this.list.filter(
            (doc) =>
                doc.documentType === DocumentDTODocumentTypeEnum.CUSTOMERCIPC,
        );
    }

    @computed get customerDirectordIdsDocs() {
        return this.list.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.CUSTOMERDIRECTORIDS,
        );
    }

    @computed get customerProofOfAddress() {
        return this.list.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.CUSTOMERPROOFOFADDRESS,
        );
    }

    @computed get customerTaxClearenceDocs() {
        return this.list.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.CUSTOMERTAXCLEARANCE,
        );
    }

    @computed get customerAuthorizationLetter() {
        return this.list.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.CUSTOMERAUTHORIZATIONLETTER,
        );
    }

    @computed get customerCBDocs() {
        return this.list.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.CUSTOMERCREDITBUREAU,
        );
    }
}

export const CustomerDocumnetsStore = new CustomerDocumnetsStoreClass();
