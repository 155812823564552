import { Env } from 'src/core/Environment';
import {
    BackofficeCustomerUserControllerApiFactory,
    BackofficeCustomerUserDocumentControllerApiFactory,
    BackofficeLoanApplicationControllerApiFactory,
    BackofficeLoanApplicationDocumentControllerApiFactory,
    BackofficeLoanControllerApiFactory,
    BackofficeReportControllerApiFactory,
    BackofficeRoleControllerApiFactory,
    BackofficeUserControllerApiFactory,
    Configuration,
    CsvLogControllerApiFactory,
    LoanOperationControllerApiFactory,
    LoanProductControllerApiFactory,
    NotificationControllerApiFactory,
    SystemOperationControllerApiFactory,
    UiSettingsControllerApiFactory,
    UserActionControllerApiFactory,
} from 'src/generated-api-client';

const apiConfig: Configuration | undefined = undefined;

export const uiSettingsApi = UiSettingsControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const usersApi = BackofficeUserControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const rolesApi = BackofficeRoleControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const loansProductsApi = LoanProductControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const profileApi = BackofficeUserControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const customersApi = BackofficeCustomerUserControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const loanApplicationsApi =
    BackofficeLoanApplicationControllerApiFactory(apiConfig, Env.basePath);

export const customerDocumentsApi =
    BackofficeCustomerUserDocumentControllerApiFactory(apiConfig, Env.basePath);

export const applicationsDocuments =
    BackofficeLoanApplicationDocumentControllerApiFactory(
        apiConfig,
        Env.basePath,
    );

export const notificationApi = NotificationControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const loansApi = BackofficeLoanControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const loanOperationsApi = LoanOperationControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const reportApi = BackofficeReportControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const importDataApi = CsvLogControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const systemOperationApi = SystemOperationControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const userActionApi = UserActionControllerApiFactory(
    apiConfig,
    Env.basePath,
);
