import { Col, notification, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordField } from 'src/components/Form/PasswordField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';

export const ChangeUserPasswordDialog = observer(({ ...props }) => {
    const { t } = useTranslation();
    const { currentItem } = UsersStore;

    return (
        <Modal
            {...props}
            title={t('Page.ChangePassword.Title', 'Change password')}
            isLoading={UsersStore.onChangePasswordAction.isLoading}
            onFinish={async (values) => {
                await UsersStore.onChangePassword(
                    Number(currentItem?.data?.id),
                    values,
                );

                if (!UsersStore.createItemLoader.hasError) {
                    notification.success({
                        message: t('Message.Success'),
                    });
                    await UsersStore.loadItem(
                        currentItem?.data?.id?.toString(),
                    );
                }
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <PasswordField
                        name="password"
                        label={t(
                            'Label.ChangePassword.OldPassword',
                            'Password',
                        )}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.password,
                        ]}
                    />
                </Col>

                <Col span={24}>
                    <PasswordField
                        name="passwordConfirmation"
                        label={t(
                            'Label.ChangePassword.PasswordConfirmation',
                            'Password confirmation',
                        )}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.confirmPassword,
                        ]}
                    />
                </Col>
            </Row>
        </Modal>
    );
});

export const ChangePasswordModal = addModalRenderer(ChangeUserPasswordDialog);
