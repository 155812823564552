import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { LoanItem } from 'src/components/LoanItem/LoanItem';
import { Spinner } from 'src/components/Spinner/Spinner';
import { EntityLinks } from 'src/core/router/EntityLinks';
import {
    LoanApplicationListDTO,
    LoanApplicationStatus,
} from 'src/generated-api-client';
import { CustomerApplicationsStore } from 'src/stores/CustomerApplicationsStore/CustomerApplicationsStore';
import { CustomersStore } from 'src/stores/CustomersStore/CustomersStore';
import {
    applicationStatusMapColors,
    LoanApplicationMapping,
} from 'src/utils/common-constants';
import styled from 'styled-components';

const store = CustomerApplicationsStore;

const applicationStatusesForApprovedProperties = [
    LoanApplicationStatus.OFFERISSUED,
    LoanApplicationStatus.SIGNINGPROCESS,
    LoanApplicationStatus.EXPIRED,
    LoanApplicationStatus.DECLINEDBYCUSTOMER,
];

export const LoanApplications = observer(() => {
    useEffect(() => {
        store.loadList(CustomersStore.currentItem?.data?.id);
    }, [CustomersStore.currentItem?.data?.id]);

    return store.listLoader.isLoading ? (
        <StyledSpinnerContainer>
            <Spinner />{' '}
        </StyledSpinnerContainer>
    ) : (
        <>
            {store.list.map((application: LoanApplicationListDTO) => (
                <LoanItem
                    id={application?.id}
                    date={application.submitDate}
                    dateText={
                        <Trans
                            i18nKey="Page.Customers.Tabs.LoanApplications.StartDate"
                            tOptions={{ id: application.id }}
                        />
                    }
                    linkAddress={EntityLinks.loanApplication.itemDetails(
                        application?.id,
                    )}
                    status={
                        LoanApplicationMapping[
                            application.status as keyof typeof LoanApplicationMapping
                        ]
                    }
                    statusColor={
                        applicationStatusMapColors[
                            application.status as keyof typeof applicationStatusMapColors
                        ]
                    }
                    amount={
                        applicationStatusesForApprovedProperties.includes(
                            application.status as LoanApplicationStatus,
                        )
                            ? application.approvedAmount
                            : application.requestedAmount
                    }
                    period={
                        applicationStatusesForApprovedProperties.includes(
                            application.status as LoanApplicationStatus,
                        )
                            ? application.approvedTerm
                            : application.requestedTerm
                    }
                    type={application.type}
                    discloserType={application.disclosure}
                />
            ))}
        </>
    );
});

const StyledSpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
