import { Col, notification, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'src/components/Form/SelectField';
import { TextArea } from 'src/components/Form/TextArea';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { AuthoritiesStore } from 'src/stores/AuthoritiesStore/AuthoritiesStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';
import { START_PAGES_OPTIONS } from 'src/utils/common-constants';
import { addModalRenderer } from 'src/utils/ModalRenderer';

export const EditRoleDialog = observer(({ ...props }) => {
    const { t } = useTranslation();

    const { currentItem } = RolesStore;

    return (
        <Modal
            {...props}
            title={t('Page.Edit.Role.Dialog.Title', 'Edit role')}
            isLoading={RolesStore.createItemLoader.isLoading}
            storeLoader={RolesStore.createItemLoader}
            onFinish={async (values) => {
                await RolesStore.updateItem(
                    Number(currentItem?.data?.id),
                    values,
                );

                if (!RolesStore.createItemLoader.hasError) {
                    notification.success({
                        message: t('Message.Success'),
                    });
                    RolesStore.loadItem(currentItem?.data?.id?.toString());
                    ProfileStore.loadProfile();
                }
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <TextField
                        name="name"
                        label={t('Page.Edit.Role.Dialog.Name', 'Name')}
                        rules={[ValidationRules.required]}
                        initialValue={currentItem?.data?.name}
                    />
                </Col>

                <Col span={24}>
                    <SelectField
                        mode="multiple"
                        store={AuthoritiesStore}
                        convertListItemToOption={(authoritie: any) => ({
                            children: `${authoritie.description}`,
                            value: authoritie.value,
                        })}
                        name="authorities"
                        label={t(
                            'Page.Edit.Role.Dialog.Authorities',
                            'Authorities',
                        )}
                        rules={[ValidationRules.required]}
                        initialValue={currentItem?.data?.authorities}
                    />
                </Col>

                <Col span={24}>
                    <SelectField
                        options={START_PAGES_OPTIONS}
                        name="startPage"
                        label={t(
                            'Page.Edit.Role.Dialog.StartPage',
                            'Start page',
                        )}
                        rules={[ValidationRules.required]}
                        initialValue={currentItem?.data?.startPage}
                    />
                </Col>

                <Col span={24}>
                    <TextArea
                        name="description"
                        rows={6}
                        label={t(
                            'Page.Edit.Role.Dialog.Description',
                            'Description',
                        )}
                        rules={[ValidationRules.required]}
                        initialValue={currentItem?.data?.description}
                    />
                </Col>
            </Row>
        </Modal>
    );
});

export const EditRoleModal = addModalRenderer(EditRoleDialog);
