import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';

const store = ApplicationsStore;

export const ReviewalProcess = observer(() => {
    const { t } = useTranslation();

    const currentApplication = store.currentItem?.data;

    return (
        <>
            <Row>
                <BlockTitle>
                    {t(
                        'Page.Applications.ReviewalProcess.Title',
                        'Reviewal process',
                    )}
                </BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        value={currentApplication?.submitDate}
                        format="date-time-seconds"
                        label={t(
                            'Page.Applications.ReviewalProcess.CreationDate',
                            'Creation date',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentApplication?.creditBureauCheckDate}
                        format="date-time-seconds"
                        label={t(
                            'Page.Applications.ReviewalProcess.CreditBureauReview',
                            'Credit bureau review',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentApplication?.reviewApprovalDate}
                        format="date-time-seconds"
                        label={t(
                            'Page.Applications.ReviewalProcess.FirstReview',
                            'First review',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentApplication?.creditCommitteeApprovalDate}
                        format="date-time-seconds"
                        label={t(
                            'Page.Applications.ReviewalProcess.CCReview',
                            'CC review',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentApplication?.offerAcceptanceDate}
                        format="date-time-seconds"
                        label={t(
                            'Page.Applications.ReviewalProcess.OfferAcceptance',
                            'Offer acceptance',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentApplication?.agreementSigningDate}
                        format="date-time-seconds"
                        label={t(
                            'Page.Applications.ReviewalProcess.AgreementSigning',
                            'Agreement signing',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentApplication?.decliningDate}
                        format="date-time-seconds"
                        label={t(
                            'Page.Applications.ReviewalProcess.DecliningDate',
                            'Declining date',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentApplication?.firstReviewer}
                        label={t(
                            'Page.UserDetails.FirstReviewer',
                            'First reviewer',
                        )}
                        format="link-internal"
                        formatterProps={{
                            to:
                                currentApplication?.firstReviewerId &&
                                EntityLinks.users.itemDetails(
                                    currentApplication?.firstReviewerId,
                                ),
                        }}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentApplication?.ccReviewer}
                        label={t('Page.UserDetails.CCReviewer', 'CC reviewer')}
                        format="link-internal"
                        formatterProps={{
                            to:
                                currentApplication?.ccReviewerId &&
                                EntityLinks.users.itemDetails(
                                    currentApplication?.ccReviewerId,
                                ),
                        }}
                    />
                </Col>
            </Row>
        </>
    );
});
