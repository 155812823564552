import { ApiResponseBodyMapLongString } from 'src/generated-api-client';
import { loansProductsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoanProductsListStoreClass extends BasicStore<string> {
    api: BasicStoreApi<string> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loansProductsApi.getProductMap(),
            );

            return (
                Object.values(
                    (result as ApiResponseBodyMapLongString)?.data || {},
                ).map((item: string) => item) || []
            );
        },
    };
}

export const LoanProductsListStore = new LoanProductsListStoreClass();
