import { DatePicker as AntDatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';

export const DateRangePicker = (props: RangePickerProps) => {
    return (
        <AntDatePicker.RangePicker
            placeholder={['', '']}
            format="DD.MM.YYYY"
            {...props}
        />
    );
};
