import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { CustomersStore } from 'src/stores/CustomersStore/CustomersStore';
import styled from 'styled-components';

const store = CustomersStore;

export const CompanyBankAccount = observer(() => {
    const { t } = useTranslation();
    const currentCustomer = store.currentItem?.data?.bankAccounts;

    return (
        <StyledTable pagination={false} dataSource={currentCustomer}>
            <Column
                title={t('Page.Customer.BankAccount.ID', 'ID')}
                dataIndex="id"
                key="id"
            />
            <Column title="Bank name" dataIndex="bankName" key="bankName" />
            <Column
                title={t(
                    'Page.Customer.BankAccount.AccountName',
                    'Account holder name',
                )}
                dataIndex="accountName"
                key="accountName"
            />
            <Column
                title={t(
                    'Page.Customer.BankAccount.AccountType',
                    'Account type',
                )}
                dataIndex="accountType"
                key="accountType"
            />
            <Column
                title={t('Page.Customer.BankAccount.BranchCode', 'Branch code')}
                dataIndex="branchCode"
                key="branchCode"
            />
            <Column
                title={t(
                    'Page.Customer.BankAccount.AccountNumber',
                    'Account number',
                )}
                dataIndex="accountNumber"
                key="accountNumber"
            />
        </StyledTable>
    );
});

const StyledTable = styled(Table)`
    margin-top: 10px;
    .ant-table-thead {
        .ant-table-cell {
            background: transparent;
            font-weight: 600;
        }
        .ant-table-cell::before {
            display: none;
        }
    }
    .ant-table-tbody {
        .ant-table-cell {
            color: #000000;
            font-size: 17px;
        }
    }
`;
