import { DatePicker, Form, FormItemProps } from 'antd';
import React from 'react';
import { DEFAULT_DATE_FORMAT } from 'src/utils/DateHelper';
import styled from 'styled-components';

export type DatePickerFieldProps = FormItemProps & {
    name: string;
    label: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    format?: string;
    // eslint-disable-next-line react/require-default-props
    showTime?: boolean;
    // eslint-disable-next-line react/require-default-props
    placeholder?: string;
};

export const DatePickerField = ({
    name,
    label,
    format = DEFAULT_DATE_FORMAT,
    showTime,
    placeholder,
    ...props
}: DatePickerFieldProps) => {
    return (
        <Form.Item {...props} name={name} label={label}>
            <DatePickerStyled
                allowClear={false}
                format={format}
                showTime={showTime}
                placeholder={placeholder || ''}
            />
        </Form.Item>
    );
};

export const DatePickerStyled = styled(DatePicker)`
    width: 100%;
    .ant-picker-input {
        display: flex;
        border-color: #000000;
    }
    border: 1px solid #000000;
    border-radius: 6px;
    height: 40px;
`;
