import { Col, notification, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DnDUploader } from 'src/components/DnDUploader/DnDUploader';
import {
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { ImportDataStore } from 'src/stores/ImportDataStore/ImportDataStore';
import { UploadFilesStatusStore } from 'src/stores/UploadFilesStatusStore/UploadFilesStatusStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';

export const ImportCSVDialog = observer(({ ...props }) => {
    const { t } = useTranslation();

    return (
        <Modal
            {...props}
            isLoading={ImportDataStore.importCSVAction.isLoading}
            documentLoader={
                UploadFilesStatusStore.getUploadingProgressBarStatus
            }
            onFinish={async (values) => {
                await ImportDataStore.importCSV(
                    values?.files?.[0]?.originFileObj,
                );

                if (!ImportDataStore.importCSVAction.hasError) {
                    notification.success({
                        message: t('Message.Success'),
                    });
                    await ImportDataStore.loadList();
                    Router.navigate(RoutePaths.importData);
                }
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <DnDUploader
                        name="files"
                        rules={[
                            ValidationRules.required,
                            ValidationRules.maxFileSize(25, FileSizeUnit.Mb),
                            ValidationRules.validateCSVFilesType,
                        ]}
                    />
                </Col>
            </Row>
        </Modal>
    );
});

export const ImportCSVModal = addModalRenderer(ImportCSVDialog);
