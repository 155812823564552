import { Form, FormItemProps, InputNumber } from 'antd';
import React from 'react';
import styled from 'styled-components';

export type TextFieldProps = FormItemProps & {
    label: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    prefix?: any;
    // eslint-disable-next-line react/require-default-props
    suffix?: any;
    // eslint-disable-next-line react/require-default-props
    max?: number;
};

export const NumberField = ({
    name,
    label,
    prefix,
    suffix,
    max,
    ...props
}: TextFieldProps) => {
    return (
        <Form.Item {...props} name={name} label={label}>
            <StyledInputNumber prefix={prefix} max={max} />
        </Form.Item>
    );
};

const StyledInputNumber = styled(InputNumber)`
    width: 100%;
    height: 40px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    border-color: #000000;
    .ant-input-number-handler-wrap {
        display: none;
    }
`;
