import { Form, FormInstance } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'src/components/Form/SelectField';
import { TextArea } from 'src/components/Form/TextArea';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { NotificationType } from 'src/generated-api-client';
import { NotificationSelectOptions } from 'src/pages/NotificationsPage/Components/NotificationSelectOptions';
import styled from 'styled-components';

type NotificationDialogFormProps = {
    form: FormInstance<any>;
};

export const NotificationDialogForm = observer(
    ({ form }: NotificationDialogFormProps) => {
        const { t } = useTranslation();
        const notificationType = Form?.useWatch('type', form);
        const isSMSNotification = notificationType === NotificationType.SMS;
        const isEmailNotification = notificationType === NotificationType.EMAIL;

        const notificationTypeOptions: OptionProps[] = Object.values(
            NotificationType,
        ).map((value) => ({
            value,
            children: t(`Notification.Type.${value}`),
        }));

        useEffect(() => {
            if (isSMSNotification) {
                form.resetFields(['subject', 'contentType']);
            }
        }, [notificationType]);

        return (
            <>
                <TextField
                    name="title"
                    label={t('Dialog.CreateNotification.NotificationName')}
                    rules={[
                        ValidationRules.required,
                        ValidationRules.maxLengthProductTitle,
                    ]}
                />
                <SelectField
                    name="triggerType"
                    label={t('Dialog.CreateNotification.TriggerType')}
                    options={NotificationSelectOptions.triggerType}
                    rules={[ValidationRules.required]}
                />
                <SelectField
                    name="type"
                    label={t('Dialog.CreateNotification.NotificationType')}
                    options={notificationTypeOptions}
                    rules={[ValidationRules.required]}
                />
                {isEmailNotification && (
                    <>
                        <TextField
                            name="subject"
                            label={t('Dialog.CreateNotification.Subject')}
                            dependencies={['type']}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxNotificationSubjectLength,
                            ]}
                        />
                        <SelectField
                            name="contentType"
                            label={t('Dialog.CreateNotification.ContentType')}
                            options={NotificationSelectOptions.contentType}
                            dependencies={['type']}
                            rules={[ValidationRules.required]}
                        />
                    </>
                )}
                <StyledTextArea
                    name="content"
                    label={t('Dialog.CreateNotification.Content')}
                    dependencies={['type']}
                    rules={[
                        ValidationRules.required,
                        isSMSNotification
                            ? ValidationRules.maxSmsNotificationLength
                            : {},
                        isEmailNotification
                            ? ValidationRules.maxEmailNotificationLength
                            : {},
                    ]}
                />
            </>
        );
    },
);

const StyledTextArea = styled(TextArea)`
    textarea.ant-input {
        min-height: 100px;
    }
`;
