import { Col, Row, Tooltip } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { MasterDetailsAsideItem } from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import {
    EntityModelLoanApplicationListDTO,
    LoanApplicationStatus,
} from 'src/generated-api-client';
import { applicationStatusMapColors } from 'src/utils/common-constants';
import styled from 'styled-components';

type ApplicationListItemProps = { item: EntityModelLoanApplicationListDTO };

export const ApplicationsListItem = observer(
    ({ item }: ApplicationListItemProps) => {
        return (
            <MasterDetailsAsideItem
                to={EntityLinks.loanApplication.itemDetails(item.id)}
            >
                <StyledHeaderRow gutter={5} wrap={false}>
                    <Col flex="auto">
                        <BoldText>
                            <NameContainer>#{item.id}</NameContainer>
                        </BoldText>
                    </Col>
                    <Col>
                        <Tooltip title={item.customerLegalName}>
                            <EllipsisContainer>
                                <NameContainer>
                                    {item.customerLegalName}
                                </NameContainer>
                            </EllipsisContainer>
                        </Tooltip>
                    </Col>
                </StyledHeaderRow>
                <Row wrap={false}>
                    <Col flex="auto">
                        <StyledStatusWrapper status={item.status}>
                            {item.status ===
                            LoanApplicationStatus.WAITINGACCOUNTVERIFICATION ? (
                                <Tooltip
                                    title={t(
                                        `Loan.Application.Status.${item.status}`,
                                    )}
                                >
                                    <EllipsisContainer>
                                        {t(
                                            `Loan.Application.Status.${item.status}`,
                                        )}
                                    </EllipsisContainer>
                                </Tooltip>
                            ) : (
                                t(`Loan.Application.Status.${item.status}`)
                            )}
                        </StyledStatusWrapper>
                    </Col>
                    <Col>
                        <SecondaryText>
                            {t(`Entity.Application.Type.${item.type}`)}
                        </SecondaryText>
                    </Col>
                </Row>
            </MasterDetailsAsideItem>
        );
    },
);

const StyledHeaderRow = styled(Row)`
    margin-bottom: 5px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    max-width: 9rem;
    padding ${(props) =>
        props.status === LoanApplicationStatus.WAITINGACCOUNTVERIFICATION
            ? '0.7rem 0.4rem'
            : '0.7rem 1.2rem'};
    background: ${(props) =>
        applicationStatusMapColors[
            props?.status as keyof typeof applicationStatusMapColors
        ]};
    border-radius: 22px;
    font-size: 0.8rem;
    height: 22px;
    align-items: center;
    justify-content: center;
    white-space: break-spaces;
    color: #000000;
`;

const SecondaryText = styled.span`
    font-size: 0.7rem;
    line-height: 150%;
    color: #000000;
`;

const BoldText = styled.span`
    font-weight: 600;
`;

const NameContainer = styled.span`
    font-size: 1rem;
`;
