import { OptionProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import {
    EntityModelUserAccountActionDTOPortalTypeEnum,
    EntityModelUserAccountActionDTORequestMethodEnum,
} from 'src/generated-api-client';
import { SearchPanelFilterForm } from 'src/pages/FilterPanel/SearchPanelFilterForm';
import { ActionLogsStore } from 'src/stores/ActionLogsStore/ActionLogsStore';
import { DEFAULT_DATE_FORMAT } from 'src/utils/DateHelper';
import styled from 'styled-components';

export const FilterActionLogsPanel = observer((props: any) => {
    const { t } = useTranslation();

    const portalTypeOptions: OptionProps[] = Object.values(
        EntityModelUserAccountActionDTOPortalTypeEnum,
    ).map((value) => ({
        value,
        children: t(`Portal.Type.${value}`),
    }));

    const requestMethodOptions: OptionProps[] = Object.values(
        EntityModelUserAccountActionDTORequestMethodEnum,
    ).map((value) => ({
        value,
        children: t(`Request.Method.${value}`),
    }));

    return (
        <SearchPanelFilterForm
            {...props}
            onFinish={async ({ active, ...values }: any) => {
                ActionLogsStore.filterCriteria?.applyFilter({
                    ...values,
                });
                ActionLogsStore.pager?.resetPage();
                await ActionLogsStore.loadList();
            }}
        >
            <TextField
                onlyNumbers
                name="id"
                label={t('Page.ActionLogs.Filter.ID', 'ID')}
            />
            <TextField
                name="userFullName"
                label={t('Page.ActionLogs.Filter.User', 'User')}
            />
            <SelectField
                name="portalTypes"
                label={t('Page.ActionLogs.Filter.Portal', 'Portal')}
                options={portalTypeOptions}
            />
            <SelectField
                name="requestMethods"
                label={t(
                    'Page.ActionLogs.Filter.RequestMethods',
                    'Request type',
                )}
                options={requestMethodOptions}
            />
            <TextField
                name="url"
                label={t('Page.ActionLogs.Filter.URL', 'URL')}
            />
            <TextField name="ip" label={t('Page.ActionLogs.Filter.IP', 'IP')} />

            <StyledDatePickerField
                name="dateFrom"
                label={t('Page.ActionLogs.Filter.UR', 'Date from')}
                format={DEFAULT_DATE_FORMAT}
            />
            <StyledDatePickerField
                name="dateTo"
                label={t('Page.ActionLogs.Filter.UR', 'Date to')}
                format={DEFAULT_DATE_FORMAT}
            />
        </SearchPanelFilterForm>
    );
});

const StyledDatePickerField = styled(DatePickerField)`
    .ant-picker {
        border: 1px solid #d9d9d9;
    }
`;
