import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { CustomersStore } from 'src/stores/CustomersStore/CustomersStore';

const store = CustomersStore;

export const CompanyDetails = observer(() => {
    const { t } = useTranslation();

    const currentCustomer = store.currentItem?.data;

    return (
        <>
            <Row>
                <BlockTitle>
                    {t('Page.Customer.Details.Title', 'Company details')}
                </BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentCustomer?.cipc}
                        label={t('Page.Customer.Details.CIPC', 'cipc number')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentCustomer?.taxId}
                        label={t(
                            'Page.Customer.Details.TaxNumber',
                            'tax number',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentCustomer?.contactDetails.email}
                        label={t('Page.Customer.Details.Email', 'Email')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentCustomer?.contactDetails.phoneNumber}
                        label={t(
                            'Page.Customer.Details.PhoneNumber',
                            'TELEPHONE NUMBER',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="date-time-seconds"
                        value={currentCustomer?.lastActivityAt}
                        label={t(
                            'Page.Customer.Details.LastActivityAt',
                            'LAST ACTIVITY',
                        )}
                    />
                </Col>
            </Row>
        </>
    );
});
