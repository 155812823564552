import { useParams } from 'react-router-dom';

export function useImportDataId() {
    const params = useParams<{ dataId: string }>();

    return {
        importDataId: params?.dataId,
        hasimportDataId: Boolean(params?.dataId),
    };
}
