import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import { Header } from 'src/components/Header';
import { SplashScreen } from 'src/components/SplashScreen/SplashScreen';
import { RoutesLayout } from 'src/containers/RoutesLayout/RoutesLayout';
import { AppStore } from 'src/stores/AppStore/AppStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { CssVariables } from 'src/styles/CssVariables';
import styled from 'styled-components';

export const AppLayout = observer(() => {
    if (!AuthStore.authenticated || !AppStore.started) {
        return <SplashScreen />;
    }

    return (
        <Layout>
            <Header />
            <StyledContent>
                <StyledLayoutContent>
                    <RoutesLayout />
                </StyledLayoutContent>
            </StyledContent>
        </Layout>
    );
});

const StyledContent = styled(Layout.Content)`
    background-color: white;
    height: calc(100vh - ${CssVariables.headerHeight});
    margin-top: ${CssVariables.headerHeight};
    position: relative;
    display: flex;
`;

const StyledLayoutContent = styled(Layout.Content)`
    padding: 0;
    position: relative;
    overflow-y: auto;
`;
