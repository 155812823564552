import { TabPaneProps, Tabs as AntTabs, TabsProps as AntTabsProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { TabPane } = AntTabs;

export type TabDefinition = Omit<TabPaneProps, 'tab'> & {
    tabKey: string;
    title: React.ReactNode;
    Component: React.ReactNode;
    visible?: boolean;
};

type TabsProps = AntTabsProps & {
    tabs: TabDefinition[];
};

export const Tabs = ({ tabs, ...props }: TabsProps) => {
    const tabPanes = tabs
        .filter((tab) => tab.visible !== false)
        .map(({ title, tabKey, Component, ...paneProps }) => {
            return (
                <TabPane {...paneProps} key={tabKey} tab={title}>
                    {Component}
                </TabPane>
            );
        });

    return (
        <StyledTabs
            {...props}
            getPopupContainer={(triggerNode) => triggerNode}
            destroyInactiveTabPane
        >
            {tabPanes}
        </StyledTabs>
    );
};

const StyledTabs = styled(AntTabs)`
    .ant-tabs-tab {
        padding: 7px 15px;
    }
    .ant-tabs-tab {
        font-size: 1.2rem;
    }
    .ant-tabs-tab-active {
        border: 1px solid #000000 !important;
        border-bottom: none;
        border-radius: 10px;
        .ant-tabs-tab-btn {
            color: #000000;
        }
    }
    .ant-tabs-ink-bar-animated {
        display: none;
    }
    .ant-tabs-nav::before {
        border: none;
    }
    .ant-tabs-nav:hover {
        color: #5b5b5b;
    }
`;
