import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Brief } from 'src/components/Brief/Brief';
import { Tabs } from 'src/components/Tabs/Tabs';
import {
    LoanApplicationStatus,
    LoanApplicationType,
} from 'src/generated-api-client';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { SigningLogs } from 'src/pages/ApplicationsPage/TabsContent/SigningLogs';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import styled from 'styled-components';

import { EntityHeader } from './Components';
import { ApplicationConditions } from './TabsContent/ApplicationConditions';
import { ApplicationDocuments } from './TabsContent/ApplicationDocuments';
import { ApplicationLoans } from './TabsContent/ApplicationLoans';
import { BorrowersBankAccount } from './TabsContent/BorrowersBankAccount';
import { FinancialAnalysis } from './TabsContent/FinancialAnalysis';
import { ReviewalProcess } from './TabsContent/ReviewalProcess';

const store = ApplicationsStore;

const accessFinancialTabApplicationTypes = [
    LoanApplicationType.PURCHASEORDERFINANCE,
    LoanApplicationType.FINANCEOFDEBTOR,
];

export const ApplicationsEntityPresentation = observer(() => {
    const { t } = useTranslation();
    const { applicationId } = useApplicationId();

    const currentApplication = store.currentItem?.data;

    const TabsContent = [
        {
            tabKey: 'general-information',
            title: t(
                'Page.Application.Details.GeneralInformation.Tab',
                'General information',
            ),
            Component: (
                <>
                    <ApplicationConditions />
                    <ReviewalProcess />
                    <BorrowersBankAccount />
                </>
            ),
        },
        {
            tabKey: 'application-documents',
            title: t('Page.Application.Details.Documents.Tab', 'Documents'),
            Component: <ApplicationDocuments />,
        },
        {
            tabKey: 'financial-analysis',
            title: t(
                'Page.Application.Details.FinancialAnalysis.Tab',
                'Financial analysis',
            ),
            Component: <FinancialAnalysis />,
            visible:
                accessFinancialTabApplicationTypes.includes(
                    currentApplication?.type as LoanApplicationType,
                ) &&
                currentApplication?.status !==
                    LoanApplicationStatus.INITIALREVIEW,
        },
        {
            tabKey: 'application-loans',
            title: t('Page.Application.Details.Loans.Tab', 'Loans'),
            Component: <ApplicationLoans />,
        },
        {
            tabKey: 'signing-logs',
            title: t(
                'Page.Application.Details.FinancialAnalysis.SigningLogs',
                'Signing logs',
            ),
            Component: <SigningLogs />,
        },
    ];

    useEffect(() => {
        if (!applicationId) {
            return;
        }
        store.loadItem(applicationId);
    }, [applicationId]);

    return (
        <MainContainer>
            <EntityHeader />
            <Brief>
                <Tabs tabs={TabsContent} />
            </Brief>
        </MainContainer>
    );
});

const MainContainer = styled.div`
    margin: 60px 30px 0;
`;
