/* eslint-disable max-lines */
import {
    Button,
    Form as AntForm,
    FormInstance,
    FormProps,
    Modal as AntModal,
    ModalProps as AntModalProps,
    Progress,
    Typography,
} from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'src/components/Form/Form';
import { Spinner } from 'src/components/Spinner/Spinner';
import { FieldValidationHelper } from 'src/utils/FieldValidationHelper';
import styled from 'styled-components';

export type ModalProps = AntModalProps & {
    // eslint-disable-next-line react/require-default-props
    formProps?: Omit<FormProps, 'onFinish'>;
    // eslint-disable-next-line react/require-default-props
    form?: FormInstance;
    // eslint-disable-next-line react/require-default-props
    onFinish?: FormProps['onFinish'];
    // eslint-disable-next-line react/require-default-props
    isLoading?: boolean;
    // eslint-disable-next-line react/require-default-props
    width?: number;
    // eslint-disable-next-line react/require-default-props
    closable?: boolean;
    // eslint-disable-next-line react/require-default-props
    title?: string;
    // eslint-disable-next-line react/require-default-props
    subtitle?: string;
    // eslint-disable-next-line react/require-default-props
    storeLoader?: any;
    // eslint-disable-next-line react/require-default-props
    initialValues?: any;
    // eslint-disable-next-line react/require-default-props
    documentLoader?: number;
};

const { Title } = Typography;

export const Modal: React.FC<ModalProps> = ({
    formProps,
    form,
    onFinish,
    isLoading,
    children,
    onCancel,
    width = 520,
    closable = true,
    subtitle,
    title,
    storeLoader,
    initialValues,
    documentLoader = 0,
    ...props
}: ModalProps) => {
    const fileLoadingProgress = Math.round(documentLoader);

    const { t } = useTranslation();

    const [innerForm] = AntForm.useForm();

    const targetForm = useMemo(() => {
        return form || innerForm;
    }, [form]);

    const okText = props.okText || t('Component.Modal.Btn.Ok');
    const cancelText = props.cancelText || t('Component.Modal.Btn.Cancel');

    const handleOnCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (isLoading) {
            return;
        }

        onCancel?.(e);
    };

    return (
        <StyledModal
            width={width}
            closable={closable}
            onCancel={handleOnCancel}
            title={
                <StyledHeader>
                    {title && <Title level={3}>{title}</Title>}
                    {subtitle && <Title level={5}>{subtitle}</Title>}
                </StyledHeader>
            }
            footer={[
                <StyledResetButton
                    {...props.cancelButtonProps}
                    key="cancel"
                    disabled={isLoading}
                    onClick={handleOnCancel}
                >
                    {cancelText}
                </StyledResetButton>,
                <StyledSaveButton
                    {...props.okButtonProps}
                    key="ok"
                    type="primary"
                    loading={isLoading}
                    onClick={async (e) => {
                        await targetForm.submit();
                        await props.onOk?.(e);
                    }}
                >
                    {okText}
                </StyledSaveButton>,
            ]}
            {...props}
        >
            <StyledForm
                form={targetForm}
                initialValues={initialValues}
                onFinish={async (values) => {
                    await onFinish?.(values);
                    if (storeLoader?.hasError) {
                        FieldValidationHelper.setFieldErrorsFromRequest(
                            storeLoader?.errorData?.violations || [],
                            targetForm,
                        );
                    } else {
                        await onCancel?.(true as any);
                    }
                }}
            >
                {isLoading && <StyledSpinner />}
                {documentLoader > 0 && (
                    <StyledProgress
                        strokeColor={
                            fileLoadingProgress === 100 ? '#52c41a' : '#d00824'
                        }
                        type="circle"
                        percent={fileLoadingProgress}
                    />
                )}
                {children}
            </StyledForm>
        </StyledModal>
    );
};

const StyledModal = styled(AntModal)`
    border: 0.8px solid #000000;
    overflow: hidden;
    border-radius: 10px;
    padding: 0;
    .ant-modal-header {
        color: #000000;
        padding: 25px 24px;
        border: none;
        .ant-modal-title {
            text-align: center;
            font-weight: bold;
            font-size: 21px;
        }
    }
    .ant-modal-footer {
        border: none;
        display: flex;
        justify-content: center;
        padding: 35px 0;
    }
`;

const StyledForm = styled(Form)`
    position: relative;

    .ant-form-item-label {
        font-weight: bold;
        padding: 0 0 4px;
    }
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 99;
    background-color: white;
    opacity: 0.7;
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const StyledHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ant-typography + h5.ant-typography {
        margin: 0;
    }
    .ant-typography {
        margin-bottom: 5px;
    }
`;

const StyledProgress = styled(Progress)`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 99;
    background-color: white;
    opacity: 0.9;
`;
