import { Col, Row, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { MasterDetailsAsideItem } from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import {
    EntityModelBackofficeUserDTO,
    UserStatus,
} from 'src/generated-api-client';
import styled from 'styled-components';

type UserListItemProps = { item: EntityModelBackofficeUserDTO };

export const UsersListItem = observer(({ item }: UserListItemProps) => {
    return (
        <MasterDetailsAsideItem to={EntityLinks.users.itemDetails(item.id)}>
            <StyledHeaderRow gutter={5} wrap={false}>
                <Col flex="auto">
                    <BoldText>
                        <NameContainer>#{item.id}</NameContainer>
                    </BoldText>
                </Col>
                <Col>
                    <Tooltip title={item.fullName}>
                        <EllipsisContainer>
                            <NameContainer>{item.fullName}</NameContainer>
                        </EllipsisContainer>
                    </Tooltip>
                </Col>
            </StyledHeaderRow>
            <Row wrap={false}>
                <Col flex="auto">
                    <StyledStatusWrapper status={item.status}>
                        {item.status?.toLocaleLowerCase()}
                    </StyledStatusWrapper>
                </Col>
                <Col>
                    <SecondaryText>{item.roleName}</SecondaryText>
                </Col>
            </Row>
        </MasterDetailsAsideItem>
    );
});

const StyledHeaderRow = styled(Row)`
    margin-bottom: 5px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    max-width: 120px;
    padding: 5px 19px;
    background: ${(props) =>
        props.status === UserStatus.ACTIVE ? '#ACF392' : '#EB7382'};
    border-radius: 22px;
    font-size: 13px;
    height: 22px;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
`;

const SecondaryText = styled.span`
    font-size: 13px;
    line-height: 150%;
    color: #000000;
`;

const BoldText = styled.span`
    font-weight: 600;
`;

const NameContainer = styled.span`
    font-size: 20px;
`;
