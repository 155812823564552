import { Button, ButtonProps, Form, Popover } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { ReactComponent as FilterIcon } from 'src/icons/icon-filter.svg';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

import { SearchPanelFilterFormProps } from './SearchPanelFilterForm';

export type FilterBlockProps = {
    placeholder?: string;
    store: BasicStore<any>;
    renderPanel?: (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props?: SearchPanelFilterFormProps,
    ) => React.ReactElement<SearchPanelFilterFormProps>;
};

type PopperElement = { close: () => void };

export const FilterBlock = observer(
    ({ store, renderPanel }: FilterBlockProps) => {
        const popover = useRef<PopperElement>();
        const [form] = Form.useForm();

        return (
            <Form
                form={form}
                onFinish={(values) => {
                    store.filterCriteria?.applyFilter(values);
                    store.pager?.reset();
                    store.loadList();
                }}
                layout="vertical"
            >
                {store.filterCriteria && renderPanel && (
                    <StyledPopper
                        ref={(el: PopperElement) => {
                            popover.current = el;
                        }}
                        content={renderPanel({
                            form,
                            closePopper: () => popover.current?.close(),
                        })}
                        trigger="click"
                        placement="bottomLeft"
                    >
                        <StyledFilterButton>
                            <FilterIcon />
                        </StyledFilterButton>
                    </StyledPopper>
                )}
            </Form>
        );
    },
);

const StyledFilterButton = styled(Button)<
    ButtonProps & { hasFilter?: boolean }
>`
    border: none;
    background: white;
    color: ${(props) => (props.hasFilter ? '#0A65ED' : 'black')};
    border-radius: 5px;
    height: 32px;
    padding: 0 12px;

    &:hover,
    &:focus,
    &.active {
        background: #22232566;
        border-color: #22232566;
    }
`;

export const StyledPopper = styled(Popover)`
    .ant-popover-inner {
        border-radius: 6px;
    }
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        border-radius: 6px;
    }
`;
