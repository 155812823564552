/* eslint-disable max-lines */
import { Col, notification, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type EditLoanProductDialogProps = ModalRendererProps & {
    loanProductId: number;
};
export const EditLoanProductComponent = observer(
    ({ loanProductId, ...props }: EditLoanProductDialogProps) => {
        const { t } = useTranslation();

        return (
            <Modal
                {...props}
                closable={false}
                width={670}
                title={t('Page.LoanProducts.EditLoanProductDialog.Title')}
                storeLoader={LoanProductsStore.updateItemLoader}
                subtitle={t('Page.LoanProducts.EditLoanProductDialog.SubTitle')}
                isLoading={LoanProductsStore.updateItemLoader.isLoading}
                onFinish={async (values) => {
                    await LoanProductsStore.updateItem(loanProductId, values);
                    if (!LoanProductsStore.updateItemLoader.hasError) {
                        notification.success({
                            message: t('Message.Success'),
                        });
                        await LoanProductsStore.loadList();
                        await LoanProductsStore.loadItem(loanProductId);
                    }
                }}
                initialValues={LoanProductsStore.currentItem?.data}
            >
                <Row gutter={16}>
                    <Col span={20}>
                        <TextField
                            name="productName"
                            label={t(
                                'Page.LoanProducts.EditLoanProductDialog.ProductName',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxLengthProductTitle,
                            ]}
                        />
                    </Col>
                </Row>
                <StyledDivider />
                <Row justify="space-between">
                    <Col span={10}>
                        <NumberField
                            prefix="R"
                            name="initiationFee"
                            label={t(
                                'Page.LoanProducts.EditLoanProductDialog.InitiationFee',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.notMorethenMillion,
                                ValidationRules.decimal,
                            ]}
                        />
                    </Col>
                    <Col span={10}>
                        <NumberField
                            prefix="R"
                            name="monthlyFee"
                            label={t(
                                'Page.LoanProducts.EditLoanProductDialog.MonthlyFee',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.notMorethenMillion,
                                ValidationRules.decimal,
                            ]}
                        />
                    </Col>
                </Row>
                <StyledDivider />
                <Row justify="space-between">
                    <Col span={10}>
                        <NumberField
                            prefix="%"
                            name="interestRate"
                            label={t(
                                'Page.LoanProducts.EditLoanProductDialog.InterestRate',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.notMoreThanHundered,
                                ValidationRules.decimal,
                            ]}
                        />
                    </Col>
                    <Col span={10}>
                        <NumberField
                            prefix="%"
                            name="vatRate"
                            label={t(
                                'Page.LoanProducts.EditLoanProductDialog.VatRate',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.notMoreThanHundered,
                                ValidationRules.decimal,
                            ]}
                        />
                    </Col>
                </Row>
                <StyledTransparentDivider />
                <Row justify="space-between" align="stretch">
                    <Col span={10}>
                        <StyledLabel>
                            <span>*</span>
                            {t(
                                'Page.LoanProducts.EditLoanProductDialog.PenaltyInterestMultiplier',
                            )}
                        </StyledLabel>
                    </Col>
                    <Col span={10}>
                        <NumberField
                            name="penaltyInterestMultiplier"
                            label=""
                            rules={[
                                ValidationRules.required,
                                ValidationRules.notMoreThanTen,
                                ValidationRules.penaltyInterestMultiplierDecimal,
                            ]}
                        />
                    </Col>
                </Row>
                <Row justify="space-between" align="stretch">
                    <Col span={10}>
                        <StyledLabel>
                            <span>*</span>
                            {t(
                                'Page.LoanProducts.EditLoanProductDialog.InsuranceRateMultiplier',
                            )}
                        </StyledLabel>
                    </Col>
                    <Col span={10}>
                        <NumberField
                            prefix="%"
                            name="insuranceRateMultiplier"
                            label=""
                            rules={[
                                ValidationRules.required,
                                ValidationRules.notMoreThanHundered,
                                ValidationRules.decimal,
                            ]}
                        />
                    </Col>
                </Row>
                <Row justify="space-between" align="stretch">
                    <Col span={10}>
                        <StyledLabel>
                            <span>*</span>
                            {t(
                                'Page.LoanProducts.EditLoanProductDialog.InterestRateRestriction',
                            )}
                        </StyledLabel>
                    </Col>
                    <Col span={10}>
                        <NumberField
                            prefix="%"
                            name="interestRateRestriction"
                            label=""
                            rules={[
                                ValidationRules.required,
                                ValidationRules.notMoreThanHundered,
                                ValidationRules.decimal,
                            ]}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const EditLoanProductDialog = addModalRenderer(EditLoanProductComponent);

const StyledDivider = styled.div`
    margin-bottom: 30px;
    border-bottom: 1px solid #00000029;
`;
const StyledTransparentDivider = styled.div`
    margin-bottom: 30px;
`;
const StyledLabel = styled.label`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px !important;
    span {
        color: red;
        margin-right: 4px;
    }
`;
