/* eslint-disable sonarjs/no-identical-functions */
import { Button, Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityActions } from 'src/components/EntityActions/EntityActions';
import {
    EntityModelReportDTOStatusEnum,
    ReportDTOFileTypeEnum,
} from 'src/generated-api-client';
import { useStatisticId } from 'src/hooks/useStatisticId';
import { StatisticsStore } from 'src/stores/StatisticsStore/StatisticsStore';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

const { Text } = Typography;

const store = StatisticsStore;

export const StatisticEntityPresentation = observer(() => {
    const { t } = useTranslation();
    const { statisticId } = useStatisticId();

    const statisticItem = store.currentItem?.data;

    useEffect(() => {
        if (!statisticId) {
            return;
        }
        store.loadItem(statisticId);
    }, [statisticId]);

    const entityActions = [
        {
            key: 'generate',
            label: t('Page.Statisc.Action.Generate'),
            onClick: () => {
                store.regenerateReport(statisticId);
            },
        },
    ];

    return (
        <MainContainer>
            <ColoredHeader align="middle" justify="space-between">
                <Col span={2}>
                    <TextBold>{`#${statisticItem?.id}`}</TextBold>
                </Col>
                <Col flex="auto">
                    <TextBold>
                        {DateHelper.format(statisticItem?.date as string)}
                    </TextBold>
                </Col>
                <Col>
                    {statisticItem?.status && (
                        <StyledStatusWrapper status={statisticItem?.status}>
                            {statisticItem?.status.toLowerCase()}
                        </StyledStatusWrapper>
                    )}
                </Col>
                <Col>
                    <EntityActions items={entityActions} />
                </Col>
            </ColoredHeader>
            <StyledReportBlock gutter={24}>
                <Col span={24}>
                    <Text strong>
                        {t('Page.Statisc.Presentation.Title.Report')}
                    </Text>
                </Col>
                <Col span={24}>
                    <StyledButton
                        type="link"
                        onClick={() =>
                            store.downloadReports(
                                statisticItem?.id as number,
                                statisticItem?.fileType as ReportDTOFileTypeEnum,
                                statisticItem?.fileName as string,
                            )
                        }
                    >
                        {statisticItem?.fileName}
                    </StyledButton>
                </Col>
            </StyledReportBlock>
        </MainContainer>
    );
});

const MainContainer = styled.div`
    margin: 60px 30px 0;
`;

const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    height: 120px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
`;

const TextBold = styled.span`
    font-weight: 600;
    font-size: 29px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) =>
        props.status === EntityModelReportDTOStatusEnum.SUCCESSFUL
            ? '#ACF392'
            : '#FFE4E4'};
    border-radius: 5px;
    font-size: 15px;
    height: 35px;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    text-decoration: capitalize;
    color: #000000;
`;

const StyledButton = styled(Button)`
    padding: 0;
`;

const StyledReportBlock = styled(Row)`
    margin-top: 59px;
`;
