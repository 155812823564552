import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';
import { DataFormatterFormat } from 'src/utils/DataFormatterProvider';

const store = LoanProductsStore;

export const EntityPresentationBody = observer(() => {
    const { t } = useTranslation();
    const loanProduct = store.currentItem?.data;

    return (
        <Brief>
            <Row>
                <BlockTitle>
                    {t(
                        'Page.LoanProductDetails.ProductConditions',
                        'Product conditions',
                    )}
                </BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        suffix="%"
                        value={loanProduct?.interestRate}
                        label={t(
                            'Page.LoanProductDetails.Rate',
                            '30 days rate',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        suffix="%"
                        value={loanProduct?.insuranceRateMultiplier}
                        label={t(
                            'Page.LoanProductDetails.InsuranceRateMultiplier',
                            'Insurance rate multiplier',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        prefix="R"
                        value={loanProduct?.monthlyFee}
                        label={t(
                            'Page.LoanProductDetails.MonthlyFee',
                            'Monthly fee',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        prefix="R"
                        value={loanProduct?.initiationFee}
                        label={t(
                            'Page.LoanProductDetails.initiationFee',
                            'Initiation fee',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        suffix="%"
                        value={loanProduct?.vatRate}
                        label={t('Page.UserDetails.VATRate', 'VAT rate')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={loanProduct?.penaltyInterestMultiplier}
                        label={t(
                            'Page.UserDetails.PenaltyInterestMultiplier',
                            'PENALTY INTEREST MULTIPLIER',
                        )}
                        format={DataFormatterFormat.numberInfo}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        suffix="%"
                        value={loanProduct?.interestRateRestriction}
                        label={t(
                            'Page.UserDetails.RateRestriction',
                            'YEARLY INTEREST RATE RESTRICTION',
                        )}
                    />
                </Col>
            </Row>
        </Brief>
    );
});
