import { action, computed, observable } from 'mobx';
import {
    ApiResponseBodyCustomerUserDTO,
    CreditBureauCheckLog,
    CustomerVerificationStatus,
    EntityModelCustomerUserDTO,
} from 'src/generated-api-client';
import { customersApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type CustomersFilter = {
    legalName?: string;
    cipc?: number;
    id?: string;
    email?: string;
    representativeEmail?: string;
    CustomerUserStatus?: CustomerVerificationStatus[];
};

export class CustomersStoreClass extends BasicStore<
    EntityModelCustomerUserDTO,
    CustomersFilter,
    undefined,
    undefined
> {
    @observable private currentCustomerCBLogs: CreditBureauCheckLog[] = [];
    api: BasicStoreApi<
        EntityModelCustomerUserDTO,
        undefined,
        undefined,
        ApiResponseBodyCustomerUserDTO
    > = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                customersApi.entityList9(
                    this.filterCriteria.filter.id
                        ? Number(this.filterCriteria.filter.id)
                        : undefined,
                    this.filterCriteria.filter.legalName,
                    this.filterCriteria.filter.cipc?.toString(),
                    this.filterCriteria.filter.CustomerUserStatus,
                    this.filterCriteria.filter.email,
                    this.filterCriteria.filter.representativeEmail,
                    this.pager?.page,
                    this.pager?.size,
                ),
            );

            this.pager?.setTotal(result.page?.totalElements as number);

            return result.content || [];
        },

        loadItem: (id: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                customersApi.entityView9(Number(id)),
            );
        },

        activate: async (id) => {
            await RequestHelper.unwrapFromAxiosPromise(
                customersApi.activate3(id),
            );
        },

        deactivate: async (id) => {
            await RequestHelper.unwrapFromAxiosPromise(
                customersApi.deactivate3(id),
            );
        },
    };

    getCustomerCBLogsAction = new AsyncOperationWithStatus((id: number) =>
        RequestHelper.unwrapFromAxiosPromise(
            customersApi.creditBureauCheckLogs(id),
        ),
    );

    customerCBRestartAction = new AsyncOperationWithStatus(
        (customerId: number, logId: number) =>
            RequestHelper.unwrapFromAxiosPromise(
                customersApi.creditBureauCheckLogRestart(customerId, logId),
            ),
    );

    @action async getCustomerCBLogs(id: number) {
        const result = await this.getCustomerCBLogsAction.call(id);
        if (result?.data?.length) {
            this.currentCustomerCBLogs = result?.data as CreditBureauCheckLog[];
        }
    }

    @action async customerCBRestart(customerId: number, logId: number) {
        await this.customerCBRestartAction.call(customerId, logId);
        if (!this.customerCBRestartAction.hasError) {
            this.getCustomerCBLogs(customerId);
        }
    }

    @computed get customerCBLogs() {
        return this.currentCustomerCBLogs;
    }

    filterCriteria = new FilterCriteria<CustomersFilter>();

    pager?: Pager | undefined = new Pager();
}

export const CustomersStore = new CustomersStoreClass();
