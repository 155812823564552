import { Col, notification, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'src/components/Form/SelectField';
import { TextArea } from 'src/components/Form/TextArea';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { AuthorityItem } from 'src/generated-api-client';
import { AuthoritiesStore } from 'src/stores/AuthoritiesStore/AuthoritiesStore';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';
import { START_PAGES_OPTIONS } from 'src/utils/common-constants';
import { addModalRenderer } from 'src/utils/ModalRenderer';

export const CreateRoleDialog = observer(({ ...props }) => {
    const { t } = useTranslation();

    return (
        <Modal
            {...props}
            title={t('Dialog.Create.Role.Dialog.Title', 'Create role')}
            isLoading={RolesStore.createItemLoader.isLoading}
            storeLoader={RolesStore.createItemLoader}
            onFinish={async (values) => {
                await RolesStore.createItem(values);

                if (!RolesStore.createItemLoader.hasError) {
                    notification.success({
                        message: t('Message.Success'),
                    });
                    await RolesStore.loadList();
                }
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <TextField
                        name="name"
                        label={t('Page.Create.Role.Dialog.Name', 'Name')}
                        rules={[ValidationRules.required]}
                    />
                </Col>

                <Col span={24}>
                    <SelectField
                        mode="multiple"
                        store={AuthoritiesStore}
                        convertListItemToOption={(
                            authority: AuthorityItem,
                        ) => ({
                            children: authority.description,
                            value: authority.value,
                        })}
                        name="authorities"
                        label={t(
                            'Page.Create.Role.Dialog.Authorities',
                            'Authorities',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>

                <Col span={24}>
                    <SelectField
                        options={START_PAGES_OPTIONS}
                        name="startPage"
                        label={t(
                            'Page.Create.Role.Dialog.StartPage',
                            'Start page',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>

                <Col span={24}>
                    <TextArea
                        name="description"
                        rows={6}
                        label={t(
                            'Page.Create.Role.Dialog.Description',
                            'Description',
                        )}
                        rules={[ValidationRules.required]}
                    />
                </Col>
            </Row>
        </Modal>
    );
});

export const AddRoleModal = addModalRenderer(CreateRoleDialog);
