import { Col, Row } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { EntityActions } from 'src/components/EntityActions/EntityActions';
import {
    NotificationDTO,
    NotificationStatus,
    NotificationType,
} from 'src/generated-api-client';
import { EditNotificationDialogModal } from 'src/pages/NotificationsPage/Dialogs/EditNotificationDialog';
import { NotificationMapColors } from 'src/pages/NotificationsPage/NotificationsListItem';
import { NotificationsStore } from 'src/stores/NotificationsStore/NotificationsStore';
import styled from 'styled-components';

const store = NotificationsStore;

export const NotificationDetails = observer(() => {
    const { t } = useTranslation();
    const { params } = useRouteMatch<{ id: string }>();

    const {
        content,
        contentType,
        type,
        triggerType,
        status,
        title,
        id,
        subject,
        editDisabled,
    } = store?.currentItem?.data || ({} as NotificationDTO);

    const isEmailType = type === NotificationType.EMAIL;
    const isActive = status === NotificationStatus.ACTIVE;
    const isInactive = status === NotificationStatus.INACTIVE;

    useEffect(() => {
        store.loadItem(params.id);

        return () => {
            store.itemLoader.reset();
        };
    }, [params.id]);

    const entityActions: ItemType[] = [
        !editDisabled
            ? {
                  label: t('Page.NotificationDetails.Actions.Edit'),
                  key: 'edit-notification',
                  onClick: () => {
                      EditNotificationDialogModal.render({
                          notificationId: params.id,
                      });
                  },
              }
            : null,
        isActive
            ? {
                  label: t(
                      'Page.NotificationDetails.Actions.Deactivate',
                      'Deactivate',
                  ),
                  key: 'deactivate',
                  onClick: async () => {
                      await store.deactivate(params.id);

                      if (!store.deactivateLoader.hasError) {
                          await store.loadList();
                          await store.loadItem(params.id);
                      }
                  },
              }
            : null,
        isInactive
            ? {
                  label: t(
                      'Page.NotificationDetails.Actions.Activate',
                      'Activate',
                  ),
                  key: 'activate',
                  onClick: async () => {
                      await store.activate(params.id);

                      if (!store.activateLoader.hasError) {
                          await store.loadList();
                          await store.loadItem(params.id);
                      }
                  },
              }
            : null,
    ];

    return (
        <MainContainer>
            <ColoredHeader align="middle" justify="space-between">
                <Col flex="auto">
                    {id && title && (
                        <TextBold>
                            {`#${id}`} {title}
                        </TextBold>
                    )}
                </Col>

                <Col>
                    {status && (
                        <StyledStatusWrapper status={status}>
                            {status && t(`Notification.Status.${status}`)}
                        </StyledStatusWrapper>
                    )}
                </Col>
                <Col>
                    {!editDisabled && <EntityActions items={entityActions} />}
                </Col>
            </ColoredHeader>

            <Brief>
                <BlockTitle>{t('Page.NotificationDetails.Title')}</BlockTitle>
                <DataView
                    label={t('Page.NotificationDetails.Type')}
                    value={type}
                    format="enum"
                    formatterProps={{ i18nKey: 'Notification.Type.' }}
                />
                <DataView
                    label={t('Page.NotificationDetails.TriggerType')}
                    value={triggerType}
                    format="enum"
                    formatterProps={{ i18nKey: 'Trigger.Type.' }}
                />
                <DataView
                    label={t('Page.NotificationDetails.Content')}
                    value={content}
                />
                {isEmailType && (
                    <>
                        <DataView
                            label={t('Page.NotificationDetails.Subject')}
                            value={subject}
                        />
                        <DataView
                            label={t('Page.NotificationDetails.ContentType')}
                            format="enum"
                            formatterProps={{ i18nKey: 'Content.Type.' }}
                            value={contentType}
                        />
                    </>
                )}
            </Brief>
        </MainContainer>
    );
});

const MainContainer = styled.div`
    margin: 60px 30px 0;
`;

const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    height: 120px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
`;

const TextBold = styled.span`
    font-weight: 600;
    font-size: 26px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) =>
        NotificationMapColors[
            props?.status as keyof typeof NotificationMapColors
        ]};
    border-radius: 5px;
    font-size: 15px;
    height: 35px;
    line-height: 22px;
    text-decoration: capitalize;
    color: #000000;
`;
