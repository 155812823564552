import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const ApplicationsInsertFinancialsHeader = ({
    isBottomBorder,
}: {
    // eslint-disable-next-line react/require-default-props
    isBottomBorder?: boolean;
}) => {
    return (
        <StyledFormHeader isBottomBorder={isBottomBorder}>
            <StyledTitleCol />
            <StyledHeaderGroup>
                <StyledHeaderItem>
                    <Text>Current quarter</Text>
                </StyledHeaderItem>
                <StyledHeaderItem>
                    <Text>Previous quarter</Text>
                </StyledHeaderItem>
                <StyledHeaderItem>
                    <Text>Two quarters before</Text>
                </StyledHeaderItem>
                <StyledHeaderItem>
                    <Text>Three quarters before</Text>
                </StyledHeaderItem>
            </StyledHeaderGroup>
        </StyledFormHeader>
    );
};

const StyledFormHeader = styled.div<{ isBottomBorder?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 15px;
    border-bottom: ${(props) =>
        props.isBottomBorder ? '2px solid #000000' : 'none'};
    font-size: ${(props) => (props.isBottomBorder ? '1.1rem' : '0.8rem')};
    .ant-typography {
        font-weight: 600;
    }
`;

const StyledTitleCol = styled.div`
    width: 32%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const StyledHeaderItem = styled.div`
    width: 30%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
`;

const StyledHeaderGroup = styled.div`
    width: 100%;
    gap: 2.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
