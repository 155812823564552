import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { useOpenFirstListItem } from 'src/hooks/useOpenFirstListItem';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';

import { ApplicationsCCReviewPage } from './ApplicationsCCReviewPage';
import { ApplicationsEntityPresentation } from './ApplicationsEntityPresentation';
import { ApplicationsHeaderPanel } from './ApplicationsHeaderPanel';
import { ApplicationsInsertFinancialsPage } from './ApplicationsInsertFinancialsPage';
import { ApplicationsListItem } from './ApplicationsListItem';

const store = ApplicationsStore;

export const ApplicationsPage = observer(() => {
    useOpenFirstListItem(store);

    useEffect(() => {
        return () => {
            store.listLoader.reset();
            store.filterCriteria?.resetFilter();
            store.pager?.reset();
        };
    }, []);

    return (
        <MasterDetailsPage
            store={store}
            asidePanel={<ApplicationsAsidePanel />}
        >
            <Switch>
                <Route
                    path={RoutePaths.applicationsCCReviewPage}
                    exact
                    render={() => <ApplicationsCCReviewPage />}
                />
                <Route
                    path={RoutePaths.applicationsInsertFinancials}
                    exact
                    render={() => <ApplicationsInsertFinancialsPage />}
                />
                <Route
                    path={RoutePaths.applicationsDetails}
                    exact
                    render={() => <ApplicationsEntityPresentation />}
                />
            </Switch>
        </MasterDetailsPage>
    );
});

const ApplicationsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: any) => {
                return (
                    <List.Item key={item.id}>
                        <ApplicationsListItem item={item} />
                    </List.Item>
                );
            }}
            header={<ApplicationsHeaderPanel />}
        />
    );
});
