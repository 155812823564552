import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';

const store = LoansStore;

export const Counterparty = observer(() => {
    const { t } = useTranslation();

    const currentLoan = store.currentItem?.data;

    return (
        <>
            <Row>
                <BlockTitle>
                    {t('Page.Loans.Counterparty.Title', 'Counterparty')}
                </BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentLoan?.thirdPartyCipc}
                        label={t('Page.Loans.Counterparty.CIPC', 'CIPC')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentLoan?.thirdPartyLegalName}
                        label={t(
                            'Page.Loans.Counterparty.LegalName',
                            'Legal name',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.thirdPartyEmail}
                        label={t('Page.Loans.Counterparty.Email', 'Email')}
                    />
                </Col>
            </Row>
        </>
    );
});
