/* eslint-disable max-lines */
import { Col, Form as AntForm, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { Form } from 'src/components/Form/Form';
import { TextArea } from 'src/components/Form/TextArea';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    LoanApplicationDisclosure,
    LoanApplicationFinancedItemDTO,
} from 'src/generated-api-client';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { feeRepaymentMethodMapping } from 'src/utils/common-constants';
import { DataFormatterFormat } from 'src/utils/DataFormatterProvider';

import { FinancedDocumentsDetails } from './FinancedDocumentsDetails';

const store = ApplicationsStore;
const prefix = 'R';

export const ApplicationConditions = observer(() => {
    const { t } = useTranslation();
    const [form] = AntForm.useForm();

    const currentApplication = store.currentItem?.data;

    form.setFieldValue('comment', currentApplication?.comment);

    return (
        <>
            <Row>
                <BlockTitle>
                    {t('Page.Applications.ApplicationConditions')}
                </BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={
                            feeRepaymentMethodMapping[
                                currentApplication?.feeRepaymentMethod as keyof typeof feeRepaymentMethodMapping
                            ]
                        }
                        label={t(
                            'Page.UserDetails.Role2',
                            'INITIATION FEE REPAYMENT',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentApplication?.loanProductName}
                        label={t('Page.UserDetails.Email2', 'LOAN PRODUCT')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={
                            currentApplication?.calculationData
                                ?.interestRatePercentage
                        }
                        label={t(
                            'Page.Applications.ApplicationConditions.30daysRate',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        prefix={
                            currentApplication?.calculationData?.initiationFee
                                ? prefix
                                : undefined
                        }
                        value={
                            currentApplication?.calculationData?.initiationFee
                        }
                        label={t(
                            'Page.Applications.ApplicationConditions.InitiationFee',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={
                            currentApplication?.calculationData
                                ?.insuranceRateMultiplierPercentage
                        }
                        label={t(
                            'Page.Applications.ApplicationConditions.InsuranceRateMultiplierPercentage',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        prefix={
                            currentApplication?.calculationData?.monthlyFee
                                ? prefix
                                : undefined
                        }
                        value={currentApplication?.calculationData?.monthlyFee}
                        label={t(
                            'Page.Applications.ApplicationConditions.MonthlyFee',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={
                            currentApplication?.calculationData
                                ?.vatRatePercentage
                        }
                        label={t(
                            'Page.Applications.ApplicationConditions.VatRatePercentage',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={
                            currentApplication?.calculationData
                                ?.penaltyInterestMultiplier
                        }
                        label={t(
                            'Page.Applications.ApplicationConditions.PenaltyInterestMultiplier',
                        )}
                        format={DataFormatterFormat.numberInfo}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={
                            currentApplication?.calculationData
                                ?.interestRateRestrictionPercentage
                        }
                        label={t(
                            'Page.Applications.ApplicationConditions.InterestRateRestrictionPercentage',
                        )}
                    />
                </Col>
                <Col span={16}>
                    <DataView
                        format="string"
                        value={
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form
                                        form={form}
                                        onFinish={(value) => {
                                            store.updateApplicationComment(
                                                currentApplication?.id?.toString() as string,
                                                value.comment,
                                            );
                                        }}
                                    >
                                        <TextArea
                                            handleBlur={form.submit}
                                            rows={6}
                                            label=""
                                            name="comment"
                                            rules={[
                                                ValidationRules.maxCommentLength,
                                            ]}
                                        />
                                    </Form>
                                </Col>
                            </Row>
                        }
                        label={t('Page.UserDetails.Phone2', 'COMMENT')}
                    />
                </Col>
                <Col span={16}>
                    <DataView
                        format="string"
                        value={currentApplication?.contrOffer}
                        label={t(
                            'Page.sss.Login2',
                            'CUSTOMER CONTROFFER (IF ANY)',
                        )}
                    />
                </Col>
            </Row>
            <Row>
                <BlockTitle>
                    {t(
                        'Page.LoanProductDetails.InsurasdnceRateMultiplierqw2',
                        'Financed documents details',
                    )}
                </BlockTitle>
            </Row>
            <Row>
                <Col>
                    <FinancedDocumentsDetails
                        dataSource={
                            currentApplication?.financedItems as LoanApplicationFinancedItemDTO[]
                        }
                    />
                </Col>
            </Row>
            {currentApplication?.disclosure ===
                LoanApplicationDisclosure.DISCLOSED && (
                <>
                    <Row>
                        <BlockTitle>
                            {t(
                                'Page.LoanProductDetails.InsuranceRateMultiplierqw2',
                                'Counterparty',
                            )}
                        </BlockTitle>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <DataView
                                format="string"
                                value={currentApplication?.thirdParty?.cipc}
                                label={t('Page.UserDetails.CIPC', 'CIPC')}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                format="string"
                                value={
                                    currentApplication?.thirdParty?.legalName
                                }
                                label={t(
                                    'Page.UserDetails.legalName',
                                    'LEGAL NAME',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                value={currentApplication?.thirdParty?.email}
                                label={t(
                                    'Page.UseccdrDetails.Phonewww2',
                                    'EMAIL',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                format="string"
                                value={currentApplication?.thirdParty?.address}
                                label={t(
                                    'Page.UserDetails.Lssssssogin2',
                                    'FULL ADDRESS',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                format="string"
                                value={
                                    currentApplication?.thirdParty?.bankAccount
                                        ?.bankName
                                }
                                label={t(
                                    'Page.UserDetails.Phonewww2',
                                    'BANK NAME',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                format="string"
                                value={
                                    currentApplication?.thirdParty?.bankAccount
                                        ?.accountName
                                }
                                label={t(
                                    'Page.UserDethjkl;ails.Login2',
                                    'ACCOUNT NAME',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                format="string"
                                value={
                                    currentApplication?.thirdParty?.bankAccount
                                        ?.accountType
                                }
                                label={t(
                                    'Page.UserDetails.Login2',
                                    'ACCOUNT TYPE',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                format="string"
                                value={
                                    currentApplication?.thirdParty?.bankAccount
                                        ?.branchCode
                                }
                                label={t(
                                    'Page.UserDetails.Phonewww2',
                                    'BRANCH CODE',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                format="string"
                                value={
                                    currentApplication?.thirdParty?.bankAccount
                                        ?.accountNumber
                                }
                                label={t(
                                    'Page.UserDetails.Login2',
                                    'ACCOUNT NUMBER',
                                )}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
});
