import { Form, Input, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { CustomerVerificationStatus } from 'src/generated-api-client';
import { CustomersStore } from 'src/stores/CustomersStore/CustomersStore';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

import { FilterBlock } from '../FilterPanel/FilterBlock';
import { SearchPanelFilterForm } from '../FilterPanel/SearchPanelFilterForm';

const store = CustomersStore;

export const CustomersHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <FilterBlock
                store={store}
                placeholder={t('AsideHeader.Search.Placeholder', {
                    defaultValue: 'Search',
                })}
                renderPanel={(props: any) => <FilterPanel {...props} />}
            />
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: flex;
`;

const FilterPanel = observer((props: any) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{}}
            onFinish={({ active, ...values }: any) => {
                store.filterCriteria?.applyFilter({
                    ...values,
                });
                store.pager?.resetPage();
                store.loadList();
            }}
        >
            <Form.Item
                label={t('Page.Customer.Filter.Id', {
                    defaultValue: 'ID',
                })}
                name="id"
            >
                <StyledInput type="string" />
            </Form.Item>
            <Form.Item
                label={t('Page.Customer.Filter.LegalName', {
                    defaultValue: 'Legal name',
                })}
                name="legalName"
            >
                <StyledInput type="string" />
            </Form.Item>
            <Form.Item
                label={t('Page.Customer.Filter.CIPC', {
                    defaultValue: 'CIPC',
                })}
                name="cipc"
            >
                <StyledInput type="string" />
            </Form.Item>
            <StyledSelect
                label={t('Page.Customer.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="CustomerUserStatus"
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    <Select.Option value={CustomerVerificationStatus.VERIFIED}>
                        {t(`data:status.users.ACTIVE`, 'Verified')}
                    </Select.Option>
                    <Select.Option value={CustomerVerificationStatus.DECLINED}>
                        {t(`data:status.users.INACTIVE`, 'Declined')}
                    </Select.Option>
                    <Select.Option value={CustomerVerificationStatus.INREVIEW}>
                        {t(
                            `data:status.users.INACTIVE`,
                            'Awaiting verification',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={CustomerVerificationStatus.WAITDOCUMENTS}
                    >
                        {t(
                            `data:status.users.WAITDOCUMENTS`,
                            'Awaiting documents',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={CustomerVerificationStatus.CREDITBUREAUCHECK}
                    >
                        {t(`data:status.users.INACTIVE`, 'CB check')}
                    </Select.Option>
                    <Select.Option
                        value={
                            CustomerVerificationStatus.REJECTEDBYCREDITBUREAU
                        }
                    >
                        {t(`data:status.users.INACTIVE`, 'Rejected by CB')}
                    </Select.Option>
                    <Select.Option
                        value={CustomerVerificationStatus.INFOREQUESTED}
                    >
                        {t(`data:status.users.INACTIVE`, 'Info requested')}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <Form.Item
                label={t('Page.Customer.Filter.Email', {
                    defaultValue: 'Email',
                })}
                name="email"
            >
                <StyledInput type="string" />
            </Form.Item>
            <Form.Item
                label={t('Page.Customer.Filter.RepresentativeEmail', {
                    defaultValue: 'Representative email',
                })}
                name="representativeEmail"
            >
                <StyledInput type="string" />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        min-height: 40px;
        border-radius: 6px;
        .ant-select-selection-search {
            display: flex;
            align-items: center;
        }
    }
`;

const StyledInput = styled(Input)`
    height: 40px;
    border-radius: 6px;
`;
