import { action, computed, observable } from 'mobx';
import moment from 'moment';
import {
    EntityModelLoanViewDTO,
    LoanApplicationDisclosure,
    LoanApplicationType,
    LoanPaymentParams,
    LoanSnapshotDTO,
    LoanWriteOffParams,
} from 'src/generated-api-client';
import { loanOperationsApi, loansApi } from 'src/services/apiServices';
import { DateHelper } from 'src/utils/DateHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LoansFilters = {
    id?: string;
    legalName?: string;
    cipc?: string;
    statuses?: Array<
        'ACTIVE' | 'OVERDUE' | 'DEFAULT' | 'PAID_UP' | 'WRITTEN_OFF'
    >;
    types?: Array<LoanApplicationType>;
    disclosures?: Array<LoanApplicationDisclosure>;
    startDateFrom?: string;
    startDateTo?: string;
    maturityDateFrom?: string;
    maturityDateTo?: string;
    totalExposureFrom?: number;
    totalExposureTo?: number;
    productName?: string;
};

export class LoansStoreClass extends BasicStore<
    EntityModelLoanViewDTO,
    LoansFilters
> {
    @observable private operations?: LoanSnapshotDTO[];
    api: BasicStoreApi<EntityModelLoanViewDTO, any, any, any> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loansApi.entityList6(
                    this.filterCriteria.filter.id,
                    this.filterCriteria.filter.legalName,
                    this.filterCriteria.filter.cipc,
                    this.filterCriteria.filter.statuses,
                    this.filterCriteria.filter.types,
                    this.filterCriteria.filter.disclosures,
                    this.filterCriteria.filter.startDateFrom &&
                        DateHelper.format(
                            this.filterCriteria.filter.startDateFrom,
                        ),
                    this.filterCriteria.filter.startDateTo &&
                        DateHelper.format(
                            this.filterCriteria.filter.startDateTo,
                        ),
                    this.filterCriteria.filter.maturityDateFrom &&
                        DateHelper.format(
                            this.filterCriteria.filter.maturityDateFrom,
                        ),
                    this.filterCriteria.filter.maturityDateTo &&
                        DateHelper.format(
                            this.filterCriteria.filter.maturityDateTo,
                        ),
                    this.filterCriteria.filter.totalExposureFrom,
                    this.filterCriteria.filter.totalExposureTo,
                    this.filterCriteria.filter.productName,
                    this.pager?.page,
                    this.pager?.size,
                ),
            );

            this.pager?.setTotal(result.page?.totalElements as number);

            return result.content || [];
        },

        loadItem: (id: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.entityView6(Number(id)),
            );
        },
    };

    recalculateLoanAction = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            loansApi.fullLoanRecalculate(id),
        );
    });

    getOperationsAction = new AsyncOperationWithStatus((id: string) =>
        RequestHelper.unwrapFromAxiosPromise(
            loansApi.getAllSnapshots(Number(id)),
        ),
    );

    registerRepaymentAction = new AsyncOperationWithStatus(
        (loanPaymentParams: LoanPaymentParams) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanOperationsApi.createPayment(loanPaymentParams),
            ),
    );

    initLoanOperationAction = new AsyncOperationWithStatus(
        (
            loanId: number,
            loanOperationType: 'DISBURSEMENT' | 'PAYMENT' | 'WRITE_OFF',
        ) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanOperationsApi.initLoanOperationCreation(
                    loanId,
                    loanOperationType,
                ),
            ),
    );

    createWriteOffOperationAction = new AsyncOperationWithStatus(
        (loanWriteOffParams: LoanWriteOffParams) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanOperationsApi.createWriteOffOperation(loanWriteOffParams),
            ),
    );

    @action async recalculateLoan(id: string) {
        await this.recalculateLoanAction.call(Number(id));
        if (!this.recalculateLoanAction.hasError) {
            await this.loadItem(id);
        }
    }

    @action async getOperations(id: string) {
        const res = await this.getOperationsAction.call(id);
        this.operations = res?.data;
    }

    @computed get getAllSnapshots() {
        return this.operations?.sort(
            (a, b) =>
                moment(b.dateTime).valueOf() - moment(a.dateTime).valueOf(),
        );
    }

    @action async registerRepayment(loanPaymentParams: LoanPaymentParams) {
        await this.registerRepaymentAction.call(loanPaymentParams);
    }

    @action async initLoanOperation(
        loanId: number,
        loanOperationType: 'DISBURSEMENT' | 'PAYMENT' | 'WRITE_OFF',
    ) {
        await this.initLoanOperationAction.call(loanId, loanOperationType);
    }

    @action async createWriteOffOperation(
        loanWriteOffParams: LoanWriteOffParams,
    ) {
        await this.createWriteOffOperationAction.call(loanWriteOffParams);
    }

    filterCriteria = new FilterCriteria<LoansFilters>();

    pager?: Pager | undefined = new Pager();
}

export const LoansStore = new LoansStoreClass();
