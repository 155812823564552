import { Col, Row, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { MasterDetailsAsideItem } from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { EntityModelBackofficeRoleDTO } from 'src/generated-api-client';
import styled from 'styled-components';

type RoleListItemProps = { item: EntityModelBackofficeRoleDTO };

export const RolesListItem = observer(({ item }: RoleListItemProps) => {
    return (
        <MasterDetailsAsideItem to={EntityLinks.roles.itemDetails(item.id)}>
            <StyledHeaderRow gutter={5} wrap={false}>
                <Col flex="auto">
                    <BoldText>
                        <NameContainer>#{item.id}</NameContainer>
                    </BoldText>
                </Col>
                <Col>
                    <Tooltip title={item.name}>
                        <EllipsisContainer>
                            <NameContainer>{item.name}</NameContainer>
                        </EllipsisContainer>
                    </Tooltip>
                </Col>
            </StyledHeaderRow>
        </MasterDetailsAsideItem>
    );
});

const StyledHeaderRow = styled(Row)`
    margin-bottom: 29px;
`;

const BoldText = styled.span`
    font-weight: 600;
`;

const NameContainer = styled.span`
    font-size: 20px;
`;
