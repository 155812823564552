import { Col, Row, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { MasterDetailsAsideItem } from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { LoanProductDTO, LoanProductStatus } from 'src/generated-api-client';
import styled from 'styled-components';

type LoanProductsItemProps = { item: LoanProductDTO };

export const LoanProductsListItem = observer(
    ({ item }: LoanProductsItemProps) => {
        const { t } = useTranslation();

        return (
            <MasterDetailsAsideItem
                to={EntityLinks.loanProducts.itemDetails(item.id)}
            >
                <StyledHeaderRow gutter={5} wrap={false}>
                    <Col flex="auto">
                        <BoldText>
                            {' '}
                            <NameContainer>#{item.id}</NameContainer>
                        </BoldText>
                    </Col>
                    <Col>
                        <Tooltip title={item.productName}>
                            <EllipsisContainer>
                                <NameContainer>
                                    {item.productName}
                                </NameContainer>
                            </EllipsisContainer>
                        </Tooltip>
                    </Col>
                </StyledHeaderRow>
                <Row wrap={false}>
                    <Col flex="auto">
                        <StyledStatusWrapper status={item.status}>
                            {item.status?.toLocaleLowerCase()}
                        </StyledStatusWrapper>
                    </Col>
                    <Col>
                        <SecondaryText>
                            {t('Page.LoanProducts.ListItem.InterestRate', {
                                rate: item.interestRate,
                            })}
                        </SecondaryText>
                    </Col>
                </Row>
            </MasterDetailsAsideItem>
        );
    },
);

const StyledHeaderRow = styled(Row)`
    margin-bottom: 5px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    text-transform: capitalize;
    max-width: 120px;
    padding: 5px 19px;
    background: ${(props) =>
        props.status === LoanProductStatus.ACTIVE ? '#D6F7A0' : '#EB7382'};
    border-radius: 22px;
    font-size: 13px;
    height: 22px;
    line-height: 22px;
    align-items: center;
    justify-content: center;
`;

const SecondaryText = styled.span`
    font-size: 13px;
    line-height: 150%;
    color: #000000;
`;

const BoldText = styled.span`
    font-weight: 600;
`;

const NameContainer = styled.span`
    font-size: 20px;
`;
