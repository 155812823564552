import { AuthorityItem } from 'src/generated-api-client';
import { rolesApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class AuthoritiesStoreClass extends BasicStore<AuthorityItem> {
    api: BasicStoreApi<AuthorityItem> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                rolesApi.authorities(),
            );

            return result.data || [];
        },
    };
}

export const AuthoritiesStore = new AuthoritiesStoreClass();
