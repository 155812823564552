import { Button } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { NotificationStatus, NotificationType } from 'src/generated-api-client';
import { FilterBlock } from 'src/pages/FilterPanel/FilterBlock';
import { SearchPanelFilterForm } from 'src/pages/FilterPanel/SearchPanelFilterForm';
import { CreateNotificationDialogModal } from 'src/pages/NotificationsPage/Dialogs/CreateNotificationDialog';
import { NotificationsStore } from 'src/stores/NotificationsStore/NotificationsStore';
import styled from 'styled-components';

const store = NotificationsStore;

export const NotificationsHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <FilterBlock
                store={store}
                renderPanel={(props: any) => <FilterPanel {...props} />}
            />
            <StyledCreateButton
                type="ghost"
                onClick={() => {
                    CreateNotificationDialogModal.render();
                }}
            >
                {t('Button.CreateNew')}
            </StyledCreateButton>
        </StyledHeaderWrapper>
    );
});

const FilterPanel = observer((props: any) => {
    const { t } = useTranslation();

    const notificationType: OptionProps[] = Object.values(NotificationType).map(
        (value) => ({
            value,
            children: t(`Notification.Type.${value}`),
        }),
    );

    const status: OptionProps[] = Object.values(NotificationStatus).map(
        (value) => ({
            value,
            children: t(`Notification.Status.${value}`),
        }),
    );

    return (
        <SearchPanelFilterForm
            {...props}
            onFinish={async ({ active, ...values }: any) => {
                store.filterCriteria?.applyFilter({
                    ...values,
                });
                store.pager?.resetPage();
                await store.loadList();
            }}
        >
            <TextField name="id" label={t('Page.NotificationsS.Filter.ID')} />
            <TextField
                name="title"
                label={t('Page.NotificationsS.Filter.Name')}
            />
            <SelectField
                name="types"
                label={t('Page.NotificationsS.Filter.Channel')}
                options={notificationType}
            />
            <SelectField
                name="statuses"
                label={t('Page.NotificationsS.Filter.Status')}
                options={status}
            />
        </SearchPanelFilterForm>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: flex;
`;

const StyledCreateButton = styled(Button)`
    width: 100%;
    min-width: 50px;
    height: 32px;
    margin-left: 5px;
    border-radius: 10px;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;
