import { Env } from 'src/core/Environment';

export const RoutePaths = {
    index: `${Env.basePath}/`,
    pageNotFound: `${Env.basePath}/not-found`,

    users: `${Env.basePath}/users`,
    userCreate: `${Env.basePath}/users/create`,
    userDetails: `${Env.basePath}/users/:userId`,

    roles: `${Env.basePath}/roles`,
    roleCreate: `${Env.basePath}/roles/create`,
    roleDetails: `${Env.basePath}/roles/:roleId`,

    loanProducts: `${Env.basePath}/loan-products`,
    loanProductsCreate: `${Env.basePath}/loan-products/create`,
    loanProductsDetails: `${Env.basePath}/loan-products/:loanProductId`,

    customers: `${Env.basePath}/customers`,
    customersDetails: `${Env.basePath}/customers/:customerId`,

    applications: `${Env.basePath}/loan-applications`,
    applicationsDetails: `${Env.basePath}/loan-applications/:applicationId`,
    applicationsInsertFinancials: `${Env.basePath}/loan-applications/:applicationId/insert-financial`,
    applicationsCCReviewPage: `${Env.basePath}/loan-applications/:applicationId/cc-review`,

    loans: `${Env.basePath}/loans`,
    loansDetails: `${Env.basePath}/loans/:loanId`,

    notifications: `${Env.basePath}/notifications`,
    notificationsDetails: `${Env.basePath}/notifications/:id`,

    statistics: `${Env.basePath}/statistics`,
    statisticsDetails: `${Env.basePath}/statistics/:statisticId`,

    importData: `${Env.basePath}/import-data`,
    importDataDetails: `${Env.basePath}/import-data/:dataId`,

    systemOperations: `${Env.basePath}/system-operations`,
    userActions: `${Env.basePath}/action`,
};
