import {
    CreateLoanProductForm,
    EntityModelLoanProductDTO,
    LoanProductStatus,
    UpdateLoanProductForm,
} from 'src/generated-api-client';
import { loansProductsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LoanProductsFilter = {
    name?: string[];
    id?: string;
    interestRateFrom?: number;
    interestRateTo?: number;
    statuses?: LoanProductStatus[];
};

export class LoanProductsStoreClass extends BasicStore<
    EntityModelLoanProductDTO,
    LoanProductsFilter,
    any,
    CreateLoanProductForm
> {
    api: BasicStoreApi<EntityModelLoanProductDTO, any, CreateLoanProductForm> =
        {
            loadList: async () => {
                const result = await RequestHelper.unwrapFromAxiosPromise(
                    loansProductsApi.entityList7(
                        this.filterCriteria.filter.id,
                        this.filterCriteria.filter.name,
                        this.filterCriteria.filter.statuses,
                        this.filterCriteria.filter.interestRateFrom,
                        this.filterCriteria.filter.interestRateTo,
                        this.pager?.page,
                        this.pager?.size,
                    ),
                );

                this.pager?.setTotal(result.page?.totalElements as number);

                return result.content || [];
            },

            loadItem: (loanProductId: string) => {
                return RequestHelper.unwrapFromAxiosPromise(
                    loansProductsApi.entityView7(Number(loanProductId)),
                );
            },

            createItem: async (data) => {
                await RequestHelper.unwrapFromAxiosPromise(
                    loansProductsApi.createLoanProduct(data),
                );
            },
            updateItem: async (id, data: UpdateLoanProductForm) => {
                await RequestHelper.unwrapFromAxiosPromise(
                    loansProductsApi.updateLoanProduct({
                        ...data,
                        id: Number(id),
                    }),
                );
            },

            activate: async (id) => {
                await RequestHelper.unwrapFromAxiosPromise(
                    loansProductsApi.activate2(id),
                );
            },

            deactivate: async (id) => {
                await RequestHelper.unwrapFromAxiosPromise(
                    loansProductsApi.deactivate2(id),
                );
            },
        };

    filterCriteria = new FilterCriteria<LoanProductsFilter>();

    pager?: Pager | undefined = new Pager();
}

export const LoanProductsStore = new LoanProductsStoreClass();
