// @ts-nocheck
import { Layout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { NavLink, useLocation } from 'react-router-dom';
import { LanguageSwitcher } from 'src/components/LanguageSwitcher/LanguageSwitcher';
import { Logo } from 'src/components/Logo';
import { UserMenu } from 'src/components/UserMenu';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { RouterStore } from 'src/stores/RouterStore/RouterStore';
import { isRouteGroup } from 'src/stores/RouterStore/RouterStore.types';
import { CssVariables } from 'src/styles/CssVariables';
import { AuthoritiesHelper } from 'src/utils/AuthoritiesHelper';
import styled from 'styled-components';

export const Header = observer(() => {
    const { pathname } = useLocation();

    return (
        <StyledHeader>
            <StyledAppHeader>
                <Logo />
                <StyledMenu
                    mode="horizontal"
                    defaultSelectedKeys={['/']}
                    selectedKeys={[`/${pathname.split('/')[1]}`]}
                >
                    {RouterStore.menuItems
                        .filter((router) =>
                            ProfileStore.hasPermissions(...router.permissions),
                        )
                        .map((router) => {
                            return isRouteGroup(router) ? (
                                <Menu.SubMenu title={router?.menuTitle?.()}>
                                    {router?.children.map(
                                        (item) =>
                                            AuthoritiesHelper.roleHasAuthorities(
                                                ProfileStore.currentAuthorities,
                                                item.permissions,
                                            ) && (
                                                <Menu.Item key={item.key}>
                                                    <NavLink
                                                        to={item.path}
                                                        activeClassName="active"
                                                    >
                                                        {item?.menuTitle?.()}
                                                    </NavLink>
                                                </Menu.Item>
                                            ),
                                    )}
                                </Menu.SubMenu>
                            ) : (
                                <Menu.Item
                                    key={`${router.key}-${Math.random()}`}
                                >
                                    <NavLink
                                        to={router.path}
                                        activeClassName="active"
                                    >
                                        {router?.menuTitle?.()}
                                    </NavLink>
                                </Menu.Item>
                            );
                        })}
                </StyledMenu>
                <LanguageSwitcher />
                <UserMenu />
            </StyledAppHeader>
        </StyledHeader>
    );
});

const StyledAppHeader = styled.div`
    display: flex;
    align-items: center;
`;

const StyledHeader = styled(Layout.Header)`
    box-shadow: 0 2px 8px #d9d9d9;
    padding: 0 29px;
    height: ${CssVariables.headerHeight};
    background-color: #ffffff;
    line-height: ${CssVariables.headerLineHeight};
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
`;

const StyledMenu = styled(Menu)`
    flex: 1;
    color: #000000;
    margin: 15px 20px;

    .ant-menu-sub {
        a.active {
            color: #000000 !importatnt;
        }
    }

    .ant-menu-submenu-title,
    .ant-menu-title-content {
        span,
        a {
            color: #000000 !important;
            font-weight: 600;
            font-size: 1rem !important;

            &:hover {
                color: #a4a6a8;
                text-decoration: none;
            }
        }
    }

    &.ant-menu-horizontal {
        border: none;
    }

    &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
        padding: 0;
    }

    .ant-menu-sub,
    .ant-menu-submenu,
    .ant-menu-item {
        &:after {
            display: none;
        }

        + .ant-menu-item {
            margin-left: 1px;
        }

        span,
        a {
            font-size: 1.2rem;
            line-height: 165%;
            display: inline-block;
            padding: 3px 14px;
            border-radius: 6px;

            letter-spacing: -0.02em;

            color: #a4a6a8;
            background-color: transparent;

            transition: background-color 0.2s, easy-in-out;

            &:hover {
                opacity: 0.7;
            }
        }

        a.active {
            border: 1px solid #000000;
            border-radius: 10px;
            color: #000000;
            background: transparent;
        }
    }
`;
