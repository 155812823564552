/* eslint-disable max-lines */
import { Col, notification, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export const CreateLoanProductDialog = observer(({ ...props }) => {
    const { t } = useTranslation();

    return (
        <Modal
            {...props}
            closable={false}
            width={670}
            title={t(
                'Page.LoanProducts.Modal.Create.Title',
                'CREATE NEW LOAN PRODUCT',
            )}
            storeLoader={LoanProductsStore.createItemLoader}
            subtitle={t(
                'Page.LoanProducts.Modal.Create.SubTitle',
                'Please, specify new product conditions:',
            )}
            isLoading={LoanProductsStore.createItemLoader.isLoading}
            onFinish={async (values) => {
                await LoanProductsStore.createItem(values);
                if (!LoanProductsStore.createItemLoader.hasError) {
                    notification.success({
                        message: t('Message.Success'),
                    });
                    await LoanProductsStore.loadList();
                    Router.navigate(RoutePaths.loanProducts);
                }
            }}
        >
            <Row gutter={16}>
                <Col span={20}>
                    <TextField
                        name="productName"
                        label={t(
                            'Page.LoanProducts.Modal.Create.ProductName',
                            'LOAN PRODUCT NAME:',
                        )}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.maxLengthProductTitle,
                        ]}
                    />
                </Col>
            </Row>
            <StyledDivider />
            <Row justify="space-between">
                <Col span={10}>
                    <NumberField
                        prefix="R"
                        name="initiationFee"
                        label={t(
                            'Page.LoanProducts.Modal.Create.InitiationFee',
                            'Initiation fee:',
                        )}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.notMorethenMillion,
                            ValidationRules.decimal,
                        ]}
                    />
                </Col>
                <Col span={10}>
                    <NumberField
                        prefix="R"
                        name="monthlyFee"
                        label={t(
                            'Page.LoanProducts.Modal.Create.MonthlyFee',
                            'Monthly service fee:',
                        )}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.notMorethenMillion,
                            ValidationRules.decimal,
                        ]}
                    />
                </Col>
            </Row>
            <StyledDivider />
            <Row justify="space-between">
                <Col span={10}>
                    <NumberField
                        prefix="%"
                        name="interestRate"
                        label={t(
                            'Page.LoanProducts.Modal.Create.InterestRate',
                            '30 days rate:',
                        )}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.notMoreThanHundered,
                            ValidationRules.decimal,
                        ]}
                    />
                </Col>
                <Col span={10}>
                    <NumberField
                        prefix="%"
                        name="vatRate"
                        label={t(
                            'Page.LoanProducts.Modal.Create.VatRate',
                            'VAT rate:',
                        )}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.notMoreThanHundered,
                            ValidationRules.decimal,
                        ]}
                    />
                </Col>
            </Row>
            <StyledTransparentDivider />
            <Row justify="space-between" align="stretch">
                <Col span={10}>
                    <StyledLabel>
                        <span>*</span>
                        {t(
                            'Page.LoanProducts.Modal.Create.PenaltyInterestMultiplier',
                            'Penalty interest multiplier:',
                        )}
                    </StyledLabel>
                </Col>
                <Col span={10}>
                    <NumberField
                        name="penaltyInterestMultiplier"
                        label=""
                        rules={[
                            ValidationRules.required,
                            ValidationRules.notMoreThanTen,
                            ValidationRules.penaltyInterestMultiplierDecimal,
                        ]}
                    />
                </Col>
            </Row>
            <Row justify="space-between" align="stretch">
                <Col span={10}>
                    <StyledLabel>
                        <span>*</span>
                        {t(
                            'Page.LoanProducts.Modal.Create.InsuranceRateMultiplier',
                            'Insurance rate multiplier:',
                        )}
                    </StyledLabel>
                </Col>
                <Col span={10}>
                    <NumberField
                        prefix="%"
                        name="insuranceRateMultiplier"
                        label=""
                        rules={[
                            ValidationRules.required,
                            ValidationRules.notMoreThanHundered,
                            ValidationRules.decimal,
                        ]}
                    />
                </Col>
            </Row>
            <Row justify="space-between" align="stretch">
                <Col span={10}>
                    <StyledLabel>
                        <span>*</span>
                        {t(
                            'Page.LoanProducts.Modal.Create.InterestRateRestriction',
                            'Yearly interest rate restriction:',
                        )}
                    </StyledLabel>
                </Col>
                <Col span={10}>
                    <NumberField
                        prefix="%"
                        name="interestRateRestriction"
                        label=""
                        rules={[
                            ValidationRules.required,
                            ValidationRules.notMoreThanHundered,
                            ValidationRules.decimal,
                        ]}
                    />
                </Col>
            </Row>
        </Modal>
    );
});

export const AddLoanProductModal = addModalRenderer(CreateLoanProductDialog);

const StyledDivider = styled.div`
    margin-bottom: 30px;
    border-bottom: 1px solid #00000029;
`;
const StyledTransparentDivider = styled.div`
    margin-bottom: 30px;
`;
const StyledLabel = styled.label`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px !important;
    span {
        color: red;
        margin-right: 4px;
    }
`;
