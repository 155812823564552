import { useParams } from 'react-router-dom';

export function useLoanProductId() {
    const params = useParams<{ loanProductId: string }>();

    return {
        loanProductId: params?.loanProductId,
        hasLoanId: Boolean(params?.loanProductId),
    };
}
