import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Router } from 'src/core/router/Router';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { getStartPage } from 'src/utils/StartPageHelper';

export const IndexPage = observer(() => {
    const profile = ProfileStore?.profile;

    useEffect(() => {
        if (!profile?.role?.startPage) {
            return;
        }

        Router.navigate(getStartPage(profile?.role?.startPage).route);
    }, [profile]);

    return <BasePage isLoading />;
});
