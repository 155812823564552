import { Col, notification, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import {
    LoanViewDTO,
    LoanWriteOffParamsOperationTypeEnum,
} from 'src/generated-api-client';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { DateHelper } from 'src/utils/DateHelper';
import { addModalRenderer } from 'src/utils/ModalRenderer';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

type RepaymentRegistrationDialogProps = {
    currentLoan: LoanViewDTO;
};

const store = LoansStore;

const { Title } = Typography;

export const RepaymentRegistrationDialog = observer(
    ({ currentLoan, ...props }: RepaymentRegistrationDialogProps) => {
        const { t } = useTranslation();

        return (
            <Modal
                {...props}
                title={t(
                    'Page.Loans.Modal.Repayment.Title',
                    'Manual repayment registration',
                )}
                isLoading={store.registerRepaymentAction.isLoading}
                onFinish={async (values) => {
                    await store.registerRepayment({
                        ...values,
                        activatedAt: DateHelper.format(values.activatedAt),
                        loanId: currentLoan.id,
                        operationType:
                            LoanWriteOffParamsOperationTypeEnum.PAYMENT,
                        message: 'Manually created operation',
                    });

                    if (!store.registerRepaymentAction.hasError) {
                        notification.success({
                            message: t('Message.Success'),
                        });
                        await store.loadList();
                        await store.loadItem(currentLoan?.id?.toString());
                    }
                }}
            >
                <Row gutter={16}>
                    <StyledTExtContainer span={24}>
                        <Row gutter={16} justify="start">
                            <Col span={8}>
                                <Title level={5}>
                                    {t(
                                        'Page.Loans.Modal.Repayment.CurrentExposure',
                                        'Current exposure:',
                                    )}
                                </Title>
                            </Col>

                            <Col span={8}>
                                <Title level={5}>
                                    R{' '}
                                    {NumberHelper.formatThousandSeparator(
                                        currentLoan?.totalExposure as number,
                                        2,
                                    )}
                                </Title>
                            </Col>
                        </Row>
                    </StyledTExtContainer>
                    <TextField
                        name="startDate"
                        hidden
                        initialValue={currentLoan.startDate}
                    />
                    <Col span={24}>
                        <TextField
                            name="amount"
                            label={t(
                                'Page.Loans.Modal.Repayment.PaymentAmount',
                                'Payment amount',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.notLessThanZero,
                            ]}
                        />
                    </Col>

                    <Col span={24}>
                        <DatePickerField
                            name="activatedAt"
                            label={t(
                                'Page.Loans.Modal.Repayment.PaymentDate',
                                'Payment date',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.paymentDate,
                            ]}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

const StyledTExtContainer = styled(Col)`
    margin-bottom: 20px;
`;

export const RepaymentRegistrationModal = addModalRenderer(
    RepaymentRegistrationDialog,
);
