import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { useOpenFirstListItem } from 'src/hooks/useOpenFirstListItem';
import { NotificationDetails } from 'src/pages/NotificationsPage/NotificationDetails';
import { NotificationsHeaderPanel } from 'src/pages/NotificationsPage/NotificationsHeaderPanel';
import { NotificationsListItem } from 'src/pages/NotificationsPage/NotificationsListItem';
import { NotificationsStore } from 'src/stores/NotificationsStore/NotificationsStore';

const store = NotificationsStore;

export const NotificationsPage = observer(() => {
    useOpenFirstListItem(store);

    useEffect(() => {
        return () => {
            store.listLoader.reset();
            store.filterCriteria?.resetFilter();
            store.pager?.reset();
        };
    }, []);

    return (
        <MasterDetailsPage store={store} asidePanel={<UsersAsidePanel />}>
            <Switch>
                <Route
                    path={RoutePaths.notificationsDetails}
                    exact
                    render={() => <NotificationDetails />}
                />
            </Switch>
        </MasterDetailsPage>
    );
});

const UsersAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: any) => {
                return (
                    <List.Item key={item.id}>
                        <NotificationsListItem item={item} />
                    </List.Item>
                );
            }}
            header={<NotificationsHeaderPanel />}
        />
    );
});
