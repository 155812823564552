import { Col, Form as AntForm, FormInstance, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';

const financingSourcesLabels = [
    'Page.Application.MoneyMarketTransactor',
    'Page.Application.PlatinumBusinessAccount',
];

const FIELD_PREFIX = '%';

export const CCReviewFinancingSource = observer(
    ({ form }: { form: FormInstance<any> }) => {
        const { t } = useTranslation();

        const handleFieldChange = (value: string, fieldName: number) => {
            if (!Number.isNaN(Number(value))) {
                form.setFieldValue(
                    [
                        'disbursementBankAccountInfos',
                        fieldName === 0 ? 1 : 0,
                        'accountAllocationPercentage',
                    ],
                    100 - Number(value),
                );
            }
        };

        return (
            <AntForm.List name="disbursementBankAccountInfos">
                {(fields) => (
                    <>
                        {fields.map((field, index) => (
                            <Row gutter={24}>
                                <Col span={12}>
                                    <TextField
                                        {...field}
                                        name={[
                                            field.name,
                                            'accountAllocationPercentage',
                                        ]}
                                        prefix={FIELD_PREFIX}
                                        label={t(financingSourcesLabels[index])}
                                        onChange={(value) => {
                                            handleFieldChange(
                                                value,
                                                field.name,
                                            );
                                        }}
                                        rules={[
                                            ValidationRules.checkSumOfSourceFields(
                                                [
                                                    'disbursementBankAccountInfos',
                                                    field.name === 0 ? 1 : 0,
                                                    'accountAllocationPercentage',
                                                ],
                                                form,
                                            ),
                                            ValidationRules.decimal,
                                        ]}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </>
                )}
            </AntForm.List>
        );
    },
);
