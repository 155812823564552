import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';

const store = ApplicationsStore;
const MONEY_MARKET_TRANSACTOR = 'MONEY MARKET TRANSACTOR';
const PLATINUM_BUSINESS_ACCOUNT = 'PLATINUM BUSINESS ACCOUNT';

export const BorrowersBankAccount = observer(() => {
    const { t } = useTranslation();

    const currentApplication = store.currentItem?.data;

    const moneyMarketTransactor =
        currentApplication?.disbursementBankAccountInfos?.find(
            (item) =>
                item?.bankAccount?.accountType === MONEY_MARKET_TRANSACTOR,
        )?.accountAllocationPercentage;

    const platinumBusinessAccount =
        currentApplication?.disbursementBankAccountInfos?.find(
            (item) =>
                item?.bankAccount?.accountType === PLATINUM_BUSINESS_ACCOUNT,
        )?.accountAllocationPercentage;

    return (
        <>
            <Row>
                <BlockTitle>
                    {t(
                        'Page.Application.BankAccount.Title',
                        "Borrower's bank account",
                    )}
                </BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentApplication?.bankAccount?.bankName}
                        label={t(
                            'Page.Application.BankAccount.BankName',
                            'Bank name',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentApplication?.bankAccount?.accountName}
                        label={t(
                            'Page.Application.BankAccount.AccountName',
                            'Account holder name',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentApplication?.bankAccount?.accountType}
                        label={t(
                            'Page.Application.BankAccount.AccountType',
                            'Account type',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentApplication?.bankAccount?.branchCode}
                        label={t(
                            'Page.Application.BankAccount.BranchCode',
                            'Branch code',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentApplication?.bankAccount?.accountNumber}
                        label={t(
                            'Page.Application.BankAccount.AccountNumber',
                            'Account number',
                        )}
                    />
                </Col>
            </Row>
            <Row>
                <BlockTitle>
                    {t(
                        'Page.Application.BankAccount.FinancingSources',
                        'Financing sources',
                    )}
                </BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={moneyMarketTransactor}
                        label={t('Page.Application.MoneyMarketTransactor')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={platinumBusinessAccount}
                        label={t('Page.Application.PlatinumBusinessAccount')}
                    />
                </Col>
            </Row>
        </>
    );
});
