/* eslint-disable max-lines */
import { Rule, RuleObject } from 'antd/lib/form';
import { t } from 'i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';
import moment, { Moment } from 'moment';
import { Trans } from 'react-i18next';

export enum AttachmentsFormat {
    jpeg = 'image/jpeg',
    jpg = 'image/jpg',
    gif = 'image/gif',
    png = 'image/png',
    svg = 'image/svg+xml',
    bmp = 'image/bmp',
    tiff = 'image/tiff',
    pdf = 'application/pdf',
    doc = 'application/msword',
    docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    csv = 'text/csv',
    txt = 'text/plain',
    ppt = 'application/vnd.ms-powerpoint',
    pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rtf = 'application/rtf',
    xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls = 'application/vnd.ms-excel',
}

export enum FileSizeUnit {
    bytes = 1,
    Kb = 2 ** 10,
    Mb = 2 ** 20,
    Gb = 2 ** 30,
    Tb = 2 ** 40,
}

export const FileMeasures = {
    mb: 'Mb',
};

export const MAX_FILE_SIZE = 5;

export enum FieldMaxLength {
    Name = 100,
    Address = 100,
    Email = 100,
    Comment = 255,
    PeriodDays = 30,
    PeriodHours = 23,
    PeriodMinutes = 59,
    AmlCheckDurationHours = 1000,
    RepeatedRequestsAmount = 1000,
}

const NumberPattern = new RegExp(/^\+?\d*$/);
const RegExpName = new RegExp(/^[\p{L}, \s-]+$/u);

const getIdNumberPattern = (lastTwoYearDigits: string, count = '6') => {
    return new RegExp(`^\\d{4}${lastTwoYearDigits}\\d{${count}}$`);
};
const decimalPattern = new RegExp(/^\d{1,100}\.?\d{0,2}$/);

/* eslint-disable no-useless-escape */
// eslint-disable-next-line unicorn/no-unsafe-regex
const emailPattern = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
);

const financedPercentage: Rule = {
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.FinancedPercentage" />
    ),
    pattern: new RegExp(/^([1-9]|[1-9]\d|100)$/),
};

const penaltyInterestMultiplierDecimal: Rule = {
    pattern: new RegExp(/^\d{1,100}\.?\d{0,5}$/),
    message: 'Invalid value, five decimal places allowed',
};

const requiredFinancedPercentage: Rule = {
    required: true,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.FinancedPercentage.Required" />
    ),
};

const name: Rule = {
    message: <Trans i18nKey="Form.Rules.Validator.Messages.NameError" />,
    pattern: RegExpName,
};

const minLength: Rule = {
    min: 3,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.MinLength" />,
};

const minAccountBankLength: Rule = {
    min: 12,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.AccountBankMinLength" />
    ),
};

const maxLength: Rule = {
    max: 100,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.MaxLength" />,
};

const maxLengthProductTitle: Rule = {
    max: 25,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.MaxLength" />,
};

const maxAccountBankLength: Rule = {
    max: 18,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.AccountBankMaxLength" />
    ),
};

const minPhoneLength: Rule = {
    min: 11,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.MinPhoneLengthError" />
    ),
};

const maxPhoneLength: Rule = {
    max: 12,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.MaxPhoneLengthError" />
    ),
};

const maxNotificationSubjectLength: Rule = {
    max: 60,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.MaxNotificationSubjectLength" />
    ),
};
const maxSmsNotificationLength: Rule = {
    max: 1600,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.MaxSmsNotificationLength" />
    ),
};
const maxEmailNotificationLength: Rule = {
    max: 10000,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.MaxEmailNotificationLength" />
    ),
};

const maxCommentLength: Rule = {
    max: FieldMaxLength.Comment,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.MaxCommentLengthError" />
    ),
};

const email: Rule = {
    pattern: emailPattern,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.EmailError" />,
};

const required: Rule = {
    required: true,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.Required" />,
};

const systemOperations: Rule = {
    required: true,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.SystemOperations" />,
};

const phone: Rule = {
    validator: (_, value) => {
        if (
            (isValidPhoneNumber(value) && NumberPattern.test(value)) ||
            !value.length
        ) {
            return Promise.resolve(true);
        }

        return Promise.reject(new Error(t('Form.Rules.WrongFormat')));
    },
};

const number: Rule = {
    pattern: new RegExp(/^\d{1,}$/),
    message: <Trans i18nKey="Form.Rules.WrongFormat" />,
};

const idNumber: Rule = (form): RuleObject => {
    return {
        validator(_, value: string) {
            return new Promise((resolve, reject) => {
                const year = (form.getFieldValue('birthDate') as Moment).get(
                    'year',
                );
                const lastTwoYearDigits = String(year).slice(2);

                const validators =
                    !value.length ||
                    (year >= 2020
                        ? isNewIdNumber(value, lastTwoYearDigits)
                        : isOldIdNumber(value, lastTwoYearDigits));

                if (validators) {
                    resolve(true);
                } else {
                    reject(t('Form.Rules.Validator.Messages.NationalId'));
                }
            });
        },
    };
};

const onlyNumber: Rule = {
    pattern: new RegExp(/^\d+$/),
    message: <Trans i18nKey="Form.Rules.Validator.Messages.OnlyNumberError" />,
};

const nationalId: Rule = (form): RuleObject => {
    return {
        validator(_, value: string) {
            return new Promise((resolve, reject) => {
                const year = (form.getFieldValue('birthDate') as Moment).get(
                    'year',
                );
                const lastTwoYearDigits = String(year).slice(2);

                const validators =
                    !value.length ||
                    (year >= 2020
                        ? isNewIdNumber(value, lastTwoYearDigits)
                        : isOldIdNumber(value, lastTwoYearDigits));

                if (validators) {
                    resolve(true);
                } else {
                    reject(t('Form.Rules.WrongFormat'));
                }
            });
        },
    };
};

const getFileSizeInBytes = (size: number, unit: FileSizeUnit) => {
    return size * unit;
};

const maxFileSize = (size: number, unit: FileSizeUnit = FileSizeUnit.Mb) => {
    return {
        validator: (_: any, value: any) => {
            if (value.length !== 0) {
                if (
                    value[0].originFileObj.size < getFileSizeInBytes(size, unit)
                ) {
                    return Promise.resolve(true);
                }

                return Promise.reject(
                    new Error(
                        t('Form.Rules.Validator.Messages.MaxFileSize', {
                            context: FileSizeUnit[unit],
                            size,
                        }),
                    ),
                );
            }

            return Promise.resolve(true);
        },
    };
};

const isOldIdNumber = (value: string, lastTwoYearDigits: string) => {
    if (value.length === 9 && NumberPattern.test(value)) {
        return true;
    }

    return getIdNumberPattern(lastTwoYearDigits).test(value);
};

const isNewIdNumber = (value: string, lastTwoYearDigits: string) => {
    return getIdNumberPattern(lastTwoYearDigits).test(value);
};

const password: Rule = {
    pattern: new RegExp(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    ),
    message: <Trans i18nKey="Form.Rules.Password" default="Invalid password" />,
};

const confirmPassword: Rule = (form): RuleObject => {
    return {
        validator(_, value) {
            if (!value || form.getFieldValue('password') === value) {
                return Promise.resolve();
            }

            return Promise.reject(
                new Error(
                    `${t('Components.Form.NotMatch', "Passwords don't match")}`,
                ),
            );
        },
    };
};

const amountValidationMessage = 'Form.Rules.Validator.Messages.ValidAmount';

const notMorethenMillion: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            if (value <= 1000000) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(t(amountValidationMessage)));
        },
    };
};

const notMoreThanHundered: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            if (value <= 100) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(t(amountValidationMessage)));
        },
    };
};

const notMoreThanTen: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            if (value <= 10) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(t(amountValidationMessage)));
        },
    };
};

const notLessThanZero: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            if (value > 0) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(t(amountValidationMessage)));
        },
    };
};

const maxTwoDigits: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            const clearValue = value.toString().replace(/\./g, '');
            if (clearValue.split('').length <= 2) {
                return Promise.resolve();
            }

            return Promise.reject(new Error('Max 2 digits'));
        },
    };
};

const decimal: Rule = {
    pattern: new RegExp(/^\d{1,100}\.?\d{0,2}$/),
    message: 'Invalid value, two decimal places allowed',
};

const decimalNumber: Rule = (): RuleObject => {
    return {
        validator(_, value: string) {
            return new Promise((resolve, reject) => {
                const absValue = Math.abs(Number(value));

                if (
                    value &&
                    (absValue > 1000000 ||
                        !decimalPattern.test(absValue.toString()))
                ) {
                    return reject(
                        t('Form.Rules.Validator.Messages.DecimalNumber'),
                    );
                }

                return resolve(true);
            });
        },
    };
};
// eslint-disable-next-line max-params
const checkSumOfSourceFields = (fieldPath: any[], form: any) => {
    return {
        validator: (_: any, value: any) => {
            const requiredSum = 100;
            const sum =
                Math.abs(value) + Math.abs(form.getFieldValue(fieldPath));
            if (sum !== requiredSum) {
                return Promise.reject(
                    new Error(
                        `${t(
                            'Validation.CCReview.SourceFields',
                            '"Financing source" field must be 100%',
                        )}`,
                    ),
                );
            }

            return Promise.resolve(true);
        },
    };
};

const paymentDate: Rule = (form): RuleObject => {
    return {
        validator(_, value: string) {
            const startDate = form.getFieldValue('startDate');
            if (
                moment(value).isSameOrAfter(startDate) &&
                moment(value).isSameOrBefore(moment(new Date()))
            ) {
                return Promise.resolve(true);
            }

            return Promise.reject(new Error(`${t('Validation.Payment.Date')}`));
        },
    };
};

const validateCSVFilesType: Rule = (): RuleObject => {
    return {
        validator: (_: any, value: any) => {
            if (value?.length > 0) {
                if (
                    value.every(
                        (item: any) => item.type !== AttachmentsFormat.csv,
                    )
                ) {
                    return Promise.reject(
                        new Error(`This file format is not supported`),
                    );
                }

                return Promise.resolve(true);
            }

            return Promise.resolve(true);
        },
    };
};

export const ValidationRules = {
    maxLength,
    minLength,
    required,
    email,
    name,
    idNumber,
    nationalId,
    phone,
    number,
    minPhoneLength,
    maxPhoneLength,
    minAccountBankLength,
    maxAccountBankLength,
    onlyNumber,
    maxCommentLength,
    maxFileSize,
    password,
    confirmPassword,
    maxLengthProductTitle,
    notMorethenMillion,
    notMoreThanHundered,
    notMoreThanTen,
    penaltyInterestMultiplierDecimal,
    maxTwoDigits,
    decimal,
    checkSumOfSourceFields,
    notLessThanZero,
    paymentDate,
    maxNotificationSubjectLength,
    maxEmailNotificationLength,
    maxSmsNotificationLength,
    validateCSVFilesType,
    systemOperations,
    decimalNumber,
    financedPercentage,
    requiredFinancedPercentage,
};
