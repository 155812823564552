import { Col, Row } from 'antd';
import { DocumentDTO } from 'src/generated-api-client';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

import { FileComponent } from '../FileComponent/FileComponent';

interface FilesListComponentProps {
    filesList: DocumentDTO[];
    store: BasicStore<any>;
}

export const FilesListComponent = ({
    filesList,
    store,
}: FilesListComponentProps) => (
    <Row gutter={[24, 24]}>
        {filesList.map((file) => (
            <Col span={12}>
                <FileComponent
                    key={file.documentId}
                    file={file}
                    store={store}
                />
            </Col>
        ))}
    </Row>
);
