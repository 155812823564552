import { action } from 'mobx';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    CreateBackofficeUserForm,
    EntityModelBackofficeUserDTO,
    PasswordForm,
    UpdateBackofficeUserForm,
    UserStatus,
} from 'src/generated-api-client';
import { usersApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type UsersFilter = {
    fullName?: string;
    email?: string;
    roles?: number;
    id?: string;
    statuses?: UserStatus[];
};

export class UsersStoreClass extends BasicStore<
    EntityModelBackofficeUserDTO,
    UsersFilter,
    UpdateBackofficeUserForm,
    CreateBackofficeUserForm
> {
    api: BasicStoreApi<
        EntityModelBackofficeUserDTO,
        UpdateBackofficeUserForm,
        CreateBackofficeUserForm
    > = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                usersApi.entityList(
                    this.filterCriteria.filter.id,
                    this.filterCriteria.filter.fullName,
                    [this.filterCriteria.filter.roles] as number[],
                    this.filterCriteria.filter.email,
                    this.filterCriteria.filter.statuses as UserStatus[],
                    this.pager?.page,
                    this.pager?.size,
                    undefined,
                    undefined,
                    ['id'],
                    'DESC',
                ),
            );

            this.pager?.setTotal(result.page?.totalElements as number);

            return result.content || [];
        },

        loadItem: (userId: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                usersApi.entityView(Number(userId)),
            );
        },

        createItem: async (data) => {
            const res = await RequestHelper.unwrapFromAxiosPromise(
                usersApi.createUser(data),
            );

            Router.navigate(`${RoutePaths.users}/${res?.data?.id}`);
        },

        updateItem: async (id, data) => {
            const userId = Number(id);
            await RequestHelper.unwrapFromAxiosPromise(
                usersApi.updateUser({ ...data, id: userId }),
            );
        },

        activate: async (id) => {
            await RequestHelper.unwrapFromAxiosPromise(usersApi.activate(id));
        },

        deactivate: async (id) => {
            await RequestHelper.unwrapFromAxiosPromise(usersApi.deactivate(id));
        },
    };

    onChangePasswordAction = new AsyncOperationWithStatus(
        (id: number, passwordForm: PasswordForm) =>
            RequestHelper.unwrapFromAxiosPromise(
                usersApi.changePassword(id, passwordForm),
            ),
    );

    @action async onChangePassword(id: number, passwordForm: PasswordForm) {
        await this.onChangePasswordAction.call(id, passwordForm);
    }

    filterCriteria = new FilterCriteria<UsersFilter>();

    pager?: Pager | undefined = new Pager();
}

export const UsersStore = new UsersStoreClass();
