import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { LoanSnapshotDTO } from 'src/generated-api-client';
import { DateHelper } from 'src/utils/DateHelper';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const StyledTable = styled(Table)`
    margin-top: 10px;
    margin-bottom: 40px;
    .ant-table-thead {
        .ant-table-cell {
            font-size: 12px;
            background: transparent;
            font-weight: 600;
        }
        .ant-table-cell::before {
            display: none;
        }
    }
    .ant-table-tbody {
        .ant-table-cell {
            color: #000000;
            font-size: 12px;
        }
    }
    .ant-table-summary {
        background: #bfbfbf5c;
    }
`;

const columns = [
    {
        title: <Trans i18nKey="Page.Loans.Operations.Date" defaults="Date" />,
        dataIndex: 'dateTime',
        key: 'dateTime',
        render: (date: any) => <>{DateHelper.format(date)}</>,
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.Operation"
                defaults="Operation"
            />
        ),
        dataIndex: 'calculationEvent',
        key: 'calculationEvent',
        render: (calculationEvent: any) => (
            <Trans
                i18nKey={`Loans.SnapshotDTO.calculationEvent.${calculationEvent}`}
            />
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.PrincipalDebt"
                defaults="Principal debt"
            />
        ),
        dataIndex: 'principalDue',
        key: 'principalDue',
        render: (principalDue: any) => (
            <>{NumberHelper.formatThousandSeparator(principalDue, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.InterestAccrued"
                defaults="Interest accrued"
            />
        ),
        dataIndex: 'accruedInterestDue',
        key: 'accruedInterestDue',
        render: (accruedInterestDue: any) => (
            <>{NumberHelper.formatThousandSeparator(accruedInterestDue, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.InitiationFee"
                defaults="Initiation fee"
            />
        ),
        dataIndex: 'initiationFeeDue',
        key: 'initiationFeeDue',
        render: (initiationFeeDue: any) => (
            <>{NumberHelper.formatThousandSeparator(initiationFeeDue, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.InitiationFeeVAT"
                defaults="Initiation fee VAT"
            />
        ),
        dataIndex: 'vatInitiationFeeDue',
        key: 'vatInitiationFeeDue',
        render: (vatInitiationFeeDue: any) => (
            <>{NumberHelper.formatThousandSeparator(vatInitiationFeeDue, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.MonthlyFee"
                defaults="Monthly fee"
            />
        ),
        dataIndex: 'monthlyServiceFeeDue',
        key: 'monthlyServiceFeeDue',
        render: (monthlyServiceFeeDue: any) => (
            <>{NumberHelper.formatThousandSeparator(monthlyServiceFeeDue, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.MonthlyFeeVAT"
                defaults="Monthly fee VAT"
            />
        ),
        dataIndex: 'vatMonthlyServiceFeeDue',
        key: 'vatMonthlyServiceFeeDue',
        render: (vatMonthlyServiceFeeDue: any) => (
            <>
                {NumberHelper.formatThousandSeparator(
                    vatMonthlyServiceFeeDue,
                    2,
                )}
            </>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.InsuranceFee"
                defaults="Insurance fee"
            />
        ),
        dataIndex: 'insuranceFeeDue',
        key: 'insuranceFeeDue',
        render: (insuranceFeeDue: any) => (
            <>{NumberHelper.formatThousandSeparator(insuranceFeeDue, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.InsuranceFeeVAT"
                defaults="Insurance fee VAT"
            />
        ),
        dataIndex: 'vatInsuranceFeeDue',
        key: 'vatInsuranceFeeDue',
        render: (vatInsuranceFeeDue: any) => (
            <>{NumberHelper.formatThousandSeparator(vatInsuranceFeeDue, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.PenaltyInterest"
                defaults="Penalty interest"
            />
        ),
        dataIndex: 'penaltyInterest',
        key: 'penaltyInterest',
        render: (penaltyInterest: any) => (
            <>{NumberHelper.formatThousandSeparator(penaltyInterest, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.Operations.OtherOverdueBalances"
                defaults="Other overdue balances"
            />
        ),
        dataIndex: 'overdueTotal',
        key: 'overdueTotal',
        render: (overdueTotal: any) => (
            <>{NumberHelper.formatThousandSeparator(overdueTotal, 2)}</>
        ),
    },
];

export const Operations = observer(
    ({ dataSource }: { dataSource: LoanSnapshotDTO[] }) => {
        return (
            <StyledTable
                pagination={false}
                columns={columns}
                dataSource={dataSource}
            />
        );
    },
);
