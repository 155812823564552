export type FieldData = {
    name: string | string[];
    errors: string[];
    touched: boolean;
};

type Violation = { field: string; message: string };
const getFieldDataItems = (errorData?: {
    violations: Violation[];
}): FieldData[] => {
    const fieldViolationsMap = getViolationsMap(errorData?.violations || []);

    return Object.entries(fieldViolationsMap).map(([fieldName, violations]) => {
        return {
            name: getFieldPath(removeCrossParameter(fieldName)),
            errors: violations.map((v) => v.message),
            touched: true,
        };
    });
};

const getViolationsMap = (
    violations: Violation[],
): Record<string, Violation[]> => {
    return (
        violations.reduce((acc, violation) => {
            if (!acc[violation.field]) {
                acc[violation.field] = [];
            }

            acc[violation.field].push(violation);

            return acc;
        }, {} as Record<string, Violation[]>) || {}
    );
};

const getFieldPath = (fieldName: string) => {
    return fieldName.split('.');
};

const CROSS_PARAMETER_PATTERN = /.*<cross-parameter>\./i;
const removeCrossParameter = (fieldName: string) => {
    return fieldName.replace(CROSS_PARAMETER_PATTERN, '');
};

const filterOptionsForSelect = (input: any, option: any) => {
    return (option?.children ?? '')
        ?.toString()
        ?.toLowerCase()
        ?.includes(input.toString().toLowerCase());
};

export const FormHelper = { getFieldDataItems, filterOptionsForSelect };
