import { Form, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal/Modal';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { CreateNotificationForm } from 'src/generated-api-client';
import { NotificationDialogForm } from 'src/pages/NotificationsPage/Dialogs/NotificationDialogForm';
import { NotificationsStore } from 'src/stores/NotificationsStore/NotificationsStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';

export const CreateNotificationDialog = observer(({ ...props }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onFinish = async (values: CreateNotificationForm) => {
        await NotificationsStore.createItem({
            ...values,
            content: values.content.trim(),
            title: values.title.trim(),
        });
        if (!NotificationsStore.createItemLoader.hasError) {
            notification.success({
                message: t('Message.Success'),
            });
            await NotificationsStore.loadList();
            Router.navigate(RoutePaths.notifications);
        }
    };

    return (
        <Modal
            {...props}
            closable={false}
            width={670}
            form={form}
            title={t('Dialog.CreateNotification.Title')}
            storeLoader={NotificationsStore.createItemLoader}
            subtitle={t('Dialog.CreateNotification.Subtitle')}
            isLoading={NotificationsStore.createItemLoader.isLoading}
            onFinish={onFinish}
        >
            <NotificationDialogForm form={form} />
        </Modal>
    );
});

export const CreateNotificationDialogModal = addModalRenderer(
    CreateNotificationDialog,
);
