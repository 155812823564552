import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { DisbursementAccountStatistic } from 'src/generated-api-client';
import { useOpenFirstListItem } from 'src/hooks/useOpenFirstListItem';
import { StatisticsStore } from 'src/stores/StatisticsStore/StatisticsStore';
import styled from 'styled-components';

import { HeaderTable } from './Components/HeaderTable';
import { StatisticEntityPresentation } from './StatisticEntityPresentation';
import { StatisticHeaderPanel } from './StatisticHeaderPanel';
import { StatisticListItem } from './StatisticListItem';

const store = StatisticsStore;

export const StatisticsPage = observer(() => {
    const { path } = useRouteMatch();
    useOpenFirstListItem(store);
    const { t } = useTranslation();

    useEffect(() => {
        store.getStatisticsReports();

        return () => {
            store.listLoader.reset();
            store.filterCriteria?.resetFilter();
            store.pager?.reset();
        };
    }, []);

    const reportStaticsData = store.getStatistics;

    return (
        <MasterDetailsPage store={store} asidePanel={<StatisticsAsidePanel />}>
            {reportStaticsData ? (
                <HeaderTable
                    dataSource={
                        reportStaticsData as DisbursementAccountStatistic[]
                    }
                />
            ) : (
                <StyledWrapper>
                    <div>{t('Page.Statisc.Error')}</div>
                </StyledWrapper>
            )}
            <Switch>
                <Route
                    path={`${path}/:statisticId`}
                    exact
                    render={() => <StatisticEntityPresentation />}
                />
            </Switch>
        </MasterDetailsPage>
    );
});

const StatisticsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: any) => {
                return (
                    <List.Item key={item.id}>
                        <StatisticListItem item={item} />
                    </List.Item>
                );
            }}
            header={<StatisticHeaderPanel />}
        />
    );
});

const StyledWrapper = styled.div<{ status?: string }>`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
`;
