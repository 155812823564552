import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { LoanScheduleDTO } from 'src/generated-api-client';
import { DateHelper } from 'src/utils/DateHelper';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const StyledTable = styled(Table)`
    margin-top: 10px;
    .ant-table-thead {
        .ant-table-cell {
            background: transparent;
            font-weight: 600;
        }
        .ant-table-cell::before {
            display: none;
        }
    }
    .ant-table-tbody {
        .ant-table-cell {
            color: #000000;
            font-size: 15px;
        }
    }
    .ant-table-summary {
        background: #bfbfbf5c;
    }
`;

const columns = [
    {
        title: (
            <Trans
                i18nKey="Page.Loans.HeaderTable.PaymentDate"
                defaults="Payment date"
            />
        ),
        dataIndex: 'paymentDate',
        key: 'paymentDate',
        render: (date: any) => <>{DateHelper.format(date)}</>,
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.HeaderTable.TotalPayment"
                defaults="Total payment"
            />
        ),
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (amount: any) => (
            <>{NumberHelper.formatThousandSeparator(amount, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.HeaderTable.PrincipalDebt"
                defaults="Principal debt"
            />
        ),
        dataIndex: 'principal',
        key: 'principal',
        render: (principal: any) => (
            <>{NumberHelper.formatThousandSeparator(principal, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.HeaderTable.InterestAccrued"
                defaults="Interest accrued"
            />
        ),
        dataIndex: 'interest',
        key: 'interest',
        render: (interest: any) => (
            <>{NumberHelper.formatThousandSeparator(interest, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.HeaderTable.MonthlyFee"
                defaults="Monthly fee"
            />
        ),
        dataIndex: 'monthlyServiceFee',
        key: 'monthlyServiceFee',
        render: (monthlyServiceFee: any) => (
            <>{NumberHelper.formatThousandSeparator(monthlyServiceFee, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.HeaderTable.MonthlyFeeVAT"
                defaults="Monthly fee VAT"
            />
        ),
        dataIndex: 'vatMonthlyServiceFee',
        key: 'vatMonthlyServiceFee',
        render: (vatMonthlyServiceFee: any) => (
            <>{NumberHelper.formatThousandSeparator(vatMonthlyServiceFee, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.HeaderTable.InitiationFee"
                defaults="Initiation fee"
            />
        ),
        dataIndex: 'initiationFee',
        key: 'initiationFee',
        render: (initiationFee: any) => (
            <>{NumberHelper.formatThousandSeparator(initiationFee, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.HeaderTable.InitiationFeeVAT"
                defaults="Initiation fee VAT"
            />
        ),
        dataIndex: 'vatInitiationFee',
        key: 'vatInitiationFee',
        render: (vatInitiationFee: any) => (
            <>{NumberHelper.formatThousandSeparator(vatInitiationFee, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.HeaderTable.InsuranceFee"
                defaults="Insurance fee"
            />
        ),
        dataIndex: 'insuranceFee',
        key: 'insuranceFee',
        render: (insuranceFee: any) => (
            <>{NumberHelper.formatThousandSeparator(insuranceFee, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Loans.HeaderTable.InsuranceFeeVAT"
                defaults="Insurance fee VAT"
            />
        ),
        dataIndex: 'vatInsuranceFee',
        key: 'vatInsuranceFee',
        render: (vatInsuranceFee: any) => (
            <>{NumberHelper.formatThousandSeparator(vatInsuranceFee, 2)}</>
        ),
    },
];

export const HeaderTable = observer(
    ({ dataSource }: { dataSource: LoanScheduleDTO[] }) => {
        return (
            <StyledTable
                pagination={false}
                columns={columns}
                dataSource={dataSource}
            />
        );
    },
);
