import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import { BackOfficeUserProfileDTO } from 'src/generated-api-client';
import { usersApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProfileStoreClass {
    @observable private currentUser?: BackOfficeUserProfileDTO;
    @observable private currentUserAuthorities?: string[];

    profileLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(usersApi.getProfileByKeycloakId()),
    );

    @action async loadProfile() {
        const result = await this.profileLoader.call();
        this.currentUser = result?.data;
        this.currentUserAuthorities = result?.data?.role?.authorities;
    }

    @computed get profile() {
        return this.currentUser;
    }

    @computed get profileAuthorities() {
        return this.currentUser?.role?.authorities;
    }

    @computed get currentAuthorities() {
        return this.currentUserAuthorities;
    }

    hasPermissions(...permissions: string[]) {
        return (
            _.intersection(
                this.currentUserAuthorities?.map((role) => role.toLowerCase()),
                permissions.map((permission) => permission.toLowerCase()),
            ).length > 0
        );
    }
}

export const ProfileStore = new ProfileStoreClass();
