import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { NumberHelper } from 'src/utils/NumberHelper';

const { Text } = Typography;

const store = ApplicationsStore;

export const CCReviewLoanInformation = observer(() => {
    const { t } = useTranslation();
    const { applicationId } = useApplicationId();
    const ccReviewData = store.ccReview;
    const currentApplication = store.currentItem?.data;

    return (
        <>
            <Row>
                <BlockTitle>
                    {t('Page.Applications.FinalReviewApplicationTiltle')}
                    {` #${applicationId}`}
                </BlockTitle>
            </Row>
            <Row gutter={24} justify="space-between">
                <Col span={10}>
                    <DataView
                        label={t('Page.Applications.CustomerDetaisTitle')}
                        value={
                            <Row gutter={24}>
                                <Col span={24}>
                                    {t(
                                        'Page.Applications.CurrentLoanExposure.Label',
                                        'Current loan exposure: R',
                                    )}{' '}
                                    {NumberHelper.formatThousandSeparator(
                                        Number(
                                            ccReviewData?.totalLoansExposure,
                                        ),
                                        2,
                                    )}
                                </Col>
                                <br />
                                <Col span={24}>
                                    <Text italic>
                                        {t(
                                            'Page.Applications.IncludingDisclosedLoans.Label',
                                            'Including disclosed loans: R',
                                        )}{' '}
                                        {NumberHelper.formatThousandSeparator(
                                            Number(
                                                ccReviewData?.disclosedLoansExposure,
                                            ),
                                            2,
                                        )}
                                    </Text>
                                </Col>
                                <Col span={24}>
                                    <Text italic>
                                        {t(
                                            'Page.Applications.IncludingNonDisclosedLoans.Label',
                                            'Including non-disclosed loans: R',
                                        )}{' '}
                                        {NumberHelper.formatThousandSeparator(
                                            Number(
                                                ccReviewData?.undisclosedLoansExposure,
                                            ),
                                            2,
                                        )}
                                    </Text>
                                </Col>
                                <br />
                                <br />
                                <Col span={24}>
                                    {t(
                                        'Page.Applications.OverdueLoansExposure.Label',
                                        'Overdue loans exposure: R',
                                    )}{' '}
                                    {NumberHelper.formatThousandSeparator(
                                        Number(
                                            ccReviewData?.totalOverdueLoansExposure,
                                        ),
                                        2,
                                    )}
                                </Col>
                                <br />
                                <Col span={24}>
                                    <Text italic>
                                        {t(
                                            'Page.Applications.IncludingDisclosedLoans.Label',
                                            'Including disclosed loans: R',
                                        )}{' '}
                                        {NumberHelper.formatThousandSeparator(
                                            Number(
                                                ccReviewData?.overdueDisclosedLoansExposure,
                                            ),
                                            2,
                                        )}
                                    </Text>
                                </Col>
                                <Col span={24}>
                                    <Text italic>
                                        {t(
                                            'Page.Applications.IncludingNonDisclosedLoans.Label',
                                            'Including non-disclosed loans: R',
                                        )}{' '}
                                        {NumberHelper.formatThousandSeparator(
                                            Number(
                                                ccReviewData?.overdueUndisclosedLoansExposure,
                                            ),
                                            2,
                                        )}
                                    </Text>
                                </Col>
                            </Row>
                        }
                    />
                </Col>
                <Col span={10}>
                    {currentApplication?.contrOffer && (
                        <DataView
                            label={t(
                                'Page.Applications.CustomersControffer',
                                'Customer controffer',
                            )}
                            value={currentApplication?.contrOffer}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
});
