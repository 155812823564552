import { Checkbox, Form, FormItemProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { Item } = Form;

export type CheckboxFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    name: string;
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    checkboxLabel?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    checked?: boolean;
    // eslint-disable-next-line react/require-default-props
    suffix?: React.ReactNode;
};

export const CheckboxField = ({
    name,
    label,
    disabled,
    suffix,
    checkboxLabel,
    checked,
    ...props
}: CheckboxFieldProps) => {
    return (
        <StyledCheckBox
            {...props}
            name={name}
            valuePropName="checked"
            label={label}
        >
            {checkboxLabel ? (
                <Checkbox disabled={disabled}>{checkboxLabel}</Checkbox>
            ) : (
                <Checkbox disabled={disabled} />
            )}
        </StyledCheckBox>
    );
};

const StyledCheckBox = styled(Item)`
    .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
    }
    .checkboxSuffix {
        font-size: 18px;
        margin-left: 15px;
        color: #000000;
    }
    .ant-checkbox {
        border: 1px solid black;
    }
    .ant-checkbox-inner {
        background-color: transparent;
        border-color: transparent !important;
    }
    .ant-checkbox-inner::after {
        border-top: none !important;
        border-left: none !important;
        border: 2px solid #000000;
    }
    .ant-checkbox-checked::after {
        border: none !important;
    }
`;
