/* eslint-disable max-lines */
import { Col, Form, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'src/components/DateRangePicker/DateRangePicker';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import {
    EntityModelLoanViewDTOStatusEnum,
    LoanApplicationDisclosure,
    LoanApplicationType,
} from 'src/generated-api-client';
import { LoanProductsListStore } from 'src/stores/LoanProductsStore/LoanProductsListStore';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

import { FilterBlock } from '../FilterPanel/FilterBlock';
import { SearchPanelFilterForm } from '../FilterPanel/SearchPanelFilterForm';

const store = LoansStore;

export const LoanHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <FilterBlock
                store={store}
                placeholder={t('Page.Products.AsideHeader.Search.Placeholder', {
                    defaultValue: 'Search',
                })}
                renderPanel={(props: any) => <FilterPanel {...props} />}
            />
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: flex;
`;

const FilterPanel = observer((props: any) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{ issuedAt: [] }}
            onFinish={({ ...values }: any) => {
                const { issuedAt, issuedAtMaturity } = values;
                const [maturityDateFrom, maturityDateTo] = issuedAtMaturity || [
                    undefined,
                    undefined,
                ];
                const [startDateFrom, startDateTo] = issuedAt || [
                    undefined,
                    undefined,
                ];
                startDateFrom?.set('hour', 0).set('minute', 0).set('second', 0);
                startDateTo
                    ?.set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59);
                maturityDateFrom
                    ?.set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0);
                maturityDateTo
                    ?.set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59);
                store.filterCriteria?.applyFilter({
                    ...values,
                    startDateFrom,
                    startDateTo,
                    maturityDateFrom,
                    maturityDateTo,
                });
                store.pager?.resetPage();
                store.loadList();
            }}
        >
            <Form.Item
                label={t('Page.Loans.Filter.Id', {
                    defaultValue: 'ID',
                })}
                name="id"
            >
                <StyledInput type="string" />
            </Form.Item>
            <Form.Item
                label={t('Page.Loans.Filter.CustomerName', {
                    defaultValue: 'Customer legal name',
                })}
                name="legalName"
            >
                <StyledInput type="string" />
            </Form.Item>
            <Form.Item
                label={t('Page.Loans.Filter.CIPC', {
                    defaultValue: 'Customer CIPC',
                })}
                name="cipc"
            >
                <StyledInput type="string" />
            </Form.Item>
            <StyledSelect
                label={t('Page.Loans.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="statuses"
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    <Select.Option
                        value={EntityModelLoanViewDTOStatusEnum.ACTIVE}
                    >
                        {t(
                            'Loans.EntityModelLoanViewDTOStatusEnum.Type.ACTIVE',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={EntityModelLoanViewDTOStatusEnum.OVERDUE}
                    >
                        {t(
                            'Loans.EntityModelLoanViewDTOStatusEnum.Type.OVERDUE',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={EntityModelLoanViewDTOStatusEnum.DEFAULT}
                    >
                        {t(
                            'Loans.EntityModelLoanViewDTOStatusEnum.Type.DEFAULT',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={EntityModelLoanViewDTOStatusEnum.PAIDUP}
                    >
                        {t(
                            'Loans.EntityModelLoanViewDTOStatusEnum.Type.PAID_UP',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={EntityModelLoanViewDTOStatusEnum.WRITTENOFF}
                    >
                        {t(
                            'Loans.EntityModelLoanViewDTOStatusEnum.Type.WRITTEN_OFF',
                        )}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <StyledSelect
                label={t('Page.Loans.Filter.Type', {
                    defaultValue: 'Type',
                })}
                name="types"
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    <Select.Option value={LoanApplicationType.FINANCEOFDEBTOR}>
                        {t('Entity.Application.Type.FINANCE_OF_DEBTOR')}
                    </Select.Option>
                    <Select.Option value={LoanApplicationType.INVOICEFINANCE}>
                        {t('Entity.Application.Type.INVOICE_FINANCE')}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationType.OUTANDOUTINVOICEFINANCE}
                    >
                        {t(
                            'Entity.Application.Type.OUT_AND_OUT_INVOICE_FINANCE',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationType.PURCHASEORDERFINANCE}
                    >
                        {t('Entity.Application.Type.PURCHASE_ORDER_FINANCE')}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <StyledSelect
                label={t('Page.Loans.Filter.Form', {
                    defaultValue: 'Form',
                })}
                name="disclosures"
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    <Select.Option value={LoanApplicationDisclosure.DISCLOSED}>
                        {t('Loan.Application.Disclosure.DISCLOSED')}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationDisclosure.UNDISCLOSED}
                    >
                        {t('Loan.Application.Disclosure.UNDISCLOSED')}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <Form.Item
                label={t('Page.Loans.Filter.StartDate', {
                    defaultValue: 'Start date',
                })}
                name="issuedAt"
            >
                <DateRangePicker />
            </Form.Item>
            <Form.Item
                label={t('Page.Loans.Filter.Maturity', {
                    defaultValue: 'Maturity',
                })}
                name="issuedAtMaturity"
            >
                <DateRangePicker />
            </Form.Item>
            <StyledLabel>
                {t('Page.Loans.Filter.Exposure', {
                    defaultValue: 'Exposure',
                })}
            </StyledLabel>
            <Row justify="space-between">
                <Col span={10}>
                    <TextField name="totalExposureFrom" />
                </Col>

                <Col span={10}>
                    <TextField name="totalExposureTo" />
                </Col>
            </Row>
            <SelectField
                mode="multiple"
                store={LoanProductsListStore}
                convertListItemToOption={(productName: any) => ({
                    children: `${productName}`,
                    value: productName,
                })}
                name="productName"
                label={t('Page.Loans.LoanProduct', 'Loan product')}
            />
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        min-height: 40px;
        border-radius: 6px;
        .ant-select-selection-search {
            display: flex;
            align-items: center;
        }
    }
`;

const StyledInput = styled(Input)`
    height: 40px;
    border-radius: 6px;
`;

const StyledLabel = styled.label`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px !important;
`;
