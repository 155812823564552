import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { ListPage } from 'src/components/ListPage/ListPage';
import { EntityLinks } from 'src/core/router/EntityLinks';
import {
    EntityModelUserAccountActionDTO,
    EntityModelUserAccountActionDTOPortalTypeEnum,
} from 'src/generated-api-client';
import { FilterActionLogsPanel } from 'src/pages/ActionLogsPage/FilterActionLogsPanel';
import { FilterBlock } from 'src/pages/FilterPanel/FilterBlock';
import { ActionLogsStore } from 'src/stores/ActionLogsStore/ActionLogsStore';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

export const ActionLogsPage = observer(() => {
    const { t } = useTranslation();
    const columns = getColumns();

    return (
        <StyledListPage
            header={
                <BlockTitle>
                    <Row gutter={[24, 24]} align="bottom">
                        <Col>{t('Page.ActionLogs.Title', 'Action log')}</Col>
                        <Col>
                            <FilterBlock
                                store={ActionLogsStore}
                                renderPanel={(props: any) => (
                                    <FilterActionLogsPanel {...props} />
                                )}
                            />
                        </Col>
                    </Row>
                </BlockTitle>
            }
            store={ActionLogsStore}
            columns={columns}
        />
    );
});

export const getColumns = (): ColumnsType<EntityModelUserAccountActionDTO> => [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Page.ActionLogs.List.ID" />,
    },
    {
        key: 'date',
        dataIndex: 'date',
        title: <Trans i18nKey="Page.ActionLogs.List.Date" />,
        sorter: true,
        width: 180,
        render(value: string) {
            return value ? DateHelper.formatDateTime(value) : null;
        },
    },
    {
        key: 'portalType',
        dataIndex: 'portalType',
        title: <Trans i18nKey="Page.ActionLogs.List.PortalType" />,
        sorter: true,
        render(value: string) {
            return value ? <Trans i18nKey={`Portal.Type.${value}`} /> : null;
        },
    },
    {
        key: 'ip',
        dataIndex: 'ip',
        title: <Trans i18nKey="Page.ActionLogs.List.IP" />,
        width: 180,
    },
    {
        key: 'userFullName',
        dataIndex: 'userFullName',
        title: <Trans i18nKey="Page.ActionLogs.List.UserFullName" />,
        sorter: true,
        render: (user, { userId, portalType }) => {
            if (
                portalType === EntityModelUserAccountActionDTOPortalTypeEnum.SME
            ) {
                return userId ? (
                    <Link to={EntityLinks.customers.itemDetails(userId)}>
                        {user}
                    </Link>
                ) : (
                    <span>{user}</span>
                );
            }
            if (
                portalType === EntityModelUserAccountActionDTOPortalTypeEnum.BO
            ) {
                return userId ? (
                    <Link to={EntityLinks.users.itemDetails(userId)}>
                        {user}
                    </Link>
                ) : (
                    <span>{user}</span>
                );
            }

            return <span>{user}</span>;
        },
    },
    {
        key: 'requestMethod',
        dataIndex: 'requestMethod',
        title: <Trans i18nKey="Page.ActionLogs.List.RequestMethod" />,
        sorter: true,
        render(value: string) {
            return value ? <Trans i18nKey={`Request.Method.${value}`} /> : null;
        },
    },
    {
        key: 'url',
        dataIndex: 'url',
        title: <Trans i18nKey="Page.ActionLogs.List.Url" />,
    },
    {
        key: 'params',
        dataIndex: 'params',
        title: <Trans i18nKey="Page.ActionLogs.List.Params" />,
    },
];

const StyledListPage = styled(ListPage)`
    .app-table {
        white-space: pre;
    }

    .ant-table-thead th.ant-table-column-has-sorters,
    .ant-table-thead > tr > th {
        min-width: 140px;
    }
`;
