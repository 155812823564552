/* eslint-disable max-lines */
import { Button, Form, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';
import styled from 'styled-components';

import { FilterBlock } from '../FilterPanel/FilterBlock';
import { SearchPanelFilterForm } from '../FilterPanel/SearchPanelFilterForm';
import { AddRoleModal } from './Dialogs/CreateRoleDialog';

const store = RolesStore;

export const RolesHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <FilterBlock
                store={store}
                placeholder={t('AsideHeader.Placeholder.Search', {
                    defaultValue: 'Search',
                })}
                renderPanel={(props: any) => <FilterPanel {...props} />}
            />
            <StyledCreateButton
                type="ghost"
                onClick={() => {
                    AddRoleModal.render();
                }}
            >
                {t('Button.Create', 'Create new')}
            </StyledCreateButton>
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: flex;
`;

const StyledCreateButton = styled(Button)`
    width: 100%;
    min-width: 50px;
    height: 32px;
    margin-left: 5px;
    border-radius: 10px;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3 !important;
        color: #ffffff !important;
        border: none;
    }
`;

const FilterPanel = observer((props: any) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{}}
            onFinish={({ active, ...values }: any) => {
                store.filterCriteria?.applyFilter({
                    ...values,
                });
                store.pager?.resetPage();
                store.loadList();
            }}
        >
            <Form.Item
                label={t('Page.Roles.Filter.Id', {
                    defaultValue: 'ID',
                })}
                name="id"
            >
                <StyledInput type="number" />
            </Form.Item>
            <Form.Item
                label={t('Page.Roles.Filter.Name', {
                    defaultValue: 'Name',
                })}
                name="name"
            >
                <StyledInput type="string" />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});

const StyledInput = styled(Input)`
    height: 40px;
    border-radius: 6px;
`;
