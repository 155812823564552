import { NavLink, NavLinkProps } from 'react-router-dom';
import { CssColors } from 'src/styles/CssVariables';
import styled from 'styled-components';

export const MasterDetailsAsideItem = (
    props: React.PropsWithoutRef<NavLinkProps<any>> &
        React.RefAttributes<HTMLAnchorElement>,
) => {
    return (
        <StyledNavLink
            {...props}
            onClick={(e) => {
                if (
                    (e.currentTarget as HTMLElement).classList.contains(
                        'active',
                    )
                ) {
                    e.preventDefault();
                }
            }}
        />
    );
};

export const StyledNavLink = styled(NavLink)`
    display: block;
    padding: 14px 24px 14px 20px;
    background: white;
    border-right: 2px solid white;
    color: ${CssColors.black};

    &:hover {
        background: #f5f6f7;
        border-color: #f5f6f7;
        color: ${CssColors.black};
        text-decoration: none;
    }

    &.active {
        background: #f5f6f7;
        border-color: #f5f6f7;
        color: ${CssColors.black};
    }
`;
