/* eslint-disable max-lines */
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'src/components/DateRangePicker/DateRangePicker';
import { StatisticsStore } from 'src/stores/StatisticsStore/StatisticsStore';
import styled from 'styled-components';

import { FilterBlock } from '../FilterPanel/FilterBlock';
import { SearchPanelFilterForm } from '../FilterPanel/SearchPanelFilterForm';

const store = StatisticsStore;

export const StatisticHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <FilterBlock
                store={store}
                placeholder={t('Page.Products.AsideHeader.Search.Placeholder', {
                    defaultValue: 'Search',
                })}
                renderPanel={(props: any) => <FilterPanel {...props} />}
            />
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: flex;
`;

const FilterPanel = observer((props: any) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{}}
            onFinish={(values) => {
                const { issuedDate } = values;
                const [dateFrom, dateTo] = issuedDate || [undefined, undefined];
                store.filterCriteria?.applyFilter({
                    ...values,
                    dateFrom,
                    dateTo,
                });
                store.pager?.resetPage();
                store.loadList();
            }}
        >
            <Form.Item
                label={t('Page.Statisc.Filter.Label.ReportDate')}
                name="issuedDate"
            >
                <DateRangePicker />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});
