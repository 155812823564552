import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from 'src/components/Errors/NotFound';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { RouterStore } from 'src/stores/RouterStore/RouterStore';

export const RoutesLayout = observer(() => {
    useEffect(() => {
        ProfileStore.loadProfile();
    }, []);

    return (
        <Switch>
            {RouterStore.routes.map((route) => {
                const Component = !ProfileStore.hasPermissions(
                    ...route.permissions,
                )
                    ? NotAccessPage
                    : route.Component;

                return (
                    <Route
                        key={route.key}
                        path={route.path}
                        exact={route.exact !== false}
                    >
                        <Component />
                    </Route>
                );
            })}

            <Route path="*" component={NotFound} />
        </Switch>
    );
});

/*
const NotFound = () => <div>Not found</div>;
*/
const NotAccessPage = () => <div>Not access</div>;
