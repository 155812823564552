import { Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import styled from 'styled-components';

export const UserMenu = observer(() => {
    const { profile } = ProfileStore;
    const SubMenu = ({ profileId }: { profileId: number }) => {
        return (
            <StyledMenu>
                <Menu.Item
                    onClick={() => {
                        Router.replace(`${RoutePaths.users}/${profileId}`);
                    }}
                >
                    Profile
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                    onClick={() => {
                        AuthStore.logout(`${window.location.origin}/`);
                    }}
                >
                    <Trans i18nKey="App.Dropdown.Menu.Logout" />
                </Menu.Item>
            </StyledMenu>
        );
    };

    return (
        <Dropdown
            overlay={() => <SubMenu profileId={profile?.id as number} />}
            placement="bottomRight"
        >
            <StyledUserName>
                <StyledAvatar>
                    {AppUser.userProfile?.username?.[0]}
                </StyledAvatar>
            </StyledUserName>
        </Dropdown>
    );
});

const StyledUserName = styled.div`
    display: flex;
    align-items: center;
`;

const StyledAvatar = styled.div`
    display: inline-flex;
    height: 36px;
    width: 36px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2f333a;
    margin-right: 5px;
    text-transform: uppercase;
    font-weight: 700;
    border: 3px solid #222325;
    color: #ffffff;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    width: 160px;
`;
