import { generatePath } from 'react-router-dom';
import { RoutePaths } from 'src/core/router/RoutePaths';

type Links = {
    list: () => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemDetails: (id?: string | number) => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemEdit?: (id?: string | number) => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    process?: (id?: string | number) => string;

    create?: () => string;
};

type EntityNames =
    | 'users'
    | 'roles'
    | 'loanProducts'
    | 'customers'
    | 'Notifications'
    | 'loan'
    | 'statistic'
    | 'importData'
    | 'loanApplication';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EntityLinks: { [key in EntityNames]: Links } = {
    users: {
        list: () =>
            generatePath(RoutePaths.users, {
                userId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.userDetails, {
                userId: id,
            }),
        create: () =>
            generatePath(RoutePaths.userCreate, {
                userId: undefined,
            }),
    },
    roles: {
        list: () =>
            generatePath(RoutePaths.roles, {
                roleId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.roleDetails, {
                roleId: id,
            }),
    },
    loanProducts: {
        list: () =>
            generatePath(RoutePaths.loanProducts, {
                loanProductId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.loanProductsDetails, {
                loanProductId: id,
            }),
    },
    customers: {
        list: () =>
            generatePath(RoutePaths.customers, {
                loanProductId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.customersDetails, {
                customerId: id,
            }),
    },
    loanApplication: {
        list: () =>
            generatePath(RoutePaths.applications, {
                applicationId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.applicationsDetails, {
                applicationId: id,
            }),
    },
    Notifications: {
        list: () =>
            generatePath(RoutePaths.notifications, {
                applicationId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.notificationsDetails, {
                id,
            }),
    },
    loan: {
        list: () =>
            generatePath(RoutePaths.loans, {
                loanId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.loansDetails, {
                loanId: id,
            }),
    },
    statistic: {
        list: () =>
            generatePath(RoutePaths.statistics, {
                statisticId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.statisticsDetails, {
                statisticId: id,
            }),
    },
    importData: {
        list: () =>
            generatePath(RoutePaths.importData, {
                dataId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.importDataDetails, {
                dataId: id,
            }),
    },
};
