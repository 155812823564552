/* eslint-disable max-lines */
import { Col, Form, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'src/components/DateRangePicker/DateRangePicker';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import {
    EntityModelLoanProductDTO,
    LoanApplicationDisclosure,
    LoanApplicationStatus,
    LoanApplicationTerm,
    LoanApplicationType,
} from 'src/generated-api-client';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';
import { periodMapping } from 'src/utils/common-constants';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

import { FilterBlock } from '../FilterPanel/FilterBlock';
import { SearchPanelFilterForm } from '../FilterPanel/SearchPanelFilterForm';

const store = ApplicationsStore;

export const ApplicationsHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <FilterBlock
                store={store}
                placeholder={t('AsideHeader.Search.Placeholder', {
                    defaultValue: 'Search',
                })}
                renderPanel={(props: any) => <FilterPanel {...props} />}
            />
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: flex;
`;

const FilterPanel = observer((props: any) => {
    const { t } = useTranslation();

    const approvedTermOptions = [
        {
            value: LoanApplicationTerm.THIRTY,
            children: periodMapping.THIRTY.toString(),
        },
        {
            value: LoanApplicationTerm.SIXTY,
            children: periodMapping.SIXTY.toString(),
        },
        {
            value: LoanApplicationTerm.NINETY,
            children: periodMapping.NINETY.toString(),
        },
    ];

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{ issuedAt: [] }}
            onFinish={(values) => {
                const { issuedAt } = values;
                const [submitDateFrom, submitDateTo] = issuedAt || [
                    undefined,
                    undefined,
                ];
                moment(submitDateFrom).startOf('day');
                moment(submitDateTo).endOf('day');
                store.pager?.resetPage();
                store.filterCriteria?.applyFilter({
                    ...values,
                    submitDateFrom,
                    submitDateTo,
                });
                store.loadList();
            }}
        >
            <Form.Item
                label={t('Page.Applications.Filter.Id4', {
                    defaultValue: 'ID',
                })}
                name="id"
            >
                <StyledInput type="string" />
            </Form.Item>
            <Form.Item
                label={t('Page.Applications.Filter.CustomerName', {
                    defaultValue: 'Customer Legal name',
                })}
                name="customerLegalName"
            >
                <StyledInput type="string" />
            </Form.Item>
            <Form.Item
                label={t('Page.Applications.Filter.CIPC', {
                    defaultValue: 'Customer CIPC',
                })}
                name="customerCipc"
            >
                <StyledInput type="string" />
            </Form.Item>
            <StyledSelect
                label={t('Page.Applications.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="statuses"
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    <Select.Option value={LoanApplicationStatus.SIGNINGPROCESS}>
                        {t(
                            `Loan.Application.Status.SIGNING_PROCESS`,
                            'Signing process',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationStatus.CREDITBUREAUCHECKING}
                    >
                        {t(
                            `Loan.Application.Status.CREDIT_BUREAU_CHECKING`,
                            'CB checking',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationStatus.INITIALWAITINGDOCUMENTS}
                    >
                        {t(
                            `Loan.Application.Status.INITIAL_WAITING_DOCUMENTS`,
                            'Waiting documents',
                        )}
                    </Select.Option>
                    <Select.Option value={LoanApplicationStatus.INITIALREVIEW}>
                        {t(
                            `Loan.Application.Status.INITIAL_REVIEW`,
                            'Initial review',
                        )}
                    </Select.Option>
                    <Select.Option value={LoanApplicationStatus.INFOREQUESTED}>
                        {t(
                            `Loan.Application.Status.INFO_REQUESTED`,
                            'Info requested',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationStatus.CREDITCOMMITTEEREVIEW}
                    >
                        {t(
                            `Loan.Application.Status.CREDIT_COMMITTEE_REVIEW`,
                            'CC review',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationStatus.FINALOFFERISSUED}
                    >
                        {t(
                            `Loan.Application.Status.FINAL_OFFER_ISSUED`,
                            'Final offer issued',
                        )}
                    </Select.Option>
                    <Select.Option value={LoanApplicationStatus.OFFERISSUED}>
                        {t(
                            `Loan.Application.Status.OFFER_ISSUED`,
                            'Offer issued',
                        )}
                    </Select.Option>
                    <Select.Option value={LoanApplicationStatus.SIGNED}>
                        {t(`Loan.Application.Status.SIGNED`, 'Signed')}
                    </Select.Option>
                    <Select.Option value={LoanApplicationStatus.EXPIRED}>
                        {t(`Loan.Application.Status.EXPIRED`, 'Expired')}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationStatus.DECLINEDBYREVIEWER}
                    >
                        {t(
                            `Loan.Application.Status.DECLINED_BY_REVIEWER`,
                            'Declined',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationStatus.DECLINEDBYCREDITBUREAU}
                    >
                        {t(
                            `Loan.Application.Status.DECLINED_BY_CREDIT_BUREAU`,
                            'Declined by CB',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationStatus.DECLINEDBYCREDITCOMMITTEE}
                    >
                        {t(
                            `Loan.Application.Status.DECLINED_BY_CREDIT_COMMITTEE`,
                        )}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationStatus.DECLINEDBYCUSTOMER}
                    >
                        {t(
                            `Loan.Application.Status.DECLINED_BY_CUSTOMER`,
                            'Refused',
                        )}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationStatus.WAITINGACCOUNTVERIFICATION}
                    >
                        {t(
                            'Loan.Application.Status.WAITING_ACCOUNT_VERIFICATION',
                        )}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <StyledSelect
                label={t('Page.Application.Filter.Type', {
                    defaultValue: 'Type',
                })}
                name="types"
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    <Select.Option value={LoanApplicationType.FINANCEOFDEBTOR}>
                        {t('Loan.Application.Type.FINANCE_OF_DEBTOR')}
                    </Select.Option>
                    <Select.Option value={LoanApplicationType.INVOICEFINANCE}>
                        {t('Loan.Application.Type.INVOICE_FINANCE')}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationType.OUTANDOUTINVOICEFINANCE}
                    >
                        {t('Loan.Application.Type.OUT_AND_OUT_INVOICE_FINANCE')}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationType.PURCHASEORDERFINANCE}
                    >
                        {t('Loan.Application.Type.PURCHASE_ORDER_FINANCE')}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <StyledSelect
                label={t('Page.Application.Filter.Form', {
                    defaultValue: 'Form',
                })}
                name="disclosures"
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    <Select.Option value={LoanApplicationDisclosure.DISCLOSED}>
                        {t('Loan.Application.Disclosure.DISCLOSED')}
                    </Select.Option>
                    <Select.Option
                        value={LoanApplicationDisclosure.UNDISCLOSED}
                    >
                        {t('Loan.Application.Disclosure.UNDISCLOSED')}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <Form.Item
                label={t('Page.Application.Filter.IssuedAt', {
                    defaultValue: 'Application date',
                })}
                name="issuedAt"
            >
                <DateRangePicker />
            </Form.Item>
            <StyledLabel>
                {t('Page.Application.Filter.Id', {
                    defaultValue: 'Approved amount',
                })}
            </StyledLabel>
            <Row justify="space-between">
                <Col span={10}>
                    <TextField name="approvedAmountFrom" />
                </Col>

                <Col span={10}>
                    <TextField name="approvedAmountTo" />
                </Col>
            </Row>

            <SelectField
                mode="multiple"
                options={approvedTermOptions}
                name="approvedTerms"
                label={t('Page.Application.Filter.ApprovedTerm', {
                    defaultValue: 'Approved term',
                })}
            />

            <SelectField
                mode="multiple"
                store={LoanProductsStore}
                convertListItemToOption={(
                    loanProduct: EntityModelLoanProductDTO,
                ) => ({
                    children: `${loanProduct.productName}`,
                    value: loanProduct.id,
                })}
                name="loanProducts"
                label={t('Component.Label.LoanProduct')}
            />
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        min-height: 40px;
        border-radius: 6px;
        .ant-select-selection-search {
            display: flex;
            align-items: center;
        }
    }
`;

const StyledInput = styled(Input)`
    height: 40px;
    border-radius: 6px;
`;

const StyledLabel = styled.label`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px !important;
`;
