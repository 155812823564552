import { action, computed, observable } from 'mobx';
import { AttachmentsFormat } from 'src/components/Form/ValidationRules';
import {
    ApiResponseBodyReportDTO,
    DisbursementAccountStatistic,
    EntityModelReportDTO,
    ReportDTOFileTypeEnum,
} from 'src/generated-api-client';
import { reportApi } from 'src/services/apiServices';
import { DateHelper } from 'src/utils/DateHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type StatisticsFilters = {
    dateFrom?: string;
    dateTo?: string;
};

export class StatisticsStoreClass extends BasicStore<
    EntityModelReportDTO,
    StatisticsFilters,
    undefined,
    undefined,
    ApiResponseBodyReportDTO
> {
    @observable private statisticsReport?:
        | DisbursementAccountStatistic[]
        | null;

    api: BasicStoreApi<
        EntityModelReportDTO,
        undefined,
        undefined,
        ApiResponseBodyReportDTO
    > = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                reportApi.entityList4(
                    this.filterCriteria.filter.dateFrom &&
                        DateHelper.format(this.filterCriteria.filter.dateFrom),
                    this.filterCriteria.filter.dateTo &&
                        DateHelper.format(this.filterCriteria.filter.dateTo),
                    this.pager?.page,
                    this.pager?.size,
                ),
            );

            this.pager?.setTotal(result.page?.totalElements as number);

            return result.content || [];
        },

        loadItem: (statisticId: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                reportApi.entityView4(Number(statisticId)),
            );
        },
    };

    getStatisticsReportsAction = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(reportApi.getStatistics()),
    );

    regenerateReportAction = new AsyncOperationWithStatus((id: string) =>
        RequestHelper.unwrapFromAxiosPromise(
            reportApi.regenerateReport(Number(id)),
        ),
    );

    downloadReportsAction = new AsyncOperationWithStatus((id: number) =>
        RequestHelper.unwrapFromAxiosPromise(
            reportApi.downloadReport(id, {
                responseType: 'arraybuffer',
            }),
        ),
    );

    @action async getStatisticsReports() {
        const res = await this.getStatisticsReportsAction.call();
        if (res?.status === 200) {
            this.statisticsReport = res?.data;

            return;
        }

        this.statisticsReport = null;
    }

    @action async regenerateReport(id: string) {
        await this.regenerateReportAction.call(id);
        if (!this.regenerateReportAction.hasError) {
            this.loadItem(id);
            this.loadList();
        }
    }

    @action async downloadReports(
        id: number,
        type: ReportDTOFileTypeEnum,
        fileName: string,
    ) {
        const res = await this.downloadReportsAction.call(id);
        const file = window.URL.createObjectURL(
            new Blob([res], {
                type: AttachmentsFormat[
                    type.toLocaleLowerCase() as keyof typeof AttachmentsFormat
                ],
            }),
        );
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    @computed get getStatistics() {
        return this.statisticsReport;
    }

    filterCriteria = new FilterCriteria<StatisticsFilters>();

    pager?: Pager | undefined = new Pager();
}

export const StatisticsStore = new StatisticsStoreClass();
