import { Button, Col, Form, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NumberField } from 'src/components/Form/NumberField';
import { SelectField } from 'src/components/Form/SelectField';
import { LoanProductStatus } from 'src/generated-api-client';
import { LoanProductsListStore } from 'src/stores/LoanProductsStore/LoanProductsListStore';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

import { FilterBlock } from '../FilterPanel/FilterBlock';
import { SearchPanelFilterForm } from '../FilterPanel/SearchPanelFilterForm';
import { AddLoanProductModal } from './Dialogs/CreateLoanProductDialog';

const store = LoanProductsStore;

export const LoanProductHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <FilterBlock
                store={store}
                placeholder={t('AsideHeader.Search.Placeholder', {
                    defaultValue: 'Search',
                })}
                renderPanel={(props: any) => <FilterPanel {...props} />}
            />
            <StyledCreateButton
                type="ghost"
                onClick={() => {
                    AddLoanProductModal.render();
                }}
            >
                {t('Button.CreateNew')}
            </StyledCreateButton>
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: flex;
`;

const StyledCreateButton = styled(Button)`
    width: 100%;
    min-width: 50px;
    height: 32px;
    margin-left: 5px;
    border-radius: 10px;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const FilterPanel = observer((props: any) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{}}
            onFinish={({ active, ...values }: any) => {
                store.filterCriteria?.applyFilter({
                    ...values,
                });
                store.pager?.resetPage();
                store.loadList();
            }}
        >
            <Form.Item
                label={t('Page.LoanProducts.Filter.Id', {
                    defaultValue: 'ID',
                })}
                name="id"
            >
                <StyledInput type="string" />
            </Form.Item>
            <SelectField
                mode="multiple"
                store={LoanProductsListStore}
                convertListItemToOption={(productName: any) => ({
                    children: `${productName}`,
                    value: productName,
                })}
                name="name"
                label={t(
                    'Page.LoanProducts.Filter.Title',
                    'Loan product title',
                )}
            />
            <StyledLabel>
                {t('Page.LoanProducts.Filter.Id', {
                    defaultValue: '30 days interest rate',
                })}
            </StyledLabel>
            <Row justify="space-between">
                <Col span={10}>
                    <NumberField label="" name="interestRateFrom" />
                </Col>

                <Col span={10}>
                    <NumberField label="" name="interestRateTo" />
                </Col>
            </Row>
            <StyledSelect
                label={t('Page.LoanProducts.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="statuses"
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    <Select.Option value={LoanProductStatus.ACTIVE}>
                        {t(`data:status.users.ACTIVE`, 'Active')}
                    </Select.Option>
                    <Select.Option value={LoanProductStatus.INACTIVE}>
                        {t(`data:status.users.INACTIVE`, 'Inactive')}
                    </Select.Option>
                </Select>
            </StyledSelect>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 6px;
        .ant-select-selection-search {
            display: flex;
            align-items: center;
        }
    }
`;

const StyledInput = styled(Input)`
    height: 40px;
    border-radius: 6px;
`;

const StyledLabel = styled.label`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px !important;
`;
