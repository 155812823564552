import { Col, notification, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal/Modal';
import {
    LoanViewDTO,
    LoanWriteOffParamsOperationTypeEnum,
} from 'src/generated-api-client';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

type WriteOffDialogProps = {
    currentLoan: LoanViewDTO;
};

const { Paragraph } = Typography;

const store = LoansStore;

export const WriteOffDialog = observer(
    ({ currentLoan, ...props }: WriteOffDialogProps) => {
        const { t } = useTranslation();

        return (
            <Modal
                {...props}
                title={t(
                    'Page.Loans.Modal.WriteOffTheLoan.Title',
                    'Write-off the loan',
                )}
                isLoading={store.createWriteOffOperationAction.isLoading}
                onFinish={async () => {
                    await store.createWriteOffOperation({
                        loanId: Number(currentLoan.id),
                        operationType:
                            LoanWriteOffParamsOperationTypeEnum.WRITEOFF,
                        reason: 'Manually created operation',
                    });

                    if (!store.createWriteOffOperationAction.hasError) {
                        notification.success({
                            message: t('Message.Success'),
                        });
                        await store.loadList();
                        await store.loadItem(`${currentLoan.id}`);
                    }
                }}
            >
                <Row gutter={24} justify="center">
                    <Col span={16}>
                        <StyledParagraph>
                            <Trans
                                i18nKey="Page.Loans.Modal.WriteOffTheLoan.Text"
                                defaults={`Caution: after proceeding with this operation all
                            loan balances will be written off without
                            possibility to recover.`}
                            />
                        </StyledParagraph>
                    </Col>
                </Row>
            </Modal>
        );
    },
);

const StyledParagraph = styled(Paragraph)`
    font-weight: 600;
    font-size: 16px;
    text-align: center;
`;

export const WriteOffModal = addModalRenderer(WriteOffDialog);
