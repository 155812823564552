import styled from 'styled-components';

export const EllipsisContainer = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 270px;

    @media only screen and (max-width: 1600px) {
        max-width: 220px;
    }
`;
