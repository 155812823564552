import { OptionProps } from 'antd/lib/select';
import React from 'react';
import { Trans } from 'react-i18next';
import { ContentType, NotificationTriggerType } from 'src/generated-api-client';

const triggerType: OptionProps[] = Object.values(NotificationTriggerType)
    .map((value) => ({
        value,
        children: <Trans i18nKey={`Trigger.Type.${value}`} />,
    }))
    .filter((type) => {
        if (
            type.value ===
                NotificationTriggerType.BACKOFFICEAPPLICATIONINREVIEW ||
            type.value === NotificationTriggerType.BACKOFFICECUSTOMERINREVIEW ||
            type.value === NotificationTriggerType.BACKOFFICEAPPLICATIONCCREVIEW
        ) {
            return;
        }

        return type;
    });

const contentType: OptionProps[] = Object.values(ContentType).map((value) => ({
    value,
    children: <Trans i18nKey={`Content.Type.${value}`} />,
}));

export const NotificationSelectOptions = {
    triggerType,
    contentType,
};
