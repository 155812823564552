/* eslint-disable max-lines */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
    LoanApplicationQuarterFigures,
    LoanApplicationRatioFeature,
} from 'src/generated-api-client';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const quatersKeys = [
    'currentQuarter',
    'previousQuarter',
    'twoQuarterBefore',
    'threeQuarterBefore',
];

const LONG_DASH = '—';

export const FinancialAnalysisDataElement = observer(
    ({
        ratioItem,
        title,
    }: {
        ratioItem: LoanApplicationRatioFeature;
        title: string;
    }) => {
        const { t } = useTranslation();

        return (
            <>
                <StyledTitleRow>{t(title)}</StyledTitleRow>
                <StyledDataContainer>
                    <StyledTitleCol>
                        {t(
                            'Page.Application.Details.FinancialAnalysis.Ratio.Value.Label',
                        )}
                    </StyledTitleCol>
                    <StyledDataRow>
                        {quatersKeys.map((item) => (
                            <StyledDataItem>
                                {ratioItem?.value?.[
                                    item as keyof LoanApplicationQuarterFigures
                                ] == null ? (
                                    LONG_DASH
                                ) : (
                                    <TextContainerWithBraces
                                        value={
                                            ratioItem?.value?.[
                                                item as keyof LoanApplicationQuarterFigures
                                            ] as number
                                        }
                                    >
                                        {ratioItem?.value?.[
                                            item as keyof LoanApplicationQuarterFigures
                                        ] !== null
                                            ? NumberHelper.formatThousandSeparator(
                                                  Math.abs(
                                                      ratioItem?.value?.[
                                                          item as keyof LoanApplicationQuarterFigures
                                                      ] as number,
                                                  ),
                                                  2,
                                              )
                                            : LONG_DASH}
                                    </TextContainerWithBraces>
                                )}
                            </StyledDataItem>
                        ))}
                    </StyledDataRow>
                </StyledDataContainer>
                <StyledDataContainer>
                    <StyledTitleCol>
                        {t(
                            'Page.Application.Details.FinancialAnalysis.Ratio.IndustryAverage.Label',
                        )}
                    </StyledTitleCol>
                    <StyledDataRow>
                        {quatersKeys.map((item) => (
                            <StyledDataItem>
                                {ratioItem?.industryAverage?.[
                                    item as keyof LoanApplicationQuarterFigures
                                ] == null ? (
                                    LONG_DASH
                                ) : (
                                    <TextContainerWithBraces
                                        value={
                                            ratioItem?.industryAverage?.[
                                                item as keyof LoanApplicationQuarterFigures
                                            ] as number
                                        }
                                    >
                                        {ratioItem?.industryAverage?.[
                                            item as keyof LoanApplicationQuarterFigures
                                        ] !== null
                                            ? NumberHelper.formatThousandSeparator(
                                                  Math.abs(
                                                      ratioItem
                                                          ?.industryAverage?.[
                                                          item as keyof LoanApplicationQuarterFigures
                                                      ] as number,
                                                  ),
                                                  2,
                                              )
                                            : LONG_DASH}
                                    </TextContainerWithBraces>
                                )}
                            </StyledDataItem>
                        ))}
                    </StyledDataRow>
                </StyledDataContainer>
                <br />
                <StyledDataContainer>
                    <StyledTitleCol>
                        {t(
                            'Page.Application.Details.FinancialAnalysis.Ratio.Variance.Label',
                        )}
                    </StyledTitleCol>
                    <StyledDataRow>
                        {quatersKeys.map((item) => (
                            <StyledDataItem>
                                {ratioItem?.variance?.[
                                    item as keyof LoanApplicationQuarterFigures
                                ] == null ? (
                                    LONG_DASH
                                ) : (
                                    <ColoredTextContainer
                                        value={
                                            ratioItem?.variance?.[
                                                item as keyof LoanApplicationQuarterFigures
                                            ] as number
                                        }
                                    >
                                        {ratioItem?.variance?.[
                                            item as keyof LoanApplicationQuarterFigures
                                        ] !== null
                                            ? NumberHelper.formatThousandSeparator(
                                                  Math.abs(
                                                      ratioItem?.variance?.[
                                                          item as keyof LoanApplicationQuarterFigures
                                                      ] as number,
                                                  ),
                                                  2,
                                              )
                                            : LONG_DASH}
                                    </ColoredTextContainer>
                                )}
                            </StyledDataItem>
                        ))}
                    </StyledDataRow>
                </StyledDataContainer>
                <StyledDataContainer>
                    <StyledTitleCol>
                        {t(
                            'Page.Application.Details.FinancialAnalysis.Ratio.Quarter.Label',
                        )}
                    </StyledTitleCol>
                    <StyledDataRow>
                        {quatersKeys.map((item) => (
                            <StyledDataItem>
                                {ratioItem?.quarterChange?.[
                                    item as keyof LoanApplicationQuarterFigures
                                ] == null ? (
                                    LONG_DASH
                                ) : (
                                    <ColoredTextContainer
                                        value={
                                            ratioItem?.quarterChange?.[
                                                item as keyof LoanApplicationQuarterFigures
                                            ] as number
                                        }
                                    >
                                        {ratioItem?.quarterChange?.[
                                            item as keyof LoanApplicationQuarterFigures
                                        ] !== null
                                            ? NumberHelper.formatThousandSeparator(
                                                  Math.abs(
                                                      ratioItem
                                                          ?.quarterChange?.[
                                                          item as keyof LoanApplicationQuarterFigures
                                                      ] as number,
                                                  ),
                                                  2,
                                              )
                                            : LONG_DASH}
                                    </ColoredTextContainer>
                                )}
                            </StyledDataItem>
                        ))}
                    </StyledDataRow>
                </StyledDataContainer>
            </>
        );
    },
);

const StyledDataContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledTitleRow = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    margin: 21px 0 6px;
`;

const StyledDataRow = styled.div`
    width: 100%;
    gap: 2.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledDataItem = styled.div`
    width: 30%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
`;

const StyledTitleCol = styled.div`
    width: 32%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const ColoredTextContainer = styled.span<{ value: number }>`
    color: ${(props) => (props.value > 0 ? '#4B7902' : '#A30014')};
    &::after ${(props) => (props.value > 0 ? '' : '{ content: ")" }')}
    &::before ${(props) => (props.value > 0 ? '' : '{ content: "(" }')}
`;

const TextContainerWithBraces = styled.span<{ value: number }>`
    &::after ${(props) =>
        props.value > 0 || props.value === null || props.value === 0
            ? ''
            : '{ content: ")" }'}
    &::before ${(props) =>
        props.value > 0 || props.value === null || props.value === 0
            ? ''
            : '{ content: "(" }'}
`;
