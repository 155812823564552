import { Form, FormItemProps } from 'antd';
import { PasswordInput } from 'src/components/PasswordInput/PasswordInput';

export type PasswordFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    readOnly?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    maxLength?: number;
};

export const PasswordField = ({
    name,
    label,
    readOnly,
    disabled,
    maxLength,
    ...props
}: PasswordFieldProps) => {
    return (
        <Form.Item {...props} name={name} label={label}>
            <PasswordInput
                readOnly={readOnly}
                disabled={disabled}
                maxLength={maxLength}
            />
        </Form.Item>
    );
};
