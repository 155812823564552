/* eslint-disable sonarjs/no-identical-functions */
import { Checkbox, Col, Form, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { EntityActions } from 'src/components/EntityActions/EntityActions';
import { AuthorityItem, StartPage } from 'src/generated-api-client';
import { useRoleId } from 'src/hooks/useRoleId';
import { AuthoritiesStore } from 'src/stores/AuthoritiesStore/AuthoritiesStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';
import { getStartPage } from 'src/utils/StartPageHelper';
import styled from 'styled-components';

import { EditRoleModal } from './Dialogs/EditRoleDialog';

const store = RolesStore;
const authorities = AuthoritiesStore;

export const RolesEntityPresentation = observer(() => {
    const { t } = useTranslation();
    const { roleId } = useRoleId();
    const { id } = ProfileStore.profile?.role || {};

    useEffect(() => {
        if (!roleId) {
            return;
        }
        store.loadItem(roleId);
    }, [roleId]);

    useEffect(() => {
        authorities.loadList();
    }, []);

    const role = store.currentItem?.data;
    const isOwnRole = id && String(id) !== roleId;

    const entityActions = [
        {
            key: 'editRole',
            label: t('Actions.Edit', 'Edit'),
            onClick: () => {
                EditRoleModal.render();
            },
        },
    ];

    return (
        <MainContainer>
            <ColoredHeader align="middle" justify="space-between">
                <Col flex="auto">
                    <TextBold>
                        {`#${role?.id}`} {role?.name}
                    </TextBold>
                </Col>
                <Col>
                    {isOwnRole && <EntityActions items={entityActions} />}
                </Col>
            </ColoredHeader>
            <Brief>
                <Row gutter={16}>
                    <Col span={24}>
                        <DataView
                            value={role?.description}
                            label={t('Page.Roles.Description', 'DESCRIPTION')}
                        />
                    </Col>
                    <Col span={24}>
                        <DataView
                            value={
                                getStartPage(role?.startPage as StartPage).name
                            }
                            format="link-internal"
                            formatterProps={{
                                to: getStartPage(role?.startPage as StartPage)
                                    .route,
                            }}
                            label={t('Page.Roles.StartPage', 'STARTING PAGE')}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={16}>
                        <DataView
                            label={t('Page.Roles.Authorities', 'AUTHORITIES')}
                            value={
                                <Row gutter={24}>
                                    {authorities.list.map(
                                        (auth: AuthorityItem) => (
                                            <Col span={8} key={auth.value}>
                                                <Form.Item
                                                    name={auth.value}
                                                    label={auth.description}
                                                >
                                                    <Checkbox
                                                        checked={role?.authorities?.includes(
                                                            auth.value as string,
                                                        )}
                                                        disabled
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ),
                                    )}
                                </Row>
                            }
                        />
                    </Col>
                </Row>
            </Brief>
        </MainContainer>
    );
});

const MainContainer = styled.div`
    margin: 60px 30px 0;
`;

const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    height: 120px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
`;

const TextBold = styled.span`
    font-weight: 600;
    font-size: 29px;
`;
