import { Button, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { CreditBureauCheckLogStatusEnum } from 'src/generated-api-client';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { CustomersStore } from 'src/stores/CustomersStore/CustomersStore';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

const store = CustomersStore;

const StyledTable = styled(Table)`
    margin-top: 10px;
    .ant-table-thead {
        .ant-table-cell {
            background: transparent;
            font-weight: 600;
        }
        .ant-table-cell::before {
            display: none;
        }
    }
    .ant-table-tbody {
        .ant-table-cell {
            color: #000000;
            font-size: 15px;
        }
    }
    .ant-table-summary {
        background: #bfbfbf5c;
    }
`;

const LONG_DASH = '—';

const StyledSaveButton = styled(Button)`
    width: 79px;
    height: 32px;
    border-radius: 20px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

export const CBLogsTable = observer(() => {
    const { customerId } = useCustomerId();

    const { t } = useTranslation();

    const columns: {
        title: string;
        dataIndex: string;
        key: string;
        render?: any;
    }[] = [
        {
            title: t('Page.Customer.CBLogs.CreatedAt', 'Created at'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date: any) => DateHelper.formatDateTimeSeconds(date),
        },
        {
            title: t(
                'Page.Customer.CBLogs.LastRequestedAt',
                'Last Requested at',
            ),
            dataIndex: 'requestedAt',
            key: 'requestedAt',
            render: (date: any) => DateHelper.formatDateTimeSeconds(date),
        },
        {
            title: t('Page.Customer.CBLogs.Step', 'Step'),
            dataIndex: 'step',
            key: 'step',
            render: (text: any) => (
                <Trans i18nKey={`Customer.CB.Logs.Step.${text}`} />
            ),
        },
        {
            title: t('Page.Customer.CBLogs.Status', 'Status'),
            dataIndex: 'status',
            key: 'status',
            render: (text: any) => (
                <Trans i18nKey={`Customer.CB.Logs.Status.${text}`} />
            ),
        },
        {
            title: t('Page.Customer.CBLogs.MessageText', 'Message text'),
            dataIndex: 'errorMessage',
            key: 'errorMessage',
            render: (text: any) => (
                <DataView value={text} format="string" hideLabel />
            ),
        },
        {
            title: t('Page.Customer.CBLogs.Actions', 'Actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, row: any) =>
                row.status === CreditBureauCheckLogStatusEnum.FAILED ? (
                    <StyledSaveButton
                        loading={store.customerCBRestartAction.isLoading}
                        onClick={() =>
                            store.customerCBRestart(Number(customerId), row.id)
                        }
                    >
                        <Trans i18nKey="Action.Restart" default="Restart" />
                    </StyledSaveButton>
                ) : (
                    LONG_DASH
                ),
        },
    ];

    return (
        <StyledTable
            loading={store.getCustomerCBLogsAction.isLoading}
            pagination={false}
            columns={columns}
            dataSource={store.customerCBLogs}
        />
    );
});
