import { Form, FormItemProps, Input } from 'antd';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

export type TextAreaProps = FormItemProps & {
    label: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    readOnly?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    maxLength?: number;
    // eslint-disable-next-line react/require-default-props
    values?: string;
    // eslint-disable-next-line react/require-default-props
    rows?: number;
    // eslint-disable-next-line react/require-default-props
    onChange?: (_value: ChangeEvent<HTMLTextAreaElement>) => void;
    // eslint-disable-next-line react/require-default-props
    handleBlur?: () => void;
};

export const TextArea = ({
    name,
    label,
    readOnly,
    disabled,
    maxLength,
    values,
    rows,
    onChange,
    handleBlur,
    ...props
}: TextAreaProps) => {
    return (
        <Form.Item {...props} name={name} label={label}>
            <StyledInput
                rows={rows}
                onBlur={handleBlur}
                maxLength={maxLength}
                readOnly={readOnly}
                disabled={disabled}
                value={values}
                size="large"
                onChange={onChange}
            />
        </Form.Item>
    );
};

const StyledInput = styled(Input.TextArea)`
    border-radius: 6px;
    border-color: #000000;
`;
