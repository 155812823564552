import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Brief } from 'src/components/Brief/Brief';
import { DataView } from 'src/components/DataView/DataView';
import { EntityActions } from 'src/components/EntityActions/EntityActions';
import { UserStatus } from 'src/generated-api-client';
import { useUserId } from 'src/hooks/useUserId';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';
import styled from 'styled-components';

import { ActivateUserModal } from './Dialogs/ActivateUserDialog';
import { ChangePasswordModal } from './Dialogs/ChangeUserPasswordDialog';
import { DeactivateUserModal } from './Dialogs/DeactivateUserDialog';
import { EditUserModal } from './Dialogs/EditUserDialog';

const store = UsersStore;

export const UserEntityPresentation = observer(() => {
    const { t } = useTranslation();
    const { userId } = useUserId();
    const currentId = ProfileStore.profile?.id;
    const user = store.currentItem?.data;

    useEffect(() => {
        if (!userId) {
            return;
        }
        store.loadItem(userId);
    }, [userId]);

    const isCurrentUserCard = currentId === user?.id;

    const entityActions = [
        {
            key: 'editUser',
            label: t('Actions.Edit', 'Edit'),
            onClick: () => {
                EditUserModal.render();
            },
        },
        {
            key: 'changePasswordUser',
            label: t('Actions.ChangePassword', 'Change password'),
            onClick: () => {
                ChangePasswordModal.render();
            },
        },
    ];

    const activateDeactivateActions = isCurrentUserCard
        ? []
        : store.currentItem?.data?.status === UserStatus.ACTIVE
        ? [
              {
                  key: 'deactivateUser',
                  label: t('Actions.Deactivate', 'Deactivate'),
                  onClick: () => {
                      DeactivateUserModal.render();
                  },
              },
          ]
        : [
              {
                  key: 'activateUser',
                  label: t('Actions.Activate', 'Activate'),
                  onClick: () => {
                      ActivateUserModal.render();
                  },
              },
          ];

    return (
        <MainContainer>
            <ColoredHeader align="middle" justify="space-between">
                <Col flex="auto">
                    <TextBold>
                        {`#${user?.id}`} {user?.fullName}
                    </TextBold>
                </Col>
                <Col>
                    {user?.status && (
                        <StyledStatusWrapper status={user?.status}>
                            {user?.status.toLowerCase()}
                        </StyledStatusWrapper>
                    )}
                </Col>
                <Col>
                    <EntityActions
                        items={[...entityActions, ...activateDeactivateActions]}
                    />
                </Col>
            </ColoredHeader>
            <Brief>
                <Row gutter={16}>
                    <Col span={8}>
                        <DataView
                            value={user}
                            format="roles"
                            label={t('Page.UserDetails.Role')}
                        />
                    </Col>
                    <Col span={8}>
                        <DataView
                            value={user?.email}
                            label={t('Page.UserDetails.Email')}
                        />
                    </Col>
                    <Col span={8}>
                        <DataView
                            value={user?.phone}
                            label={t('Page.UserDetails.Phone')}
                        />
                    </Col>
                    <Col span={8}>
                        <DataView
                            format="string"
                            value={user?.login}
                            label={t('Page.UserDetails.Login')}
                        />
                    </Col>
                </Row>
            </Brief>
        </MainContainer>
    );
});

const MainContainer = styled.div`
    margin: 60px 30px 0;
`;

const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    height: 120px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
`;

const TextBold = styled.span`
    font-weight: 600;
    font-size: 29px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) =>
        props.status === UserStatus.ACTIVE ? '#ACF392' : '#EB7382'};
    border-radius: 5px;
    font-size: 15px;
    height: 35px;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    text-decoration: capitalize;
    color: #000000;
`;
