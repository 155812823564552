import { Form, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal/Modal';
import {
    ContentType,
    NotificationStatus,
    UpdateNotificationForm,
} from 'src/generated-api-client';
import { NotificationDialogForm } from 'src/pages/NotificationsPage/Dialogs/NotificationDialogForm';
import { NotificationsStore } from 'src/stores/NotificationsStore/NotificationsStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';

type EditNotificationDialogProps = {
    notificationId: string;
};

export const EditNotificationDialog = observer(
    ({ notificationId, ...props }: EditNotificationDialogProps) => {
        const { t } = useTranslation();
        const [form] = Form.useForm();

        const onFinish = async (values: UpdateNotificationForm) => {
            await NotificationsStore.updateItem(notificationId, {
                ...values,
                id: Number(notificationId),
                status: NotificationsStore.currentItem?.data
                    ?.status as NotificationStatus,
                contentType: values?.contentType || ContentType.PLAINTEXT,
                content: values.content.trim(),
                title: values.title.trim(),
            });
            if (!NotificationsStore.updateItemLoader.hasError) {
                notification.success({
                    message: t('Message.Success'),
                });
                await NotificationsStore.loadList();
                await NotificationsStore.loadItem(notificationId);
            }
        };

        return (
            <Modal
                {...props}
                closable={false}
                width={670}
                form={form}
                title={t('Dialog.EditNotification.Title')}
                storeLoader={NotificationsStore.updateItemLoader}
                subtitle={t('Dialog.EditNotification.Subtitle')}
                isLoading={NotificationsStore.updateItemLoader.isLoading}
                initialValues={NotificationsStore.currentItem?.data}
                onFinish={onFinish}
            >
                <NotificationDialogForm form={form} />
            </Modal>
        );
    },
);

export const EditNotificationDialogModal = addModalRenderer(
    EditNotificationDialog,
);
