import { action } from 'mobx';
import { AttachmentsFormat } from 'src/components/Form/ValidationRules';
import {
    ApiResponseBodyCsvLogDTO,
    DocumentDTOFileTypeEnum,
    EntityModelCsvLogDTO,
    EntityModelCsvLogDTOStatusEnum,
} from 'src/generated-api-client';
import { importDataApi } from 'src/services/apiServices';
import { DateHelper } from 'src/utils/DateHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

import { UploadFilesStatusStore } from '../UploadFilesStatusStore/UploadFilesStatusStore';

export type ImportDataFilters = {
    id?: string;
    user?: string;
    statuses?: EntityModelCsvLogDTOStatusEnum[];
    createdAtFrom?: string;
    createdAtTo?: string;
};

export class ImportDataStoreClass extends BasicStore<
    EntityModelCsvLogDTO,
    ImportDataFilters,
    undefined,
    undefined,
    ApiResponseBodyCsvLogDTO
> {
    api: BasicStoreApi<
        EntityModelCsvLogDTO,
        undefined,
        undefined,
        ApiResponseBodyCsvLogDTO
    > = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                importDataApi.entityList10(
                    this.filterCriteria.filter.id,
                    this.filterCriteria.filter.user,
                    this.filterCriteria.filter.statuses,
                    this.filterCriteria.filter.createdAtFrom &&
                        DateHelper.format(
                            this.filterCriteria.filter.createdAtFrom,
                        ),
                    this.filterCriteria.filter.createdAtTo &&
                        DateHelper.format(
                            this.filterCriteria.filter.createdAtTo,
                        ),
                    this.pager?.page,
                    this.pager?.size,
                ),
            );

            this.pager?.setTotal(result.page?.totalElements as number);

            return result.content || [];
        },

        loadItem: (dataId: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                importDataApi.entityView10(Number(dataId)),
            );
        },
    };

    downloadExampleAction = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(
            importDataApi.downloadCsvExample('PAYMENTS', {
                responseType: 'arraybuffer',
            }),
        ),
    );

    downloadCSVAction = new AsyncOperationWithStatus(
        (dataId: number, documentId: number) =>
            RequestHelper.unwrapFromAxiosPromise(
                importDataApi.downloadCsvFile(dataId, documentId, {
                    responseType: 'arraybuffer',
                }),
            ),
    );

    importCSVAction = new AsyncOperationWithStatus((file: any) =>
        RequestHelper.unwrapFromAxiosPromise(
            importDataApi.importCsv('PAYMENTS', file, {
                onUploadProgress: (progressEvent: any) => {
                    UploadFilesStatusStore.onUploadProgressAction(
                        progressEvent,
                        'PAYMENTS',
                    );
                },
            }),
        ),
    );

    @action async downloadCSV(
        dataId: string,
        documentId: number,
        type: DocumentDTOFileTypeEnum,
        fileName: string,
    ) {
        const res = await this.downloadCSVAction.call(
            Number(dataId),
            Number(documentId),
        );
        const file = window.URL.createObjectURL(
            new Blob([res], {
                type: AttachmentsFormat[
                    type.toLocaleLowerCase() as keyof typeof AttachmentsFormat
                ],
            }),
        );
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    @action async downloadCSVExample() {
        const res = (await this.downloadExampleAction.call()) as any;
        const file = window.URL.createObjectURL(
            new Blob([res], {
                type: AttachmentsFormat.csv,
            }),
        );
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', 'form');
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    @action async importCSV(file: any) {
        await this.importCSVAction.call(file);
    }

    filterCriteria = new FilterCriteria<ImportDataFilters>();

    pager?: Pager | undefined = new Pager();
}

export const ImportDataStore = new ImportDataStoreClass();
