/* eslint-disable max-lines */
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import styled from 'styled-components';

const store = LoansStore;

export const LoanBalances = observer(() => {
    const { t } = useTranslation();

    const currentLoan = store.currentItem?.data;

    return (
        <>
            <Row>
                <BlockTitle>
                    {t('Page.Loans.Balances.Title', 'Loan balances')}
                </BlockTitle>
            </Row>
            <Row gutter={32}>
                <Col span={32}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.principalDue}
                                label={t(
                                    'Page.Loans.Balances.PrincipalDebt',
                                    'Principal debt',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.accruedInterestDue}
                                label={t(
                                    'Page.Loans.Balances.InterestAccrued',
                                    'Interest accrued',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.initiationFeeDue}
                                label={t(
                                    'Page.Loans.Balances.InitiationFee',
                                    'Initiation fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.vatInitiationFeeDue}
                                label={t(
                                    'Page.Loans.Balances.VatInitiationFee',
                                    'VAT initiation fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.monthlyServiceFeeDue}
                                label={t(
                                    'Page.Loans.Balances.MonthlyServiceFee',
                                    'Monthly service fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.vatMonthlyServiceFeeDue}
                                label={t(
                                    'Page.Loans.Balances.VatMonthlyServiceFee',
                                    'VAT monthly service fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.insuranceFeeDue}
                                label={t(
                                    'Page.Loans.Balances.InsuranceFee',
                                    'Insurance fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.vatInsuranceFeeDue}
                                label={t(
                                    'Page.Loans.Balances.VatInsuranceFee',
                                    'VAT Insurance fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.balance}
                                label={t(
                                    'Page.Loans.Balances.NonAllocatedFunds',
                                    'Non-allocated funds',
                                )}
                            />
                        </Col>
                    </Row>
                </Col>
                <StyledDivider span={32} />
                <Col span={32}>
                    <Row gutter={32}>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.principalOverdue}
                                label={t(
                                    'Page.Loans.Balances.OverduePrincipalDebt',
                                    'Overdue principal debt',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.accruedInterestOverdue}
                                label={t(
                                    'Page.Loans.Balances.OverdueInterestAccrued',
                                    'Overdue interest accrued',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.initiationFeeOverdue}
                                label={t(
                                    'Page.Loans.Balances.OverdueInitiationFee',
                                    'Overdue initiation fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.vatInitiationFeeOverdue}
                                label={t(
                                    'Page.Loans.Balances.VatOverduePrincipalDebt',
                                    'Overdue VAT initiation fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.monthlyServiceFeeOverdue}
                                label={t(
                                    'Page.Loans.Balances.OverdueMonthlyServiceFee',
                                    'Overdue monthly service fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.vatMonthlyServiceFeeOverdue}
                                label={t(
                                    'Page.Loans.Balances.OverdueVatMonthlyServiceFee',
                                    'Overdue VAT monthly service fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.insuranceFeeOverdue}
                                label={t(
                                    'Page.Loans.Balances.OverdueInsuranceFee',
                                    'Overdue insurance fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.vatInsuranceFeeOverdue}
                                label={t(
                                    'Page.Loans.Balances.OverdueVatInsuranceFee',
                                    'Overdue VAT Insurance fee',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix="R"
                                value={currentLoan?.penaltyInterest}
                                label={t(
                                    'Page.Loans.Balances.PenaltyInterest',
                                    'Penalty interest',
                                )}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
});

const StyledDivider = styled(Col)`
    width: 100%;
    margin-bottom: 30px;
    border-bottom: 1px solid #000000;
`;
