import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    CreateBackofficeRoleForm,
    EntityModelBackofficeRoleDTO,
    UpdateBackofficeRoleForm,
} from 'src/generated-api-client';
import { rolesApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type RolesFilters = {
    name?: string;
    id?: string;
    count?: number;
};

export class RolesStoreClass extends BasicStore<
    EntityModelBackofficeRoleDTO,
    RolesFilters,
    UpdateBackofficeRoleForm,
    CreateBackofficeRoleForm
> {
    api: BasicStoreApi<
        EntityModelBackofficeRoleDTO,
        UpdateBackofficeRoleForm,
        CreateBackofficeRoleForm
    > = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                rolesApi.entityList3(
                    this.filterCriteria.filter.id,
                    this.filterCriteria.filter.name,
                    this.pager?.page,
                    this.filterCriteria.filter.count || this.pager?.size,
                    undefined,
                    undefined,
                    ['id'],
                    'DESC',
                ),
            );

            this.pager?.setTotal(result.page?.totalElements as number);

            return result.content || [];
        },

        loadItem: (roleId: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                rolesApi.entityView3(Number(roleId)),
            );
        },

        createItem: async (data) => {
            const res = await RequestHelper.unwrapFromAxiosPromise(
                rolesApi.createRole(data),
            );

            Router.navigate(`${RoutePaths.roles}/${res?.data?.id}`);
        },

        updateItem: async (id, data) => {
            const roleId = Number(id);
            await RequestHelper.unwrapFromAxiosPromise(
                rolesApi.updateRole({ ...data, id: roleId }),
            );
        },
    };

    filterCriteria = new FilterCriteria<RolesFilters>();

    pager?: Pager | undefined = new Pager();
}

export const RolesStore = new RolesStoreClass();
