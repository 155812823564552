import { Col, Row, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { MasterDetailsAsideItem } from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { EntityModelNotificationDTO } from 'src/generated-api-client';
import styled from 'styled-components';

type CustomerListItemProps = { item: EntityModelNotificationDTO };

export const NotificationsListItem = observer(
    ({ item }: CustomerListItemProps) => {
        const { t } = useTranslation();

        return (
            <MasterDetailsAsideItem
                to={EntityLinks.Notifications.itemDetails(item.id)}
            >
                <StyledHeaderRow gutter={5} wrap={false}>
                    <Col flex="auto">
                        <BoldText>
                            <NameContainer>#{item.id}</NameContainer>
                        </BoldText>
                    </Col>
                    <Col>
                        {item.title && (
                            <Tooltip title={item.title}>
                                <StyledEllipsisContainer>
                                    <NameContainer>{item.title}</NameContainer>
                                </StyledEllipsisContainer>
                            </Tooltip>
                        )}
                    </Col>
                </StyledHeaderRow>
                <Row wrap={false}>
                    <Col flex="auto">
                        <StyledStatusWrapper status={item.status}>
                            {item.status &&
                                t(`Notification.Status.${item.status}`)}
                        </StyledStatusWrapper>
                    </Col>
                    <Col>
                        <SecondaryText>
                            {item.type && t(`Notification.Type.${item.type}`)}
                        </SecondaryText>
                    </Col>
                </Row>
            </MasterDetailsAsideItem>
        );
    },
);

export const NotificationMapColors = {
    ACTIVE: '#ACF392',
    INACTIVE: '#EB7382',
};

const StyledEllipsisContainer = styled(EllipsisContainer)`
    max-width: 200px;
`;

const StyledHeaderRow = styled(Row)`
    margin-bottom: 5px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    max-width: 160px;
    padding: 5px 19px;
    background: ${(props) =>
        NotificationMapColors[
            props?.status as keyof typeof NotificationMapColors
        ]};
    border-radius: 22px;
    font-size: 13px;
    height: 22px;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    color: #000000;
`;

const SecondaryText = styled.span`
    font-size: 13px;
    line-height: 150%;
    color: #000000;
`;

const BoldText = styled.span`
    font-weight: 600;
`;

const NameContainer = styled.span`
    font-size: 20px;
`;
