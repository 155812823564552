/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { EntityActions } from 'src/components/EntityActions/EntityActions';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import {
    EntityModelLoanViewDTOStatusEnum,
    LoanApplicationType,
    LoanScheduleDTO,
} from 'src/generated-api-client';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { loanMapColors } from 'src/utils/common-constants';
import { DateHelper } from 'src/utils/DateHelper';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

import { useLoanId } from '../../../hooks/useLoanId';
import { UserPermission } from '../../../stores/AppUserStore/UserPermissions';
import { ProfileStore } from '../../../stores/ProfileStore/ProfileStore';
import { RepaymentRegistrationModal } from '../Dialogs/RepaymentRegistration';
import { WriteOffModal } from '../Dialogs/WriteOffDialog';
import { HeaderTable } from './HeaderTable';

const store = LoansStore;

const repaymentActionStatusAcceptence = [
    EntityModelLoanViewDTOStatusEnum.PAIDUP,
    EntityModelLoanViewDTOStatusEnum.WRITTENOFF,
];

const { Text } = Typography;

// eslint-disable-next-line complexity
export const LoanEntityHeader = observer(() => {
    const { t } = useTranslation();
    const { loanId } = useLoanId();

    const currentLoan = store?.currentItem?.data;

    const schedules = currentLoan?.schedules?.sort(
        (a, b) =>
            moment(a.paymentDate).valueOf() - moment(b.paymentDate).valueOf(),
    );

    const repaymentAccessCriteria = !repaymentActionStatusAcceptence.includes(
        currentLoan?.status as EntityModelLoanViewDTOStatusEnum,
    );

    const isManageLoansPermission = ProfileStore.profileAuthorities?.includes(
        UserPermission.ManageLoans,
    );

    const entityActions: any = [
        repaymentAccessCriteria && {
            key: 'registerManualRepayment',
            label: t('Actions.Deactijvate', 'Register manual repayment'),
            onClick: () => {
                RepaymentRegistrationModal.render({
                    currentLoan,
                });
            },
        },
        repaymentAccessCriteria && {
            key: 'wrightOff',
            label: t('Actions.WriteOffLoan', 'Write-off loan'),
            onClick: () => {
                WriteOffModal.render({ currentLoan });
            },
        },
        isManageLoansPermission && {
            key: 'recalculate-loan',
            label: t('Actions.RecalculateLoan', 'Recalculate the loan'),
            onClick: async () => {
                await store.recalculateLoan(loanId);
            },
        },
    ];

    const openCustomer = () => {
        Router.navigate(
            EntityLinks.customers.itemDetails(currentLoan?.customerId),
        );
    };

    return (
        <ColoredHeader align="middle" justify="space-between" gutter={24}>
            <Col span={24}>
                <Row>
                    <Col flex="auto">
                        <TextBold onClick={openCustomer}>
                            {`#${currentLoan?.id}`} {currentLoan?.legalName}
                        </TextBold>
                    </Col>
                    <Col>
                        {currentLoan?.status && (
                            <StyledStatusWrapper status={currentLoan?.status}>
                                {t(
                                    `Loans.EntityModelLoanViewDTOStatusEnum.Type.${currentLoan.status}`,
                                )}
                            </StyledStatusWrapper>
                        )}
                    </Col>
                    {(isManageLoansPermission || repaymentAccessCriteria) && (
                        <Col>
                            <EntityActions items={entityActions} />
                        </Col>
                    )}
                </Row>
            </Col>
            <Col span={24}>
                <Row>
                    <Col>
                        <Text strong>
                            {t(`Entity.Application.Type.${currentLoan?.type}`)}
                        </Text>{' '}
                        /
                        <Text strong>
                            {t(
                                `Loan.Application.Disclosure.${currentLoan?.disclosure}`,
                            )}
                        </Text>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <StyledFlexRow>
                    {currentLoan?.financedItemTotalAmount !== null &&
                        currentLoan?.type ===
                            LoanApplicationType.OUTANDOUTINVOICEFINANCE && (
                            <Col>
                                <Text strong>
                                    Expected payment amount: R{' '}
                                    {NumberHelper.formatThousandSeparator(
                                        currentLoan?.financedItemTotalAmount ||
                                            0,
                                        2,
                                    )}
                                </Text>
                            </Col>
                        )}
                    <Col>
                        {currentLoan?.latestPaymentDate &&
                            currentLoan?.type ===
                                LoanApplicationType.OUTANDOUTINVOICEFINANCE && (
                                <Text strong>
                                    Expected payment date:{' '}
                                    {DateHelper.format(
                                        currentLoan?.latestPaymentDate,
                                    )}
                                </Text>
                            )}
                    </Col>
                </StyledFlexRow>
            </Col>
            <Col span={24}>
                <StyledFlexRow>
                    {currentLoan?.initialAmount !== null &&
                        currentLoan?.type ===
                            LoanApplicationType.OUTANDOUTINVOICEFINANCE && (
                            <Col>
                                <Text strong>
                                    Financins amount: R{' '}
                                    {NumberHelper.formatThousandSeparator(
                                        currentLoan?.initialAmount || 0,
                                        2,
                                    )}
                                </Text>
                            </Col>
                        )}
                    {currentLoan?.type ===
                    LoanApplicationType.OUTANDOUTINVOICEFINANCE ? (
                        <Col>
                            <Text strong>
                                Total exposure: R{' '}
                                {NumberHelper.formatThousandSeparator(
                                    currentLoan?.totalExposure || 0,
                                    2,
                                )}
                            </Text>
                        </Col>
                    ) : null}
                    {currentLoan?.type ===
                    LoanApplicationType.OUTANDOUTINVOICEFINANCE ? (
                        <Col>
                            <Text strong>
                                Returing amount: R{' '}
                                {NumberHelper.formatThousandSeparator(
                                    (currentLoan?.balance as number) || 0,
                                    2,
                                )}
                            </Text>
                        </Col>
                    ) : null}
                    {currentLoan?.initialAmount !== null &&
                    currentLoan?.type !==
                        LoanApplicationType.OUTANDOUTINVOICEFINANCE ? (
                        <Col>
                            <Text strong>
                                Initial amount: R{' '}
                                {NumberHelper.formatThousandSeparator(
                                    currentLoan?.initialAmount || 0,
                                    2,
                                )}
                            </Text>
                        </Col>
                    ) : null}
                    {currentLoan?.type !==
                    LoanApplicationType.OUTANDOUTINVOICEFINANCE ? (
                        <Col>
                            <Text strong>
                                Exposure: R{' '}
                                {NumberHelper.formatThousandSeparator(
                                    currentLoan?.totalExposure || 0,
                                    2,
                                )}
                            </Text>
                        </Col>
                    ) : null}
                    {currentLoan?.type !==
                        LoanApplicationType.OUTANDOUTINVOICEFINANCE && (
                        <Col>
                            <Text strong>
                                Penalty interest: R{' '}
                                {NumberHelper.formatThousandSeparator(
                                    currentLoan?.penaltyInterest as number,
                                    2,
                                )}
                            </Text>
                        </Col>
                    )}
                </StyledFlexRow>
            </Col>
            {currentLoan?.type !==
                LoanApplicationType.OUTANDOUTINVOICEFINANCE && (
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <HeaderTable
                                dataSource={schedules as LoanScheduleDTO[]}
                            />
                        </Col>
                    </Row>
                </Col>
            )}
        </ColoredHeader>
    );
});
const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
    gap: 1rem;
    .ant-table {
        background: #f5f6f7 !important;
        font-size: 12px !important;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #000000;
        font-size: 11px !important;
    }
`;

const TextBold = styled.span`
    font-weight: 600;
    font-size: 29px;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) =>
        loanMapColors[props?.status as keyof typeof loanMapColors]};
    border-radius: 5px;
    font-size: 15px;
    height: 35px;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    text-decoration: capitalize;
    color: #000000;
`;

const StyledFlexRow = styled(Row)`
    gap: 1rem;
`;
