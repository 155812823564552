import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { LoanApplicationListDTO, LoanViewDTO } from 'src/generated-api-client';
import { customersApi, loanApplicationsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class CustomerApplicationsStoreClass extends BasicStore<LoanApplicationListDTO> {
    @observable private customerLoans?: LoanViewDTO[];

    api: BasicStoreApi<LoanApplicationListDTO> = {
        loadList: async (customerId: number) => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.getCustomerApplications(customerId),
            );

            return result.data || [];
        },
    };

    @action getCustomerLoans(id: string | number) {
        return this.customerLoansLoader.call(Number(id));
    }

    @computed get customerLoansSortedByDate() {
        return this.customerLoans?.sort((a, b) => {
            if (
                moment(b.startDate).valueOf() ===
                    moment(a.startDate).valueOf() &&
                b?.id &&
                a?.id
            ) {
                return b.id - a.id;
            }

            return (
                moment(b.startDate).valueOf() - moment(a.startDate).valueOf()
            );
        });
    }

    customerLoansLoader = new AsyncOperationWithStatus(async (id: number) => {
        const result = await RequestHelper.unwrapFromAxiosPromise(
            customersApi.getLoans(id),
        );

        this.customerLoans = result.data;
    });
}

export const CustomerApplicationsStore = new CustomerApplicationsStoreClass();
