import { useParams } from 'react-router-dom';

export function useStatisticId() {
    const params = useParams<{ statisticId: string }>();

    return {
        statisticId: params?.statisticId,
        hasstatisticId: Boolean(params?.statisticId),
    };
}
