import { Layout, Typography } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import { observer } from 'mobx-react-lite';
import { Spinner } from 'src/components/Spinner/Spinner';
import styled from 'styled-components';

export type BasePageProps = Omit<React.HTMLAttributes<any>, 'title'> & {
    title?: React.ReactNode;
    header?: React.ReactNode;
    isLoading?: boolean;
    isUpdating?: boolean;
    showOnlyLoading?: boolean;
};
export const BasePage: React.FC<BasePageProps> = observer(
    ({ children, isLoading, title, header, showOnlyLoading, ...props }) => {
        if (showOnlyLoading && isLoading) {
            return (
                <StyledContent {...props}>
                    <StyledSpinner />
                </StyledContent>
            );
        }

        return (
            <StyledContent {...props}>
                {isLoading && <StyledSpinner />}
                {title && <StyledPageTitle>{title}</StyledPageTitle>}
                {header && <StyledPageHeader>{header}</StyledPageHeader>}
                {children}
            </StyledContent>
        );
    },
);

export const StyledContent = styled<React.FC<BasicProps>>(Layout.Content)`
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    min-height: 200px;
    padding: 20px;
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 99;
    background-color: white;
`;

const StyledPageTitle = styled(Typography.Title)`
    font-size: 1rem;
    line-height: 105%;
    color: #0d0f1c;

    &.ant-typography {
        margin-bottom: 20px;
    }
`;

const StyledPageHeader = styled.div`
    position: relative;
    margin-bottom: 20px;
`;
