export enum UserPermission {
    ManageUsers = 'user:manage',
    ManageRole = 'role:manage',
    ManageLoanProduct = 'loan-product:manage',
    ManageNotifications = 'notification:manage',
    LoanApplicationReviewer = 'loan-application:reviewer',
    CreditCommittee = 'loan-application:credit-committee',
    ManageSME = 'customer:manage',
    ManageDocumentTemplates = 'document:manage-template',
    ManageLoans = 'loan:manage',
    StatisticObserve = 'statistic:observe',
    ImportData = 'import-data:manage',
    ManageSystemOperations = 'system-operation:manage',
}
