import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { useOpenFirstListItem } from 'src/hooks/useOpenFirstListItem';
import { CustomersStore } from 'src/stores/CustomersStore/CustomersStore';

import { CustomersEntityPresentation } from './CustomersEntityPresentation';
import { CustomersHeaderPanel } from './CustomersHeaderPanel';
import { CustomersListItem } from './CustomersListItem';

const store = CustomersStore;

export const CustomersPage = observer(() => {
    useOpenFirstListItem(store);

    useEffect(() => {
        return () => {
            store.listLoader.reset();
            store.filterCriteria?.resetFilter();
            store.pager?.reset();
        };
    }, []);

    return (
        <MasterDetailsPage store={store} asidePanel={<UsersAsidePanel />}>
            <Switch>
                <Route
                    path={RoutePaths.customersDetails}
                    exact
                    render={() => <CustomersEntityPresentation />}
                />
            </Switch>
        </MasterDetailsPage>
    );
});

const UsersAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: any) => {
                return (
                    <List.Item key={item.id}>
                        <CustomersListItem item={item} />
                    </List.Item>
                );
            }}
            header={<CustomersHeaderPanel />}
        />
    );
});
