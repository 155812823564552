import { Col, notification, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea } from 'src/components/Form/TextArea';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { LoanApplicationViewDTO } from 'src/generated-api-client';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';

type RequestAdditionalInformationProps = {
    currentApplication: LoanApplicationViewDTO;
};

export const RequestAdditionalInformation = observer(
    ({ currentApplication, ...props }: RequestAdditionalInformationProps) => {
        const { t } = useTranslation();

        return (
            <Modal
                {...props}
                title={t('Application.Action.RequestAdditionalInformation')}
                subtitle={t(
                    'Application.Modal.Subtitle.RequestAdditionalInformation',
                )}
                isLoading={
                    ApplicationsStore.requestAdditionalInformationAction
                        .isLoading
                }
                onFinish={async (values) => {
                    await ApplicationsStore.requestAdditionalInformation(
                        currentApplication.id as number,
                        values,
                    );

                    if (
                        !ApplicationsStore.requestAdditionalInformationAction
                            .hasError
                    ) {
                        ApplicationsStore.loadList();
                        ApplicationsStore.loadItem(
                            currentApplication.id?.toString(),
                        );
                        notification.success({
                            message: t('Message.Success'),
                        });
                    }
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <TextArea
                            rows={8}
                            name="requestNote"
                            label=""
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxCommentLength,
                            ]}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const RequestAdditionalInformationModal = addModalRenderer(
    RequestAdditionalInformation,
);
