import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { FilesListComponent } from 'src/components/FilesListComponent/FilesListComponent';
import { Spinner } from 'src/components/Spinner/Spinner';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { CustomerDocumnetsStore } from 'src/stores/CustomerDocumnetsStore/CustomerDocumnetsStore';
import styled from 'styled-components';

const store = CustomerDocumnetsStore;

export const CompanyDocuments = observer(() => {
    const { customerId } = useCustomerId();
    const { t } = useTranslation();

    const documentsList = [
        {
            title: t('Document.DTODocument.Type.CUSTOMER_CIPC'),
            files: store.customerCIPCDocs,
        },
        {
            title: t('Document.DTODocument.Type.CUSTOMER_DIRECTOR_IDS'),
            files: store.customerDirectordIdsDocs,
        },
        {
            title: t('Document.DTODocument.Type.CUSTOMER_PROOF_OF_ADDRESS'),
            files: store.customerProofOfAddress,
        },
        {
            title: t('Document.DTODocument.Type.CUSTOMER_TAX_CLEARANCE'),
            files: store.customerTaxClearenceDocs,
        },
        {
            title: t('Document.DTODocument.Type.CUSTOMER_AUTHORIZATION_LETTER'),
            files: store.customerAuthorizationLetter,
        },
        {
            title: t('Document.DTODocument.Type.CUSTOMER_CREDIT_BUREAU'),
            files: store.customerCBDocs,
        },
    ];

    useEffect(() => {
        store.loadList(Number(customerId) as number);
    }, [customerId]);

    return store.listLoader.isLoading ? (
        <StyledSpinnerContainer>
            <Spinner />{' '}
        </StyledSpinnerContainer>
    ) : (
        <Row gutter={[24, 24]}>
            {documentsList.map((file) => (
                <Col span={12}>
                    <DataView
                        value={
                            <FilesListComponent
                                filesList={file.files}
                                store={store}
                            />
                        }
                        label={file.title}
                    />
                </Col>
            ))}
        </Row>
    );
});

const StyledSpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
