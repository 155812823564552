import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    LoanApplicationDisclosure,
    LoanApplicationTerm,
    LoanApplicationType,
} from 'src/generated-api-client';
import { DateHelper } from 'src/utils/DateHelper';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

import { NavLink } from '../NavLink/NavLink';

type LoanItemProps = {
    // eslint-disable-next-line react/require-default-props
    id?: number;
    // eslint-disable-next-line react/require-default-props
    date?: string;
    // eslint-disable-next-line react/require-default-props
    dateText?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    amount?: number;
    // eslint-disable-next-line react/require-default-props
    period?: LoanApplicationTerm;
    // eslint-disable-next-line react/require-default-props
    status?: string;
    // eslint-disable-next-line react/require-default-props
    type?: LoanApplicationType;
    // eslint-disable-next-line react/require-default-props
    statusColor?: string;
    // eslint-disable-next-line react/require-default-props
    discloserType?: LoanApplicationDisclosure;
    // eslint-disable-next-line react/require-default-props
    linkAddress?: string;
    // eslint-disable-next-line react/require-default-props
    isFinished?: boolean;
};

export const LoanItem = ({
    id,
    date,
    dateText,
    amount,
    period,
    status,
    type,
    statusColor,
    discloserType,
    linkAddress,
    isFinished,
}: LoanItemProps) => {
    const { t } = useTranslation();
    const loanAmount = NumberHelper.formatThousandSeparator(
        amount as number,
        2,
    );

    return (
        <NavLink to={linkAddress} key={id}>
            <StyledContainer key={id} isFinished={isFinished}>
                <Row gutter={[12, 12]} justify="center" align="bottom">
                    <StyledItem span={24}>
                        {date && (
                            <StyledApplicationTitle>
                                {dateText}: {DateHelper.format(date)}
                            </StyledApplicationTitle>
                        )}
                    </StyledItem>

                    <StyledItem span={24}>
                        <StyledStatus color={statusColor}>
                            {status}
                        </StyledStatus>
                    </StyledItem>
                </Row>
                <div>
                    <StyledAmounyContainer>
                        <span>{loanAmount}</span>
                        {t('Loan.Application.Term.RandsFor')}
                    </StyledAmounyContainer>
                    <StyledAmounyContainer>
                        <span>{t(`Loan.Application.Term.${period}`)}</span>
                        {t('Loan.Application.Term.Period')}
                    </StyledAmounyContainer>
                </div>
                <div>
                    <StyledApplicationTypes>
                        {t(`Loan.Application.Type.${type}`)}
                    </StyledApplicationTypes>
                    <StyledApplicationTypes>
                        {t(`Loan.Application.Disclosure.${discloserType}`)}
                    </StyledApplicationTypes>
                </div>
            </StyledContainer>
        </NavLink>
    );
};

const StyledContainer = styled.div<{ isFinished?: boolean }>`
    opacity: ${(props) => (props.isFinished ? 0.4 : 1)};
    border-radius: 6px;
    background: #f5f5f580;
    padding: 24px 48px;
    margin-bottom: 14px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    box-shadow: 1px 1px 1px 2px #797979;
    max-width: 1400px;
    color: #000000;
`;

const StyledStatus = styled.div<{ color?: string }>`
    min-width: 205px;
    display: flex;
    border: 1px solid #797979;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) => props.color};
    border-radius: 5px;
    height: 35px;
    text-decoration: capitalize;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    letter-spacing: 1px;
    white-space: nowrap;
`;

const StyledApplicationTitle = styled.div`
    font-size: 20px;
`;

const StyledItem = styled(Col)`
    text-align: center;
    display: flex;
    justify-content: center;
`;

const StyledAmounyContainer = styled.div`
    text-align: center;
    font-size: 20px;
    span {
        font-weight: 600;
    }
    span::after {
        content: ' ';
    }
`;

const StyledApplicationTypes = styled.div`
    text-align: end;
    font-size: 20px;
    font-weight: 600;
`;
