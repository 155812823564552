import { useParams } from 'react-router-dom';

export function useRoleId() {
    const params = useParams<{ roleId: string }>();

    return {
        roleId: params?.roleId,
        hasroleId: Boolean(params?.roleId),
    };
}
