import { Button, Form, Input, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'src/components/DateRangePicker/DateRangePicker';
import { EntityModelReportDTOStatusEnum } from 'src/generated-api-client';
import { ImportDataStore } from 'src/stores/ImportDataStore/ImportDataStore';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

import { FilterBlock } from '../FilterPanel/FilterBlock';
import { SearchPanelFilterForm } from '../FilterPanel/SearchPanelFilterForm';
import { ImportCSVModal } from './Dialogs/ImportCSVDialog';

const store = ImportDataStore;

export const ImportDataHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <FilterBlock
                store={store}
                placeholder={t('AsideHeader.Search.Placeholder', {
                    defaultValue: 'Search',
                })}
                renderPanel={(props: any) => <FilterPanel {...props} />}
            />
            <ButtonWrapper>
                <StyledCreateButton
                    type="ghost"
                    onClick={() => {
                        ImportCSVModal.render();
                    }}
                >
                    {t('Button.ImportNewBatch', 'Import new batch')}
                </StyledCreateButton>
                <Button
                    type="link"
                    onClick={async () => {
                        await store.downloadCSVExample();
                    }}
                >
                    {t('Button.DownloadTemplate', 'Download template')}
                </Button>
            </ButtonWrapper>
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: flex;
`;

const FilterPanel = observer((props: any) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{}}
            onFinish={(values) => {
                const { issuedDate } = values;
                const [createdAtFrom, createdAtTo] = issuedDate || [
                    undefined,
                    undefined,
                ];
                store.filterCriteria?.applyFilter({
                    ...values,
                    createdAtFrom,
                    createdAtTo,
                });
                store.pager?.resetPage();
                store.loadList();
            }}
        >
            <Form.Item
                label={t('Page.ImportData.Filter.Id', {
                    defaultValue: 'ID',
                })}
                name="id"
            >
                <StyledInput type="string" />
            </Form.Item>
            <Form.Item
                label={t('Page.ImportData.Filter.User', {
                    defaultValue: 'User',
                })}
                name="user"
            >
                <StyledInput type="string" />
            </Form.Item>
            <Form.Item
                label={t('Page.ImportData.Filter.Label.Date', 'Date')}
                name="issuedDate"
            >
                <DateRangePicker />
            </Form.Item>
            <StyledSelect
                label={t('Page.ImportData.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="statuses"
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    <Select.Option
                        value={EntityModelReportDTOStatusEnum.SUCCESSFUL}
                    >
                        {t('Page.ImportData.Status.SUCCESSFUL', 'Successful')}
                    </Select.Option>
                    <Select.Option
                        value={EntityModelReportDTOStatusEnum.FAILURE}
                    >
                        {t('Page.ImportData.Status.FAILURE', 'Failure')}
                    </Select.Option>
                </Select>
            </StyledSelect>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        min-height: 40px;
        border-radius: 6px;
        .ant-select-selection-search {
            display: flex;
            align-items: center;
        }
    }
`;

const StyledInput = styled(Input)`
    height: 40px;
    border-radius: 6px;
`;

const StyledCreateButton = styled(Button)`
    min-width: 50px;
    width: 100%;
    height: 32px;
    margin-left: 5px;
    border-radius: 10px;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3 !important;
        color: #ffffff !important;
        border: none;
    }
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
