import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EntityActions } from 'src/components/EntityActions/EntityActions';
import { LoanProductStatus, UserStatus } from 'src/generated-api-client';
import { useLoanProductId } from 'src/hooks/useLoanProductId';
import { ActivateLoanProductModal } from 'src/pages/LoanProductsPage/Dialogs/ActivateUserDialog';
import { DeactivateLoanProductModal } from 'src/pages/LoanProductsPage/Dialogs/DeactivateUserDialog';
import { EditLoanProductDialog } from 'src/pages/LoanProductsPage/Dialogs/EditLoanProductDialog';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import styled from 'styled-components';

const store = LoanProductsStore;

export const EntityPresentationHeader = observer(() => {
    const { t } = useTranslation();
    const { loanProductId } = useLoanProductId();
    const loanProduct = store.currentItem?.data;

    const entityActions = [
        loanProduct?.status === LoanProductStatus.ACTIVE
            ? {
                  key: 'deactivateLoanProduct',
                  label: t('Actions.Deactivate', 'Deactivate'),
                  onClick: () => {
                      DeactivateLoanProductModal.render();
                  },
              }
            : {
                  key: 'activateLoanProduct',
                  label: t('Actions.Activate', 'Activate'),
                  onClick: () => {
                      ActivateLoanProductModal.render();
                  },
              },
        ProfileStore.hasPermissions(UserPermission.ManageLoanProduct)
            ? {
                  key: 'updateLoanProduct',
                  label: t('Actions.Edit', 'Edit'),
                  onClick: () => {
                      EditLoanProductDialog.render({ loanProductId });
                  },
              }
            : null,
    ];

    return (
        <ColoredHeader align="middle" justify="space-between">
            <Col flex="auto">
                <TextBold>
                    {`#${loanProduct?.id}`} {loanProduct?.productName}
                </TextBold>
            </Col>
            <Col>
                {loanProduct?.status && (
                    <StyledStatusWrapper status={loanProduct?.status}>
                        {loanProduct?.status}
                    </StyledStatusWrapper>
                )}
            </Col>
            <Col>
                <EntityActions items={entityActions} />
            </Col>
        </ColoredHeader>
    );
});

const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    height: 120px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
`;

const TextBold = styled.span`
    font-weight: 600;
    font-size: 29px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) =>
        props.status === UserStatus.ACTIVE ? '#D6F7A0' : '#EB7382'};
    border-radius: 5px;
    font-size: 15px;
    height: 35px;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    text-decoration: capitalize;
    color: #000000;
`;
