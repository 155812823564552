import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLoanProductId } from 'src/hooks/useLoanProductId';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';
import styled from 'styled-components';

import { EntityPresentationBody } from './Components/EntityPresentationBody';
import { EntityPresentationHeader } from './Components/EntityPresentationHeader';

const store = LoanProductsStore;

export const LoanProductsEntityPresentation = observer(() => {
    const { loanProductId } = useLoanProductId();

    useEffect(() => {
        if (!loanProductId) {
            return;
        }

        store.loadItem(loanProductId);
    }, [loanProductId]);

    return (
        <MainContainer>
            <EntityPresentationHeader />
            <EntityPresentationBody />
        </MainContainer>
    );
});

const MainContainer = styled.div`
    margin: 60px 30px 0;
`;
