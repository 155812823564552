import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import {
    DocumentDTOFileTypeEnum,
    EntityModelReportDTOStatusEnum,
} from 'src/generated-api-client';
import { useImportDataId } from 'src/hooks/useImportDataId';
import { ImportDataStore } from 'src/stores/ImportDataStore/ImportDataStore';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

const store = ImportDataStore;

export const ImportDataEntityPresentation = observer(() => {
    const { t } = useTranslation();
    const { importDataId } = useImportDataId();

    const dataItem = store.currentItem?.data;

    useEffect(() => {
        if (!importDataId) {
            return;
        }
        store.loadItem(importDataId);
    }, [importDataId]);

    return (
        <MainContainer>
            <ColoredHeader align="middle" justify="space-between">
                <Col span={2}>
                    <TextBold>{`#${dataItem?.id}`}</TextBold>
                </Col>
                <Col>
                    <TextBold>
                        {DateHelper.formatDateTime(
                            dataItem?.createdAt as string,
                        )}
                    </TextBold>
                </Col>
                <Col>
                    <TextBold>{dataItem?.createdBy}</TextBold>
                </Col>
                <Col>
                    {dataItem?.status && (
                        <StyledStatusWrapper status={dataItem?.status}>
                            {dataItem?.status.toLowerCase()}
                        </StyledStatusWrapper>
                    )}
                </Col>
            </ColoredHeader>
            <Row>
                <Col flex="auto">
                    <DataView
                        label={t(
                            'Page.ImportData.InitialCSV',
                            'Initial CSV file',
                        )}
                        value={
                            <StyledButton
                                type="link"
                                onClick={() =>
                                    store.downloadCSV(
                                        importDataId as string,
                                        dataItem?.documents?.[0]
                                            ?.documentId as number,
                                        dataItem?.documents?.[0]
                                            ?.fileType as DocumentDTOFileTypeEnum,
                                        dataItem?.documents?.[0]
                                            ?.fileName as string,
                                    )
                                }
                            >
                                {dataItem?.documents?.[0]?.fileName}
                            </StyledButton>
                        }
                    />
                </Col>
                <Col flex="auto">
                    <DataView
                        label={t(
                            'Page.ImportData.ResultCSV',
                            'Result CSV file',
                        )}
                        value={
                            <StyledButton
                                type="link"
                                onClick={() =>
                                    store.downloadCSV(
                                        importDataId as string,
                                        dataItem?.documents?.[1]
                                            ?.documentId as number,
                                        dataItem?.documents?.[1]
                                            ?.fileType as DocumentDTOFileTypeEnum,
                                        dataItem?.documents?.[1]
                                            ?.fileName as string,
                                    )
                                }
                            >
                                {dataItem?.documents?.[1]?.fileName}
                            </StyledButton>
                        }
                    />
                </Col>
            </Row>
        </MainContainer>
    );
});

const MainContainer = styled.div`
    margin: 60px 30px 0;
`;

const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    height: 120px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
`;

const TextBold = styled.span`
    font-weight: 600;
    font-size: 29px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) =>
        props.status === EntityModelReportDTOStatusEnum.SUCCESSFUL
            ? '#ACF392'
            : '#EB7382'};
    border-radius: 5px;
    font-size: 15px;
    height: 35px;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    text-decoration: capitalize;
    color: #000000;
`;

const StyledButton = styled(Button)`
    padding: 0;
`;
