import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { CustomersStore } from 'src/stores/CustomersStore/CustomersStore';

const store = CustomersStore;

export const RepresentativePersonDetails = observer(() => {
    const { t } = useTranslation();
    const currentCustomer = store.currentItem?.data;

    return (
        <>
            <Row>
                <BlockTitle>
                    {t(
                        'Page.Customer.RepresentativePerson.Title',
                        'Representative person',
                    )}
                </BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={
                            currentCustomer?.contactDetails
                                ?.representativeFullName
                        }
                        label={t(
                            'Page.Customer.RepresentativePerson.FullName',
                            'FULL NAME',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={
                            currentCustomer?.contactDetails
                                ?.representativePhoneNumber
                        }
                        label={t(
                            'Page.Customer.RepresentativePerson.Phone',
                            'PHONE',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={
                            currentCustomer?.contactDetails?.representativeEmail
                        }
                        label={t(
                            'Page.Customer.RepresentativePerson.Email',
                            'EMAIL',
                        )}
                    />
                </Col>
            </Row>
        </>
    );
});
