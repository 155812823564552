import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Router } from 'src/core/router/Router';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { Entity } from 'src/utils/mobx/BasicStore/BasicStore.types';

export const useOpenFirstListItem = (store: BasicStore<Entity>) => {
    const { path } = useRouteMatch();
    const { pathname } = useLocation();

    useEffect(() => {
        const nestedEntityPathnamePattern = new RegExp(`^${path}/.+`);

        if (store.list?.length && !nestedEntityPathnamePattern.test(pathname)) {
            const firstItem = store.list[0];
            Router.replace(`${path}/${firstItem.id}`);
        }
    }, [store.list, path, pathname]);
};
