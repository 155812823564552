import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Brief } from 'src/components/Brief/Brief';
import { Tabs } from 'src/components/Tabs/Tabs';
import {
    LoanApplicationDisclosure,
    LoanSnapshotDTO,
} from 'src/generated-api-client';
import { useLoanId } from 'src/hooks/useLoanId';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import styled from 'styled-components';

import { Counterparty } from './Components/Counterparty';
import { LoanBalances } from './Components/LoanBalances';
import { LoanDetails } from './Components/LoanDetails';
import { LoanEntityHeader } from './Components/LoanEntityHeader';
import { Operations } from './Components/Operations';

const store = LoansStore;

export const LoanEntityPresentation = observer(() => {
    const { t } = useTranslation();

    const { loanId } = useLoanId();

    const currentLoan = store?.currentItem?.data;

    const operationsData = store?.getAllSnapshots;

    const showDisclosure =
        currentLoan?.disclosure === LoanApplicationDisclosure.DISCLOSED;

    const TabsContent: any = [
        {
            tabKey: 'general-info',
            title: t('Actions.CompanyDetails', 'General info'),
            Component: (
                <>
                    <LoanDetails />
                    {showDisclosure && <Counterparty />}
                    <LoanBalances />
                </>
            ),
        },
        {
            tabKey: 'operations',
            title: t('Actions.operations', 'Operations'),
            Component: (
                <Operations dataSource={operationsData as LoanSnapshotDTO[]} />
            ),
        },
    ];

    useEffect(() => {
        store.loadItem(loanId);
        store.getOperations(loanId);
    }, [loanId]);

    return (
        <MainContainer>
            <LoanEntityHeader />
            <Brief>
                <Tabs tabs={TabsContent} />
            </Brief>
        </MainContainer>
    );
});

const MainContainer = styled.div`
    margin: 60px 30px 0;
`;
