import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { useOpenFirstListItem } from 'src/hooks/useOpenFirstListItem';
import { ImportDataStore } from 'src/stores/ImportDataStore/ImportDataStore';

import { ImportDataEntityPresentation } from './ImportDataEntityPresentation';
import { ImportDataHeaderPanel } from './ImportDataHeaderPanel';
import { ImportDataListItem } from './ImportDataListItem';

const store = ImportDataStore;

export const ImportDataPage = observer(() => {
    const { path } = useRouteMatch();
    useOpenFirstListItem(store);

    useEffect(() => {
        return () => {
            store.listLoader.reset();
            store.filterCriteria?.resetFilter();
            store.pager?.reset();
        };
    }, []);

    return (
        <MasterDetailsPage store={store} asidePanel={<ImportDataAsidePanel />}>
            <Switch>
                <Route
                    path={`${path}/:dataId`}
                    exact
                    render={() => <ImportDataEntityPresentation />}
                />
            </Switch>
        </MasterDetailsPage>
    );
});

const ImportDataAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: any) => {
                return (
                    <List.Item key={item.id}>
                        <ImportDataListItem item={item} />
                    </List.Item>
                );
            }}
            header={<ImportDataHeaderPanel />}
        />
    );
});
