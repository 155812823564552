import { Button, Col, FormInstance, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import styled from 'styled-components';

const store = ApplicationsStore;

export const CCReviewButtonsContainer = observer(
    ({ form }: { form: FormInstance<any> }) => {
        const { t } = useTranslation();
        const { applicationId } = useApplicationId();

        return (
            <Row gutter={24} justify="end">
                <StyledButtonContainer flex="nowrap">
                    <StyledSaveButton
                        key="ok"
                        type="primary"
                        loading={store.applicationCCApproveAction.isLoading}
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        {t('Action.Approve')}
                    </StyledSaveButton>
                    <StyledResetButton
                        key="cancel"
                        loading={store.applicationCCDeclineAction.isLoading}
                        onClick={() => {
                            store.applicationCCDecline(applicationId);
                            form.resetFields();
                        }}
                    >
                        {t('Action.Decline')}
                    </StyledResetButton>
                    <StyledBackButton
                        key="back"
                        type="primary"
                        onClick={() => {
                            Router.navigate(
                                `${RoutePaths.applications}/${applicationId}`,
                            );
                        }}
                    >
                        {t('Action.Back')}
                    </StyledBackButton>
                </StyledButtonContainer>
            </Row>
        );
    },
);

const StyledButtonContainer = styled(Col)`
    margin-top: 20px;
    text-align: center;
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const StyledBackButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 9px;
    border-radius: 10px;
    border: none;
    background: transparent;
    color: #000000;
    border: 1.5px solid #000000;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: transparent;
        color: #aaaaaa;
        border: 1.5px solid #aaaaaa;
    }
`;
