import { useParams } from 'react-router-dom';

export function useCustomerId() {
    const params = useParams<{ customerId: string }>();

    return {
        customerId: params?.customerId,
        hascustomerId: Boolean(params?.customerId),
    };
}
