/* eslint-disable max-lines */
import { Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EntityActions } from 'src/components/EntityActions/EntityActions';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    LoanApplicationStatus,
    LoanApplicationType,
} from 'src/generated-api-client';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { UserPermission } from 'src/stores/AppUserStore/UserPermissions';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { applicationStatusMapColors } from 'src/utils/common-constants';
import { DateHelper } from 'src/utils/DateHelper';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

import { RequestAdditionalInformationModal } from '../Dialogs/RequestAdditionalInformation';
import { SetDeclinedModal } from '../Dialogs/SetDeclinedDialog';
import { SetVerifaedModal } from '../Dialogs/SetVerifaedModal';

const store = ApplicationsStore;

const profileStore = ProfileStore;

const { Paragraph } = Typography;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const EntityHeader = observer(() => {
    const { t } = useTranslation();
    const { applicationId } = useApplicationId();

    const currentApplication = store.currentItem?.data;
    const initialReviewOrCreditCommitteeReviewStatus =
        currentApplication?.status ===
        (LoanApplicationStatus.INITIALREVIEW ||
            LoanApplicationStatus.CREDITCOMMITTEEREVIEW);

    const proceedAction = () => {
        if (
            currentApplication?.type ===
                LoanApplicationType.PURCHASEORDERFINANCE ||
            currentApplication?.type === LoanApplicationType.FINANCEOFDEBTOR
        ) {
            Router.navigate(
                `${RoutePaths.applications}/${applicationId}/insert-financial`,
            );
        } else {
            SetVerifaedModal.render();
        }
    };

    const entityActions =
        currentApplication?.status ===
        LoanApplicationStatus.CREDITCOMMITTEEREVIEW
            ? [
                  {
                      key: 'makeFinalDecision',
                      label: t('Action.MakeFinalDecision'),
                      onClick: () => {
                          Router.navigate(
                              `${RoutePaths.applications}/${applicationId}/cc-review`,
                          );
                      },
                  },
                  profileStore.hasPermissions(
                      UserPermission.LoanApplicationReviewer,
                      UserPermission.CreditCommittee,
                  )
                      ? {
                            key: 'export-pdf',
                            label: t(
                                'Application.Action.ExportPDF',
                                'Export PDF',
                            ),
                            onClick: async () => {
                                await store.generateLoanApplicationPdf(
                                    applicationId,
                                );
                            },
                        }
                      : null,
              ]
            : [
                  initialReviewOrCreditCommitteeReviewStatus
                      ? {
                            key: 'setApplicationProceed',
                            label: t('Proceed.Edit', 'Proceed'),
                            onClick: () => {
                                proceedAction();
                            },
                        }
                      : null,
                  initialReviewOrCreditCommitteeReviewStatus
                      ? {
                            key: 'setApplicationDecline',
                            label: t('Actions.Decline', 'Decline'),
                            onClick: () => {
                                SetDeclinedModal.render();
                            },
                        }
                      : null,
                  initialReviewOrCreditCommitteeReviewStatus
                      ? {
                            key: 'requestEditionalInformation',
                            label: t(
                                'Application.Action.RequestAdditionalInformation',
                                'Request additional information',
                            ),
                            onClick: () => {
                                RequestAdditionalInformationModal.render({
                                    currentApplication,
                                });
                            },
                        }
                      : null,
                  currentApplication?.status === LoanApplicationStatus.SIGNED
                      ? {
                            key: 'recreate-loans',
                            label: t(
                                'Application.Action.RecreateLoans',
                                'Recreate loans',
                            ),
                            onClick: async () => {
                                await store.recreateLoans(applicationId);
                            },
                        }
                      : null,

                  profileStore.hasPermissions(
                      UserPermission.LoanApplicationReviewer,
                      UserPermission.CreditCommittee,
                  )
                      ? {
                            key: 'export-pdf',
                            label: t(
                                'Application.Action.ExportPDF',
                                'Export PDF',
                            ),
                            // eslint-disable-next-line sonarjs/no-identical-functions
                            onClick: async () => {
                                await store.generateLoanApplicationPdf(
                                    applicationId,
                                );
                            },
                        }
                      : null,
              ];

    const openCustomer = () => {
        Router.navigate(
            EntityLinks.customers.itemDetails(currentApplication?.customerId),
        );
    };

    return (
        <ColoredHeader gutter={24} align="top" justify="space-between">
            <StyledColumn>
                <StyledTextContainer>
                    <TextBold onClick={openCustomer}>
                        {`#${currentApplication?.id}`}{' '}
                        {currentApplication?.customerLegalName}
                    </TextBold>
                </StyledTextContainer>
                <StyledTextContainer>
                    <TextSemiBold>
                        {t(
                            `Entity.Application.Type.${currentApplication?.type}`,
                        )}{' '}
                        /{' '}
                        {t(
                            `Loan.Application.Disclosure.${currentApplication?.disclosure}`,
                        )}
                    </TextSemiBold>
                </StyledTextContainer>
                <StyledTextContainer>
                    <TextSemiBold>
                        {`${t('Page.Application.Master.Requested', {
                            amount: NumberHelper.formatThousandSeparator(
                                currentApplication?.requestedAmount as number,
                                2,
                            ),
                        })} / ${t(
                            `Loan.Application.Term.${currentApplication?.requestedTerm}`,
                        )}`}
                    </TextSemiBold>
                </StyledTextContainer>
                <StyledTextContainer>
                    {currentApplication?.approvedAmount !== null && (
                        <TextSemiBold>
                            {`${t('Page.Application.Master.Approved', {
                                amount: NumberHelper.formatThousandSeparator(
                                    currentApplication?.approvedAmount as number,
                                    2,
                                ),
                            })} / ${t(
                                `Loan.Application.Term.${currentApplication?.approvedTerm}`,
                            )}`}
                        </TextSemiBold>
                    )}
                </StyledTextContainer>
            </StyledColumn>
            <StyledFlexContainer>
                <StyledColumn>
                    <StyledTextContainer>
                        {currentApplication?.status && (
                            <StyledStatusWrapper
                                status={currentApplication?.status}
                            >
                                {t(
                                    `Loan.Application.Status.${currentApplication.status}`,
                                )}
                            </StyledStatusWrapper>
                        )}
                    </StyledTextContainer>

                    <StyledTextContainer>
                        {currentApplication?.submitDate && (
                            <TextSemiBold>
                                {t(`Page.Application.Master.SubmittedDate`, {
                                    date: DateHelper.format(
                                        currentApplication?.submitDate,
                                    ),
                                })}
                            </TextSemiBold>
                        )}
                    </StyledTextContainer>
                </StyledColumn>
                <StyledColumn>
                    {profileStore.hasPermissions(
                        UserPermission.LoanApplicationReviewer,
                        UserPermission.CreditCommittee,
                    ) && (
                        <StyledTextContainer>
                            <EntityActions items={entityActions} />
                        </StyledTextContainer>
                    )}
                </StyledColumn>
            </StyledFlexContainer>
        </ColoredHeader>
    );
});

const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    min-height: 120px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
`;

const TextBold = styled.span`
    font-weight: 600;
    font-size: 1.8rem;
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
        opacity: 0.7;
    }
`;

const TextSemiBold = styled(Paragraph)`
    font-weight: 600;
    font-size: 1.2rem;
    margin: 8px 0 7px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) =>
        applicationStatusMapColors[
            props?.status as keyof typeof applicationStatusMapColors
        ]};
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    height: 35px;
    line-height: 22px;
    text-decoration: capitalize;
    color: #000000;
`;

const StyledColumn = styled.div``;

const StyledTextContainer = styled.div``;

const StyledFlexContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
`;
