import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { CssVariables } from 'src/styles/CssVariables';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

import { BasePage, BasePageProps } from '../BasePage/BasePage';

export type MasterDetailsPageProps<T> = BasePageProps & {
    store: BasicStore<T>;
    asidePanel: React.ReactNode;
};

export const MasterDetailsPage: React.FC<MasterDetailsPageProps<any>> =
    observer(({ children, asidePanel, store, ...props }) => {
        useEffect(() => {
            store.loadList();

            return () => {
                store.listLoader.reset();
            };
        }, []);

        return (
            <StyledBasePage
                {...props}
                title={null}
                isLoading={Boolean(store.itemLoader.isLoading)}
            >
                {asidePanel}
                <StyledDetails>{children}</StyledDetails>
            </StyledBasePage>
        );
    });

const StyledBasePage = styled(BasePage)`
    height: calc(100vh - ${CssVariables.headerHeight});
    overflow: hidden;
    display: flex;
    position: relative;
    padding-top: 0;
    .ant-card-body {
        padding: 24px 0 0;
    }
`;

const StyledDetails = styled.section`
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
`;
