import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type SearchPanelFilterFormProps<ValuesType = any> = {
    form?: FormInstance<ValuesType>;
    closePopper?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFinish?: (values: ValuesType) => void | Promise<void>;
    initialValues?: Record<string, any>;
};
export const SearchPanelFilterForm: React.FC<SearchPanelFilterFormProps> =
    observer(({ form, closePopper, onFinish, initialValues, children }) => {
        const { t } = useTranslation();

        return (
            <StyledWrapper>
                <Form<{ status?: string; issuedAt?: [] }>
                    form={form}
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={async (values) => {
                        await onFinish?.(values);
                        closePopper?.();
                    }}
                    onReset={() => {
                        form?.resetFields();
                        form?.submit();
                    }}
                >
                    {children}
                    <Row justify="space-between">
                        <StyledSubmit htmlType="submit">
                            {t('Component.AsidePanel.Filter.Apply', 'Apply')}
                        </StyledSubmit>
                        <StyledClear
                            type="text"
                            icon={<CloseOutlined />}
                            htmlType="reset"
                        />
                    </Row>
                </Form>
            </StyledWrapper>
        );
    });

const StyledClear = styled(Button)`
    color: #222325;
    svg {
        font-size: 24px;
    }
    .anticon {
        transform: translateY(-3px);
    }
    .anticon + span,
    .ant-btn > span + .anticon {
        margin-left: 3px;
    }
`;

const StyledSubmit = styled(Button)`
    width: 80%;
    color: #ffffff;
    border-radius: 10px !important;
    background: #222325;
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const StyledWrapper = styled.div`
    width: 324px;
    position: relative;

    .ant-form-item-label {
        font-weight: bold;
        padding: 0 0 4px;
    }

    .ant-select-selector {
        border-radius: 5px;
    }

    .ant-picker-range {
        border-radius: 6px;
        width: 100%;
        height: 40px;
    }
    .ant-form-item {
    }

    .ant-btn {
        border-radius: 5px;
    }

    .ant-btn-primary {
        background: #0a65ed;
        border-radius: 6px;
        font-weight: 700;
        color: #ffffff;
    }

    input {
        border: 1px solid #d9d9d9;
    }

    .ant-select-selector {
        border-color: #d9d9d9 !important;
    }

    .ant-picker {
        input {
            border: none;
        }
    }

    .ant-input-number-input-wrap {
        input {
            border: none;
        }
    }
`;
