/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
import { Checkbox, Col, Form as AntForm, Row } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { CheckboxField } from 'src/components/Form/CheckboxField';
import { Form } from 'src/components/Form/Form';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    EntityModelLoanProductDTO,
    LoanApplicationFinancedItemDTO,
    LoanApplicationTerm,
    LoanApplicationType,
    LoanProductStatus,
} from 'src/generated-api-client';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';
import styled from 'styled-components';

import {
    CCReviewButtonsContainer,
    CCReviewFinancingSource,
    CCReviewLoanInformation,
} from './Components';
import { FinancedDocumentsDetails } from './TabsContent/FinancedDocumentsDetails';

const store = ApplicationsStore;

const initialFormValue = {
    disbursementBankAccountInfos: [
        {
            accountAllocationPercentage: null,
            bankAccountId: 2,
        },
        {
            accountAllocationPercentage: null,
            bankAccountId: 1,
        },
    ],
};

export const ApplicationsCCReviewPage = observer(() => {
    const { t } = useTranslation();
    const [form] = AntForm.useForm();
    const { applicationId } = useApplicationId();
    const ccReviewData = store.ccReview;
    const currentApplication = store.currentItem?.data;
    const [selectedTerm, setSelectedTerm] = useState<string>();

    const showCheckBox =
        (selectedTerm === LoanApplicationTerm.SIXTY ||
            selectedTerm === LoanApplicationTerm.NINETY) &&
        currentApplication?.type !==
            LoanApplicationType.OUTANDOUTINVOICEFINANCE;

    useEffect(() => {
        store.loadItem(applicationId);
        store.getCCReview(Number(applicationId));
    }, []);

    const [hasPaymentHolidays, setHasPaymentHolidays] = useState(false);

    const handleChangeHasPaymentHolidays = (
        checkedValues: CheckboxValueType[],
    ) => {
        if (checkedValues.length > 0) {
            setHasPaymentHolidays(true);
            form.setFieldValue('hasPaymentHolidays', true);
        } else {
            setHasPaymentHolidays(false);
            form.setFieldValue('hasPaymentHolidays', false);
        }
    };

    const handleSetPaymentHoliday = (value: string) => {
        setSelectedTerm(value);
        if (value === LoanApplicationTerm.THIRTY || value === undefined) {
            setHasPaymentHolidays(false);
            form.setFieldValue('hasPaymentHolidays', false);
        }
    };

    const paymentHolidaysOptions =
        selectedTerm === LoanApplicationTerm.SIXTY
            ? [
                  {
                      children: t(
                          'Page.Application.Select.PaymentHolidays.SecondPeriod',
                          'Second period',
                      ),
                      value: LoanApplicationTerm.THIRTY,
                  },
              ]
            : [
                  {
                      children: t(
                          'Page.Application.Select.PaymentHolidays.SecondPeriod',
                          'Second period',
                      ),
                      value: LoanApplicationTerm.THIRTY,
                  },
                  {
                      children: t(
                          'Page.Application.Select.PaymentHolidays.ThirdPeriod',
                          'Third period',
                      ),
                      value: LoanApplicationTerm.SIXTY,
                  },
              ];

    return (
        <StyledPageContainer>
            <Form
                form={form}
                initialValues={initialFormValue}
                onFinish={async (values) => {
                    await store.applicationCCApprove(applicationId, values);
                }}
            >
                <CCReviewButtonsContainer form={form} />
                <CCReviewLoanInformation />
                <StyledDivider />
                <Row gutter={24}>
                    <Col span={24}>
                        <DataView
                            label={t(
                                'Page.Applications.OutlineLoanConditionsTitle',
                            )}
                            value={
                                <Row gutter={24} justify="start" align="middle">
                                    <Col span={8}>
                                        <SelectField
                                            convertListItemToOption={(
                                                loanTerm,
                                            ) => ({
                                                children: `${loanTerm.children}`,
                                                value: loanTerm.value,
                                            })}
                                            options={[
                                                {
                                                    children: 30,
                                                    value: LoanApplicationTerm.THIRTY,
                                                },
                                                {
                                                    children: 60,
                                                    value: LoanApplicationTerm.SIXTY,
                                                },
                                                {
                                                    children: 90,
                                                    value: LoanApplicationTerm.NINETY,
                                                },
                                            ]}
                                            name="approvedTerm"
                                            label={t(
                                                'Page.Applications.Label.FinalTerm',
                                            )}
                                            rules={[ValidationRules.required]}
                                            onChange={handleSetPaymentHoliday}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <TextField
                                            name="financedPercentage"
                                            prefix="%"
                                            onlyNumbers
                                            label={t(
                                                'Page.Applications.Label.Discount',
                                            )}
                                            rules={[
                                                ValidationRules.requiredFinancedPercentage,
                                                ValidationRules.financedPercentage,
                                            ]}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <SelectField
                                            store={LoanProductsStore}
                                            filters={{
                                                statuses: [
                                                    LoanProductStatus.ACTIVE,
                                                ],
                                            }}
                                            convertListItemToOption={(
                                                loanProduct: EntityModelLoanProductDTO,
                                            ) => ({
                                                children: `${loanProduct.productName}`,
                                                value: loanProduct.id,
                                            })}
                                            rules={[ValidationRules.required]}
                                            name="loanProductId"
                                            label={t(
                                                'Component.Label.LoanProduct',
                                            )}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        {showCheckBox && (
                                            <Checkbox.Group
                                                onChange={
                                                    handleChangeHasPaymentHolidays
                                                }
                                            >
                                                <CheckboxField
                                                    name="hasPaymentHolidays"
                                                    label={<div />}
                                                    checkboxLabel={
                                                        <Trans i18nKey="Page.Application.Label.Checkbox.PaymentHolidays" />
                                                    }
                                                />
                                            </Checkbox.Group>
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {hasPaymentHolidays && (
                                            <SelectField
                                                convertListItemToOption={(
                                                    loanTerm,
                                                ) => ({
                                                    children: `${loanTerm.children}`,
                                                    value: loanTerm.value,
                                                })}
                                                options={paymentHolidaysOptions}
                                                name="holidayTerm"
                                                label={
                                                    <Trans i18nKey="Page.Application.Label.Select.PaymentHolidays" />
                                                }
                                                rules={[
                                                    ValidationRules.required,
                                                ]}
                                            />
                                        )}
                                    </Col>
                                    <Col span={12}>
                                        {currentApplication?.type ===
                                            LoanApplicationType.PURCHASEORDERFINANCE && (
                                            <TextField
                                                name="precedentConditions"
                                                initialValue="N/a"
                                                label={t(
                                                    'Page.Application.Label.PrecedentConditions',
                                                )}
                                                rules={[
                                                    ValidationRules.required,
                                                ]}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            }
                        />
                    </Col>
                </Row>
                <StyledDivider />
                <Row gutter={24}>
                    <Col span={24}>
                        <DataView
                            label={t('Page.Application.FinancingSourcesTitle')}
                            value={<CCReviewFinancingSource form={form} />}
                        />
                    </Col>
                </Row>
                <StyledDivider />
                <Row gutter={24}>
                    <Col span={24}>
                        <DataView
                            label={t(
                                'Page.Application.FinancedDocumentsDetailsTitle',
                            )}
                            value={
                                <FinancedDocumentsDetails
                                    dataSource={
                                        ccReviewData?.financedItems as LoanApplicationFinancedItemDTO[]
                                    }
                                />
                            }
                        />
                    </Col>
                </Row>
            </Form>
        </StyledPageContainer>
    );
});

const StyledDivider = styled.div`
    margin-bottom: 30px;
    border-bottom: 1px solid #000000;
`;

const StyledPageContainer = styled.div`
    margin: 20px 29px;
`;
