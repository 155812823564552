export const CssVariables = {
    headerHeight: '79px',
    headerLineHeight: '52px',

    masterDetailsAsideWidth: '24%',
};

export const CssColors = {
    black: 'black',
};
