import { Col, notification, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal/Modal';
import { CustomersStore } from 'src/stores/CustomersStore/CustomersStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

export const SetDeclinedDialog = observer(({ ...props }) => {
    const { t } = useTranslation();
    const { currentItem } = CustomersStore;

    return (
        <Modal
            {...props}
            title={t('Page.Customer.Modal.Declined.Title', 'Set as declined')}
            isLoading={CustomersStore.deactivateItemLoader.isLoading}
            onFinish={async () => {
                await CustomersStore.deactivate(Number(currentItem?.data?.id));

                if (!CustomersStore.deactivateItemLoader.hasError) {
                    notification.success({
                        message: t('Message.Success'),
                    });
                    await CustomersStore.loadItem(
                        currentItem?.data?.id?.toString(),
                    );
                    await CustomersStore.loadList();
                }
            }}
            okText={t('Button.Confirm', 'Confirm')}
        >
            <Row gutter={16}>
                <Col>
                    <StyledTextSize>
                        {t(
                            'Page.Customer.Modal.Declined.Text',
                            'Set as declined this customer?',
                        )}
                    </StyledTextSize>
                </Col>
            </Row>
        </Modal>
    );
});

export const SetDeclinedModal = addModalRenderer(SetDeclinedDialog);

const StyledTextSize = styled(Text)`
    font-size: 19px;
`;
