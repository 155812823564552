import { Col, notification, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal/Modal';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

export const DeactivateLoanProductDialog = observer(({ ...props }) => {
    const { t } = useTranslation();
    const { currentItem } = LoanProductsStore;

    return (
        <Modal
            {...props}
            title={t(
                'Page.LoanProduct.Modal.Deactivate.Title',
                'Deactivate loan product',
            )}
            isLoading={LoanProductsStore.deactivateItemLoader.isLoading}
            onFinish={async () => {
                await LoanProductsStore.deactivate(
                    Number(currentItem?.data?.id),
                );

                if (!LoanProductsStore.deactivateItemLoader.hasError) {
                    notification.success({
                        message: t('Message.Success'),
                    });
                    await LoanProductsStore.loadItem(
                        currentItem?.data?.id?.toString(),
                    );
                    await LoanProductsStore.loadList();
                }
            }}
            okText={t('Button.Confirm', 'Confirm')}
        >
            <Row gutter={16}>
                <Col>
                    <StyledTextSize>
                        {t(
                            'Page.LoanProduct.Modal.Deactivate.Text',
                            'Do you want to deactivate this product?',
                        )}
                    </StyledTextSize>
                </Col>
            </Row>
        </Modal>
    );
});

export const DeactivateLoanProductModal = addModalRenderer(
    DeactivateLoanProductDialog,
);

const StyledTextSize = styled(Text)`
    font-size: 19px;
`;
