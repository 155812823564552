import { Button, Form, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { SelectField } from 'src/components/Form/SelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { SystemOperationsStore } from 'src/stores/SystemOperationsStore/SystemOperationsStore';
import styled from 'styled-components';

export const SystemOperationsPage = observer(() => {
    const { t } = useTranslation();

    return (
        <BasePage isLoading={SystemOperationsStore.updateItemLoader.isLoading}>
            <BlockTitle>
                {t(
                    'Page.SystemOperationsPage.Title',
                    'System operation launching',
                )}
            </BlockTitle>
            <StyledForm
                layout="vertical"
                onFinish={async (values: any) => {
                    await SystemOperationsStore.updateItem(
                        '',
                        values.operation,
                    );

                    if (!SystemOperationsStore.updateItemLoader.hasError) {
                        notification.success({
                            message: t('Message.Success'),
                        });
                    }
                }}
            >
                <StyledSelect
                    requiredMark="optional"
                    store={SystemOperationsStore}
                    name="operation"
                    label={t(
                        'Page.SystemOperationsPage.Operations',
                        'Please, choose which system operation should be initiated',
                    )}
                    convertListItemToOption={(productName: any) => ({
                        children: t(`System.Operation.${productName}`),
                        value: productName,
                    })}
                    rules={[ValidationRules.systemOperations]}
                />

                <StyledSaveButton htmlType="submit">
                    {t('Button.Execute', 'Execute')}
                </StyledSaveButton>
            </StyledForm>
        </BasePage>
    );
});

const StyledForm = styled(Form)`
    margin-top: 24px;
`;

const StyledSelect = styled(SelectField)`
    max-width: 600px;
    margin-bottom: 30px;
    label {
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 1.1rem;
        text-transform: uppercase;
    }
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:active,
    &:focus,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;
