import { action } from 'mobx';
import {
    ApiResponseBodyNotificationDTO,
    CreateNotificationForm,
    EntityModelNotificationDTO,
    NotificationStatus,
    NotificationType,
    UpdateNotificationForm,
} from 'src/generated-api-client';
import { notificationApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type NotificationsFilter = {
    id?: string;
    title?: string;
    types?: NotificationType[];
    statuses?: NotificationStatus[];
    page?: number;
    count?: number;
    countDistinct?: boolean;
    display?: boolean;
    sortFields?: Array<string>;
    sortOrder?: string;
};

export class NotificationsStoreClass extends BasicStore<
    EntityModelNotificationDTO,
    NotificationsFilter,
    UpdateNotificationForm,
    CreateNotificationForm
> {
    api: BasicStoreApi<
        EntityModelNotificationDTO,
        UpdateNotificationForm,
        CreateNotificationForm,
        ApiResponseBodyNotificationDTO
    > = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                notificationApi.entityList5(
                    this.filterCriteria.filter.id,
                    this.filterCriteria.filter.title,
                    this.filterCriteria.filter.types,
                    this.filterCriteria.filter.statuses,
                    this.pager?.page,
                    this.pager?.size,
                    this.filterCriteria.filter.countDistinct,
                    this.filterCriteria.filter.display,
                    this.filterCriteria.filter.sortFields,
                    'desc',
                ),
            );
            this.pager?.setTotal(result.page?.totalElements as number);

            return result.content || [];
        },

        loadItem: (id: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                notificationApi.entityView5(Number(id)),
            );
        },
        createItem: async (data) => {
            await RequestHelper.unwrapFromAxiosPromise(
                notificationApi.createNotification(data),
            );
        },
        updateItem: async (_, data: UpdateNotificationForm) => {
            await RequestHelper.unwrapFromAxiosPromise(
                notificationApi.updateNotification(data),
            );
        },
    };

    activateLoader = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            notificationApi.activate1(id),
        );
    });

    deactivateLoader = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            notificationApi.deactivate1(id),
        );
    });

    @action activate(id: string | number) {
        return this.activateLoader.call(Number(id));
    }

    @action deactivate(id: string | number) {
        return this.deactivateLoader.call(Number(id));
    }

    filterCriteria = new FilterCriteria<NotificationsFilter>();

    pager?: Pager | undefined = new Pager();
}

export const NotificationsStore = new NotificationsStoreClass();
