import { Col, notification, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal/Modal';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

export const SetVerifaedDialog = observer(({ ...props }) => {
    const { t } = useTranslation();
    const { currentItem } = ApplicationsStore;

    return (
        <Modal
            {...props}
            title={t('Page.User.Activate', 'Set as verified')}
            isLoading={ApplicationsStore.activateItemLoader.isLoading}
            onFinish={async () => {
                await ApplicationsStore.activate(Number(currentItem?.data?.id));

                if (!ApplicationsStore.activateItemLoader.hasError) {
                    notification.success({
                        message: t('Message.Success'),
                    });
                    await ApplicationsStore.loadItem(
                        currentItem?.data?.id?.toString(),
                    );
                    await ApplicationsStore.loadList();
                }
            }}
            okText={t('Button.Confirm', 'Confirm')}
        >
            <Row gutter={16}>
                <Col>
                    <StyledTextSize>
                        {t(
                            'Page.User.Activate.Text',
                            'Verify this application?',
                        )}
                    </StyledTextSize>
                </Col>
            </Row>
        </Modal>
    );
});

export const SetVerifaedModal = addModalRenderer(SetVerifaedDialog);

const StyledTextSize = styled(Text)`
    font-size: 19px;
`;
