import { Button, Col, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { Spinner } from 'src/components/Spinner/Spinner';
import {
    ContractSigningLog,
    ContractSigningLogStatusEnum,
} from 'src/generated-api-client';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import styled from 'styled-components';

const store = ApplicationsStore;

export const SigningLogs = observer(() => {
    const { t } = useTranslation();
    const { applicationId } = useApplicationId();

    useEffect(() => {
        store.loadApplicationContractSigningLogs(applicationId);

        return () => {
            store.applicationContractSigningLogsLoader.reset();
        };
    }, [applicationId]);

    return store.applicationContractSigningLogsLoader.isLoading ? (
        <StyledSpinnerContainer>
            <Spinner />{' '}
        </StyledSpinnerContainer>
    ) : (
        <Row>
            <Col span={24}>
                <BlockTitle>
                    {t(
                        'Page.Application.Tabs.SigningLogs.Header',
                        'Signing logs',
                    )}
                </BlockTitle>
                <StyleListView
                    pagination={false}
                    columns={columns}
                    store={
                        {
                            list: store.applicationContractSigningLogsSortedByDate,
                        } as any
                    }
                />
            </Col>
        </Row>
    );
});

const columns: ColumnsType<ContractSigningLog> = [
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: <Trans i18nKey="Page.Application.List.CreatedAt" />,
        render(value: string) {
            return (
                <DataView value={value} hideLabel format="date-time-seconds" />
            );
        },
    },
    {
        key: 'requestedAt',
        dataIndex: 'requestedAt',
        title: <Trans i18nKey="Page.Application.List.RequestedAt" />,
        render(value: string) {
            return (
                <DataView hideLabel value={value} format="date-time-seconds" />
            );
        },
    },
    {
        key: 'step',
        dataIndex: 'step',
        title: <Trans i18nKey="Page.Application.List.Step" />,
        render(value: string) {
            return (
                <DataView
                    value={value}
                    format="enum"
                    hideLabel
                    formatterProps={{ i18nKey: 'Contract.Signing.Log.Step.' }}
                />
            );
        },
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Page.Application.List.Status" />,
        render(value: number) {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format="enum"
                    formatterProps={{ i18nKey: 'Customer.CB.Logs.Status.' }}
                />
            );
        },
    },
    {
        key: 'errorMessage',
        dataIndex: 'errorMessage',
        title: <Trans i18nKey="Page.Application.List.ErrorMessage" />,
        render(value: number) {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'actions',
        title: <Trans i18nKey="Page.Application.List.Actions" />,
        render(value, { status, id, loanApplicationId }) {
            return (
                <DataView
                    value={
                        status === ContractSigningLogStatusEnum.FAILED ? (
                            <StyledButton
                                onClick={async () => {
                                    if (id && loanApplicationId) {
                                        await store.contractSigningLogRestart(
                                            loanApplicationId,
                                            id,
                                        );
                                    }
                                }}
                            >
                                <Trans i18nKey="Button.Restart" />{' '}
                            </StyledButton>
                        ) : null
                    }
                    hideLabel
                />
            );
        },
    },
];

const StyleListView = styled(ListView)`
    .app-data-view {
        margin-bottom: 0;
    }
`;

const StyledButton = styled(Button)`
    margin-left: 5px;
    border-radius: 50px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledSpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
