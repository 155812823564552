import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { DataFormatterFormat } from 'src/utils/DataFormatterProvider';

const store = LoansStore;
const prefix = 'R';

export const LoanDetails = observer(() => {
    const { t } = useTranslation();

    const currentLoan = store.currentItem?.data;

    return (
        <>
            <Row>
                <BlockTitle>
                    {t('Page.Loans.Details.Title', 'Loan details')}
                </BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.startDate}
                        label={t('Page.Loans.Details.StartDate', 'Start date')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.maturityDate}
                        label={t(
                            'Page.Loans.Details..MaturityDate',
                            'Maturity date',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.interestDaily}
                        label={t(
                            'Page.Loans.Details.FixedDaily',
                            'Fixed daily interest accrual',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="link-internal"
                        formatterProps={{
                            to:
                                currentLoan?.loanApplicationId &&
                                EntityLinks.loanApplication.itemDetails(
                                    currentLoan?.loanApplicationId,
                                ),
                        }}
                        value={currentLoan?.loanApplicationId}
                        label={t(
                            'Page.Loans.Details.Application',
                            'Application',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.productName}
                        label={t(
                            'Page.Loans.Details.LoanProduct',
                            'Loan product',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentLoan?.daysOverdue}
                        label={t(
                            'Page.Loans.Details.OverdueDays',
                            'Overdue days',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.finishedAt}
                        label={t('Page.LoanDetails.ClosingDate')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={
                            currentLoan?.calculationData?.interestRatePercentage
                        }
                        label={t('Page.LoanDetails.30daysRate')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        prefix={
                            currentLoan?.calculationData?.initiationFee
                                ? prefix
                                : undefined
                        }
                        value={currentLoan?.calculationData?.initiationFee}
                        label={t('Page.LoanDetails.InitiationFee')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={
                            currentLoan?.calculationData
                                ?.insuranceRateMultiplierPercentage
                        }
                        label={t(
                            'Page.LoanDetails.InsuranceRateMultiplierPercentage',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        prefix={
                            currentLoan?.calculationData?.monthlyFee
                                ? prefix
                                : undefined
                        }
                        value={currentLoan?.calculationData?.monthlyFee}
                        label={t('Page.LoanDetails.MonthlyFee')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={currentLoan?.calculationData?.vatRatePercentage}
                        label={t('Page.LoanDetails.VatRatePercentage')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={
                            currentLoan?.calculationData
                                ?.penaltyInterestMultiplier
                        }
                        label={t('Page.LoanDetails.PenaltyInterestMultiplier')}
                        format={DataFormatterFormat.numberInfo}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={
                            currentLoan?.calculationData
                                ?.interestRateRestrictionPercentage
                        }
                        label={t(
                            'Page.LoanDetails.InterestRateRestrictionPercentage',
                        )}
                    />
                </Col>
            </Row>
        </>
    );
});
