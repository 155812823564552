import { Col, notification, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { UpdateBackofficeUserForm } from 'src/generated-api-client';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';
import { NumberHelper, PHONE_MASK } from 'src/utils/NumberHelper';

export const EditUserDialog = observer(({ ...props }) => {
    const { t } = useTranslation();
    const { currentItem } = UsersStore;

    return (
        <Modal
            {...props}
            title={t('Page.User.Modal.Edit.Title', 'Edit user')}
            isLoading={UsersStore.updateItemLoader.isLoading}
            storeLoader={UsersStore.updateItemLoader}
            onFinish={async (values: UpdateBackofficeUserForm) => {
                const modifiedValues = {
                    ...values,
                    phone: NumberHelper.formatToPhoneNumber(values.phone),
                };

                await UsersStore.updateItem(
                    Number(currentItem?.data?.id),
                    modifiedValues,
                );

                if (!UsersStore.updateItemLoader.hasError) {
                    notification.success({
                        message: t('Message.Success'),
                    });
                    await UsersStore.loadList();
                    await UsersStore.loadItem(
                        currentItem?.data?.id?.toString(),
                    );
                    Router.navigate(
                        `${RoutePaths.users}/${currentItem?.data?.id}`,
                    );
                }
            }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        name="email"
                        label={t('Page.User.Modal.Edit.Email', 'Email')}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.email,
                        ]}
                        initialValue={currentItem?.data?.email}
                    />
                </Col>

                <Col span={12}>
                    <TextField
                        name="fullName"
                        label={t('Page.User.Modal.Edit.FullName', 'Full name')}
                        rules={[ValidationRules.required, ValidationRules.name]}
                        initialValue={currentItem?.data?.fullName}
                    />
                </Col>

                <Col span={12}>
                    <TextField
                        name="login"
                        label={t('Page.User.Modal.Edit.Login', 'Login')}
                        rules={[ValidationRules.required]}
                        initialValue={currentItem?.data?.login}
                    />
                </Col>

                <Col span={12}>
                    <SelectField
                        store={RolesStore}
                        convertListItemToOption={(role: any) => ({
                            children: role.name,
                            value: role.id,
                        })}
                        name="roleId"
                        initialValue={currentItem?.data?.roleId}
                        label={t('Page.User.Modal.Edit.Role', 'Role')}
                        rules={[ValidationRules.required]}
                    />
                </Col>

                <Col span={12}>
                    <TextField
                        name="phone"
                        label={t('Page.User.Modal.Edit.Phone', 'Phone')}
                        prefix={PHONE_MASK}
                        rules={[
                            ValidationRules.required,
                            ValidationRules.maxPhoneLength,
                            ValidationRules.minPhoneLength,
                        ]}
                        initialValue={currentItem?.data?.phone?.replace(
                            PHONE_MASK,
                            '',
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    );
});

export const EditUserModal = addModalRenderer(EditUserDialog);
