import {
    ApiResponseBodyListSystemOperationType,
    SystemOperationExecuteRequestDTOOperationEnum,
} from 'src/generated-api-client';
import { systemOperationApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class SystemOperationsStoreClass extends BasicStore<
    string,
    SystemOperationExecuteRequestDTOOperationEnum
> {
    api: BasicStoreApi<string, any> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                systemOperationApi.getManualSystemOperations(),
            );

            return (
                Object.values(
                    (result as ApiResponseBodyListSystemOperationType)?.data ||
                        {},
                ).map((item: string) => item) || []
            );
        },
        updateItem: async (
            id,
            operation: SystemOperationExecuteRequestDTOOperationEnum,
        ) => {
            await RequestHelper.unwrapFromAxiosPromise(
                systemOperationApi.executeLaunch({
                    operation,
                }),
            );
        },
    };
}

export const SystemOperationsStore = new SystemOperationsStoreClass();
