import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { useOpenFirstListItem } from 'src/hooks/useOpenFirstListItem';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';

import { LoanEntityPresentation } from './LoanEntityPresentation';
import { LoanHeaderPanel } from './LoanHeaderPanel';
import { LoansListItem } from './LoansListItem';

const store = LoansStore;

export const LoansPage = observer(() => {
    useOpenFirstListItem(store);

    useEffect(() => {
        return () => {
            store.listLoader.reset();
            store.filterCriteria?.resetFilter();
            store.pager?.reset();
        };
    }, []);

    return (
        <MasterDetailsPage store={store} asidePanel={<LoansAsidePanel />}>
            <Switch>
                <Route
                    path={RoutePaths.loansDetails}
                    exact
                    render={() => <LoanEntityPresentation />}
                />
            </Switch>
        </MasterDetailsPage>
    );
});

const LoansAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: any) => {
                return (
                    <List.Item key={item.id}>
                        <LoansListItem item={item} />
                    </List.Item>
                );
            }}
            header={<LoanHeaderPanel />}
        />
    );
});
