import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LoanItem } from 'src/components/LoanItem/LoanItem';
import { Spinner } from 'src/components/Spinner/Spinner';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { LoanViewDTOStatusEnum } from 'src/generated-api-client';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { loanMapColors } from 'src/utils/common-constants';
import styled from 'styled-components';

const store = ApplicationsStore;

export const ApplicationLoans = observer(() => {
    const { t } = useTranslation();
    const { applicationId } = useApplicationId();

    useEffect(() => {
        store.getApplicationLoans(applicationId);

        return () => {
            store.applicationLoansLoader.reset();
        };
    }, [applicationId]);

    return store.applicationLoansLoader.isLoading ? (
        <StyledSpinnerContainer>
            <Spinner />{' '}
        </StyledSpinnerContainer>
    ) : (
        <div>
            {store.applicationLoansSortedByDate?.map((application) => (
                <LoanItem
                    id={application?.id}
                    date={application.startDate}
                    dateText={
                        <Trans
                            i18nKey="Page.Application.Tabs.Loans.StartDate"
                            tOptions={{ id: application.id }}
                        />
                    }
                    linkAddress={EntityLinks.loan.itemDetails(application?.id)}
                    status={t(
                        `Loans.EntityModelLoanViewDTOStatusEnum.Type.${application.status}`,
                    )}
                    statusColor={
                        loanMapColors[
                            application.status as LoanViewDTOStatusEnum
                        ]
                    }
                    isFinished={finishedLoanStatuses.includes(
                        application.status as LoanViewDTOStatusEnum,
                    )}
                    amount={application.initialAmount}
                    period={application.approvedTerm}
                    type={application.type}
                    discloserType={application.disclosure}
                />
            ))}
        </div>
    );
});

const StyledSpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const finishedLoanStatuses = [
    LoanViewDTOStatusEnum.PAIDUP,
    LoanViewDTOStatusEnum.WRITTENOFF,
];
