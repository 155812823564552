import { Col, notification, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal/Modal';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const { Text } = Typography;

export const ActivateLoanProductDialog = observer(({ ...props }) => {
    const { t } = useTranslation();
    const { currentItem } = LoanProductsStore;

    return (
        <Modal
            {...props}
            title={t(
                'Page.LoanProduct.Activate.Modal.Activate',
                'Activate loan product',
            )}
            isLoading={LoanProductsStore.activateItemLoader.isLoading}
            onFinish={async () => {
                await LoanProductsStore.activate(Number(currentItem?.data?.id));

                if (!LoanProductsStore.activateItemLoader.hasError) {
                    notification.success({
                        message: t('Message.Success'),
                    });
                    await LoanProductsStore.loadItem(
                        currentItem?.data?.id?.toString(),
                    );
                    await LoanProductsStore.loadList();
                }
            }}
            okText={t('Button.Confirm', 'Confirm')}
        >
            <Row gutter={16}>
                <Col>
                    <StyledTextSize>
                        {t(
                            'Page.LoanProduct.Activate.Modal.Text',
                            'Do you want activate this product?',
                        )}
                    </StyledTextSize>
                </Col>
            </Row>
        </Modal>
    );
});

export const ActivateLoanProductModal = addModalRenderer(
    ActivateLoanProductDialog,
);

const StyledTextSize = styled(Text)`
    font-size: 19px;
`;
