import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { CustomersStore } from 'src/stores/CustomersStore/CustomersStore';

const store = CustomersStore;

export const AddressDetails = observer(() => {
    const { t } = useTranslation();

    const currentCustomer = store.currentItem?.data;

    return (
        <>
            <Row>
                <BlockTitle>
                    {t('Page.Customer.Address.Title', 'Address')}
                </BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={
                            currentCustomer?.contactDetails?.address?.country
                                ?.name
                        }
                        label={t('Page.Customer.Address.Country', 'COUNTRY')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentCustomer?.contactDetails?.address?.city}
                        label={t('Page.Customer.Address.City', 'CITY')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={
                            currentCustomer?.contactDetails?.address?.province
                                ?.name
                        }
                        label={t('Page.Customer.Address.Province', 'PROVINCE')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentCustomer?.contactDetails?.address?.street}
                        label={t('Page.Customer.Address.Street', 'STREET')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={currentCustomer?.contactDetails?.address?.house}
                        label={t(
                            'Page.Customer.Address.Building',
                            'BUILDING NUMBER',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={
                            currentCustomer?.contactDetails?.address
                                ?.buildingName
                        }
                        label={t(
                            'Page.Customer.Address.BuildingName',
                            'BUILDING NAME',
                        )}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="string"
                        value={
                            currentCustomer?.contactDetails?.address?.postalCode
                        }
                        label={t(
                            'Page.Customer.Address.PostalCode',
                            'POSTAL CODE',
                        )}
                    />
                </Col>
            </Row>
        </>
    );
});
