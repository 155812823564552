import { action, computed, observable } from 'mobx';

export class FilterCriteria<Filter extends Record<string, any>> {
    @observable sort?: string[];
    @observable filter: Filter;

    constructor(private initialFilter: Filter = {} as Filter) {
        this.filter = this.initialFilter;
    }

    @action setFilter(filter: Filter) {
        this.filter = filter;
    }

    @action applyFilter(filter: Filter) {
        this.filter = { ...this.filter, ...filter };
    }

    @action resetFilter() {
        this.filter = {} as Filter;
    }

    @computed get hasFilter() {
        return (
            Object.values(this.filter)
                .map((value) => {
                    if (Array.isArray(value)) {
                        return value.length > 0;
                    }
                    if (typeof value === 'number') {
                        return true;
                    }

                    return Boolean(value);
                })
                .filter(Boolean).length > 0
        );
    }
}
