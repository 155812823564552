import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Brief } from 'src/components/Brief/Brief';
import { EntityActions } from 'src/components/EntityActions/EntityActions';
import { Tabs } from 'src/components/Tabs/Tabs';
import { CustomerVerificationStatus } from 'src/generated-api-client';
import { useCustomerId } from 'src/hooks/useCustomerId';
import { CustomerLoans } from 'src/pages/CustomersPage/TabsContent/CustomerLoans';
import { RepresentativePersonDetails } from 'src/pages/CustomersPage/TabsContent/RepresentativePersonDetails';
import { CustomersStore } from 'src/stores/CustomersStore/CustomersStore';
import { customerMapColors } from 'src/utils/common-constants';
import styled from 'styled-components';

import { SetDeclinedModal } from './Dialogs/SetDeclinedDialog';
import { SetVerifaedModal } from './Dialogs/SetVerifaedModal';
import { AddressDetails } from './TabsContent/AddressDetails';
import { CBLogsTable } from './TabsContent/CBLogsTable';
import { CompanyBankAccount } from './TabsContent/CompanyBankAccount';
import { CompanyDetails } from './TabsContent/CompanyDetails';
import { CompanyDocuments } from './TabsContent/CompanyDocuments';
import { LoanApplications } from './TabsContent/LoanApplications';

const store = CustomersStore;

export const CustomersEntityPresentation = observer(() => {
    const { t } = useTranslation();
    const { customerId } = useCustomerId();

    const currentCustomer = store?.currentItem?.data;

    const TabsContent = [
        {
            tabKey: 'company-details',
            title: t('Customer.Tabs.CompanyDetails', 'Company details'),
            Component: (
                <>
                    <CompanyDetails />
                    <AddressDetails />
                    <RepresentativePersonDetails />
                </>
            ),
        },
        {
            tabKey: 'bank-accounts',
            title: t('Customer.Tabs.BankAccounts', 'Bank accounts'),
            Component: <CompanyBankAccount />,
        },
        {
            tabKey: 'applications',
            title: t('Customer.Tabs.Applications434', 'Applications'),
            Component: <LoanApplications />,
        },
        {
            tabKey: 'companyDocuments',
            title: t('Customer.Tabs.Applications', 'Company documents'),
            Component: <CompanyDocuments />,
        },
        {
            tabKey: 'customerCBoperations',
            title: t('Customer.Tabs.Applications', 'CB operations'),
            Component: <CBLogsTable />,
        },
        {
            tabKey: 'customer-loans',
            title: t('Customer.Tabs.Loans', 'Loans'),
            Component: <CustomerLoans />,
        },
    ];

    useEffect(() => {
        store.loadItem(customerId);
        store.getCustomerCBLogs(Number(customerId));
    }, [customerId]);

    const entityActions = [
        currentCustomer?.verificationStatus ===
        CustomerVerificationStatus.VERIFIED
            ? {
                  key: 'setCustomerDeclined',
                  label: t('Actions.Declined', 'Set as declined'),
                  onClick: () => {
                      SetDeclinedModal.render();
                  },
              }
            : null,
        currentCustomer?.verificationStatus ===
        CustomerVerificationStatus.DECLINED
            ? {
                  key: 'setCustomerVerified',
                  label: t('Actions.Verified', 'Set as verified'),
                  onClick: () => {
                      SetVerifaedModal.render();
                  },
              }
            : null,
        currentCustomer?.verificationStatus ===
        CustomerVerificationStatus.INREVIEW
            ? {
                  key: 'setCustomerDeclined',
                  label: t('Actions.Declined', 'Set as declined'),
                  onClick: () => {
                      SetDeclinedModal.render();
                  },
              }
            : null,
        currentCustomer?.verificationStatus ===
        CustomerVerificationStatus.INREVIEW
            ? {
                  key: 'setCustomerVerified',
                  label: t('Actions.Verified', 'Set as verified'),
                  onClick: () => {
                      SetVerifaedModal.render();
                  },
              }
            : null,
    ];

    return (
        <MainContainer>
            <ColoredHeader align="middle" justify="space-between">
                <Col flex="auto">
                    <TextBold>
                        {`#${currentCustomer?.id}`} {currentCustomer?.legalName}
                    </TextBold>
                </Col>
                <Col>
                    {currentCustomer?.verificationStatus && (
                        <StyledStatusWrapper
                            status={currentCustomer?.verificationStatus}
                        >
                            {t(
                                `Customer.VerificationStatus.${currentCustomer?.verificationStatus}`,
                            )}
                        </StyledStatusWrapper>
                    )}
                </Col>
                {[
                    CustomerVerificationStatus.VERIFIED,
                    CustomerVerificationStatus.DECLINED,
                    CustomerVerificationStatus.INREVIEW,
                ].includes(
                    currentCustomer?.verificationStatus as CustomerVerificationStatus,
                ) && (
                    <Col>
                        <EntityActions items={entityActions} />
                    </Col>
                )}
            </ColoredHeader>
            <Brief>
                <Tabs tabs={TabsContent} />
            </Brief>
        </MainContainer>
    );
});

const MainContainer = styled.div`
    margin: 60px 30px 0;
`;

const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    height: 120px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
`;

const TextBold = styled.span`
    font-weight: 600;
    font-size: 29px;
`;

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) =>
        customerMapColors[props?.status as keyof typeof customerMapColors]};
    border-radius: 5px;
    font-size: 15px;
    height: 35px;
    line-height: 22px;
    text-decoration: capitalize;
    color: #000000;
`;
