const roleHasAuthorities = (
    userAuthorities: string[],
    permittedAuthorities: string[],
) => {
    return userAuthorities.some((item) => permittedAuthorities.includes(item));
};

export const AuthoritiesHelper = {
    roleHasAuthorities,
};
