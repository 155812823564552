import { Table, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { LoanApplicationFinancedItemDTO } from 'src/generated-api-client';
import { DateHelper } from 'src/utils/DateHelper';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const { Text } = Typography;

const StyledTable = styled(Table)`
    margin-top: 10px;
    .ant-table-thead {
        .ant-table-cell {
            background: transparent;
            font-weight: 600;
        }
        .ant-table-cell::before {
            display: none;
        }
    }
    .ant-table-tbody {
        .ant-table-cell {
            color: #000000;
            font-size: 15px;
        }
    }
    .ant-table-summary {
        background: #bfbfbf5c;
    }
`;

const columns = [
    {
        title: (
            <Trans
                i18nKey="Page.Application.FinancedTable.LineNumber"
                defaults="Line Number"
            />
        ),
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Application.FinancedTable.DocumentNumber"
                defaults="Document number"
            />
        ),
        dataIndex: 'itemNumber',
        key: 'itemNumber',
    },
    {
        title: (
            <Trans
                i18nKey="Page.Application.FinancedTable.DocumentOriginationDate"
                defaults="Document origination date"
            />
        ),
        dataIndex: 'originationDate',
        key: 'originationDate',
        render: (date: any) => <>{DateHelper.format(date)}</>,
    },
    {
        title: (
            <Trans
                i18nKey="Page.Application.FinancedTable.OriginalPrice"
                defaults="Original price (amount without VAT)"
            />
        ),
        dataIndex: 'amount',
        key: 'amount',
        render: (amount: any) => (
            <>{NumberHelper.formatThousandSeparator(amount, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Application.FinancedTable.CreditNoteAmount"
                defaults="Credit Note amount"
            />
        ),
        dataIndex: 'creditNote',
        key: 'creditNote',
        render: (creditNote: any) => (
            <>{NumberHelper.formatThousandSeparator(creditNote, 2)}</>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Application.FinancedTable.TotalAmount"
                defaults="Total amount"
            />
        ),
        dataIndex: 'totalItemAmount',
        key: 'totalItemAmount',
        render: (_: any, row: any) => (
            <>
                {NumberHelper.formatThousandSeparator(
                    row.amount - row.creditNote,
                    2,
                )}
            </>
        ),
    },
    {
        title: (
            <Trans
                i18nKey="Page.Application.FinancedTable.DocumentMaturityDate"
                defaults="Document maturity date"
            />
        ),
        dataIndex: 'maturityDate',
        key: 'maturityDate',
        render: (date: any) => <>{DateHelper.format(date)}</>,
    },
];

export const FinancedDocumentsDetails = observer(
    ({ dataSource }: { dataSource: LoanApplicationFinancedItemDTO[] }) => {
        return (
            <StyledTable
                pagination={false}
                columns={columns}
                dataSource={dataSource}
                summary={(pageData) => {
                    let totalAmount = 0;
                    let totalCreditNote = 0;
                    let totalItemsAmount = 0;
                    pageData.forEach(({ amount, creditNote }: any) => {
                        totalAmount += amount;
                        totalCreditNote += creditNote;
                        totalItemsAmount += amount - creditNote;
                    });

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={1}>
                                <Text strong>
                                    <Trans
                                        i18nKey="Page.Application.FinancedTable.Total"
                                        defaults="Total"
                                    />
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} />
                            <Table.Summary.Cell index={3} />
                            <Table.Summary.Cell index={4}>
                                <Text strong>
                                    {NumberHelper.formatThousandSeparator(
                                        totalAmount,
                                        2,
                                    )}
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>
                                <Text strong>
                                    {NumberHelper.formatThousandSeparator(
                                        totalCreditNote,
                                        2,
                                    )}
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6}>
                                <Text strong>
                                    {NumberHelper.formatThousandSeparator(
                                        totalItemsAmount,
                                        2,
                                    )}
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={7} />
                        </Table.Summary.Row>
                    );
                }}
            />
        );
    },
);
