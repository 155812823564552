import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { useOpenFirstListItem } from 'src/hooks/useOpenFirstListItem';
import { LoanProductsStore } from 'src/stores/LoanProductsStore/LoanProductsStore';

import { LoanProductHeaderPanel } from './LoanProductHeaderPanel';
import { LoanProductsEntityPresentation } from './LoanProductsEntityPresentation';
import { LoanProductsListItem } from './LoanProductsListItem';

const store = LoanProductsStore;

export const LoanProductsPage = observer(() => {
    useOpenFirstListItem(store);

    useEffect(() => {
        return () => {
            store.listLoader.reset();
            store.filterCriteria?.resetFilter();
            store.pager?.reset();
        };
    }, []);

    return (
        <MasterDetailsPage
            store={store}
            asidePanel={<LoanProductsAsidePanel />}
        >
            <Switch>
                <Route
                    path={RoutePaths.loanProductsDetails}
                    exact
                    render={() => <LoanProductsEntityPresentation />}
                />
            </Switch>
        </MasterDetailsPage>
    );
});

const LoanProductsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: any) => {
                return (
                    <List.Item key={item.id}>
                        <LoanProductsListItem item={item} />
                    </List.Item>
                );
            }}
            header={<LoanProductHeaderPanel />}
        />
    );
});
