import { Button, Form, Input, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'src/components/Form/SelectField';
import { UserStatus } from 'src/generated-api-client';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';
import { UsersStore } from 'src/stores/UsersStore/UsersStore';
import { FormHelper } from 'src/utils/FormHelper';
import styled from 'styled-components';

import { FilterBlock } from '../FilterPanel/FilterBlock';
import { SearchPanelFilterForm } from '../FilterPanel/SearchPanelFilterForm';
import { AddUserModal } from './Dialogs/CreateUserDialog';

const store = UsersStore;

export const UsersHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <FilterBlock
                store={store}
                placeholder={t('AsideHeader.Placeholder.Search')}
                renderPanel={(props: any) => <FilterPanel {...props} />}
            />
            <StyledCreateButton
                type="ghost"
                onClick={() => {
                    AddUserModal.render();
                }}
            >
                {t('Action.Button.CreateNew')}
            </StyledCreateButton>
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: flex;
`;

const StyledCreateButton = styled(Button)`
    width: 100%;
    min-width: 50px;
    height: 32px;
    margin-left: 5px;
    border-radius: 10px;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const FilterPanel = observer((props: any) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{}}
            onFinish={({ active, ...values }: any) => {
                store.filterCriteria?.applyFilter({
                    ...values,
                });
                store.pager?.resetPage();
                store.loadList();
            }}
        >
            <Form.Item
                label={t('Page.Users.Filter.Id', {
                    defaultValue: 'ID',
                })}
                name="id"
            >
                <StyledInput type="string" />
            </Form.Item>
            <StyledSelect
                label={t('Page.Users.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="statuses"
            >
                <Select
                    allowClear
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    <Select.Option value={UserStatus.ACTIVE}>
                        {t(`data:status.users.ACTIVE`, 'Active')}
                    </Select.Option>
                    <Select.Option value={UserStatus.INACTIVE}>
                        {t(`data:status.users.INACTIVE`, 'Inactive')}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <SelectField
                store={RolesStore}
                convertListItemToOption={(role: any) => ({
                    children: role.name,
                    value: role.id,
                })}
                filters={{
                    count: 200,
                }}
                name="roles"
                label={t('Page.Users.Filter.Role', 'Role')}
            />
            <Form.Item
                label={t('Page.Users.Filter.Email', {
                    defaultValue: 'Email',
                })}
                name="email"
            >
                <StyledInput type="string" />
            </Form.Item>
            <Form.Item
                label={t('Page.Users.Filter.FullName', {
                    defaultValue: 'Full name',
                })}
                name="fullName"
            >
                <StyledInput type="string" />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 6px;
        .ant-select-selection-search {
            display: flex;
            align-items: center;
        }
    }
`;

const StyledInput = styled(Input)`
    height: 40px;
    border-radius: 6px;
`;
