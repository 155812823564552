/* eslint-disable unicorn/no-abusive-eslint-disable */
import React, { useMemo } from 'react';
import {
    DataFormatterFormats,
    DataFormatterProvider,
} from 'src/utils/DataFormatterProvider';
import styled from 'styled-components';

export type DataViewProps = {
    value: React.ReactNode | boolean;
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    hideLabel?: boolean;
    // eslint-disable-next-line react/require-default-props
    format?:
        | DataFormatterFormats
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        | ((value: any, options: any) => React.ReactNode);
    // eslint-disable-next-line react/require-default-props
    formatterProps?: any;
    // eslint-disable-next-line react/require-default-props
    suffix?: string;
    // eslint-disable-next-line react/require-default-props
    prefix?: string;
};

const LONG_DASH = '—';

export const DataView = ({
    label,
    hideLabel,
    value,
    format,
    formatterProps,
    suffix,
    prefix,
}: DataViewProps) => {
    const formatter = useMemo(() => {
        if (typeof format === 'function') {
            return (value: any, options: any) => format(value, options);
        }
        const targetFormat = format || DataFormatterProvider.guessFormat(value);

        return DataFormatterProvider.getFormatter(targetFormat);
    }, [format, value]);

    const hasValue =
        (value !== null && value !== undefined && value !== '') ||
        (typeof value === 'number' && Number.isFinite(Number(value)));

    return (
        <StyledDataViewWrapper className="app-data-view">
            {!hideLabel && <StyledLabel>{label || <>&nbsp;</>}:</StyledLabel>}
            <StyledValue>
                {prefix}{' '}
                {hasValue ? formatter(value, formatterProps) : LONG_DASH}{' '}
                {suffix}
            </StyledValue>
        </StyledDataViewWrapper>
    );
};

const StyledDataViewWrapper = styled.div`
    position: relative;
    margin-bottom: 27px;

    label {
        word-break: break-word;
        color: gray;
        font-size: 1rem;
    }

    a {
        cursor: pointer;
    }
`;

const StyledLabel = styled.div`
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 1.1rem;
    text-transform: uppercase;
`;

const StyledValue = styled.div`
    color: #646464;
    font-size: 1rem;
    word-break: break-all;
    white-space: pre-line;
    width: -webkit-fill-available;
`;
