import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';

const fieldsRenderData = [
    {
        name: 'currentQuarter',
        validation: [ValidationRules.decimalNumber, ValidationRules.required],
    },
    {
        name: 'previousQuarter',
        validation: [ValidationRules.decimalNumber, ValidationRules.required],
    },
    {
        name: 'threeQuarterBefore',
        validation: [ValidationRules.decimalNumber],
    },
    {
        name: 'twoQuarterBefore',
        validation: [ValidationRules.decimalNumber],
    },
];

export const FieldRenderComponent = ({
    parentObject,
}: {
    parentObject: string;
}) => (
    <>
        {fieldsRenderData.map((fieldItem) => (
            <TextField
                name={[parentObject, fieldItem.name]}
                rules={fieldItem.validation}
                key={`${new Date().getMilliseconds()}${Math.random()}`}
            />
        ))}
    </>
);
