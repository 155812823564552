import { RoutePaths } from 'src/core/router/RoutePaths';
import { StartPage } from 'src/generated-api-client';

export const getStartPage = (page: StartPage) => {
    let pageData;
    switch (page) {
        case StartPage.USERS:
            pageData = {
                name: 'Users',
                route: RoutePaths.users,
            };
            break;
        case StartPage.LOANS:
            pageData = {
                name: 'Loans',
                route: RoutePaths.loans,
            };
            break;
        case StartPage.ROLES:
            pageData = {
                name: 'Roles',
                route: RoutePaths.roles,
            };
            break;
        case StartPage.LOANPRODUCTS:
            pageData = {
                name: 'Loan products',
                route: RoutePaths.loanProducts,
            };
            break;
        case StartPage.CUSTOMERS:
            pageData = {
                name: 'Customers',
                route: RoutePaths.customers,
            };
            break;
        case StartPage.PAYMENTS:
            pageData = {
                name: 'Payments',
                route: RoutePaths.importData,
            };
            break;
        case StartPage.NOTIFICATIONS:
            pageData = {
                name: 'Notifications',
                route: RoutePaths.notifications,
            };
            break;
        case StartPage.APPLICATIONS:
            pageData = {
                name: 'Applications',
                route: RoutePaths.applications,
            };
            break;
        default:
            pageData = {
                name: 'Index',
                route: RoutePaths.index,
            };
    }

    return pageData;
};
