/* eslint-disable max-lines */
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { AttachmentsFormat } from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    ApiResponseBodyLoanApplicationViewDTO,
    ApprovedConditionForm,
    ContractSigningLog,
    CreditCommitteeReviewInfoDTO,
    DocumentDTO,
    DocumentDTODocumentTypeEnum,
    DocumentDTOFileTypeEnum,
    EntityModelLoanApplicationListDTO,
    FinancialFiguresForm,
    LoanApplicationDisclosure,
    LoanApplicationStatus,
    LoanApplicationTerm,
    LoanApplicationType,
    LoanApplicationViewDTO,
    LoanViewDTO,
    RequestInformationForm,
} from 'src/generated-api-client';
import {
    applicationsDocuments,
    loanApplicationsApi,
} from 'src/services/apiServices';
import { DateHelper, DEFAULT_DATE_TIME_FORMAT } from 'src/utils/DateHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type ApplicationsFilter = {
    id?: string;
    customerLegalName?: string;
    customerCipc?: string;
    statuses?: LoanApplicationStatus[];
    types?: LoanApplicationType[];
    disclosures?: LoanApplicationDisclosure[];
    submitDateFrom?: string;
    submitDateTo?: string;
    approvedAmountFrom?: number;
    approvedAmountTo?: number;
    approvedTerms?: LoanApplicationTerm[];
    loanProducts?: number[];
};

export class ApplicationsStoreClass extends BasicStore<
    EntityModelLoanApplicationListDTO,
    ApplicationsFilter,
    undefined,
    undefined,
    ApiResponseBodyLoanApplicationViewDTO
> {
    @observable private applicationDocumentsData?: DocumentDTO[] = [];
    @observable private ccReviewData?: CreditCommitteeReviewInfoDTO;
    @observable private applicationLoansData?: LoanViewDTO[];
    @observable private contractSigningLogsData?: ContractSigningLog[];

    api: BasicStoreApi<
        LoanApplicationViewDTO,
        undefined,
        undefined,
        ApiResponseBodyLoanApplicationViewDTO
    > = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.entityList11(
                    this.filterCriteria.filter.id,
                    this.filterCriteria.filter.customerLegalName,
                    this.filterCriteria.filter.customerCipc,
                    this.filterCriteria.filter.statuses,
                    this.filterCriteria.filter.types,
                    this.filterCriteria.filter.disclosures,
                    this.filterCriteria.filter.submitDateFrom &&
                        DateHelper.format(
                            this.filterCriteria.filter.submitDateFrom,
                        ),
                    this.filterCriteria.filter.submitDateTo &&
                        DateHelper.format(
                            this.filterCriteria.filter.submitDateTo,
                        ),
                    Number(this.filterCriteria.filter.approvedAmountFrom),
                    Number(this.filterCriteria.filter.approvedAmountTo),
                    this.filterCriteria.filter.approvedTerms,
                    this.filterCriteria.filter.loanProducts,
                    this.pager?.page,
                    this.pager?.size,
                    undefined,
                    undefined,
                    undefined,
                    'DESC',
                ),
            );

            this.pager?.setTotal(result.page?.totalElements as number);

            return result.content || [];
        },

        loadItem: (id: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.entityView11(Number(id)),
            );
        },

        activate: async (id) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.approveByReviewer(id),
            );
        },

        deactivate: async (id) => {
            await RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.declineByReviewer(id),
            );
        },
    };

    generateLoanApplicationPdfLoader = new AsyncOperationWithStatus(
        (id: string | number) =>
            RequestHelper.unwrapFromAxiosPromise(
                applicationsDocuments.generateLoanApplicationPdf(Number(id), {
                    responseType: 'arraybuffer',
                }),
            ),
    );

    recreateLoansLoader = new AsyncOperationWithStatus((id: string) =>
        RequestHelper.unwrapFromAxiosPromise(
            loanApplicationsApi.createLoansFromApplication(Number(id)),
        ),
    );

    applicationLoansLoader = new AsyncOperationWithStatus(
        async (id: number) => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.getLoans1(id),
            );

            this.applicationLoansData = result.data;
        },
    );

    applicationContractSigningLogsLoader = new AsyncOperationWithStatus(
        (id: string) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.contractSigningLogs(Number(id)),
            ),
    );

    contractSigningLogRestartAction = new AsyncOperationWithStatus(
        (applicationId: number, logId: number) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.contractSigningLogRestart(
                    applicationId,
                    logId,
                ),
            ),
    );

    applicationCCApproveAction = new AsyncOperationWithStatus(
        (id: number, data: ApprovedConditionForm) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.approveByCreditCommittee(Number(id), data),
            ),
    );

    applicationUpdateCommentAction = new AsyncOperationWithStatus(
        (id: string, data: string) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.updateComment(data, Number(id)),
            ),
    );

    applicationCCDeclineAction = new AsyncOperationWithStatus((id: number) =>
        RequestHelper.unwrapFromAxiosPromise(
            loanApplicationsApi.declineByCreditCommittee(Number(id)),
        ),
    );

    applicationDocuments = new AsyncOperationWithStatus((id: number) =>
        RequestHelper.unwrapFromAxiosPromise(
            applicationsDocuments.getDocumentForApplication1(id),
        ),
    );

    downloadDocumentsAction = new AsyncOperationWithStatus((id: number) =>
        RequestHelper.unwrapFromAxiosPromise(
            applicationsDocuments.downloadApplicationDocument(id, {
                responseType: 'arraybuffer',
            }),
        ),
    );

    calculateRatioAction = new AsyncOperationWithStatus(
        (id: number, financialFiguresForm: FinancialFiguresForm) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.calculateAndSetRatio(
                    id,
                    financialFiguresForm,
                ),
            ),
    );

    getCCReviewAction = new AsyncOperationWithStatus((id: number) =>
        RequestHelper.unwrapFromAxiosPromise(
            loanApplicationsApi.initCreditCommitteeReview(id),
        ),
    );

    requestAdditionalInformationAction = new AsyncOperationWithStatus(
        (id: number, requestInformationForm: RequestInformationForm) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.requestAdditionalInformation(
                    id,
                    requestInformationForm,
                ),
            ),
    );

    @action async recreateLoans(id: string) {
        await this.recreateLoansLoader.call(id);
        if (!this.recreateLoansLoader.hasError) {
            await this.loadItem(id);
        }
    }

    @action async generateLoanApplicationPdf(id: string | number) {
        const res = await this.generateLoanApplicationPdfLoader.call(
            Number(id),
        );

        const file = window.URL.createObjectURL(
            new Blob([res], {
                type: AttachmentsFormat.pdf,
            }),
        );
        const link = document.createElement('a');
        link.href = file;
        const fileName = `loan application #${id}_${moment()
            .format(DEFAULT_DATE_TIME_FORMAT)
            .toString()}`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    @action getApplicationLoans(id: string | number) {
        return this.applicationLoansLoader.call(Number(id));
    }

    @action async loadApplicationContractSigningLogs(id: string) {
        const res = await this.applicationContractSigningLogsLoader.call(id);

        this.contractSigningLogsData = res?.data;
    }

    @action async requestAdditionalInformation(
        id: number,
        requestInformationForm: RequestInformationForm,
    ) {
        await this.requestAdditionalInformationAction.call(
            id,
            requestInformationForm,
        );
    }

    @action async updateApplicationComment(id: string, data: string) {
        await this.applicationUpdateCommentAction.call(id, data);
        if (!this.applicationUpdateCommentAction.hasError) {
            this.loadItem(id);
        }
    }

    @action async calculateRatio(
        id: number,
        financialFiguresForm: FinancialFiguresForm,
    ) {
        await this.calculateRatioAction.call(id, financialFiguresForm);
    }

    @action async getCCReview(id: number) {
        const res = await this.getCCReviewAction.call(id);
        if (!this.getCCReviewAction.hasError) {
            this.ccReviewData = res?.data;
        }
    }

    @action async applicationCCDecline(id: string) {
        await this.applicationCCDeclineAction.call(Number(id));
        if (!this.applicationCCDeclineAction.hasError) {
            Router.navigate(`${RoutePaths.applications}/${id}`);
            this.loadList();
        }
    }

    @action async applicationCCApprove(
        id: string,
        data: ApprovedConditionForm,
    ) {
        await this.applicationCCApproveAction.call(Number(id), data);
        if (!this.applicationCCDeclineAction.hasError) {
            Router.navigate(`${RoutePaths.applications}/${id}`);
            this.loadList();
        }
    }

    @action async downloadDocuments(
        id: number,
        type: DocumentDTOFileTypeEnum,
        fileName: string,
    ) {
        const res = await this.downloadDocumentsAction.call(id);
        const file = window.URL.createObjectURL(
            new Blob([res], {
                type: AttachmentsFormat[
                    type.toLocaleLowerCase() as keyof typeof AttachmentsFormat
                ],
            }),
        );
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    @action async loadDocuments(id: number) {
        const result = await this.applicationDocuments.call(id);
        this.applicationDocumentsData = result?.data;
    }

    @computed get applicationLoansSortedByDate() {
        return this.applicationLoansData?.sort((a, b) => {
            if (
                moment(b.startDate).valueOf() ===
                    moment(a.startDate).valueOf() &&
                b?.id &&
                a?.id
            ) {
                return b.id - a.id;
            }

            return (
                moment(b.startDate).valueOf() - moment(a.startDate).valueOf()
            );
        });
    }

    @action async contractSigningLogRestart(
        applicationId: number,
        logId: number,
    ) {
        await this.contractSigningLogRestartAction.call(applicationId, logId);
        if (!this.contractSigningLogRestartAction.hasError) {
            await this.loadItem(String(applicationId));
        }
    }

    @computed get ccReview() {
        return this.ccReviewData;
    }

    @computed get document() {
        return this.applicationDocuments;
    }

    @computed get applicationBankStatement() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONBANKSTATEMENT,
        );
    }

    @computed get applicationTaxInvoice() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONTAXINVOICE,
        );
    }

    @computed get applicationContract() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONINVOICECONTRACT,
        );
    }

    @computed get customerAging() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONACCOUNTSAGING,
        );
    }

    @computed get applicationDebtorsContract() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONDEBTORSCONTRACT,
        );
    }

    @computed get applicationFinancialStatement() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONFINANCIALSTATEMENT,
        );
    }

    @computed get applicationPurcheseOrder() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONPURCHASEORDER,
        );
    }

    @computed get applicationPurcheseOrderContract() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONPURCHASEORDERCONTRACT,
        );
    }

    @computed get applicationProfitAndLossBudget() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONPROFITANDLOSSBUDGET,
        );
    }

    @computed get applicationThirdPartyProofLetter() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONTHIRDPARTYPROOFLETTER,
        );
    }

    @computed get applicationSignedAgreement() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONMAINCONTRACT,
        );
    }

    @computed get applicationContractSigningLogsSortedByDate() {
        return this.contractSigningLogsData?.sort((a, b) => {
            return (
                moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
            );
        });
    }

    @computed get customerQuotationDocs() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONQUOTATION,
        );
    }

    @computed get applicationDeliveryNote() {
        return this.applicationDocumentsData?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONDELIVERYNOTE,
        );
    }

    filterCriteria = new FilterCriteria<ApplicationsFilter>();

    pager?: Pager | undefined = new Pager();
}

export const ApplicationsStore = new ApplicationsStoreClass();
