import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { useOpenFirstListItem } from 'src/hooks/useOpenFirstListItem';
import { RolesEntityPresentation } from 'src/pages/RolesPage/RolesEntityPresentation';
import { RolesHeaderPanel } from 'src/pages/RolesPage/RolesHeaderPanel';
import { RolesListItem } from 'src/pages/RolesPage/RolesListItem';
import { RolesStore } from 'src/stores/RolesStore/RolesStore';

const store = RolesStore;

export const RolesPage = observer(() => {
    const { path } = useRouteMatch();
    useOpenFirstListItem(store);

    useEffect(() => {
        return () => {
            store.listLoader.reset();
            store.filterCriteria?.resetFilter();
            store.pager?.reset();
        };
    }, []);

    return (
        <MasterDetailsPage store={store} asidePanel={<RolesAsidePanel />}>
            <Switch>
                <Route
                    path={`${path}/:roleId`}
                    exact
                    render={() => <RolesEntityPresentation />}
                />
            </Switch>
        </MasterDetailsPage>
    );
});

const RolesAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: any) => {
                return (
                    <List.Item key={item.id}>
                        <RolesListItem item={item} />
                    </List.Item>
                );
            }}
            header={<RolesHeaderPanel />}
        />
    );
});
