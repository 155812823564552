import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { FormHelper } from 'src/utils/FormHelper';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

const { Option } = Select;

// eslint-disable-next-line react/require-default-props
export type SelectFieldProps = FormItemProps &
    // eslint-disable-next-line react/require-default-props
    Pick<SelectProps, 'allowClear' | 'mode' | 'disabled'> & {
        // eslint-disable-next-line react/require-default-props
        options?: OptionProps[];
        store?: BasicStore<any>;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        convertListItemToOption?: (item?: any) => OptionProps;
        data?: any[];
        getData?: () => Promise<any>;
        onChange?: (_arg: string) => void;
        filters?: { [key: string]: any } | number;
    };

export const SelectField = observer(
    ({
        name,
        label,
        options,
        allowClear = true,
        mode,
        disabled,
        store,
        convertListItemToOption,
        data,
        getData,
        filters,
        onChange,
        ...props
    }: SelectFieldProps) => {
        useEffect(() => {
            if (store && !store.listLoader.isLoaded) {
                if (filters) {
                    store.filterCriteria?.applyFilter(filters);
                }
                store.loadList(filters as number);
                if (!convertListItemToOption) {
                    console.error(
                        'No callback function getOptionsList. Please use the function to render options',
                    );
                }
            } else {
                getData?.();
            }
        }, [filters]);

        const optionsListFromStore: OptionProps[] =
            store?.list.length && convertListItemToOption
                ? store?.list.map(convertListItemToOption)
                : [];

        const optionListFromData: OptionProps[] =
            data && convertListItemToOption
                ? data?.map(convertListItemToOption)
                : [];

        const optionsList: OptionProps[] = data
            ? optionListFromData
            : optionsListFromStore;

        return (
            <StyledSelect {...props} name={name} label={label}>
                <Select
                    loading={store?.listLoader.isLoading}
                    allowClear={allowClear}
                    mode={mode}
                    disabled={disabled}
                    onChange={onChange}
                    showSearch
                    filterOption={FormHelper.filterOptionsForSelect}
                >
                    {(options || optionsList)?.map((option: OptionProps) => (
                        <Option key={option.value} value={option.value}>
                            {option.children}
                        </Option>
                    ))}
                </Select>
            </StyledSelect>
        );
    },
);

export const getOptionsFromEnum = (
    i18nKeyPrefix: string,
    values: Record<string, any>,
): OptionProps[] => {
    return Object.values(values).map((value) => ({
        value,
        children: <Trans i18nKey={`${i18nKeyPrefix}.${value}`} />,
    }));
};

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        min-height: 40px;
        max-height: auto;
        border-radius: 6px;
        border-color: #000000;
        .ant-select-selection-search {
            display: flex;
            align-items: center;
        }
    }
`;
